import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Header from "components/Headers/Header";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from 'react-cookie';
import moment from "moment";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import "./property.css"
import axiosInstance from "axiosInstance";
const PropertyType = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id, admin } = useParams();
  let [propertyData, setPropertyData] = useState([]);
  let navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");

  let [loader, setLoader] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const toggle3 = () => setSearch((prevState) => !prevState);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [accessType, setAccessType] = useState(null);
  const [cookies] = useCookies(['token']);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/admin/token_check`, {
          token: cookies.token
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  const getPropertyData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/propertytype/property_type/${accessType?.admin_id}`
        );
        setLoader(false);
        setPropertyData(response.data.data);
        setTotalPages(Math.ceil(response.data.data.length / pageItem));
      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    }
  };

  // Delete selected
  const deleteProperty = (id) => {
    // Show a confirmation dialog to the user
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this property!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance
          .delete(`/propertytype/property_type/${id}`)

          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Property Type Deleted Successfully!", {
                position: "top-center",
                autoClose: 500,
              });
              getPropertyData();
            } else if (response.data.statusCode === 201) {
              // Handle the case where property is already assigned

              toast.warning(
                "Property Type already assigned. Deletion not allowed.",
                {
                  position: "top-center",
                  autoClose: 500,
                }
              );
            } else {
              toast.error(response.data.message, {
                position: "top-center",
                autoClose: 500,
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting property:", error);
          });
      } else {
        toast.success("property is safe :)", {
          position: "top-center",
          autoClose: 500,
        });
      }
    });
  };

  useEffect(() => {
    getPropertyData();
  }, [accessType, pageItem]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (propertyData) {
    paginatedData = propertyData?.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const editPropertyType = (id) => {
    navigate(`/${admin}/AddPropertyType/${id}`);
  };

  const filterPropertyBySearch = () => {
    let filteredData = propertyData;

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((property) => {
        const isPropertyTypeMatch = property.property_type
          .toLowerCase()
          .includes(lowerCaseSearchQuery);
        const isPropertySubTypeMatch = property.propertysub_type
          .toLowerCase()
          .includes(lowerCaseSearchQuery);
        return isPropertyTypeMatch || isPropertySubTypeMatch;
      });
    }
    if (searchQuery2) {
      if (searchQuery2 === "All") {
        return filteredData;
      }
      const lowerCaseSearchQuery = searchQuery2.toLowerCase();
      filteredData = filteredData.filter((property) => {
        const isPropertyTypeMatch = property.property_type
          .toLowerCase()
          .includes(lowerCaseSearchQuery);
        const isPropertySubTypeMatch = property.propertysub_type
          .toLowerCase()
          .includes(lowerCaseSearchQuery);
        return isPropertyTypeMatch || isPropertySubTypeMatch;
      });
    }

    if (upArrow.length > 0) {
      const sortingArrows = upArrow.length > 0 ? upArrow : null;
      sortingArrows.forEach((sort) => {
        switch (sort) {
          case "propertysub_type":
            filteredData.sort((a, b) => {
              const comparison = a.propertysub_type?.localeCompare(
                b.propertysub_type
              );
              return upArrow.includes("propertysub_type")
                ? comparison
                : -comparison;
            });
            break;
          case "property_type":
            filteredData.sort((a, b) => {
              const comparison = a.property_type?.localeCompare(b.property_type);
              return upArrow.includes("property_type")
                ? comparison
                : -comparison;
            });
            break;
          case "createdAt":
            filteredData.sort((a, b) => {
              const comparison = new Date(a.createdAt) - new Date(b.createdAt);
              return upArrow.includes("createdAt") ? comparison : -comparison;
            });
            break;
          default:
            // If an unknown sort option is provided, do nothing
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterPropertyBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
  };

  useEffect(() => {
    // setLoader(false);
    // filterRentalsBySearch();
    getPropertyData();
  }, [upArrow, sortBy]);

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem", }}>
        <Row>
          <Col xs="12" lg="12" sm="12" className="text-right">
            <Button className="btnaddproptype"
              // color="primary"
              //  href="#rms"
              onClick={() => navigate("/" + admin + "/AddPropertyType")}
              size="small"
              style={{ background: "#152B51", color: "#fff", fontSize: "16px", fontWeight: "500", fontFamily: "Poppins", boxShadow: "0px 4px 4px 0px #00000040", borderRadius: "4px", border: "0" }}
            >
              Add Property Type
            </Button>
          </Col>
          <Col xs="12" lg="12" sm="12">
            {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Property Type</h1>
            </FormGroup> */}
            <CardHeader
              className=" mt-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Property Type
              </h2>
            </CardHeader>
          </Col>

        </Row>
        <br />
        {/* Table */}
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                {/* <Card className="shadow"> */}
                {/* <CardHeader className="border-0 p-0"> */}
                <Row className="d-flex ml-0 mb-3" >
                  <FormGroup className="mr-sm-2  searchstyle" style={{ paddingRight: "10px" }}>
                    <Input
                      className="filterh titleecolor"
                      // fullWidth
                      type="text"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setSearchQuery2("");
                      }}
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        minWidth: "200px",
                        fontWeight: "500",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        border: "1px solid #ced4da",
                        // color:"rgb(73 81 96)",
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mr-sm-2">
                    <Dropdown isOpen={search} toggle={toggle3}>
                      <DropdownToggle className="selecttypee typeselectprop titleecolor"
                        caret
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                          maxWidth: "200px",
                          minWidth: "200px",
                          backgroundColor: "transparent",
                          // color: "rgb(73 81 96)"
                        }}
                      >
                        {searchQuery2
                          ? searchQuery
                            ? "Select Type"
                            : searchQuery2
                          : "Select Type"}
                      </DropdownToggle>
                      <DropdownMenu className="dropdownmeunprop">
                        <DropdownItem className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("Residential");
                            setSearchQuery("");
                          }}
                        >
                          Residential
                        </DropdownItem>
                        <DropdownItem className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("Commercial");
                            setSearchQuery("");
                          }}
                        >
                          Commercial
                        </DropdownItem>
                        <DropdownItem className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("All");
                            setSearchQuery("");
                          }}
                        >
                          All
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Row>
                {/* </CardHeader> */}
                <Table className="align-items-center table-flush" responsive style={{ borderCollapse: "collapse" }}>
                  <thead className="" style={{
                    height: "45px",
                    fontSize: "14px",
                    fontFamily: "poppins",
                    fontWeight: "600",
                    boxShadow: " 0px 4px 4px 0px #00000040",
                  }}>
                    <tr style={{
                      border: "2px solid rgba(50, 69, 103, 1)",
                    }}>

                      <th className="tablefontstyle" scope="col" style={{
                        borderTopLeftRadius: "15px",

                        color: "#152B51"
                      }}>
                        Main Type
                        {sortBy.includes("property_type") ? (
                          upArrow.includes("property_type") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("property_type")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("property_type")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("property_type")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>
                        Sub Type
                        {sortBy.includes("propertysub_type") ? (
                          upArrow.includes("propertysub_type") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("propertysub_type")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("propertysub_type")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("propertysub_type")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>
                        Created At
                        {sortBy.includes("createdAt") ? (
                          upArrow.includes("createdAt") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("createdAt")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("createdAt")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("createdAt")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>Updated At</th>
                      <th className="tablefontstyle" scope="col" style={{ borderTopRightRadius: "15px", color: "#152B51" }}>Action</th>
                    </tr>
                  </thead>
                  {propertyData?.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="5" style={{ fontSize: "15px" }}>
                          No Property Types Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr style={{
                        border: "none",
                      }}>
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage()?.map((property) => (
                        <tr key={property._id} style={{
                          border: "0.5px solid rgba(50, 69, 103, 1)",
                          fontSize: "12px",
                          height: "40px",
                          fontFamily: "poppins",
                          fontWeight: "600",
                        }}>
                          <td className="bordertopintd tablebodyfont"> {property.property_type}</td>
                          <td className="bordertopintd tablebodyfont">{property.propertysub_type}</td>
                          <td className="bordertopintd tablebodyfont">
                            {moment(property.createdAt).format("YYYY-MM-DD")}
                          </td>
                          <td className="bordertopintd tablebodyfont">
                            {moment(property.updatedAt).format("YYYY-MM-DD")}
                          </td>
                          <td className="bordertopintd tablebodyfont">
                            <div style={{ display: "flex",gap: "5px"  }}>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  property.admin_id === "is_trial"
                                    ? freetrialData()
                                    : deleteProperty(property.property_id)
                                }
                              >
                                <img src={deleicon} width={20} height={20} />

                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  property.admin_id === "is_trial"
                                    ? freetrialData()
                                    : editPropertyType(property.property_id)
                                }
                              >
                                <img src={editicon} width={20} height={20} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>

                {paginatedData?.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2} >
                        <DropdownToggle className="paginationbtnstyle" caret>{pageItem}</DropdownToggle>
                        <DropdownMenu >
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default PropertyType;
