import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useCookies } from "react-cookie";
import axiosInstance from "axiosInstance";
import "./Radiobutton.css";
import { values } from "pdf-lib";

const Login = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);
  const [logindata, setLoginData] = useState(null);
  const [cookies, setCookie] = useCookies(["token"]);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberedEmail") !== null
  );
  const [selected, setSelected] = useState("");
  const toggleCard = (e, value) => {
    if (selected === value) {
      setSelected("");
      return;
    }
    setSelected(value);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (selected.role === "admin") {
        const url = `${baseUrl}/admin/login`;

        const adminRes = await axios.post(url, values);

        if (adminRes.status === 200) {
          const adminData = adminRes.data;
          if (adminData.statusCode === 200) {
            toast.success("Admin Login successfully!", {
              position: "top-center",
              autoClose: 500,
            });
            setTimeout(() => {
              setCookie("token", adminData.token, { path: "/" });
              setCookie("ID", adminData.admin_id, { path: "/" });
              if (location.state?.from) {
                navigate(location.state?.from)
              } else {
                navigate(`/${adminData.data}/index`);
              }
            }, 1000);
          } else if (adminData.statusCode === 202) {
            toast.error(" Invalid Admin Password. Please try again.", {
              position: "top-center",
            });
          } else if (adminData.statusCode === 204) {
            toast.error("Account is deactivated. Please contact support.", {
              position: "top-center",
            });
          }
        } else {
          toast.error("User not found", {
            autoClose: 500,
            position: "top-center",
          });
        }
      } else if (selected.role === "super_admin") {
        const superAdminRes = await axios.post(
          `${baseUrl}/admin/superadmin_login`,
          values
        );
        if (superAdminRes.status === 200) {
          const superAdminData = superAdminRes.data;
          if (superAdminData.statusCode === 200) {
            toast.success("Super Admin Login successfully!", {
              position: "top-center",
              autoClose: 500,
            });
            setTimeout(() => {
              setCookie("token", superAdminData.token, { path: "/" });
              setCookie("ID", superAdminData.data.superadmin_id, {
                path: "/",
              });
              navigate(`/superadmin/dashboard`);
            }, 1000);
          } else if (superAdminData.statusCode === 202) {
            toast.error(" Invalid Super Admin Password. Please try again.", {
              autoClose: 500,
              position: "top-center",
            });
          } else {
            toast.error("Invalid User Data", {
              autoClose: 500,
              position: "top-center",
            });
          }
        } else {
          toast.error("Invalid User Data", {
            autoClose: 500,
            position: "top-center",
          });
        }
      } else if (
        selected.role === "tenant" ||
        selected.role === "staffmember" ||
        selected.role === "vendor"
      ) {
        const roll = selected.role;
        const response = await axios.post(`${baseUrl}/${roll}/login`, {
          email: values.email,
          password: values.password,
          admin_id: selected.admin_id,
          company: selected.company_name,
        });

        if (response.status === 200) {
          const responceData = response.data;
          if (responceData.statusCode === 200) {
            toast.success(responceData.message, {
              position: "top-center",
              autoClose: 500,
            });
            setTimeout(() => {
              setCookie("token", response.data.token, { path: "/" });
              setCookie("ID", response.data.id, { path: "/" });
              if (roll === "tenant") {
                navigate("/tenant/tenantdashboard");
              } else if (roll === "vendor") {
                navigate("/vendor/vendordashboard");
              } else if (roll === "staffmember") {
                navigate("/staff/staffdashboard");
              }
            }, 1000);
          } else if (responceData.statusCode === 201) {
            toast.error("User not exist!", {
              position: "top-center",
            });
          } else if (responceData.statusCode === 202) {
            toast.error(" Invalid Password. Please try again.", {
              position: "top-center",
            });
          } else if (responceData.statusCode === 203) {
            toast.warning("Admin is not active, Please contact your company", {
              position: "top-center",
            });
          }
        }
      } else {
        toast.error("Invalid User Data", {
          autoClose: 500,
          position: "top-center",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickSubmit = async () => {
    try {
      const response = await axios.post(`${baseUrl}/admin/check_role`, {
        email: loginFormik.values.email,
      });

      if (response.data.statusCode === 200) {
        setLoginData(response.data.data);
        setisSubmit(true);
      } else if (response.data.statusCode === 201) {
        toast.error("Email not found", {
          autoClose: 1000,
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    }
  };

  let loginFormik = useFormik({
    initialValues: {
      email: localStorage.getItem("rememberedEmail") || "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      password: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (logindata && logindata?.length === 1) {
      if (logindata[0].role === "super_admin") {
        setSelected({
          role: logindata[0].role,
          superadmin_id: logindata[0].superadmin_id,
        });
      } else if (logindata[0].role === "admin") {
        setSelected({
          role: logindata[0].role,
          admin_id: logindata[0].admin_id,
          company_name: logindata[0].company_name,
        });
      } else if (logindata[0].role === "tenant") {
        setSelected({
          role: logindata[0].role,
          tenant_id: logindata[0].tenant_id,
          admin_id: logindata[0].admin_id,
          company_name: logindata[0].company_name,
        });
      } else if (logindata[0].role === "staffmember") {
        setSelected({
          role: logindata[0].role,
          staffmember_id: logindata[0].staffmember_id,
          admin_id: logindata[0].admin_id,
          company_name: logindata[0].company_name,
        });
      } else if (logindata[0].role === "vendor") {
        setSelected({
          role: logindata[0].role,
          vendor_id: logindata[0].vendor_id,
          admin_id: logindata[0].admin_id,
          company_name: logindata[0].company_name,
        });
      }
    }
  }, [logindata]);

  const handleInputChange = (e) => {
    loginFormik.handleChange(e);
    setisSubmit(false);
  };
  return (
    <>
      <Col lg="6" md="7" sm="8">
        <Card
          className="bg-secondary shadow border-0"
          onSubmit={(e) =>
            loginFormik.handleSubmit(e, loginFormik.values.rememberMe)
          }
        >
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center  mb-4">
              <h4 className="titleecolor" style={{ fontFamily: "Poppins" }}>
                Sign in with your credentials
              </h4>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    onBlur={loginFormik.handleBlur}
                    onChange={handleInputChange}
                    value={loginFormik.values.email}
                    // disabled={isSubmit}
                  />
                </InputGroup>
                {loginFormik.touched.email && loginFormik.errors.email ? (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {loginFormik.errors.email}
                  </Typography>
                ) : null}
              </FormGroup>

              {isSubmit ? (
                <>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="standard-adornment-password"
                        autoComplete="new-password"
                        name="password"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        onBlur={loginFormik.handleBlur}
                        onChange={loginFormik.handleChange}
                        value={loginFormik.values.password}
                      />

                      <IconButton
                        type="button"
                        style={{ padding: "7px" }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {<VisibilityIcon />}
                      </IconButton>
                    </InputGroup>
                    {loginFormik.touched.password &&
                    loginFormik.errors.password ? (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {loginFormik.errors.password}
                      </Typography>
                    ) : null}
                  </FormGroup>

                  {logindata && logindata?.length > 1 ? (
                    <Row>
                      {logindata?.map((company) => (
                        <Col lg="6" className="mt-2">
                          <div
                            className={`cardstyle ${
                              selected && selected === company ? "selected" : ""
                            }`}
                            onClick={(e) => toggleCard(e, company)}
                          >
                            <div className="d-flex justify-content-start">
                              <div
                                className={`radio-button ${
                                  selected && selected === company
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                {selected && selected === company && (
                                  <span className="checkmark">✔</span>
                                )}
                              </div>
                              <div className="card-content">
                                <div
                                  className="card-subtitlestyle"
                                  style={{
                                    wordBreak: "break-word",
                                    fontSize: "14px",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                  }}
                                >
                                  {company.company_name}
                                </div>
                                <div
                                  className="card-titlestyle mt-1"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {company.role}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : null}
                  {/* <Row>
                    <Col lg="6">
                    <div
                      className={`cardstyle ${selected ? "selected" : ""}`}
                      onClick={toggleCard}
                    >
                      <div className="d-flex justify-content-start">
                        <div
                          className={`radio-button ${
                            selected ? "checked" : ""
                          }`}
                        >
                          {selected && <span className="checkmark">✔</span>}
                        </div>
                        <div className="card-content">
                          <div className="card-titlestyle">Role</div>
                          <div className="card-subtitlestyle">company_name</div>
                        </div>
                      </div>
                    </div>
                    </Col>
                    <Col lg="6">
                    <div
                      className={`cardstyle ${selected ? "selected" : ""}`}
                      onClick={toggleCard}
                    >
                      <div className="d-flex justify-content-start">
                        <div
                          className={`radio-button ${
                            selected ? "checked" : ""
                          }`}
                        >
                          {selected && <span className="checkmark">✔</span>}
                        </div>
                        <div className="card-content">
                          <div className="card-titlestyle">Role</div>
                          <div className="card-subtitlestyle">company_name</div>
                        </div>
                      </div>
                    </div>
                    </Col>
                  </Row> */}

                  <div className="text-center mt-3">
                    {/* <Button className="my-4" color="primary" type="button">
                    Sign in
                  </Button> */}

                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={isLoading || !selected}
                      style={{ color: "#fff", backgroundColor: "#152B51" }}
                    >
                      {isLoading ? <CircularProgress size={24} /> : "Login"}
                    </Button>
                  </div>
                </>
              ) : (
                <div className="text-center mb-3">
                  <Button
                    // type="submit"
                    variant="contained"
                    size="large"
                    disabled={
                      isLoading ||
                      !!loginFormik.errors.email ||
                      !loginFormik.values.email ||
                      !loginFormik.touched.email
                    }
                    style={{ color: "#fff", backgroundColor: "#152B51" }}
                    onClick={() => {
                      handleClickSubmit();
                    }}
                  >
                    {isLoading ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </div>
              )}
              {/* <div className="custom-control custom-control-alternative custom-checkbox"> */}
              <div>
                <Row className="mt-3">
                  {/* <Col>
                    <input
                      className="custom-control-input"
                      id="customCheckLogin"
                      type="checkbox"
                      onChange={() => setRememberMe(!rememberMe)}
                      checked={rememberMe}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </Col> */}
                  <Col xs="6" className="d-flex justify-content-start">
                    <span
                      className="mt-2"
                      // href="#rms"
                      onClick={() => navigate(`/auth/forgetpassword`)}
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Forgot password?
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="d-flex justify-content-start mt-2">
                <Row>
                  <Col>
                    <span
                      className=""
                      style={{
                        fontSize: "14px",
                        // cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Don't have an account?
                    </span>
                    <a
                      style={{ color: "#152B51", fontWeight: "600", cursor:"pointer"}}
                      onClick={() => navigate(`/trial/trial-login`)}
                      
                    >
                      {" "}
                      Sign up
                    </a>
                  </Col>
                </Row>
                <br />
              </div>
              <br />
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#rms"
                onClick={ () => navigate(`/auth/changepassword`)}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#rms"
                onClick={(e) => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row> */}
      </Col>
      <ToastContainer />
    </>
  );
};

export default Login;
