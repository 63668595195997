import React, { useState, useEffect } from "react";
import {
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Col,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from 'react-cookie';
import VendorHeader from "components/Headers/VendorHeader";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axiosInstance from "axiosInstance";

const VendorWorkTable = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [workData, setWorkData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [search, setSearch] = React.useState(false);
  const toggle3 = () => setSearch((prevState) => !prevState);
  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;

  const [sortedColumn, setSortedColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [hoveredColumn, setHoveredColumn] = useState("createdAt");
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [accessType, setAccessType] = useState(null);

  const [cookies] = useCookies(['token']);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/vendor/token_check`, {
          token: cookies.token
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  const getWorkData = async () => {
    if (accessType?.vendor_id) {
      try {
        const response = await axiosInstance.get(
          `/work-order/vendor_work/${accessType.vendor_id}`
        );
        setWorkData(response.data.data);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    getWorkData();
  }, [accessType, pageItem]);

  const navigateToDetails = (workorder_id) => {
    navigate(`/vendor/vendorworkdetail/${workorder_id}`);
  };

  const [filteredData, setFilteredData] = useState([]);

  // const sortData = (value) => {
  //   if (!sortBy.includes(value)) {
  //     setSortBy([...sortBy, value]);
  //     setUpArrow([...upArrow, value]);
  //     filterRentalsBySearchAndPage();
  //   } else {
  //     setSortBy(sortBy.filter((sort) => sort !== value));
  //     setUpArrow(upArrow.filter((sort) => sort !== value));
  //     filterRentalsBySearchAndPage();
  //   }
  // };
  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(column, isAsc ? "desc" : "asc");
  };

  const sortData = (column, order) => {
    let sortedData = [...workData];
    switch (column) {
      case "rental_adress":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.rental_adress.localeCompare(b.rental_adress)
            : b.rental_adress.localeCompare(a.rental_adress)
        );
        break;
      case "work_subject":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_subject.localeCompare(b.work_subject)
            : b.work_subject.localeCompare(a.work_subject)
        );
        break;
      case "work_category":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_category.localeCompare(b.work_category)
            : b.work_category.localeCompare(a.work_category)
        );
        break;
      case "date":
        sortedData.sort((a, b) =>
          order === "asc"
            ? new Date(a?.date) -
              new Date(b?.date)
            : new Date(b?.date) -
              new Date(a?.date)
        );
        break;
      case "createdAt":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? new Date(a?.createdAt) -
              new Date(b?.createdAt)
            : new Date(b?.createdAt) -
              new Date(a?.createdAt)
        );
        break;
      default:
        break;
    }
    setWorkData(sortedData || []);
  };
  const filterRentalsBySearch = () => {
    if (searchQuery2 && !searchQuery) {
      if (searchQuery2 === "All") {
        return workData;
      }

      if (searchQuery2 === "Over Due") {
        return workData.filter((rental) => {
          let currentDate = new Date();
          let rentalDate = new Date(rental.date);
          return rentalDate < currentDate && rental.status !== "Completed";
        });
      }

      // else if (searchQuery2 === "New") {
      //   return workData.status === "New";
        
      // }

      return workData.filter((rental) => {
        const lowerCaseQuery = searchQuery2.toLowerCase();
        return rental.status.toLowerCase().includes(lowerCaseQuery);
      });
    }

    if (searchQuery) {
      return workData.filter((rental) => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const isUnitAddress = (rental.unit_data.rental_unit + " " + rental.rental_data.rental_adress)
          .toLowerCase()
          .includes(lowerCaseQuery);
        return (
          rental.work_subject.toLowerCase().includes(lowerCaseQuery) ||
          rental.work_category.toLowerCase().includes(lowerCaseQuery) ||
          rental.status.toLowerCase().includes(lowerCaseQuery) ||
          (rental.staffmember_name &&
            rental.staffmember_name.toLowerCase().includes(lowerCaseQuery)) ||
          isUnitAddress ||
          rental.priority.toLowerCase().includes(lowerCaseQuery)
        );
      });
    }

    // if (upArrow.length > 0) {
    //   const filteredData = workData;
    //   const sortingArrows = upArrow;
    //   sortingArrows.forEach((value) => {
    //     switch (value) {
    //       case "rental_adress":
    //         filteredData.sort((a, b) =>
    //           a.rental_data.rental_adress?.localeCompare(b.rental_data.rental_adress)
    //         );
    //         break;
    //       case "work_subject":
    //         filteredData.sort((a, b) =>
    //           a.work_subject?.localeCompare(b.work_subject)
    //         );
    //         break;
    //       case "work_category":
    //         filteredData.sort((a, b) =>
    //           a.work_category?.localeCompare(b.work_category)
    //         );
    //         break;
    //       case "staffmember_name":
    //         filteredData.sort((a, b) =>
    //           a.staffmember_name?.localeCompare(b.staffmember_name)
    //         );
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    //   return filteredData;
    // }
    if (!sortedColumn) {
      // If no sorting column is specified, sort by createdAt in descending order
      filteredData?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }
    if (!searchQuery) {
      return workData;
    }
  };

  useEffect(() => {
    if (status === "Over Due") {
      setSearchQuery2("Over Due");
    } else if (status === "New") {
      setSearchQuery2("New");
    }
  }, [status]);

  const filterRentalsBySearchAndPage = () => {
    const filteredData = filterRentalsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    // setTotalPages(Math.ceil(filteredData.length / pageItem));
    return paginatedData;
  };

  return (
    <>
      {/* <VendorHeader /> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "4rem", }}>
        <CardHeader
          className=" mt-3 mb-3 headproptypebar "
          style={{
            backgroundColor: "#152B51",
            borderRadius: "10px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
          }}
        >
          <h2
            className="mb-0 headproptypee"
            style={{
              color: "#ffffff",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Work Orders
          </h2>
        </CardHeader>
        {/* <Row>
          <Col xs="12" sm="6">
            <FormGroup>
              <h1 style={{ color: "white" }}>Work Orders</h1>
            </FormGroup>
          </Col>
        </Row> */}
        {/* <br /> */}
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                <CardHeader className="border-0 p-0 mt-3 mb-3">
                  <Row>
                    <Col xs="12" sm="12" className="d-flex">
                      <FormGroup className="searchspace searchstyle">
                        <Input
                          className="filterh"
                          fullWidth
                          type="text"
                          placeholder="Search here..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                            boxShadow: " 0px 4px 4px 0px #00000040",
                            minWidth: "200px",
                            border: "1px solid #ced4da",
                          }}
                        />
                      </FormGroup>
                      <Dropdown
                        isOpen={search}
                        toggle={toggle3}
                        className="mx-2"

                      >
                        <DropdownToggle className="selecttypee typeselectprop titleecolor"
                          caret
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040",
                            border: "1px solid #ced4da",
                            maxWidth: "200px",
                            minWidth: "200px",
                            backgroundColor: "transparent",
                          }}
                        >
                          {searchQuery2
                            ? searchQuery
                              ? "Select Status"
                              : searchQuery2
                            : "Select Status"}
                        </DropdownToggle>
                        <DropdownMenu className="dropdownmeunprop">
                          <DropdownItem className="searchcfiltertext"
                            onClick={() => {
                              setSearchQuery2("New");
                              setSearchQuery("");
                            }}
                          >
                            New
                          </DropdownItem>
                          <DropdownItem className="searchcfiltertext"
                            onClick={() => {
                              setSearchQuery2("In Progress");
                              setSearchQuery("");
                            }}
                          >
                            In Progress
                          </DropdownItem>
                          <DropdownItem className="searchcfiltertext"
                            onClick={() => {
                              setSearchQuery2("On Hold");
                              setSearchQuery("");
                            }}
                          >
                            On Hold
                          </DropdownItem>
                          <DropdownItem className="searchcfiltertext"
                            onClick={() => {
                              setSearchQuery2("Completed");
                              setSearchQuery("");
                            }}
                          >
                            Completed
                          </DropdownItem>
                          <DropdownItem className="searchcfiltertext"
                            onClick={() => {
                              setSearchQuery2("Over Due");
                              setSearchQuery("");
                            }}
                          >
                            Over Due
                          </DropdownItem>
                          <DropdownItem className="searchcfiltertext"
                            onClick={() => {
                              setSearchQuery2("All");
                              setSearchQuery("");
                            }}
                          >
                            All
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </CardHeader>
                {/* <Row
                  className="mx-2  d-flex align-items-center py-1"
                  style={{
                    borderRadius: "10px", height: "auto",
                    // boxShadow: " 0px 4px 4px 0px #00000040",

                  }}
                >
                  <Col>
                    <Row
                      className="mx-1 d-flex align-items-center"
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                        height: "45px",
                        fontSize: "14px",
                        fontFamily: "poppins",
                        fontWeight: "600",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                      }}
                    >
                      <Col>Work Order</Col>
                      <Col>Property</Col>
                      <Col>Category</Col>
                      <Col>Priority</Col>
                      <Col>Status</Col>
                      <Col>Created At</Col>
                      <Col>Updated At</Col>
                      <Col>Due Date</Col>
                    </Row>
                    <Row
                      className="mx-1 mt-3"
                      style={{
                        border: "0.5px solid rgba(50, 69, 103, 1)",
                        borderBottomLeftRadius: "12px",
                        borderBottomRightRadius: "12px",
                        overflow: "hidden",
                        fontSize: "16px",
                        fontWeight: "600",
                        // lineHeight: "19.12px",
                      }}
                    >
                      <Col>
                        {filterRentalsBySearchAndPage().map((vendor) => (
                          <Row
                            key={vendor?.workOrder_id}
                            className="d-flex align-items-center"
                            onClick={() => navigateToDetails(vendor?.workOrder_id)}
                            style={{
                              cursor: "pointer",
                              border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                            }}
                          >
                            <Col>{vendor?.work_subject}</Col>
                            <Col>
                              {vendor?.rental_data?.rental_adress}-
                              {vendor?.unit_data?.rental_unit}{" "}
                              {vendor?.unit_data?.rental_unit
                                ? " - " + vendor?.unit_data?.rental_unit
                                : null}
                            </Col>
                            <Col>{vendor?.work_category}</Col>
                            <Col>{vendor?.priority}</Col>
                            <Col>{vendor?.status}</Col>
                            <Col>{vendor?.createdAt}</Col>
                            <Col>{vendor?.updatedAt || "-"}</Col>
                            <Col>{vendor?.date || "-"}</Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
                <Table className="align-items-center table-flush" responsive style={{ borderCollapse: "collapse" }}>
                  <thead className="" style={{
                    height: "45px",
                    fontSize: "14px",
                    fontFamily: "poppins",
                    fontWeight: "600",
                    boxShadow: " 0px 4px 4px 0px #00000040",
                  }}>
                    <tr style={{
                      border: "2px solid rgba(50, 69, 103, 1)",
                    }}>
                      <th className="tablefontstyle" scope="col" style={{
                        borderTopLeftRadius: "15px",
                        color: "#152B51"
                      }}>Work Order
                        {/* {sortBy.includes("work_subject") ? (
                          upArrow.includes("work_subject") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("work_subject")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("work_subject")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("work_subject")}
                          />
                        )} */}
                        </th>
                      <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>Property
                        {/* {sortBy.includes("rental_adress") ? (
                          upArrow.includes("rental_adress") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rental_adress")}
                          />
                        )} */}
                        </th>
                      <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>Category 
                          {/* {sortBy.includes("work_category") ? (
                        upArrow.includes("work_category") ? (
                          <ArrowDropUpIcon
                            onClick={() => sortData("work_category")}
                          />
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("work_category")}
                          />
                        )
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => sortData("work_category")}
                        />
                      )} */}
                      </th>
                      <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>priority</th>
                      <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>Status</th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                        onClick={() => handleSort("createdAt")}
                        onMouseEnter={() => setHoveredColumn("createdAt")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Created At
                        {hoveredColumn === "createdAt" ||
                        sortedColumn === "createdAt" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                        onClick={() => handleSort("date")}
                        onMouseEnter={() => setHoveredColumn("date")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Due Date
                        {hoveredColumn === "date" || sortedColumn === "date" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{
                      border: "none",
                    }}>
                      {/* Empty row */}
                      <td colSpan="9"></td>
                    </tr>
                    {filterRentalsBySearchAndPage().map((vendor) => (
                      <tr
                        key={vendor?.workOrder_id}
                        onClick={() => navigateToDetails(vendor?.workOrder_id)}
                        style={{
                          cursor: "pointer",
                          border: "0.5px solid rgba(50, 69, 103, 1)",
                          fontSize: "12px",
                          height: "40px",
                          fontFamily: "poppins",
                          fontWeight: "600",
                        }}
                      >
                        <td className="bordertopintd tablebodyfont">{vendor?.work_subject}</td>
                        <td className="bordertopintd tablebodyfont">
                          {vendor?.rental_data?.rental_adress}{" "}
                          {vendor?.unit_data?.rental_unit
                            ? " - " + vendor?.unit_data?.rental_unit
                            : null}
                        </td>
                        <td className="bordertopintd tablebodyfont">{vendor?.work_category}</td>
                        <td className="bordertopintd tablebodyfont">{vendor?.priority}</td>
                        <td className="bordertopintd tablebodyfont">{vendor?.status}</td>
                        <td className="bordertopintd tablebodyfont">{vendor?.createdAt}</td>
                        <td className="bordertopintd tablebodyfont">{vendor?.date || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {workData.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>{pageItem}</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : null}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
      </Container>
    </>
  );
};

export default VendorWorkTable;
