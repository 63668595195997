import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import profile from "../../assets/img/icons/common/profile1.svg";
import TenantsHeader from "components/Headers/TenantsHeader";

import {
  CardHeader,
  FormGroup,
  Container,
  Row,
  Col,
  Button,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import { useCookies } from "react-cookie";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
// import MapComponent from "../../components/MapComponent";

const TWorkOrderDetails = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { id } = useParams();
  const [outstandDetails, setoutstandDetails] = useState({});
  const [showTenantTable, setShowTenantTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [updateButton, setUpdateButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [workOrderStatus, setWorkOrderStatus] = useState("");
  const [activeButton, setActiveButton] = useState("Summary");
  const [selectedStatus, setSelectedStatus] = useState("Select");
  const [selecteduser, setSelecteduser] = useState("Select");
  const [statusdropdownOpen, setstatusdropdownOpen] = React.useState(false);
  const toggle5 = () => setuserdropdownOpen((prevState) => !prevState);
  const [userdropdownOpen, setuserdropdownOpen] = React.useState(false);
  const [staffData, setstaffData] = useState([]);

  let navigate = useNavigate();
  const location  = useLocation();
  const [accessType, setAccessType] = useState(null);

  // const [cookies] = useCookies(["token"]);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/tenant/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  const [cookie, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const toggle6 = () => setstatusdropdownOpen((prevState) => !prevState);
  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
    // WorkFormik.values.status = status;
  };

  const [imagedetails, setImageDetails] = useState([]);
  // const getOutstandData = async () => {
  //   if (id) {
  //     try {
  //       const response = await axiosInstance.get(
  //         `/work-order/workorder_details/${id}`
  //       );
  //       setoutstandDetails(response.data.data);
  //       setLoading(false);
  //       setWorkOrderStatus(response.data.data.workorder_updates.reverse());
  //       setImageDetails(response.data.data.workOrderImage);
  //     } catch (error) {
  //       console.error("Error fetching tenant details:", error);
  //       setError(error);
  //       setLoading(false);
  //     }
  //   }
  // };
  const getOutstandData = async () => {
    if (id) {
      try {
        const response = await axiosInstance.get(
          `/work-order/workorder_details/${id}`
        );
        setoutstandDetails(response?.data?.data);

        if (
          response?.data?.data?.workorder_updates &&
          response.data.data.workorder_updates.length > 0
        ) {
          const reversedUpdates = [
            ...response?.data?.data?.workorder_updates,
          ].reverse();

          const latestUpdate = reversedUpdates[0];
          setWorkOrderStatus(reversedUpdates);
          setSelectedStatus(latestUpdate?.status);
          setSelecteduser(latestUpdate?.staffmember_name);
          updateWorkorderFormik.setValues({
            status: response?.data?.data?.status,
            staffmember_name: latestUpdate?.staffmember_name,
            date: latestUpdate?.date,
            assigned_to: response?.data?.data.staff_data?.staffmember_name,
            message: response?.data?.data?.message
              ? response?.data?.data?.message
              : "",
            statusUpdatedBy: `${accessType?.tenant_firstName} ${accessType?.tenant_lastName}(Tenant)`,
          });
        } else {
          console.log("No updates found in workorder_updates");
        }

        setLoading(false);
        setImageDetails(response?.data?.data?.workOrder_images);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
        setError(error);
        setLoading(false);
      }
    }
  };

  const handleUpdateButtonClick = () => {
    // Add any logic or state changes you need before opening the dialog
    setUpdateButton(true);
    setOpenDialog(true);
  };

  const handleMouseEnter = (buttonValue) => {
    setHoveredButton(buttonValue);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleChange = () => {
    setShowTenantTable(!showTenantTable);
  };

  function formatDateWithoutTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }

  React.useEffect(() => {
    getOutstandData();

    axiosInstance
      .get(`/addstaffmember/find_staffmember`)
      .then((response) => {
        const data = response.data;
        if (data.statusCode === 200) {
          setstaffData(data.data);
        } else {
          // Handle error
          console.error("Error:", data.message);
        }
      })
      .catch((error) => {
        // Handle network error
        console.error("Network error:", error);
      });
  }, [id]);

  React.useEffect(() => {
    getOutstandData();
  }, [id]);

  const handleDialogClose = () => {
    // Add any logic or state changes you need when the dialog is closed
    setOpenDialog(false);
  };

  const updateWorkorderFormik = useFormik({
    initialValues: {
      status: "",
      staffmember_name: "",
      date: "",
      // assigned_to: "",
      message: "",
      statusUpdatedBy: `${accessType?.tenant_firstName} ${accessType?.tenant_lastName}(Tenant)`,
    },
    onSubmit: (values) => {
      // updateValues()
      // updateWorkorder(values);
    },
  });

  const updateValues = async () => {
    handleDialogClose();
    const formattedDate = updateWorkorderFormik.values.date
      ? new Date(updateWorkorderFormik.values.date).toISOString().split("T")[0]
      : "";

    const workOrderData = {
      workOrder: {
        date: formattedDate,
        staffmember_name: updateWorkorderFormik?.values?.staffmember_name,
        // staffmember_id: updateWorkorderFormik.values.staffmember_id,
        message: updateWorkorderFormik?.values?.message,
        status: selectedStatus,
        statusUpdatedBy: `${accessType?.tenant_firstName} ${accessType?.tenant_lastName}(Tenant)`,
      },
    };

    await axiosInstance
      .put(`/work-order/work-order/${id}`, workOrderData)
      .then((res) => {
        getOutstandData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const total = () => {
    let total = 0;
    outstandDetails?.partsandcharge_data?.map((item) => {
      total = total + item.amount;
    });
    return total;
  };

  const tableHeaderStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    fontWeight: "bold",
    textAlign: "left",
  };

  const tableCellStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    textAlign: "left",
  };

  const tableFooterStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    textAlign: "left",
  };

  const SmallSummaryCard = ({ label, value, textTruncate }) => {
    return (
      <div className="small-summary-card p-3">
        {" "}
        {/* Added padding with the p-3 class */}
        <h6
          className="text-uppercase text-muted mb-0 fontstylerentr fontfamilysty"
          style={{ fontSize: "14px", fontWeight: "400" }}
        >
          {label}
        </h6>
        <span
          className={`font-weight-bold fontstylerentr textcolorblue fontfamilysty
           ${textTruncate ? "text-truncate" : ""}`}
          style={{
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {value}
        </span>
      </div>
    );
  };

  return (
    <>
      <TenantsHeader />
      {/* Page content */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col className="text-right ">
            <Button
              color="primary"
              className="mr-2 fontstylerentr background-colorsty bgtextwhite"
              //  href="#rms"
              onClick={() => navigate("/tenant/tenantwork")}
              size="small"
            >
              Back
            </Button>
          </Col>
          <Col xs="12" lg="12" sm="12">
            <FormGroup className="">
              {/* <h1 style={{ color: "red" }}>Work Order Details</h1> */}
            </FormGroup>
            <CardHeader
              className=" mt-3 mx-2 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Work Order Details
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        {/* Table */}
        <Row>
          <div className="col">
            {/* <Card className="shadow"> */}
            <CardHeader
              className="border-0 d-flex justify-content-between align-items-center"
              style={{ padding: "8px", marginBottom: "10px" }}
            >
              <ToggleButtonGroup
                className="labelfontstyle"
                color="primary"
                // value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton
                  className="labelfontstyle fontfamilysty"
                  value="Summary"
                  style={{
                    border: "none",
                    background: "none",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: activeButton === "Summary" ? "#152B51" : "inherit",
                    borderBottom:
                      activeButton === "Summary"
                        ? "0.5px solid #324567"
                        : "none",
                    borderRadius: "0px",
                  }}
                  onMouseEnter={() => handleMouseEnter("Summary")}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => setActiveButton("Summary")}
                >
                  Summary
                </ToggleButton>

                <ToggleButton
                  className="labelfontstyle fontfamilysty"
                  value="Task"
                  style={{
                    border: "none",
                    background: "none",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: activeButton === "Task" ? "#152B51" : "inherit",
                    borderBottom:
                      activeButton === "Task" ? "0.5px solid #324567" : "none",
                    borderRadius: "0px",
                  }}
                  onMouseEnter={() => handleMouseEnter("Task")}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => setActiveButton("Task")}
                >
                  Task
                </ToggleButton>
              </ToggleButtonGroup>
            </CardHeader>
            <div className="table-responsive">
              {activeButton === "Summary" && (
                <div
                  className="container-fluid"
                  // style={{
                  //   marginRight: "auto",
                  //   overflowY: "scroll",
                  //   overflowX: "hidden",
                  //   scrollbarWidth: "thin",
                  //   msOverflowStyle: "none",
                  //   maxHeight: "100vh",
                  //   paddingRight: "20px"
                  // }}
                >
                  <Row className="mb-4">
                    <Col lg="8" md="12">
                      {loading ? (
                        <div>Loading Work Order details...</div>
                      ) : error ? (
                        <div>Error: {error.message}</div>
                      ) : outstandDetails?.workOrder_id ? (
                        <>
                          <Box
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            padding="16px"
                            // maxWidth="960px"
                            boxShadow=" 0px 4px 4px 0px #00000040"

                            // margin="20px"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              marginBottom="20px"
                            >
                              <Box
                                className="background-colorsty"
                                width="40px"
                                height="40px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="8px"
                                color="white"
                                fontSize="24px"
                                marginRight="16px"
                              >
                                <FormatListBulletedIcon />
                              </Box>
                              <Box flex="1">
                                <h2 className="text text-lg fontstylerentr textcolorblue">
                                  {outstandDetails.work_subject || "N/A"}
                                </h2>
                                <span className="fontstylerentr">
                                  {outstandDetails.property_data
                                    .rental_adress || "N/A"}{" "}
                                  {outstandDetails?.unit_data?.rental_unit
                                    ? " - " +
                                      outstandDetails?.unit_data?.rental_unit
                                    : null}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection={{ xs: "column", md: "row" }}
                              alignItems="stretch"
                            >
                              {/* Left side */}
                              <Box
                                flex="1"
                                className={{ xs: "col-12", md: "col-7" }}
                                marginBottom={{ xs: "20px", md: "0" }}
                              >
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkdescription"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty textcolorblue fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Description
                                  </label>
                                  <span
                                    className="fontstylerentr fontfamilysty"
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails.work_performed || "N/A"}
                                  </span>
                                </FormGroup>
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkpermission"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty textcolorblue fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Permission to enter
                                  </label>
                                  <span
                                    className="fontstylerentr fontfamilysty"
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails?.entry_allowed
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </FormGroup>
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkvendor"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty textcolorblue fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Vendor Notes
                                  </label>
                                  <span
                                    className="fontstylerentr fontfamilysty"
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails.vendor_notes || "N/A"}
                                  </span>
                                </FormGroup>
                              </Box>

                              {/* Right side */}

                              <Box
                                flex="1"
                                className="d-flex flex-column justify-content-end"
                              >
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Status"
                                      value={outstandDetails.status || "N/A"}
                                      textTruncate // add this prop to enable text truncation
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Due Date"
                                      value={outstandDetails.date || "N/A"}
                                      textTruncate // add this prop to enable text truncation
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Assignees"
                                      value={
                                        outstandDetails?.staff_data
                                          ?.staffmember_name || "N/A"
                                      }
                                      textTruncate // add this prop to enable text truncation
                                    />
                                  </Col>
                                </Row>
                              </Box>
                            </Box>
                          </Box>
                          {outstandDetails?.partsandcharge_data?.length > 0 &&
                          outstandDetails?.is_billable === true ? (
                            <Box
                              // border="1px solid #ccc"
                              border="0.5px solid #737791"
                              borderRadius="10px"
                              padding="16px"
                              // maxWidth="960px"
                              boxShadow=" 0px 4px 4px 0px #00000040"
                              marginTop="20px"
                              style={{
                                marginRight: "auto",
                                overflowX: "auto",
                              }} // Center the box horizontally
                            >
                              <h2
                                className="text text-lg fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Parts and Labor
                              </h2>
                              <Box overflowX="auto">
                                <table
                                  className="textcolorblue fontfamilysty"
                                  style={{
                                    fontWeight: "500",
                                    width: "100%",
                                    borderCollapse: "collapse",
                                    fontSize: "14px",
                                  }}
                                >
                                  <thead>
                                    <tr
                                      className="fontstylerentr tableefontworkorder textcolorblue fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <th style={tableHeaderStyle}>QTY</th>
                                      <th style={tableHeaderStyle}>ACCOUNT</th>
                                      <th style={tableHeaderStyle}>
                                        DESCRIPTION
                                      </th>
                                      <th style={tableHeaderStyle}>PRICE</th>
                                      <th style={tableHeaderStyle}>AMOUNT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* Add your table rows dynamically here */}
                                    {outstandDetails?.partsandcharge_data.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.parts_quantity}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.account}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.description}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={{
                                              ...tableCellStyle,
                                              textAlign: "right",
                                            }}
                                          >
                                            ${item.parts_price}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={{
                                              ...tableCellStyle,
                                              textAlign: "right",
                                            }}
                                          >
                                            ${item.amount}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td
                                        className="fontstylerentmodal tableefontworkorder textcolorblue fontfamilysty"
                                        colSpan="4"
                                        style={{
                                          ...tableFooterStyle,
                                          fontWeight: "400",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Total
                                      </td>
                                      <td
                                        className="fontstylerentmodal tableefontworkorder textcolorblue fontfamilysty"
                                        style={{
                                          ...tableFooterStyle,
                                          textAlign: "right",
                                          fontWeight: "400",
                                          fontSize: "14px",
                                        }}
                                      >
                                        ${total()}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </Box>
                            </Box>
                          ) : null}
                          <Grid
                            className="mb-3"
                            container
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            padding="16px"
                            // maxWidth="960px"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                            marginTop="20px"
                            style={{
                              marginRight: "auto",
                              overflowX: "auto",
                              maxHeight: "500px",
                              scrollbarWidth: "thin",
                              msOverflowStyle: "none",
                              // overflowY: workOrderStatus.length > 6 ? "scroll" : "hidden",
                            }}
                          >
                            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                              <h2
                                className="text-lg fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Updates
                              </h2>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                              <Button
                                className="fontstylerentr titleecolor"
                                size="small"
                                onClick={handleUpdateButtonClick}
                                style={{
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  backgroundColor: "transparent",
                                  padding: "6px",
                                }}
                              >
                                Update
                              </Button>
                            </Grid>
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              {outstandDetails?.workorder_updates &&
                                outstandDetails.workorder_updates.length > 0 &&
                                workOrderStatus.map((item, index) => (
                                  <Grid item xs={12} key={index}>
                                    {" "}
                                    <Box
                                      padding="12px"
                                      maxWidth="700px"
                                      style={{
                                        // marginLeft: "auto",
                                        marginRight: "auto",
                                        overflowX: "auto",
                                      }} // Center the box horizontally
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          className="fontstylerentr"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {item.statusUpdatedBy}
                                          {item.createdAt &&
                                          (!item.updatedAt ||
                                            item.createdAt === item.updatedAt)
                                            ? " created this work order"
                                            : " updated this work order"}
                                          <span
                                            className="fontstylerentr"
                                            style={{ fontSize: "13px" }}
                                          >
                                            &nbsp;(
                                            {item.updatedAt
                                              ? item.updatedAt
                                              : item.createdAt}
                                            )
                                          </span>
                                        </div>
                                      </div>
                                      <hr
                                        style={{
                                          marginTop: "0px",
                                          marginBottom: "0px",
                                        }}
                                      />
                                      <Grid container>
                                        {(item.status && item.status.trim()) ||
                                        (item.date && item.date.trim()) ||
                                        (item.staffmember_name &&
                                          item.staffmember_name.trim()) ? (
                                          <>
                                            {item.status && (
                                              <Grid
                                                className="fontstylerentr"
                                                item
                                                xs={4}
                                              >
                                                Status: {item.status}
                                              </Grid>
                                            )}
                                            {item.date && (
                                              <Grid
                                                className="fontstylerentr"
                                                item
                                                xs={4}
                                              >
                                                Due Date: {item.date}
                                              </Grid>
                                            )}
                                            {item.staffmember_name && (
                                              <Grid
                                                className="fontstylerentr"
                                                item
                                                xs={4}
                                              >
                                                Assigned To:{" "}
                                                {item.staffmember_name}
                                              </Grid>
                                            )}
                                          </>
                                        ) : (
                                          <Grid className="fontstylerentr" item>
                                            Work Order Is Created
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Box>
                                  </Grid>
                                ))}
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <div className="fontstylerentr">No details found.</div>
                      )}
                    </Col>
                    <Col lg="4" md="12">
                      {outstandDetails?.workOrder_id ? (
                        <Box
                          className="boxworkorderstylee"
                          border="0.5px solid #737791"
                          borderRadius="10px"
                          boxShadow=" 0px 4px 4px 0px #00000040"
                          maxWidth="100%"
                          // margin="20px"
                        >
                          <Box
                            className="align-item-center text-center boxinnertextworkorder"
                            borderBottom="1px solid #ccc"
                            style={{
                              width: "100%",
                              padding: "16px",
                              textAlign: "left",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                              color: "#5e72e4",
                            }}
                          >
                            <span
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Contacts
                            </span>
                          </Box>
                          <Box
                            borderBottom="1px solid #ccc"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "100%",
                              padding: "16px 16px 5px 16px",
                            }}
                          >
                            <Box width="16px" marginRight="10px">
                              {/* SVG icon */}
                              <img src={profile} height={20} width={20} />
                            </Box>
                            <Box
                              width="100%"
                              style={{ minWidth: "100%", padding: "0 16px" }}
                            >
                              <span
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Vendor
                              </span>{" "}
                              <br />
                              <span
                                className="fontstylerentmodal fontfamilysty"
                                style={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                }}
                              >
                                {outstandDetails?.vendor_data?.vendor_name ||
                                  "N/A"}
                              </span>
                            </Box>
                          </Box>
                          {outstandDetails?.tenant_data &&
                          typeof outstandDetails?.tenant_data === "object" ? (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                minWidth: "100%",
                                padding: "16px 16px 5px 16px",
                              }}
                            >
                              <Box width="16px" marginRight="10px">
                                <img src={profile} height={20} width={20} />
                              </Box>
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  padding: "0 16px",
                                }}
                              >
                                <span
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Tenant
                                </span>{" "}
                                <br />
                                <span
                                  className="fontstylerentmodal textcolorblue fontfamilysty"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  {outstandDetails?.tenant_data
                                    ?.tenant_firstName ? (
                                    <>
                                      {
                                        outstandDetails?.tenant_data
                                          ?.tenant_firstName
                                      }{" "}
                                      {
                                        outstandDetails?.tenant_data
                                          ?.tenant_lastName
                                      }
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Box>
                            </Box>
                          ) : null}
                        </Box>
                      ) : null}
                      {outstandDetails?.property_data ? (
                        <>
                          <Box
                            className="boxcontactstyle mt-3"
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            maxWidth="100%"
                            // margin="20px"
                            display="flex"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Box
                              className="align-item-center text-center boxinnertextworkorder"
                              borderBottom="1px solid #ccc"
                              style={{
                                width: "100%",
                                padding: "16px",
                                textAlign: "left",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                color: "#5e72e4",
                              }}
                            >
                              <h2
                                className="text labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Property
                              </h2>
                            </Box>
                            {outstandDetails?.property_data?.rental_image ? (
                              <Box
                                style={{
                                  width: "100%",
                                  padding: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  width="100%"
                                  style={{
                                    minWidth: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    className="workorderimgwidthstyle"
                                    src={`${imageGetUrl}/${outstandDetails?.property_data.rental_image}`}
                                    alt="property"
                                    style={{
                                      maxWidth: "80%",
                                      maxHeight: "200px",
                                      borderRadius: "8px",
                                      border: "1px solid #ccc",
                                    }}
                                  />
                                </Box>
                              </Box>
                            ) : null}
                            <Box
                              style={{
                                width: "100%",
                                padding: "16px 16px 5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  // color: "blue",
                                }}
                                // onClick={() =>
                                //   navigate(
                                //     `/tenant/tenantpropertydetail/${outstandDetails?.property_data.rental_adress}`
                                //   )
                                // }
                              >
                                <span
                                  className="labelfontstyle fontfamilysty"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  {outstandDetails?.property_data
                                    ?.rental_adress || "N/A"}
                                  {outstandDetails?.unit_data?.rental_unit ? (
                                    " (" +
                                    outstandDetails?.unit_data?.rental_unit +
                                    ")"
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span className="labelfontstyle">
                                  {outstandDetails?.property_data
                                    ?.rental_city ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_city
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_state ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_state
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                </span>
                                <span>
                                  {outstandDetails?.property_data
                                    ?.rental_country ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_country
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_postcode ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_postcode
                                      }
                                      .
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Box>
                            </Box>
                            {/* <Box
                              width="100%"
                              style={{
                                padding: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ height: "220px", width: "90%", borderRadius: "30px", overflow: "hidden" }}>
                                <MapComponent
                                  address={`${outstandDetails?.property_data?.rental_adress}, ${outstandDetails?.property_data?.rental_city}, ${outstandDetails?.property_data?.rental_state}, ${outstandDetails?.property_data?.rental_country}, ${outstandDetails?.property_data?.rental_postcode}`}
                                />
                              </div>
                            </Box> */}
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  {updateButton && (
                    <Form onSubmit={updateWorkorderFormik.handleSubmit}>
                      <Dialog open={openDialog} onClose={handleDialogClose}>
                        <DialogTitle
                          className="barforsummaryrent textcolorblue fontfamilysty"
                          style={{
                            fontWeight: "500",
                            fontSize: "26px",
                          }}
                        >
                          Update Dialog
                        </DialogTitle>
                        <DialogContent>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-desg"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Status *
                                </label>
                                <FormGroup>
                                  <Dropdown
                                    className="fontstylerentmodal"
                                    isOpen={statusdropdownOpen}
                                    toggle={toggle6}
                                  >
                                    <DropdownToggle
                                      className="fontstylerentmodal titleecolor"
                                      caret
                                      style={{
                                        boxShadow: " 0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      {selectedStatus
                                        ? selectedStatus
                                        : "Select"}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="fontstylerentmodal"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          handleStatusSelect("New")
                                        }
                                      >
                                        New
                                      </DropdownItem>
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          handleStatusSelect("In Progress")
                                        }
                                      >
                                        In Progress
                                      </DropdownItem>
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          handleStatusSelect("On Hold")
                                        }
                                      >
                                        On Hold
                                      </DropdownItem>
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          handleStatusSelect("Completed")
                                        }
                                      >
                                        Completed
                                      </DropdownItem>
                                    </DropdownMenu>
                                    {/* {WorkFormik.errors &&
                                WorkFormik.errors?.status &&
                                WorkFormik.touched &&
                                WorkFormik.touched?.status &&
                                WorkFormik.values.status === "" ? (
                                                        <div className="requiredstylefont" style={{ color: "red" }}>

                                  {WorkFormik.errors.status}
                                </div>
                              ) : null} */}
                                  </Dropdown>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                            <Col xs={12} sm={6} className="">
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-unitadd"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Due Date
                                </label>
                                <Input
                                  className="form-control-alternative fontstylerentr titleecolor"
                                  style={{
                                    boxShadow: " 0px 4px 4px 0px #00000040",
                                  }}
                                  id="input-unitadd"
                                  type="date"
                                  name="date"
                                  value={updateWorkorderFormik.values.date}
                                  onChange={updateWorkorderFormik.handleChange}
                                  onBlur={updateWorkorderFormik.handleBlur}
                                />
                                {/* {WorkFormik.touched.due_date &&
                            WorkFormik.errors.due_date ? (
                               <div className="requiredstylefont" style={{ color: "red" }}>

                              {WorkFormik.errors.due_date}
                            </div>
                          ) : null} */}
                              </FormGroup>
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr"
                                  htmlFor="input-desg"
                                  style={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", color: "#8A95A8" }}

                                >
                                  Assigned To *
                                </label>
                                <FormGroup>
                                  <Dropdown className="fontstylerentmodal"
                                    isOpen={userdropdownOpen}
                                    toggle={toggle5}
                                  >
                                    <DropdownToggle className="fontstylerentmodal" caret style={{
                                      boxShadow: " 0px 4px 4px 0px #00000040",
                                      border: "1px solid #ced4da",
                                      backgroundColor: "transparent",
                                      color: "#C2C3CF"
                                    }}>
                                      {selecteduser ? selecteduser : "Select"}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </DropdownToggle>
                                    <DropdownMenu className="fontstylerentmodal"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <DropdownItem className="fontstylerentmodal"
                                        header
                                        style={{ color: "#152B51" }}
                                      >
                                        Staff
                                      </DropdownItem>
                                      {staffData.map((user) => (
                                        <DropdownItem
                                          key={user._id}
                                          onClick={() =>
                                            handleStaffSelect(
                                              user.staffmember_name
                                            )
                                          }
                                        >
                                          {user.staffmember_name}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                    {WorkFormik.errors &&
                                WorkFormik.errors?.staffmember_name &&
                                WorkFormik.touched &&
                                WorkFormik.touched?.staffmember_name &&
                                WorkFormik.values.staffmember_name === "" ? (
                                                        <div className="requiredstylefont" style={{ color: "red" }}>

                                  {WorkFormik.errors.staffmember_name}
                                </div>
                              ) : null} 
                                  </Dropdown>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                            </Row> */}
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-unitadd"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Message
                                </label>
                                <Input
                                  className="form-control-alternative fontstylerentr titleecolor"
                                  style={{
                                    boxShadow: " 0px 4px 4px 0px #00000040",
                                  }}
                                  id="input-unitadd"
                                  type="textarea"
                                  name="message"
                                  value={updateWorkorderFormik.values.message}
                                  onChange={updateWorkorderFormik.handleChange}
                                  onBlur={updateWorkorderFormik.handleBlur}
                                />
                                {/* {WorkFormik.touched.due_date &&
                            WorkFormik.errors.due_date ? (
                                                    <div className="requiredstylefont" style={{ color: "red" }}>

                              {WorkFormik.errors.due_date}
                            </div>
                          ) : null} */}
                              </FormGroup>
                            </Col>
                          </Row>
                        </DialogContent>
                        <DialogActions className="d-flex justify-content-start mx-3 mb-3">
                          <Button
                            className="fontstylerentr background-colorsty bgtextwhite"
                            size="small"
                            onClick={updateValues}
                          >
                            Save
                          </Button>
                          <Button
                            className="fontstylerentr textcolorblue"
                            onClick={handleDialogClose}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Form>
                  )}
                </div>
              )}

              {activeButton === "Task" && (
                <div className="container-fluid">
                  <Row className="mb-4">
                    <Col lg="8" md="12">
                      <Box
                        border="0.5px solid #737791"
                        // border="1px solid #ccc"
                        borderRadius="10px"
                        padding="16px"
                        // maxWidth="960px"
                        // margin={"20px"}
                        boxShadow=" 0px 4px 4px 0px #00000040"
                      >
                        <Row style={{ justifyContent: "space-between" }}>
                          <Col
                            lg="8"
                            className="d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <Box
                              width="40px"
                              height="40px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              backgroundColor="#152B51"
                              borderRadius="8px"
                              color="white"
                              fontSize="24px"
                            >
                              <FormatListBulletedIcon />
                            </Box>
                            <Col>
                              <h2
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                {outstandDetails?.work_subject || "N/A"}
                              </h2>

                              <span
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails?.property_data
                                  ?.rental_adress || "N/A"}
                                {outstandDetails?.unit_data?.rental_unit ? (
                                  " (" +
                                  outstandDetails?.unit_data?.rental_unit +
                                  ")"
                                ) : (
                                  <></>
                                )}
                              </span>
                            </Col>
                          </Col>
                          <Col lg="2" className="text-end">
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {/* <span
                                  style={{
                                    border: "2px solid",
                                    borderColor:
                                      outstandDetails.priority === "High"
                                        ? "red"
                                        : outstandDetails.priority === "Normal"
                                          ? "green"
                                          : outstandDetails.priority === "Low"
                                            ? "#FFD700"
                                            : "inherit",
                                    borderRadius: "8px",
                                    padding: "4px",
                                    fontSize: "15px",
                                    color:
                                      outstandDetails.priority === "High"
                                        ? "red"
                                        : outstandDetails.priority === "Normal"
                                          ? "green"
                                          : outstandDetails.priority === "Low"
                                            ? "#FFD700"
                                            : "inherit",
                                  }}
                                >
                                  &nbsp;{outstandDetails?.priority}&nbsp;
                                </span> */}
                              {outstandDetails?.priority && (
                                <span
                                  className="fontstylerentr"
                                  style={{
                                    border: "2px solid",
                                    borderColor:
                                      outstandDetails.priority === "High"
                                        ? "red"
                                        : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                        ? "#FFD700"
                                        : "inherit",
                                    borderRadius: "8px",
                                    padding: "4px",
                                    fontSize: "15px",
                                    color:
                                      outstandDetails.priority === "High"
                                        ? "red"
                                        : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                        ? "#FFD700"
                                        : "inherit",
                                  }}
                                >
                                  &nbsp;{outstandDetails?.priority}&nbsp;
                                </span>
                              )}
                            </span>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Description
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails.work_performed || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Status
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails.status || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Due Date
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {formatDateWithoutTime(
                                  outstandDetails.date || "N/A"
                                ) || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Assignees
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails?.staff_data
                                  ?.staffmember_name || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Permission to enter
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails?.entry_allowed ? "Yes" : "No"}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Box>
                    </Col>

                    <Col lg="4" md="12">
                      {/* <Box
                      border="1px solid #ccc"
                      borderRadius="8px"
                      padding="16px"
                      maxWidth="1000px"
                      margin={"20px"}
                    > */}

                      {outstandDetails ? (
                        <>
                          <Box
                            className="boxcontactstyle"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            maxWidth="100%"
                            // margin="20px"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                          >
                            <Box
                              className="align-item-center text-center boxinnertextworkorder"
                              borderBottom="1px solid #ccc"
                              style={{
                                width: "100%",
                                padding: "10px",
                                textAlign: "left",
                                color: "#152B51",
                                boxShadow: "0px 4px 4px 0px #00000040",
                                marginBottom: "10px",
                              }}
                            >
                              <span
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Images
                              </span>
                            </Box>

                            {outstandDetails?.workOrder_images &&
                            outstandDetails?.workOrder_images?.length > 0 ? (
                              <Box
                                style={{
                                  width: "100%",
                                  padding: "16px",
                                  marginTop: "10px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "center",
                                  fontFamily: "poppins",
                                }}
                              >
                                {outstandDetails?.workOrder_images?.map(
                                  (imageUrl, index) => (
                                    <Box
                                      key={index}
                                      width="48%"
                                      style={{
                                        minWidth: "48%",
                                        margin: "1%",
                                      }}
                                    >
                                      <img
                                        src={`${imageGetUrl}/${imageUrl}`}
                                        alt={`property ${index}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: "8px",
                                          border: "1px solid #ccc",
                                        }}
                                      />
                                    </Box>
                                  )
                                )}
                              </Box>
                            ) : (
                              <div className="mt-3 labelfontstyle">
                                No Images Attached
                              </div>
                            )}
                            <br />
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  // color: "blue",
                                }}
                                // onClick={() =>
                                //   navigate(
                                //     `/tenant/tenantpropertydetail/${propertyDetails.rental_adress}`
                                //   )
                                // }
                              >
                                <span
                                  className="labelfontstyle fontfamilysty"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                  }}
                                >
                                  {outstandDetails?.property_data
                                    ?.rental_adress || "N/A"}{" "}
                                  {/* {{5757-start}} */}
                                  {outstandDetails?.unit_data?.rental_unit && (
                                    <>
                                      (
                                      {outstandDetails?.unit_data
                                        ?.rental_unit || ""}
                                      )
                                    </>
                                  )}
                                  {/* {{5757-end}} */}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className="labelfontstyle fontfamilysty"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {outstandDetails?.property_data
                                    ?.rental_city ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_city
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_state ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_state
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_country ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_country
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_postcode ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_postcode
                                      }
                                      .
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <>No Details Found</>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <br />
            {/* </Card> */}
          </div>
        </Row>
        <br />
        <br />
      </Container>
    </>
  );
};

export default TWorkOrderDetails;
