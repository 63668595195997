import React from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

const Notificationmodal = ({
  notifications,
  handlePropertySelect,
  readNotification,
  role,
  closeModal,
}) => {
  const navigate = useNavigate();

  const navigateFunction = (data) => {
    if (role === "vendor") {
      navigate(
        `/vendor/vendorworkdetail/${data?.notification_type?.workorder_id}`
      );
    }
    if (role === "tenant") {
      if (data.is_workorder) {
        navigate(
          `/tenant/Tworkorderdetail/${data.notification_type.workorder_id}`
        );
      } else if (data.is_lease) {
        navigate(
          `/tenant/tenantpropertydetail/${data.notification_type.lease_id}`
        );
      } else {
        navigate(`/tenant/tenantFinancial`);
      }
    }
    if (role === "staffmember") {
      if (data.is_workorder) {
        navigate(
          `/staff/staffworkdetails/${data?.notification_type?.workorder_id}`
        );
      } else if (data.is_rental) {
        navigate(`/staff/staffpropertydetail/${data?.rental_id}`);
      }
    }
    if (role === "admin") {
      if (data.is_workorder) {
        navigate(
          `/admin/addworkorder/${data.notification_type.workorder_id}`
        );
      } else {
        navigate(
          `/admin/rentrolldetail/${data.notification_type.lease_id}?Financial`,{state:{ tab: "Financial"}}
        );
      }
    }
    if (role === "superadmin") {  
        navigate(
          `/superadmin/admin`
        );      
    }
  };
  return (
    <>
      {notifications.length > 0 ? (
        <>
          {notifications?.map((data) => {
            const notificationTitle =
              data.notification_title || "No Title Available";
            const notificationDetails =
              data.notification_detail || "No Details Available";
            const notificationTime = new Date(data.createdAt).toLocaleString();

            return (
              <div key={data._id} onClick={() => handlePropertySelect(data)}>
                <div>
                  <h4
                    className="barforsummaryrent"
                    style={{
                      color: "#152B51",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    {notificationTitle}
                  </h4>
                  <p
                    className="notificationtitle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                    }}
                  >
                    {notificationDetails}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <p
                      style={{
                        color: "#152B51",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        fontWeight: "500",
                      }}
                    >
                      {notificationTime}
                    </p>
                  </div>
                  <div>
                    {" "}
                    <Button
                      style={{
                        background: "#152B51",
                        color: "#fff",
                        textTransform: "none",
                        fontSize: "12px",
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        padding: "5px 10px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        readNotification(data.notification_id);
                        navigateFunction(data);
                        // navigate(`${url}/${data.notification_type?.workorder_id}`);
                        closeModal();
                      }}
                    >
                      View
                    </Button>
                  </div>
                </div>
                <hr style={{ margin: "10px 0" }} />
              </div>
            );
          })}
        </>
      ) : (
      <div className="d-flex justify-content-center">
        No Notification
      </div>  
      )}
    </>
  );
};

export default Notificationmodal;
