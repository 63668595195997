import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  getCardType,
  getCardDetails,
  getRentalOwnerCardTypeSettings,
  getCardLogo,
  getCustomerVaultId,
  addRecurringCards,
  getRecurringCards,
} from "plugins/ApiHandler";
import Dropdown from "components/ReusableComponents/Dropdown";
import BaseButton from "components/ReusableComponents/BaseButton";
import Loader from "components/ReusableComponents/Loader";

const RecurringTenants = ({
  isOpen,
  toggle,
  tenantsData,
  admin_id,
  rental_id,
  fetchTenantData,
}) => {
  const [loader, setLoader] = useState(true);
  const [tenantData, setTenantData] = useState([]);
  const [selectedReccuringCards, setSelectedReccuringCards] = useState([]);

  const handleSetCardDetails = async (item, tenant_id, leaseId) => {
    try {
      const cardType = await getCardType(item.cc_bin, item.cc_type);
      const response = await getRentalOwnerCardTypeSettings(tenant_id, leaseId);
      const cardLogo = await getCardLogo(item.cc_type);

      const { creditCardAccepted, debitCardAccepted } = response.data;

      if (
        (cardType.toLowerCase() === "credit" && !creditCardAccepted) ||
        (cardType.toLowerCase() === "debit" && !debitCardAccepted)
      ) {
        console.warn("Card type not accepted");
        return;
      }

      return {
        billing_id: item["@attributes"].id,
        cc_number: item.cc_number,
        cc_exp: item.cc_exp,
        cc_type: item.cc_type,
        cc_bin: item.cc_bin,
        customer_vault_id: item.customer_vault_id,
        card_type: cardType,
        card_logo: cardLogo,
      };
    } catch (error) {
      console.error(error, "error in bin check");
      return {};
    }
  };

  useEffect(() => {
    const fetchTenantData = async () => {
      setLoader(true);
      if (tenantsData.length > 0 && isOpen) {
        try {
          const recurrings = [];
          const tenants = [];
          const promises = tenantsData.map(async (tenant) => {
            const tenant_id = tenant.tenant_id;

            try {
              // Fetch customer vault ID
              const customerData = await getCustomerVaultId(tenant_id);
              const customer_vault_id = customerData.customer_vault_id;

              // Fetch card details
              const cardDetails = await getCardDetails({
                customer_vault_id,
                admin_id,
              });

              // Skip to next tenant if no card details are found
              const billingData = cardDetails?.data?.customer;
              if (!billingData) return;

              const cards = [];
              if (Array.isArray(billingData.billing)) {
                const extractedData = await Promise.all(
                  billingData.billing.map((item) =>
                    handleSetCardDetails(item, tenant_id, tenant.lease_id)
                  )
                );
                cards.push(...extractedData.filter((item) => item));
              } else if (billingData) {
                const extractedData = await handleSetCardDetails(
                  billingData.billing,
                  tenant_id,
                  tenant.lease_id
                );
                if (extractedData) cards.push(extractedData);
              }

              if (!cards.length) return; // Skip if no card details are available

              // Push tenant data with cards
              tenants.push({ ...tenant, cards });

              // Fetch recurring cards data
              const recurringData = await getRecurringCards({
                rental_id,
                tenant_id: tenant.tenant_id,
                lease_id: tenant.lease_id,
              });

              if (
                recurringData &&
                recurringData?.data &&
                recurringData?.data?.card
              ) {
                recurrings.push({
                  tenant_id: recurringData?.data?.card.tenant_id,
                  lease_id: recurringData?.data?.card.lease_id,
                  customer_vault_id:
                    recurringData?.data?.card?.customer_vault_id,
                  billing_id: recurringData?.data?.card?.billing_id,
                  card_type: recurringData?.data?.card?.card_type,
                });
              } else {
                recurrings.push({
                  tenant_id: tenant.tenant_id,
                  lease_id: tenant.lease_id,
                  customer_vault_id,
                  billing_id: cards[0]?.billing_id,
                  card_type: cards[0]?.card_type,
                });
              }
            } catch (tenantError) {
              console.error(
                `Error processing tenant ${tenant_id}: `,
                tenantError
              );
            }
          });

          await Promise.all(promises);

          setTenantData(tenants);
          setSelectedReccuringCards({
            admin_id,
            rental_id,
            recurrings,
          });
        } catch (error) {
          console.error("Error: ", error);
        } finally {
          setLoader(false);
        }
      } else {
        setTenantData([]);
        setLoader(false);
      }
    };

    fetchTenantData();
  }, [tenantsData, isOpen, rental_id]);

  const handleSelection = (item, tenant) => {
    var selectedCard = selectedReccuringCards?.recurrings?.find(
      (item) => item.tenant_id === tenant.tenant_id
    );

    var otherCards = selectedReccuringCards?.recurrings?.filter(
      (item) => item.tenant_id !== tenant.tenant_id
    );

    selectedCard = {
      ...selectedCard,
      customer_vault_id: item.customer_vault_id,
      billing_id: item.billing_id,
      card_type: item.card_type,
    };

    setSelectedReccuringCards({
      ...selectedReccuringCards,
      recurrings: [selectedCard, ...otherCards],
    });
  };

  const TenantDropDowns = ({ tenant }) => {
    var selectedCard = selectedReccuringCards?.recurrings?.find(
      (item) => item?.tenant_id === tenant?.tenant_id
    );

    var card = tenant?.cards?.find(
      (item) => item?.billing_id === selectedCard?.billing_id
    );

    return (
      <Dropdown
        emptyMsg={"Card not found!"}
        disabled={false}
        options={tenant.cards}
        labelKey="cc_number"
        placeholder={card?.cc_number || "Select card"}
        valueKey="cc_number"
        subtitleKey="card_type"
        onSelect={(item) => handleSelection(item, tenant)}
      />
    );
  };

  const handleSubmit = async () => {
    try {
      await addRecurringCards(selectedReccuringCards);
      fetchTenantData();
      toggle();
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <h2>Select Reccuring Cards</h2>
      </ModalHeader>
      <ModalBody>
        {loader ? (
          <>
            <Loader />
          </>
        ) : (
          tenantData?.map((item, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center w-100 mt-3"
            >
              <spna
                className="textcolorblue fontfamilysty"
                style={{ fontSize: "18px", fontWeight: 500 }}
              >
                {item?.tenant_firstName || ""} {item?.tenant_lastName || ""}
              </spna>
              {<TenantDropDowns tenant={item} />}
            </div>
          ))
        )}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex", gap: "10px" }}>
          <BaseButton onClick={toggle} variant="outline" label="Cancel" />
          <BaseButton
            id="payButton"
            type="submit"
            disabled={false}
            label="Save"
            onClick={handleSubmit}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RecurringTenants;
