import { useFormik } from "formik";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "reactstrap";

import { FormControl } from "react-bootstrap";
import * as yup from "yup";
import { useCookies } from "react-cookie";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import html2canvas from "html2canvas";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  InputAdornment,
  DialogActions,
  TextField,
  FormLabel,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { withStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CloseIcon from "@mui/icons-material/Close";
import AccountDialog from "components/AccountDialog";
import { RotatingLines } from "react-loader-spinner";
import "./rentrollleaseing.css";
import axiosInstance from "axiosInstance";
import SearchDropdown from "components/inputfields/SearchDropdown";
import { verifyToken } from "components/Functions/Functions";

const RentRollLeaseing = () => {
  const [error, setError] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { lease_id, applicant_id, admin } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [typedSignature, setTypedSignature] = useState("");
  const [signatureRef, setSignatureRef] = useState(null);
  const [isFromDetails, setIsFromDetails] = useState(false);

  const [signatureOption, setSignatureOption] = useState("");
  const handleSignatureChange = (newOption) => {
    setSignatureOption(newOption);
  };
  const clearSignature = () => {
    signatureRef?.clear();
  };

  const [accessType, setAccessType] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  // const [cookies] = useCookies(["token"]);

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/admin/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if (response.status !== 200) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);

  useEffect(() => {
    if (location?.state?.lease_id) {
      setIsFromDetails(true);
    }
  }, [location?.state]);

  //dropdowns
  const [propertyDropdownOpen, setPropertyDropdownOpen] = useState(false);
  const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);
  const [leaseDropdownOpen, setLeaseDropdownOpen] = useState(false);
  const [rentCycleDropdownOpen, setRentCycleDropdownOpen] = useState(false);
  const [openRecurringDialog, setOpenRecurringDialog] = useState(false);
  const [openOneTimeChargeDialog, setOpenOneTimeChargeDialog] = useState(false);
  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] =
    useState(false);

  //checkbox
  const [checkedCheckbox, setCheckedCheckbox] = useState(false);
  const [checkedTenantIds, setCheckedTenantIds] = useState([]);
  const [rentincdropdownOpen1, setRentincdropdownOpen1] = useState(false);
  const [rentincdropdownOpen2, setRentincdropdownOpen2] = useState(false);
  const [rentincdropdownOpen3, setRentincdropdownOpen3] = useState(false);
  const [rentincdropdownOpen4, setRentincdropdownOpen4] = useState(false);
  const [rentincdropdownOpen5, setRentincdropdownOpen5] = useState(false);
  const [rentincdropdownOpen6, setRentincdropdownOpen6] = useState(false);
  const [collapseper, setCollapseper] = useState(false);
  const [collapsecont, setCollapsecont] = useState(false);

  //selected variable dependancy
  const [selectedProperty, setselectedProperty] = useState("");
  const [selectedUnit, setselectedUnit] = useState("");
  const [selectedLeaseType, setSelectedLeaseType] = useState("");
  const [selectedOption, setSelectedOption] = useState("Tenant");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRentCycle, setSelectedRentCycle] = useState("");
  const [accountTypeName, setAccountTypeName] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);

  //get response variables
  const [propertyData, setPropertyData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [accountsData, setAccountsData] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [selectedTenantData, setSelectedTenantData] = useState([]);
  const [cosignerData, setCosignerData] = useState([]);
  const [recurringData, setRecurringData] = useState([]);
  const [oneTimeData, setOneTimeData] = useState([]);

  //display
  const [openTenantsDialog, setOpenTenantsDialog] = useState(false);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [showTenantTable, setShowTenantTable] = useState(false);
  const [display, setDisplay] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForm, setShowForm] = useState("Tenant");

  // other isVariableStatement
  const [alignment, setAlignment] = useState("Tenant");
  const [file, setFile] = useState("");

  //toggles
  const toggle = () => setPropertyDropdownOpen((prevState) => !prevState);
  const toggle2 = () => setUnitDropdownOpen((prevState) => !prevState);
  const toggle3 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const toggle6 = () => setRentCycleDropdownOpen((prevState) => !prevState);
  const toggle7 = () => setRentincdropdownOpen6((prevState) => !prevState);
  const toggle8 = () => setRentincdropdownOpen5((prevState) => !prevState);

  const toggle4 = () => {
    setCollapseper(!collapseper);
  };
  const toggle5 = () => {
    setCollapsecont(!collapsecont);
  };
  const toggleAddBankDialog = () => {
    setAddBankAccountDialogOpen((prevState) => !prevState);
  };

  //loaders
  const [loader, setLoader] = useState(false);
  // const [dataLoader, setDataLoader] = useState(true);

  //dropdown options
  const rentOptions = [
    "Daily",
    "Weekly",
    "Every two weeks",
    "Monthly",
    "Every two months",
    "Quarterly",
    "Yearly",
  ];
  const selectPaymentMethodData = ["Manually", "AutoPayment"];

  //formik for form
  const leaseFormik = useFormik({
    initialValues: {
      rental_id: "",
      unit_id: "",
      lease_amount: "",
      tenant_id: [],
      lease_type: "",
      start_date: "",
      end_date: "",
      uploaded_file: [],
      tenant_residentStatus: false,
    },
    validationSchema: yup.object({
      rental_id: yup.string().required("Required"),
      // unit_id: yup.string().required("Required"),
      lease_type: yup.string().required("Required"),
      start_date: yup.string().required("Required"),
      end_date: yup.string().required("Required"),
    }),
    onSubmit: async () => {
      const res = await handleDateCheck();

      if (res === 200) {
        if (lease_id && applicant_id) {
          addLease();
        } else if (lease_id) {
          updateLease();
        } else {
          addLease();
        }
      } else {
        const errorMessage = `Please select another date range. Overlapping ( ${
          selectedUnit ? selectedUnit + " - " : ""
        }${selectedProperty} to ${selectedTenantData.tenant_firstName} ${
          selectedTenantData.tenant_lastName
        }) with existing lease.`;

        leaseFormik.setFieldError("start_date", errorMessage);
      }
    },
  });

  const rentChargeFormik = useFormik({
    initialValues: {
      amount: "",
      memo: "Rent Income",
      charge_type: "Rent",
      account: "Rent Income",
      date: "",
      rent_cycle: "",
      is_paid: false,
      is_repeatable: true,
      is_lateFee: false,
    },
    validationSchema: yup.object({
      amount: yup.number().required("Required"),
      rent_cycle: yup.string().required("Required"),
    }),
  });

  const securityChargeFormik = useFormik({
    initialValues: {
      amount: "",
      memo: "Security Deposit",
      charge_type: "Security Deposit",
      account: "Security Deposit",
      date: "",
      is_paid: false,
      is_repeatable: false,
      is_lateFee: false,
    },
    validationSchema: yup.object({
      amount: yup.number().required("Required"),
    }),
  });

  let recurringFormink = useFormik({
    initialValues: {
      entry_id: "",
      amount: "",
      memo: "Recurring Charge",
      charge_type: "Recurring Charge",
      account: "",
      date: "",
      rent_cycle: "",
      is_repeatable: true,
      is_paid: false,
      is_lateFee: false,
    },
    validationSchema: yup.object({
      amount: yup.string().required("Required"),
      account: yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (editingIndex !== null) {
        setRecurringData((prevRecurringData) => {
          const updatedData = [...prevRecurringData];
          updatedData[editingIndex] = values;
          return updatedData;
        });

        setEditingIndex(null);
      } else {
        setRecurringData((prevRecurringData) => [...prevRecurringData, values]);
      }
      toast.success("Recurring Charge Added", {
        position: "top-center",
        autoClose: 1000,
      });
      setOpenRecurringDialog(false);
      resetForm();
    },
  });

  let oneTimeFormik = useFormik({
    initialValues: {
      entry_id: "",
      amount: "",
      memo: "One Time Charge",
      charge_type: "One Time Charge",
      account: "",
      date: "",
      is_repeatable: false,
      is_paid: false,
      is_lateFee: false,
    },
    validationSchema: yup.object({
      amount: yup.string().required("Required"),
      account: yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (editingIndex !== null) {
        setOneTimeData((prevOneTimeData) => {
          const updatedData = [...prevOneTimeData];
          updatedData[editingIndex] = values;
          return updatedData;
        });

        setEditingIndex(null);
      } else {
        setOneTimeData((prevOneTimeData) => [...prevOneTimeData, values]);
      }
      toast.success("One Time Charge Added", {
        position: "top-center",
        autoClose: 1000,
      });
      setOpenOneTimeChargeDialog(false);
      resetForm();
    },
  });

  const tenantFormik = useFormik({
    initialValues: {
      tenant_id: "",
      tenant_firstName: "",
      tenant_lastName: "",
      tenant_phoneNumber: "",
      tenant_alternativeNumber: "",
      tenant_email: "",
      tenant_alternativeEmail: "",
      tenant_password: "",
      tenant_birthDate: "",
      taxPayer_id: "",
      comments: "",
      percentage: "",
      emergency_contact: {
        name: "",
        relation: "",
        email: "",
        phoneNumber: "",
      },
    },
    validationSchema: yup.object({
      tenant_firstName: yup.string().required("Required"),
      tenant_lastName: yup.string().required("Required"),
      tenant_phoneNumber: yup.string().required("Required"),
      tenant_email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      // tenant_password: yup
      //   .string()
      //   .min(8, "Password is too short")
      //   .matches(
      //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //     "Must Contain One Uppercase, One Lowercase, One Number, and one special case Character"
      //   )
      //   .required("Required"),
    }),
    onSubmit: (values) => {
      toast.success("Tenant Added Successfully", {
        position: "top-center",
        autoClose: 1000,
      });
      setSelectedTenantData({
        tenant_firstName: values.tenant_firstName,
        tenant_lastName: values.tenant_lastName,
        tenant_phoneNumber: values.tenant_phoneNumber,
        percentage: values.percentage || 0,
      });
      leaseFormik.setFieldValue("tenantData", [values]);

      // setSelectedTenants((prevTenants) => {
      //   if (!Array.isArray(prevTenants)) {
      //     console.error("selectedTenants is not an array:", prevTenants);
      //     prevTenants = [];
      //   }
      //   return [...prevTenants, values];
      // });
      setTenantWithPercentage([...selectedTenants, values]);

      setOpenTenantsDialog(false);
      setDisplay(false);
      tenantFormik.resetForm();
    },
  });

  const cosignerFormik = useFormik({
    initialValues: {
      cosigner_id: "",
      cosigner_firstName: "",
      cosigner_lastName: "",
      cosigner_phoneNumber: "",
      cosigner_alternativeNumber: "",
      cosigner_email: "",
      cosigner_alternativeEmail: "",
      cosigner_address: "",
      cosigner_city: "",
      cosigner_country: "",
      cosigner_postalcode: "",
    },
    validationSchema: yup.object({
      cosigner_firstName: yup.string().required("Required"),
      cosigner_lastName: yup.string().required("Required"),
      cosigner_phoneNumber: yup.number().required("Required"),
      cosigner_email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      toast.success("Cosigner Added Successfully", {
        position: "top-center",
        autoClose: 1000,
      });
      setOpenTenantsDialog(false);
      setCosignerData(values);
    },
  });

  // const handlePercentageChange = (index, value) => {
  //   const numericValue = value.replace(/\D/g, "");
  //   const clampedValue = Math.min(Number(numericValue), 100);
  //   const updatedTenants = [...selectedTenants];
  //   updatedTenants[index].percentage = clampedValue;
  //   setSelectedTenants(updatedTenants);
  // };

  const handlePercentageChange = (index, value) => {
    const numericValue = value.replace(/\D/g, "");
    let clampedValue = Math.min(Number(numericValue), 100);

    // const totalOtherPercentages = selectedTenants.reduce(
    //   (acc, tenant, i) => (i !== index ? acc + tenant.percentage : acc),
    //   0
    // );

    const updatedTenants = [...selectedTenants];
    updatedTenants[index].percentage = clampedValue;
    setSelectedTenants(updatedTenants);

    const totalPercentage = updatedTenants.reduce(
      (acc, tenant) => acc + Number(tenant.percentage || 0),
      0
    );

    if (totalPercentage === 100) {
      setError("");
    } else {
      setError("Total percentage must be exactly 100%");
    }
  };

  //update lease
  const updateLease = async () => {
    setLoader(true);
    const fileUrl = [];

    if (file) {
      try {
        const uploadPromises = file.map(async (fileItem, i) => {
          if (fileItem instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem);

              const res = await axios.post(`${imageUrl}/images/upload`, form);

              if (
                res &&
                res.data &&
                res.data.files &&
                res.data.files.length > 0
              ) {
                fileUrl.push(res.data.files[0].filename);
                fileItem = res.data.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            fileUrl.push(fileItem);
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    try {
      const entryData = [];
      const rentEntryData = rentChargeFormik?.values?.entry_id
        ? {
            entry_id: rentChargeFormik.values.entry_id,
            memo: rentChargeFormik.values.memo,
            account: rentChargeFormik.values.account,
            amount: rentChargeFormik.values.amount,
            date: rentChargeFormik.values.date,
            rent_cycle: rentChargeFormik.values.rent_cycle,
            is_repeatable: true,
            charge_type: rentChargeFormik.values.charge_type,
          }
        : null;

      if (rentEntryData) {
        entryData.push(rentEntryData);
      }

      const depoEntryData = securityChargeFormik?.values?.entry_id
        ? {
            entry_id: securityChargeFormik.values.entry_id,
            memo: securityChargeFormik.values.memo,
            account: securityChargeFormik.values.account,
            amount: securityChargeFormik.values.amount,
            date: securityChargeFormik.values.date,
            is_repeatable: false,
            charge_type: securityChargeFormik.values.charge_type,
          }
        : null;

      if (depoEntryData) {
        entryData.push(depoEntryData);
      }

      recurringData?.forEach((item) => {
        if (item) {
          entryData.push({
            entry_id: item.entry_id || "",
            memo: item.memo || "",
            account: item.account || "",
            amount: item.amount || 0,
            date: rentChargeFormik.values.date || "",
            rent_cycle: rentChargeFormik.values.rent_cycle || "",
            is_repeatable: true,
            charge_type: item.charge_type || "",
          });
        }
      });

      oneTimeData?.forEach((item) => {
        if (item) {
          entryData.push({
            entry_id: item.entry_id || "",
            memo: item.memo || "",
            account: item.account || "",
            amount: item.amount || 0,
            date: rentChargeFormik.values.date || "",
            is_repeatable: false,
            charge_type: item.charge_type || "",
          });
        }
      });

      const tenantData = selectedTenants;

      const rentAmount = parseFloat(rentChargeFormik.values.amount) || 0;

      const totalRecurringAmount =
        recurringData?.reduce(
          (total, item) => total + parseFloat(item.amount),
          0
        ) || 0;

      const totalLeaseAmount = rentAmount + totalRecurringAmount;

      const object = {
        leaseData: {
          ...leaseFormik.values,
          company_name: admin,
          tenant_id: checkedTenantIds,
          entry: entryData,
          admin_id: accessType?.admin_id,
          lease_amount: totalLeaseAmount,
          uploaded_file: fileUrl,
          lease_id: lease_id,
        },
        tenantData: selectedTenants.map((tenant) => ({
          ...tenant,
          admin_id: accessType?.admin_id,
        })),
        cosignerData: {
          ...cosignerFormik.values,
          admin_id: accessType?.admin_id,
        },
      };

      const res = await axiosInstance.put(`/leases/leases/${lease_id}`, object);
      if (res.data.statusCode === 200) {
        toast.success("Lease Updated Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          if (isFromDetails) {
            navigate(`/${admin}/rentrolldetail/${lease_id}`);
          } else {
            navigate(`/${admin}/RentRoll`);
          }
        }, 2000);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoader(false);
  };

  const addLease = async () => {
    setLoader(true);
    const fileUrl = [];
    if (file) {
      try {
        const uploadPromises = file.map(async (fileItem, i) => {
          if (fileItem instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem);

              const res = await axios.post(`${imageUrl}/images/upload`, form);
              if (
                res &&
                res.data &&
                res.data.files &&
                res.data.files.length > 0
              ) {
                fileUrl.push(res.data.files[0].filename);
                fileItem = res.data.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            fileUrl.push(fileItem);
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    const entryData = [];
    // const rentEntryData = {
    //   memo: rentChargeFormik.values.memo,
    //   account: rentChargeFormik.values.account,
    //   amount: rentChargeFormik.values.amount,
    //   date: rentChargeFormik.values.date,
    //   rent_cycle: rentChargeFormik.values.rent_cycle,
    //   is_repeatable: true,
    //   charge_type: rentChargeFormik.values.charge_type,
    // };

    // if (rentEntryData) {
    //   entryData.push(rentEntryData);
    // }

    // const depoEntryData = {
    //   memo: securityChargeFormik.values.memo,
    //   account: securityChargeFormik.values.account,
    //   amount: securityChargeFormik.values.amount,
    //   date: securityChargeFormik.values.date,
    //   is_repeatable: false,
    //   charge_type: securityChargeFormik.values.charge_type,
    // };

    // if (depoEntryData) {
    //   entryData.push(depoEntryData);
    // }

    oneTimeData?.map((item) => {
      const data = {
        memo: item.memo,
        account: item.account,
        amount: item.amount,
        date: rentChargeFormik.values.date,
        is_repeatable: false,
        charge_type: item.charge_type,
      };
      if (data) {
        entryData.push(data);
      }
      return data;
    });

    const rentAmount = parseFloat(rentChargeFormik.values.amount) || 0;

    const totalRecurringAmount =
      recurringData?.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      ) || 0;

    const totalLeaseAmount = rentAmount + totalRecurringAmount;

    // Calculate each tenant's rent share
    // selectedTenants.forEach((tenant, index) => {
    //   const percentage = parseFloat(tenant.percentage) || 0;
    //   // const tenantRentAmount = (rentAmount * percentage) / 100;
    //   // const tenantDepositAmount =
    //   //   (securityChargeFormik.values.amount * percentage) / 100;
    //   const tenantID = tenant.tenant_id;

    const tenantRentEntry = {
      memo: rentChargeFormik.values.memo,
      account: rentChargeFormik.values.account,
      amount: rentChargeFormik.values.amount,
      date: rentChargeFormik.values.date,
      rent_cycle: rentChargeFormik.values.rent_cycle,
      is_repeatable: true,
      charge_type: rentChargeFormik.values.charge_type,
    };

    const depoEntryData = {
      memo: securityChargeFormik.values.memo,
      account: securityChargeFormik.values.account,
      amount: securityChargeFormik.values.amount,
      date: securityChargeFormik.values.date,
      is_repeatable: false,
      charge_type: securityChargeFormik.values.charge_type,
    };

    const tenantRecurringData = recurringData.map((item) => {
      const data = {
        memo: item.memo,
        account: item.account,
        amount: item.amount,
        date: rentChargeFormik.values.date,
        rent_cycle: rentChargeFormik.values.rent_cycle,
        is_repeatable: true,
        charge_type: item.charge_type,
      };
      return data;
    });
    entryData.push(tenantRentEntry, depoEntryData, ...tenantRecurringData);
    // });

    const object = {
      leaseData: {
        ...leaseFormik.values,
        company_name: admin,
        entry: entryData,
        admin_id: accessType?.admin_id,
        lease_amount: totalLeaseAmount,
        uploaded_file: fileUrl,
      },
      tenantData: selectedTenants.map((tenant) => ({
        ...tenant,
        admin_id: accessType?.admin_id,
      })),
      cosignerData: {
        ...cosignerFormik.values,
        admin_id: accessType?.admin_id,
      },
      chargeData: {
        admin_id: accessType?.admin_id,
        is_leaseAdded: true,
        entry: entryData,
      },
    };

    try {
      const res = await axiosInstance.post(`/leases/leases`, object);
      if (res.data.statusCode === 201) {
        toast.error(res.data.message, {
          position: "top-center",
          autoClose: 5000,
        });
        return;
      }

      if (res.data.statusCode === 200) {
        if (applicant_id) {
          const res2 = await axiosInstance.put(
            `/applicant/applicant/${applicant_id}`,
            {
              isMovedin: true,
              applicant_status: [
                {
                  status: "Approved",
                  statusUpdatedBy: "Admin",
                },
              ],
            }
          );
          if (res2.data.statusCode === 200) {
            toast.success("Lease Added Successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            setTimeout(() => {
              navigate(`/${admin}/RentRoll`);
            }, 2000);
          }
        } else {
          toast.success("Lease Added Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          setTimeout(() => {
            navigate(`/${admin}/RentRoll`);
          }, 2000);
          // setTimeout(() => {
          //   navigate(`/${admin}/RentRoll`);
          // }, 2000);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoader(false);
  };

  //onchange funtions
  const handlePropertyTypeSelect = async (property) => {
    try {
      setselectedProperty(property.rental_adress);
      leaseFormik.setFieldValue("rental_id", property.rental_id);
      setselectedUnit("");
      leaseFormik.setFieldValue("unit_id", "");
      await fetchUnitData(property.rental_id);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleUnitSelect = (unit) => {
    setselectedUnit(unit?.rental_unit);
    leaseFormik.setFieldValue("unit_id", unit?.unit_id);
  };

  useEffect(() => {
    if (state?.unit_id) {
      const property = {
        rental_adress: state.rental_adress,
        rental_id: state.rental_id,
      };
      handlePropertyTypeSelect(property);
      const unit = {
        rental_unit: state.rental_unit,
        unit_id: state.unit_id,
      };
      handleUnitSelect(unit);
    }
  }, []);

  const handleLeaseTypeSelect = (lease) => {
    setSelectedLeaseType(lease);
    leaseFormik.setFieldValue("lease_type", lease);
  };

  const handleDateChange = (date) => {
    if (date) {
      const nextDate = moment(date).add(1, "years").format("YYYY-MM-DD");
      leaseFormik.setFieldValue("end_date", nextDate);
    }
  };

  const handleClose = () => {
    setOpenTenantsDialog(false);
    setOpenRecurringDialog(false);
    setOpenOneTimeChargeDialog(false);
  };

  const handleChange = (value) => {
    setShowTenantTable(!showTenantTable);
    setAlignment(value);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCheckboxChange = (event, tenant) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if (!checkedTenantIds.includes(tenant.tenant_id)) {
        setCheckedTenantIds((prevState) => [...prevState, tenant.tenant_id]);
      }
    } else {
      setCheckedTenantIds((prevState) =>
        prevState.filter((id) => id !== tenant.tenant_id)
      );
    }
  };

  const setTenantWithPercentage = (uniqueTenants) => {
    uniqueTenants.forEach((element, i) => {
      if (i === 0) {
        element.percentage = element.percentage || 100;
      } else {
        element.percentage = element.percentage || 0;
      }
    });
    setSelectedTenants(uniqueTenants);
  };

  const handleAddTenant = () => {
    const selected = tenantData.filter((tenant) =>
      checkedTenantIds.includes(tenant.tenant_id)
    );
    const newTenantsData = [...selectedTenants, ...selected];
    var uniqueTenants = newTenantsData.filter(
      (tenant, index, self) =>
        index === self.findIndex((t) => t.tenant_id === tenant.tenant_id)
    );
    // var total = uniqueTenants.reduce(
    //   (acc, elem) => acc + Number(elem.percentage || 0),
    //   0
    // );
    // uniqueTenants.forEach((element) => {
    //   if (total >= 100 && total + percentage >= 100) {
    //     element.percentage = element.percentage || 0;
    //   } else {
    //     if (element.percentage) {
    //       total += percentage;
    //     } else {
    //       element.percentage = percentage;
    //       total += percentage;
    //     }
    //   }
    // });
    setTenantWithPercentage(uniqueTenants);
    handleClose();
    setDisplay(false);
  };

  const handleTenantDelete = (tenantToDelete) => {
    setSelectedTenantData({});
    setSelectedTenants((prevSelected) =>
      prevSelected.filter((tenant) => tenant !== tenantToDelete)
    );
    setCheckedTenantIds((prevChecked) =>
      prevChecked.filter((id) => id !== tenantToDelete.tenant_id)
    );
    setCheckedCheckbox(null);
    tenantFormik.resetForm();
  };

  const handleCosignerDelete = () => {
    setCosignerData({});
    cosignerFormik.resetForm();
  };

  const handleselectedRentCycle = (rentcycle) => {
    if (rentcycle && leaseFormik?.values?.start_date) {
      setSelectedRentCycle(rentcycle);

      const startDate = moment(leaseFormik.values.start_date, "YYYY-MM-DD");
      let dayFrequency = 0;

      switch (rentcycle) {
        case "Daily":
          dayFrequency = 1;
          break;
        case "Weekly":
          dayFrequency = 7;
          break;
        case "Every two weeks":
          dayFrequency = 14;
          break;
        case "Monthly":
          dayFrequency = 30;
          break;
        case "Every two months":
          dayFrequency = 60;
          break;
        case "Quarterly":
          dayFrequency = 90;
          break;
        default:
          dayFrequency = 365;
          break;
      }

      const nextDueDate = startDate
        .add(dayFrequency, "days")
        .format("YYYY-MM-DD");

      const formikFields = [
        { formik: recurringFormink, field: "date" },
        { formik: oneTimeFormik, field: "date" },
        { formik: rentChargeFormik, field: "date" },
        { formik: securityChargeFormik, field: "date" },
      ];

      formikFields.forEach(({ formik, field }) => {
        if (formik && formik.setFieldValue) {
          if (formik.values && formik.values[field] !== undefined) {
            formik.setFieldValue(field, nextDueDate);
          } else {
            console.warn(`Field ${field} does not exist in formik.values.`);
          }
        } else {
          console.warn(`Formik instance or setFieldValue is not defined.`);
        }
      });

      const formikRentCycleFields = [
        { formik: rentChargeFormik, field: "rent_cycle" },
        { formik: recurringFormink, field: "rent_cycle" },
      ];

      formikRentCycleFields.forEach(({ formik, field }) => {
        if (formik && formik.setFieldValue) {
          if (formik.values && formik.values[field] !== undefined) {
            formik.setFieldValue(field, rentcycle);
          } else {
            console.warn(`Field ${field} does not exist in formik.values.`);
          }
        } else {
          console.warn(`Formik instance or setFieldValue is not defined.`);
        }
      });
    }
  };

  useEffect(() => {
    if (leaseFormik?.values?.start_date && selectedRentCycle) {
      handleselectedRentCycle(selectedRentCycle);
    }
  }, [leaseFormik?.values?.start_date, selectedRentCycle]);

  // useEffect(() => {
  //     if (leaseFormik?.values?.start_date) {
  //         if (selectedRentCycle) {
  //             handleselectedRentCycle(selectedRentCycle);
  //         }
  //         handleDateChange(leaseFormik?.values?.start_date);
  //     }
  // }, [leaseFormik?.values?.start_date]);

  const handleClickOpenRecurring = () => {
    recurringFormink.resetForm();
    setOpenRecurringDialog(true);
  };

  const handleClickOpenOneTimeCharge = () => {
    oneTimeFormik.resetForm();
    setOpenOneTimeChargeDialog(true);
  };

  const handleClick1 = () => {
    setRentincdropdownOpen1(!rentincdropdownOpen1);
  };

  const handleClick2 = () => {
    setRentincdropdownOpen2(!rentincdropdownOpen2);
  };

  const handleClick3 = () => {
    setRentincdropdownOpen3(!rentincdropdownOpen3);
  };

  const handleClick4 = () => {
    setRentincdropdownOpen4(!rentincdropdownOpen4);
  };

  const AddNewAccountName = async (accountName) => {
    toggleAddBankDialog();
    setAccountTypeName(accountName);
  };

  const editeReccuring = (index) => {
    setOpenRecurringDialog(true);
    setEditingIndex(index);
    recurringFormink.setValues({
      entry_id: recurringData[index].entry_id,
      amount: recurringData[index].amount,
      account: recurringData[index].account,
      memo: recurringData[index].memo,
      charge_type: "Recurring Charge",
      date: "",
      rent_cycle: "",
      is_paid: false,
      is_lateFee: false,
    });
  };

  const editOneTime = (index) => {
    setOpenOneTimeChargeDialog(true);
    setEditingIndex(index);
    oneTimeFormik.setValues({
      entry_id: oneTimeData[index].entry_id,
      amount: oneTimeData[index].amount,
      account: oneTimeData[index].account,
      memo: oneTimeData[index].memo,
      charge_type: "One Time Charge",
      date: "",
      rent_cycle: "",
      is_paid: false,
      is_lateFee: false,
    });
  };

  const handleRecurringDelete = (indexToDelete) => {
    setRecurringData((prevData) => {
      return prevData.filter((data, index) => index !== indexToDelete);
    });
  };

  const handleOnetimeDelete = (indexToDelete) => {
    setOneTimeData((prevData) => {
      return prevData.filter((data, index) => index !== indexToDelete);
    });
  };

  const handleCloseButtonClick = () => {
    if (isFromDetails) {
      navigate(`/${admin}/rentrolldetail/${lease_id}`);
    } else {
      navigate("/" + admin + "/RentRoll");
    }
  };

  const handleDateCheck = async () => {
    const object = {
      admin_id: accessType?.admin_id,
      lease_id: lease_id,
      tenant_id: tenantFormik.values.tenant_id,
      rental_id: leaseFormik.values.rental_id,
      unit_id: leaseFormik.values.unit_id,
      start_date: leaseFormik.values.start_date,
      end_date: leaseFormik.values.end_date,
    };
    try {
      const { admin_id, lease_id, tenant_id, rental_id, start_date, end_date } =
        object;
      if (
        tenant_id !== "" &&
        rental_id !== "" &&
        start_date !== "" &&
        end_date !== ""
      ) {
        const res = await axiosInstance.post(`/leases/check_lease`, object);
        if (res.data.statusCode === (201 || 404)) {
          toast.warning(res.data.message, {
            position: "top-center",
          });
          return;
        }
        if (res.data.statusCode === 200) {
          return res.data.statusCode;
        }
      } else {
        return 200;
      }
    } catch (error) {
      console.error("Error :", error.message);
    }
  };

  //get data apis
  const fetchPropertyData = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/rentals/rentals/${accessType?.admin_id}`
        );
        if (res.data.statusCode === 200) {
          setPropertyData(res.data.data);
        } else if (res.data.statusCode === 201) {
          setPropertyData([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchUnitData = async (rental_id) => {
    if (rental_id) {
      try {
        const res = await axiosInstance.get(
          `/unit/rental_unit_dropdown/${rental_id}`
        );
        if (res.data.statusCode === 200) {
          const filteredData = res.data.data.filter(
            (item) => item.rental_unit !== ""
          );
          if (filteredData.length === 1) {
            leaseFormik.setFieldValue("unit_id", res.data.data[0].unit_id);
          }
          setUnitData(filteredData);
        } else if (res.data.statusCode === 201) {
          setUnitData([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchAccounts = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/accounts/accounts/${accessType?.admin_id}`
        );
        if (res.data.statusCode === 200) {
          setAccountsData(res.data.data);
        } else if (res.data.statusCode === 201) {
          setAccountsData([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchTenantData = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/tenant/tenants/${accessType?.admin_id}`
        );
        if (res.data.statusCode === 200) {
          setTenantData(res.data.data);
        } else if (res.data.statusCode === 201) {
          setTenantData([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const [editLoader, setEditLoader] = useState(lease_id ? true : false);

  const fetchLeaseData = async () => {
    if (lease_id) {
      try {
        const res = await axiosInstance.get(`/leases/get_lease/${lease_id}`);
        if (res.data.statusCode === 200) {
          const { data } = res.data;
          if (!data) {
            console.error("Empty data object received.");
            return;
          }

          const {
            leases,
            tenant,
            rent_charge_data,
            security_charge_data,
            rec_charge_data,
            one_charge_data,
            unit_data,
          } = data;

          leaseFormik.setValues(leases);
          tenantFormik.setValues(
            !Array.isArray(tenant)
              ? tenant
              : {
                  tenant_id: "",
                  tenant_firstName: "",
                  tenant_lastName: "",
                  tenant_phoneNumber: "",
                  tenant_alternativeNumber: "",
                  tenant_email: "",
                  tenant_alternativeEmail: "",
                  tenant_password: "",
                  tenant_birthDate: "",
                  taxPayer_id: "",
                  comments: "",
                  percentage: "",
                  emergency_contact: {
                    name: "",
                    relation: "",
                    email: "",
                    phoneNumber: "",
                  },
                }
          );
          setSelectedTenantData(tenant);
          setSelectedTenants(tenant);
          setSelectedLeaseType(leases?.lease_type || "Fixed");
          setSelectedRentCycle(rent_charge_data?.[0]?.rent_cycle || "Monthly");
          leaseFormik.setFieldValue("lease_type", "Fixed");
          rentChargeFormik.setFieldValue("rent_cycle", "Monthly");
          recurringFormink.setFieldValue("rent_cycle", "Monthly");

          if (rent_charge_data) {
            rentChargeFormik.setValues(rent_charge_data?.[0]);
          }
          if (security_charge_data) {
            securityChargeFormik.setValues(security_charge_data?.[0]);
          }
          if (rec_charge_data) {
            setRecurringData(rec_charge_data || []);
          }
          if (one_charge_data) {
            setOneTimeData(one_charge_data || []);
          }

          if (leases && propertyData) {
            const property = propertyData.find(
              (property) => property.rental_id === leases.rental_id
            );
            if (property) {
              await handlePropertyTypeSelect(property);
            }
          }
          setFile(leases?.uploaded_file);
          // Handle unit selection
          handleUnitSelect(unit_data);
          const tenantIds = tenant?.map((t) => t.tenant_id);
          setCheckedTenantIds(tenantIds);
          setCheckedCheckbox(tenant);

          if (applicant_id) {
            setDisplay(true);
          }
        }
        // setLoader(false);
      } catch (error) {
        console.error("Error:", error.message);
        // setLoader(false);
      } finally {
        setEditLoader(false);
      }
    }
  };

  //get data apis useeffect
  useEffect(() => {
    fetchPropertyData();
    fetchAccounts();
    fetchTenantData();
  }, [accessType]);

  useEffect(() => {
    if (lease_id) {
      fetchLeaseData();
    }
  }, [lease_id, propertyData]);

  //files set
  const fileData = (files) => {
    const filesArray = [...files];

    if (filesArray.length <= 10 && file?.length === 0) {
      const finalArray = [];

      for (let i = 0; i < filesArray.length; i++) {
        finalArray.push(filesArray[i]);
      }

      setFile([...finalArray]);
    } else if (
      file?.length >= 0 &&
      file?.length <= 10 &&
      filesArray.length + file?.length > 10
    ) {
      setFile([...file]);
    } else {
      const finalArray = [];

      for (let i = 0; i < filesArray.length; i++) {
        finalArray.push(filesArray[i]);
      }

      setFile([...file, ...finalArray]);
    }
  };

  const deleteFile = (index) => {
    const newFile = [...file];
    newFile.splice(index, 1);
    setFile(newFile);
  };

  return (
    <>
      <Container
        className="containerremovespace stylecontainer"
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className="mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {lease_id && applicant_id
                ? "Add Lease"
                : lease_id
                ? "Update Lease"
                : "Add Lease"}
            </h2>
          </CardHeader>
        </Col>
        <Row>
          <Col className="order-xl-1" xl="12">
            {loader || editLoader ? (
              <Card>
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={loader || editLoader}
                  />
                </div>
              </Card>
            ) : (
              <Form>
                <Card
                  className="mt-3 mx-3 shadow cardstyborderradius"
                  style={{
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  <CardBody>
                    {/* lease */}
                    <div className="">
                      <Row>
                        <Col lg="4" md="4" sm="6" xl="3">
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-property"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Property *
                          </label>
                          <FormGroup>
                            {/* <Dropdown
                              className="dropdownfontsyle"
                              isOpen={propertyDropdownOpen}
                              toggle={toggle}
                            >
                              <DropdownToggle
                                className="propertydropp dropdownfontsyle titleecolor"
                                caret
                                style={{
                                  width: "100%",
                                  boxShadow: " 0px 4px 4px 0px #00000040",
                                  border: "1px solid #ced4da",

                                  backgroundColor: "transparent",
                                }}
                              >
                                {selectedProperty
                                  ? selectedProperty.length > 12
                                    ? selectedProperty.substring(0, 12) + "..."
                                    : selectedProperty
                                  : "Select Property"}
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdownfontsyle"
                                style={{
                                  width: "max-content",
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {propertyData.map((property, index) => (
                                  <DropdownItem
                                    className="dropdownfontsyle"
                                    key={index}
                                    onClick={() => {
                                      handlePropertyTypeSelect(property);
                                    }}
                                  >
                                    {property.rental_adress}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                              {leaseFormik.errors &&
                              leaseFormik.errors?.rental_id &&
                              leaseFormik.touched &&
                              leaseFormik.touched?.rental_id ? (
                                <div
                                  className="requiredstylefont"
                                  style={{ color: "red" }}
                                >
                                  {leaseFormik.errors.rental_id}
                                </div>
                              ) : null}
                            </Dropdown> */}
                            <SearchDropdown
                              options={propertyData}
                              value={
                                propertyData.find(
                                  (p) => p.rental_adress === selectedProperty
                                ) || null
                              }
                              // inputValue={selectedProp}
                              onChange={(event, value) =>
                                handlePropertyTypeSelect(value)
                              }
                              placeholder="Select Property"
                              getOptionLabel={(option) =>
                                option.rental_adress || ""
                              }
                              filterOptions={(options, state) => {
                                return options.filter((option) =>
                                  option.rental_adress
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                );
                              }}
                            />
                            {leaseFormik.errors &&
                            leaseFormik.errors?.rental_id &&
                            leaseFormik.touched &&
                            leaseFormik.touched?.rental_id ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {leaseFormik.errors.rental_id}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        {/* </Row>
                      <Row className="mt-2"> */}
                        <Col lg="3" md="4" sm="6">
                          {((selectedProperty && unitData?.length > 0) ||
                            selectedUnit) && (
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                htmlFor="input-unit"
                                style={{
                                  // marginLeft: "15px",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Unit *
                              </label>
                              <FormGroup
                              // style={{ marginLeft: "15px" }}
                              >
                                <Dropdown
                                  className="dropdownfontsyle"
                                  isOpen={unitDropdownOpen}
                                  toggle={toggle2}
                                >
                                  <DropdownToggle
                                    className="dropdownfontsyle titleecolor"
                                    caret
                                    style={{
                                      width: "100%",
                                      boxShadow: " 0px 4px 4px 0px #00000040",
                                      border: "1px solid #ced4da",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {selectedUnit
                                      ? selectedUnit
                                      : "Select Unit"}
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdownfontsyle">
                                    {unitData.map((unit) => (
                                      <DropdownItem
                                        className="dropdownfontsyle"
                                        key={unit.unit_id}
                                        onClick={() => handleUnitSelect(unit)}
                                      >
                                        {unit.rental_unit}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                  {leaseFormik.errors &&
                                  leaseFormik.errors?.unit_id &&
                                  leaseFormik.touched &&
                                  leaseFormik.touched?.unit_id ? (
                                    <div
                                      className="requiredstylefont"
                                      style={{ color: "red" }}
                                    >
                                      {leaseFormik.errors.unit_id}
                                    </div>
                                  ) : null}
                                </Dropdown>
                              </FormGroup>
                            </FormGroup>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg="5" md="5" sm="12" xl="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Lease Type *
                            </label>
                            <br />
                            <Dropdown
                              className="dropdownfontsyle"
                              isOpen={leaseDropdownOpen}
                              toggle={toggle3}
                            >
                              <DropdownToggle
                                caret
                                className="leasetypee dropdownfontsyle titleecolor"
                                style={{
                                  width: "100%",
                                  boxShadow: " 0px 4px 4px 0px #00000040",
                                  border: "1px solid #ced4da",

                                  backgroundColor: "transparent",
                                }}
                              >
                                {selectedLeaseType
                                  ? selectedLeaseType ===
                                    "At-will(month to month)"
                                    ? "At-will(month...)"
                                    : selectedLeaseType
                                  : "Select Lease"}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdownfontsyle"
                                style={{ width: "100%" }}
                              >
                                <DropdownItem
                                  className="dropdownfontsyle"
                                  onClick={() => handleLeaseTypeSelect("Fixed")}
                                >
                                  Fixed
                                </DropdownItem>
                                <DropdownItem
                                  className="dropdownfontsyle"
                                  onClick={() =>
                                    handleLeaseTypeSelect("Fixed w/rollover")
                                  }
                                >
                                  Fixed w/rollover
                                </DropdownItem>
                                <DropdownItem
                                  className="dropdownfontsyle"
                                  onClick={() =>
                                    handleLeaseTypeSelect(
                                      "At-will(month to month)"
                                    )
                                  }
                                >
                                  At-will(month to month)
                                </DropdownItem>
                              </DropdownMenu>
                              {leaseFormik.errors &&
                              leaseFormik.errors?.lease_type &&
                              leaseFormik.touched &&
                              leaseFormik.touched?.lease_type ? (
                                <div
                                  className="requiredstylefont"
                                  style={{ color: "red" }}
                                >
                                  {leaseFormik.errors.lease_type}
                                </div>
                              ) : null}
                            </Dropdown>
                          </FormGroup>
                        </Col>
                        {/* &nbsp; &nbsp; &nbsp; &nbsp; */}
                        <Col lg="3" md="4" sm="6" xl="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-unitadd1"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Start Date *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              className="form-control-alternative fontstylerentr titleecolor"
                              id="input-unitadd1"
                              placeholder="3000"
                              type="date"
                              name="start_date"
                              onBlur={leaseFormik.handleBlur}
                              onChange={(e) => {
                                handleDateChange(e.target.value);
                                leaseFormik.handleChange(e);
                              }}
                              value={moment(
                                leaseFormik.values.start_date
                              ).format("YYYY-MM-DD")}
                            />
                            {leaseFormik.errors &&
                            leaseFormik.errors?.start_date &&
                            leaseFormik.touched &&
                            leaseFormik.touched?.start_date ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {leaseFormik.errors.start_date}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        {/* &nbsp; &nbsp; &nbsp; */}
                        <Col
                          lg="3"
                          md="4"
                          sm="6"
                          xl="3"
                          style={
                            selectedLeaseType === "At-will(month to month)"
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-unitadd2"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              End Date
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              className="form-control-alternative fontstylerentr titleecolor"
                              id="input-unitadd2"
                              placeholder="3000"
                              type="date"
                              name="end_date"
                              onBlur={leaseFormik.handleBlur}
                              onChange={(e) => {
                                leaseFormik.handleChange(e);
                              }}
                              value={moment(leaseFormik.values.end_date).format(
                                "YYYY-MM-DD"
                              )}
                              min={moment(leaseFormik.values.start_date).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="mt-3 mx-3 shadow cardstyborderradius"
                  style={{
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  <CardBody>
                    {/* tenant and cosigner */}
                    <h6
                      className=" mb-4 labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Add lease
                    </h6>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <span
                            className="fontstylerentr fontfamilysty"
                            onClick={() => {
                              setShowTenantTable(false);
                              setOpenTenantsDialog(true);
                              setAlignment("Tenant");
                            }}
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#26C22C",
                            }}
                          >
                            <b
                              className="labelfontstyle"
                              style={{ fontSize: "20px" }}
                            >
                              +
                            </b>{" "}
                            Add Tenant or Cosigner
                            {display === false ? (
                              <></>
                            ) : (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                Required
                              </div>
                            )}
                          </span>

                          <Dialog
                            open={openTenantsDialog}
                            onClose={handleClose}
                          >
                            <DialogTitle
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              Add Tenant or Cosigner
                            </DialogTitle>
                            <DialogContent
                              style={{ width: "100%", maxWidth: "500px" }}
                            >
                              <div
                                style={{
                                  alignItems: "center",
                                  margin: "30px 0",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    // color="primary"
                                    value={alignment}
                                    exclusive
                                    onChange={(e) => {
                                      handleChange(e.target.value);
                                    }}
                                    aria-label="Platform"
                                    style={{ width: "100%" }}
                                  >
                                    <ToggleButton
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      value="Tenant"
                                      onClick={() => {
                                        setSelectedOption("Tenant");
                                        setShowForm(true);
                                      }}
                                      style={{
                                        width: "15rem",
                                        textTransform: "capitalize",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Tenant
                                    </ToggleButton>
                                    <ToggleButton
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      value="Cosigner"
                                      onClick={() => {
                                        setSelectedOption("Cosigner");
                                        setShowForm(true);
                                      }}
                                      style={{
                                        width: "15rem",
                                        textTransform: "capitalize",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Cosigner
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                                <br />

                                {showForm && (
                                  <div>
                                    {selectedOption === "Tenant" && (
                                      <div className="tenant">
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <label
                                              htmlFor="existing-tenant"
                                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                marginTop: "8px",
                                              }}
                                            >
                                              <Checkbox
                                                id="existing-tenant"
                                                onChange={handleChange}
                                                style={{
                                                  marginRight: "10px",
                                                  color: "#152B51",
                                                }}
                                                checked={
                                                  showTenantTable === true
                                                }
                                              />
                                              Choose an existing Tenant
                                            </label>
                                          </div>
                                          <br />
                                        </div>

                                        {showTenantTable &&
                                          tenantData?.length > 0 && (
                                            <>
                                              <div
                                                style={{
                                                  maxHeight: "400px",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                <Input
                                                  className="fontstylerentr titleecolor"
                                                  type="text"
                                                  placeholder="Search by first and last name"
                                                  value={searchQuery}
                                                  onChange={handleSearch}
                                                  style={{
                                                    marginBottom: "10px",
                                                    width: "100%",
                                                    padding: "8px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "4px",
                                                  }}
                                                />
                                                <div
                                                  style={{
                                                    maxHeight:
                                                      "calc(400px - 40px)",
                                                    overflowY: "auto",
                                                    border: "1px solid #ddd",
                                                  }}
                                                >
                                                  <table
                                                    style={{
                                                      width: "100%",
                                                      borderCollapse:
                                                        "collapse",
                                                    }}
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th
                                                          className="labelfontstyle textcolorblue fontfamilysty"
                                                          style={{
                                                            padding: "15px",
                                                            fontWeight: "600",
                                                            fontSize: "18px",
                                                          }}
                                                        >
                                                          Tenant Name
                                                        </th>
                                                        <th
                                                          className="labelfontstyle textcolorblue fontfamilysty"
                                                          style={{
                                                            padding: "15px",
                                                            fontWeight: "600",
                                                            fontSize: "18px",
                                                          }}
                                                        >
                                                          Select
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {Array.isArray(
                                                        tenantData
                                                      ) &&
                                                        tenantData
                                                          .filter((tenant) => {
                                                            const fullName = `${tenant.tenant_firstName} ${tenant.tenant_lastName}`;
                                                            return fullName
                                                              .toLowerCase()
                                                              .includes(
                                                                searchQuery.toLowerCase()
                                                              );
                                                          })
                                                          .map(
                                                            (tenant, index) => (
                                                              <tr
                                                                key={index}
                                                                style={{
                                                                  border:
                                                                    "1px solid #ddd",
                                                                }}
                                                              >
                                                                <td
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                    paddingTop:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <pre
                                                                    className="fontstylerentr fontfamilysty"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      fontWeight:
                                                                        "500",
                                                                    }}
                                                                  >
                                                                    {
                                                                      tenant.tenant_firstName
                                                                    }{" "}
                                                                    {
                                                                      tenant.tenant_lastName
                                                                    }{" "}
                                                                    (
                                                                    {
                                                                      tenant.tenant_phoneNumber
                                                                    }
                                                                    )
                                                                  </pre>
                                                                </td>
                                                                <td
                                                                  className="tenantmodalrentroll"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                    paddingTop:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <Checkbox
                                                                    type="checkbox"
                                                                    name="tenant"
                                                                    sx={{
                                                                      "&.Mui-checked":
                                                                        {
                                                                          color:
                                                                            "#152B51",
                                                                        },
                                                                    }}
                                                                    id={
                                                                      tenant.tenant_phoneNumber
                                                                    }
                                                                    checked={
                                                                      Array.isArray(
                                                                        checkedTenantIds
                                                                      ) &&
                                                                      checkedTenantIds.includes(
                                                                        tenant.tenant_id
                                                                      )
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleCheckboxChange(
                                                                        event,
                                                                        tenant
                                                                      )
                                                                    }
                                                                  />
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <br />
                                              </div>
                                              <br />
                                              <div className="ml-2">
                                                <Button
                                                  type="submit"
                                                  className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                                  // disabled={
                                                  //   !tenantFormik?.isValid
                                                  // }
                                                  onClick={() => {
                                                    handleAddTenant();
                                                  }}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Done
                                                </Button>
                                                <Button
                                                  className="fontstylerentr rentrollbtncancel backgroundwhitesty textcolorblue fontfamilysty"
                                                  onClick={() => {
                                                    handleClose();
                                                    tenantFormik.resetForm();
                                                  }}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Cancel
                                                </Button>
                                                {/* {!tenantFormik?.isValid && (
                                                  <div
                                                    className="fontstylerentr"
                                                    style={{
                                                      color: "red",
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    Please fill in all fields
                                                    correctly.
                                                  </div>
                                                )} */}
                                              </div>
                                            </>
                                          )}
                                        {!showTenantTable && (
                                          <>
                                            <div
                                              className="TenantDetail"
                                              style={{ margin: "10px 10px" }}
                                            >
                                              <span
                                                className="labelfontstyle background-colorsty bgtextwhite"
                                                style={{
                                                  marginBottom: "1rem",
                                                  display: "flex",
                                                  borderRadius: "4px",
                                                  padding: "4px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                &nbsp; Contact information
                                              </span>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    flex: 1,
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="tenant_firstName"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    First Name *
                                                  </label>
                                                  <br />
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                      marginRight: "10px",
                                                      flex: 1,
                                                    }}
                                                    id="tenant_firstName"
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    variant="standard"
                                                    type="text"
                                                    placeholder="Enter first name"
                                                    name="tenant_firstName"
                                                    onBlur={
                                                      tenantFormik.handleBlur
                                                    }
                                                    onChange={
                                                      tenantFormik.handleChange
                                                    }
                                                    value={
                                                      tenantFormik.values
                                                        .tenant_firstName
                                                    }
                                                  />
                                                  {tenantFormik.touched
                                                    .tenant_firstName &&
                                                  tenantFormik.errors
                                                    .tenant_firstName ? (
                                                    <div
                                                      className="requiredstylefont"
                                                      style={{ color: "red" }}
                                                    >
                                                      {
                                                        tenantFormik.errors
                                                          .tenant_firstName
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div
                                                  style={{
                                                    flex: 1,
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="tenant_lastName"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Last Name *
                                                  </label>
                                                  <br />
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                      marginRight: "10px",
                                                      flex: 1,
                                                    }}
                                                    id="tenant_lastName"
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    variant="standard"
                                                    type="text"
                                                    placeholder="Enter last name"
                                                    name="tenant_lastName"
                                                    onBlur={
                                                      tenantFormik.handleBlur
                                                    }
                                                    onChange={
                                                      tenantFormik.handleChange
                                                    }
                                                    value={
                                                      tenantFormik.values
                                                        .tenant_lastName
                                                    }
                                                  />
                                                  {tenantFormik.touched
                                                    .tenant_lastName &&
                                                  tenantFormik.errors
                                                    .tenant_lastName ? (
                                                    <div
                                                      className="requiredstylefont"
                                                      style={{ color: "red" }}
                                                    >
                                                      {
                                                        tenantFormik.errors
                                                          .tenant_lastName
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                              <br />
                                              {/* 6002 changing inputs removing space */}
                                              <div
                                                style={{
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    flex: 1,
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="tenant_phoneNumber"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Phone Number*
                                                  </label>
                                                  <br />
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                      marginRight: "10px",
                                                      flex: 1,
                                                      width: "100%",
                                                    }}
                                                    id="tenant_phoneNumber"
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    variant="standard"
                                                    type="text"
                                                    placeholder="Enter phone number"
                                                    name="tenant_phoneNumber"
                                                    onBlur={
                                                      tenantFormik.handleBlur
                                                    }
                                                    onChange={
                                                      tenantFormik.handleChange
                                                    }
                                                    value={
                                                      tenantFormik.values
                                                        .tenant_phoneNumber
                                                    }
                                                  />
                                                  {tenantFormik.touched
                                                    .tenant_phoneNumber &&
                                                  tenantFormik.errors
                                                    .tenant_phoneNumber ? (
                                                    <div
                                                      className="requiredstylefont"
                                                      style={{ color: "red" }}
                                                    >
                                                      {
                                                        tenantFormik.errors
                                                          .tenant_phoneNumber
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  {rentincdropdownOpen1 && (
                                                    <div
                                                      className="mt-2"
                                                      style={{
                                                        flex: 1,
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      <label
                                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                        htmlFor="tenant_alternativeNumber"
                                                        style={{
                                                          paddingTop: "3%",
                                                          fontSize: "16px",
                                                          fontWeight: "500",
                                                        }}
                                                      >
                                                        Work Number
                                                      </label>
                                                      <br />
                                                      <Input
                                                        style={{
                                                          boxShadow:
                                                            " 0px 4px 4px 0px #00000040 ",
                                                          borderRadius: "6px",
                                                          marginRight: "10px",
                                                          flex: 1,
                                                          width: "100%",
                                                        }}
                                                        id="tenant_alternativeNumber"
                                                        className="form-control-alternative fontstylerentr titleecolor"
                                                        variant="standard"
                                                        type="text"
                                                        placeholder="Enter work number"
                                                        name="tenant_alternativeNumber"
                                                        onBlur={
                                                          tenantFormik.handleBlur
                                                        }
                                                        onChange={
                                                          tenantFormik.handleChange
                                                        }
                                                        value={
                                                          tenantFormik.values
                                                            .tenant_alternativeNumber
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                  <span
                                                    className="fontstylerentr fontfamilysty"
                                                    onClick={handleClick1}
                                                    style={{
                                                      cursor: "pointer",
                                                      fontSize: "14px",
                                                      color: "#26C22C",
                                                      paddingTop: "3%",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    <b
                                                      className="labelfontstyle"
                                                      style={{
                                                        fontSize: "20px",
                                                      }}
                                                    >
                                                      +
                                                    </b>
                                                    Add alternative Phone
                                                  </span>
                                                </div>
                                              </div>
                                              <br />
                                              <div
                                                style={{
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    flex: 1,
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="tenant_email"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Email *
                                                  </label>
                                                  <br />
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                      marginRight: "10px",
                                                      flex: 1,
                                                      width: "100%",
                                                    }}
                                                    id="tenant_email"
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    variant="standard"
                                                    type="text"
                                                    placeholder="Enter email"
                                                    name="tenant_email"
                                                    onBlur={
                                                      tenantFormik.handleBlur
                                                    }
                                                    onChange={
                                                      tenantFormik.handleChange
                                                    }
                                                    value={
                                                      tenantFormik.values
                                                        .tenant_email
                                                    }
                                                  />
                                                  {tenantFormik.touched
                                                    .tenant_email &&
                                                  tenantFormik.errors
                                                    .tenant_email ? (
                                                    <div
                                                      className="requiredstylefont"
                                                      style={{ color: "red" }}
                                                    >
                                                      {
                                                        tenantFormik.errors
                                                          .tenant_email
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  {rentincdropdownOpen2 && (
                                                    <div
                                                      className="mt-2"
                                                      style={{
                                                        flex: 1,
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      <label
                                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                        htmlFor="tenant_alternativeEmail"
                                                        style={{
                                                          paddingTop: "3%",
                                                          fontSize: "16px",
                                                          fontWeight: "500",
                                                        }}
                                                      >
                                                        Alternative Email
                                                      </label>
                                                      <br />
                                                      <Input
                                                        style={{
                                                          boxShadow:
                                                            " 0px 4px 4px 0px #00000040 ",
                                                          borderRadius: "6px",
                                                          marginRight: "10px",
                                                          flex: 1,
                                                          width: "100%",
                                                        }}
                                                        id="tenant_email"
                                                        className="form-control-alternative fontstylerentr titleecolor"
                                                        variant="standard"
                                                        type="text"
                                                        placeholder="Enter alternative email"
                                                        name="tenant_alternativeEmail"
                                                        onBlur={
                                                          tenantFormik.handleBlur
                                                        }
                                                        onChange={
                                                          tenantFormik.handleChange
                                                        }
                                                        value={
                                                          tenantFormik.values
                                                            .tenant_alternativeEmail
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                  <span
                                                    className="fontstylerentr fontfamilysty"
                                                    onClick={handleClick2}
                                                    style={{
                                                      cursor: "pointer",
                                                      fontSize: "14px",
                                                      color: "#26C22C",
                                                      paddingTop: "3%",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    <b
                                                      className="labelfontstyle"
                                                      style={{
                                                        fontSize: "20px",
                                                      }}
                                                    >
                                                      +
                                                    </b>
                                                    Add alternative Email
                                                  </span>
                                                </div>
                                                <div
                                                  style={{
                                                    flex: 1,
                                                    marginRight: "10px",
                                                    marginTop: "20px",
                                                  }}
                                                >
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="tenant_password"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Password
                                                  </label>
                                                  <br />
                                                  <div
                                                    className="passwprdbtnrent"
                                                    style={{
                                                      display: "flex",

                                                      width: "100%",
                                                    }}
                                                  >
                                                    <Input
                                                      style={{
                                                        boxShadow:
                                                          " 0px 4px 4px 0px #00000040 ",
                                                        borderRadius: "6px",
                                                        marginRight: "10px",
                                                        flex: 1,
                                                      }}
                                                      id="tenant_password"
                                                      className="form-control-alternative titleecolor"
                                                      variant="standard"
                                                      type={
                                                        showPassword
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      placeholder="Enter password"
                                                      name="tenant_password"
                                                      onBlur={
                                                        tenantFormik.handleBlur
                                                      }
                                                      onChange={
                                                        tenantFormik.handleChange
                                                      }
                                                      value={
                                                        tenantFormik.values
                                                          .tenant_password
                                                      }
                                                    />
                                                    <Button
                                                      type="button"
                                                      onClick={() =>
                                                        setShowPassword(
                                                          !showPassword
                                                        )
                                                      }
                                                    >
                                                      {<VisibilityIcon />}
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                              <br />
                                              <div>
                                                <span
                                                  className="labelfontstyle background-colorsty bgtextwhite"
                                                  onClick={toggle4}
                                                  style={{
                                                    marginBottom: "1rem",
                                                    display: "flex",
                                                    borderRadius: "4px",
                                                    padding: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <b>+ </b>&nbsp; Personal
                                                  information
                                                </span>
                                                <Collapse isOpen={collapseper}>
                                                  <Row>
                                                    <Col lg="5">
                                                      <FormGroup>
                                                        <label
                                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                          htmlFor="input-unitadd3"
                                                          style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          Date of Birth
                                                        </label>
                                                        <Input
                                                          style={{
                                                            boxShadow:
                                                              " 0px 4px 4px 0px #00000040 ",
                                                            borderRadius: "6px",
                                                          }}
                                                          className="form-control-alternative fontstylerentr titleecolor"
                                                          id="input-unitadd3"
                                                          placeholder="3000"
                                                          type="date"
                                                          name="tenant_birthDate"
                                                          onBlur={
                                                            tenantFormik.handleBlur
                                                          }
                                                          onChange={
                                                            tenantFormik.handleChange
                                                          }
                                                          value={
                                                            tenantFormik.values
                                                              .tenant_birthDate
                                                          }
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="7">
                                                      <FormGroup>
                                                        <label
                                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                          htmlFor="input-unitadd4"
                                                          style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          TaxPayer ID
                                                        </label>
                                                        <Input
                                                          style={{
                                                            boxShadow:
                                                              " 0px 4px 4px 0px #00000040 ",
                                                            borderRadius: "6px",
                                                            width: "80%",
                                                          }}
                                                          className="form-control-alternative fontstylerentr titleecolor"
                                                          id="input-unitadd4"
                                                          placeholder="Enter tax payer id"
                                                          type="text"
                                                          name="taxPayer_id"
                                                          onBlur={
                                                            tenantFormik.handleBlur
                                                          }
                                                          onChange={
                                                            tenantFormik.handleChange
                                                          }
                                                          value={
                                                            tenantFormik.values
                                                              .taxPayer_id
                                                          }
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                  <Row className="mt-2 mb-2">
                                                    <Col lg="7">
                                                      <FormGroup>
                                                        <label
                                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                          htmlFor="input-address"
                                                          style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          Comments
                                                        </label>
                                                        <Input
                                                          style={{
                                                            boxShadow:
                                                              " 0px 4px 4px 0px #00000040 ",
                                                            borderRadius: "6px",
                                                            height: "90px",
                                                            width: "100%",
                                                            maxWidth: "25rem",
                                                          }}
                                                          className="form-control-alternative fontstylerentr titleecolor"
                                                          id="input-address"
                                                          type="textarea"
                                                          placeholder="Enter comments"
                                                          name="comments"
                                                          onBlur={
                                                            tenantFormik.handleBlur
                                                          }
                                                          onChange={
                                                            tenantFormik.handleChange
                                                          }
                                                          value={
                                                            tenantFormik.values
                                                              .comments
                                                          }
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                </Collapse>
                                              </div>
                                              <div>
                                                <span
                                                  className="labelfontstyle background-colorsty bgtextwhite"
                                                  onClick={toggle5}
                                                  style={{
                                                    marginBottom: "1rem",
                                                    display: "flex",
                                                    borderRadius: "4px",
                                                    padding: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <b>+ </b>&nbsp; Emergency
                                                  Contact
                                                </span>
                                                <Collapse isOpen={collapsecont}>
                                                  <Row className="mt-2">
                                                    <Col lg="6">
                                                      <FormGroup>
                                                        <label
                                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                          htmlFor="input-unitadd5"
                                                          style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          Contact Name
                                                        </label>
                                                        <Input
                                                          style={{
                                                            boxShadow:
                                                              " 0px 4px 4px 0px #00000040 ",
                                                            borderRadius: "6px",
                                                          }}
                                                          className="form-control-alternative fontstylerentr titleecolor"
                                                          id="input-unitadd5"
                                                          type="text"
                                                          placeholder="Enter contact name"
                                                          name="emergency_contact.name"
                                                          onBlur={
                                                            tenantFormik.handleBlur
                                                          }
                                                          onChange={
                                                            tenantFormik.handleChange
                                                          }
                                                          value={
                                                            tenantFormik?.values
                                                              ?.emergency_contact
                                                              ?.name
                                                          }
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                      <FormGroup>
                                                        <label
                                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                          htmlFor="input-unitadd6"
                                                          style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          Relationship to Tenant
                                                        </label>
                                                        <Input
                                                          style={{
                                                            boxShadow:
                                                              " 0px 4px 4px 0px #00000040 ",
                                                            borderRadius: "6px",
                                                          }}
                                                          className="form-control-alternative fontstylerentr titleecolor"
                                                          id="input-unitadd6"
                                                          type="text"
                                                          placeholder="Relationship to tenant..."
                                                          name="emergency_contact.relation"
                                                          onBlur={
                                                            tenantFormik.handleBlur
                                                          }
                                                          onChange={
                                                            tenantFormik.handleChange
                                                          }
                                                          value={
                                                            tenantFormik?.values
                                                              ?.emergency_contact
                                                              ?.relation
                                                          }
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                  <Row className="mt-2">
                                                    <Col lg="6">
                                                      <FormGroup>
                                                        <label
                                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                          htmlFor="input-unitadd7"
                                                          style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          E-Mail
                                                        </label>
                                                        <Input
                                                          style={{
                                                            boxShadow:
                                                              " 0px 4px 4px 0px #00000040 ",
                                                            borderRadius: "6px",
                                                          }}
                                                          placeholder="Enter email"
                                                          className="form-control-alternative fontstylerentr titleecolor"
                                                          id="input-unitadd7"
                                                          type="text"
                                                          name="emergency_contact.email"
                                                          onBlur={
                                                            tenantFormik.handleBlur
                                                          }
                                                          onChange={
                                                            tenantFormik.handleChange
                                                          }
                                                          value={
                                                            tenantFormik?.values
                                                              ?.emergency_contact
                                                              ?.email
                                                          }
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                      <FormGroup>
                                                        <label
                                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                          htmlFor="input-unitadd8"
                                                          style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                          }}
                                                        >
                                                          Phone Number
                                                        </label>
                                                        <Input
                                                          style={{
                                                            boxShadow:
                                                              " 0px 4px 4px 0px #00000040 ",
                                                            borderRadius: "6px",
                                                          }}
                                                          className="form-control-alternative fontstylerentr titleecolor"
                                                          id="input-unitadd8"
                                                          type="text"
                                                          placeholder="Enter phone number"
                                                          name="emergency_contact.phoneNumber"
                                                          onBlur={
                                                            tenantFormik.handleBlur
                                                          }
                                                          onChange={
                                                            tenantFormik.handleChange
                                                          }
                                                          value={
                                                            tenantFormik?.values
                                                              ?.emergency_contact
                                                              ?.phoneNumber
                                                          }
                                                          onInput={(e) => {
                                                            const inputValue =
                                                              e.target.value;
                                                            const numericValue =
                                                              inputValue.replace(
                                                                /\D/g,
                                                                ""
                                                              );
                                                            e.target.value =
                                                              numericValue;
                                                          }}
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                </Collapse>
                                              </div>
                                            </div>

                                            <div className="ml-2">
                                              <Button
                                                type="submit"
                                                className="btn fontstylerentr fontfamilysty background-colorsty bgtextwhite "
                                                disabled={
                                                  !tenantFormik?.isValid
                                                }
                                                onClick={() => {
                                                  tenantFormik.handleSubmit();
                                                }}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "14px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Add Tenant
                                              </Button>
                                              <Button
                                                className="fontstylerentr rentrollbtncancel textcolorblue backgroundwhitesty fontfamilysty"
                                                onClick={() => {
                                                  handleClose();
                                                  tenantFormik.resetForm();
                                                }}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "14px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Cancel
                                              </Button>
                                              {!tenantFormik?.isValid && (
                                                <div
                                                  className="fontstylerentr"
                                                  style={{
                                                    color: "red",
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  Please fill in all fields
                                                  correctly.
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    )}

                                    {selectedOption === "Cosigner" && (
                                      <div className="cosigner">
                                        <div>
                                          <span
                                            className="labelfontstyle background-colorsty bgtextwhite"
                                            style={{
                                              marginBottom: "1rem",
                                              display: "flex",
                                              borderRadius: "4px",
                                              padding: "4px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            &nbsp;Contact information
                                          </span>
                                        </div>

                                        <div
                                          className="formInput"
                                          style={{ margin: "10px 10px" }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <div
                                              style={{
                                                flex: 1,
                                                marginRight: "10px",
                                              }}
                                            >
                                              <label
                                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                htmlFor="input-firstname"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                First Name
                                              </label>
                                              <Input
                                                style={{
                                                  boxShadow:
                                                    " 0px 4px 4px 0px #00000040 ",
                                                  borderRadius: "6px",
                                                }}
                                                className="form-control-alternative fontstylerentr titleecolor"
                                                id="cosigner_firstName"
                                                placeholder="Enter first name"
                                                type="text"
                                                name="cosigner_firstName"
                                                onBlur={
                                                  cosignerFormik.handleBlur
                                                }
                                                onChange={(e) =>
                                                  cosignerFormik.handleChange(e)
                                                }
                                                value={
                                                  cosignerFormik?.values
                                                    ?.cosigner_firstName
                                                }
                                              />
                                              {cosignerFormik?.errors &&
                                              cosignerFormik.errors
                                                ?.cosigner_firstName &&
                                              cosignerFormik.touched &&
                                              cosignerFormik.touched
                                                ?.cosigner_firstName ? (
                                                <div
                                                  className="requiredstylefont"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    cosignerFormik.errors
                                                      .cosigner_firstName
                                                  }
                                                </div>
                                              ) : null}
                                            </div>
                                            <div style={{ flex: 1 }}>
                                              <label
                                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                htmlFor="input-lastname"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Last Name
                                              </label>
                                              <Input
                                                style={{
                                                  boxShadow:
                                                    " 0px 4px 4px 0px #00000040 ",
                                                  borderRadius: "6px",
                                                }}
                                                className="form-control-alternative fontstylerentr titleecolor"
                                                id="cosigner_lastName"
                                                placeholder="Enter last name"
                                                type="text"
                                                name="cosigner_lastName"
                                                onBlur={
                                                  cosignerFormik.handleBlur
                                                }
                                                onChange={
                                                  cosignerFormik.handleChange
                                                }
                                                value={
                                                  cosignerFormik.values
                                                    .cosigner_lastName
                                                }
                                              />
                                              {cosignerFormik.errors &&
                                              cosignerFormik.errors
                                                ?.cosigner_lastName &&
                                              cosignerFormik.touched &&
                                              cosignerFormik.touched
                                                ?.cosigner_lastName ? (
                                                <div
                                                  className="requiredstylefont"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    cosignerFormik.errors
                                                      .cosigner_lastName
                                                  }
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <br />
                                          <div
                                            style={{
                                              // display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                flex: 1,
                                                // marginRight: "10px",
                                              }}
                                            >
                                              <label
                                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                htmlFor="input-lastname"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Phone Number
                                              </label>
                                              <br />
                                              <Input
                                                style={{
                                                  boxShadow:
                                                    " 0px 4px 4px 0px #00000040 ",
                                                  borderRadius: "6px",
                                                  width: "100%",
                                                }}
                                                className="form-control-alternative fontstylerentr titleecolor"
                                                id="cosigner_phoneNumber"
                                                placeholder="Enter phone number"
                                                type="text"
                                                name="cosigner_phoneNumber"
                                                onBlur={
                                                  cosignerFormik.handleBlur
                                                }
                                                onChange={
                                                  cosignerFormik.handleChange
                                                }
                                                value={
                                                  cosignerFormik.values
                                                    .cosigner_phoneNumber
                                                }
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <PhoneIcon />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                // onInput={(e) => {
                                                //   const inputValue =
                                                //     e.target.value;
                                                //   const numericValue =
                                                //     inputValue.replace(
                                                //       /\D/g,
                                                //       ""
                                                //     ); // Remove non-numeric characters
                                                //   e.target.value = numericValue;
                                                // }}
                                              />
                                              {cosignerFormik.errors &&
                                              cosignerFormik.errors
                                                .cosigner_phoneNumber &&
                                              cosignerFormik.touched &&
                                              cosignerFormik.touched
                                                .cosigner_phoneNumber ? (
                                                <div
                                                  className="requiredstylefont"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    cosignerFormik.errors
                                                      .cosigner_phoneNumber
                                                  }
                                                </div>
                                              ) : null}
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              {rentincdropdownOpen3 && (
                                                <div
                                                  className="mt-2"
                                                  style={{
                                                    flex: 1,
                                                    // marginRight: "10px",
                                                  }}
                                                >
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="tenant_alternativeNumber"
                                                    style={{
                                                      paddingTop: "3%",
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Work Number
                                                  </label>
                                                  <br />
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                      marginRight: "10px",
                                                      flex: 1,
                                                      width: "100%",
                                                    }}
                                                    id="cosigner_alternativeNumber"
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    variant="standard"
                                                    type="text"
                                                    placeholder="Enter work number"
                                                    name="cosigner_alternativeNumber"
                                                    onBlur={
                                                      cosignerFormik.handleBlur
                                                    }
                                                    onChange={
                                                      cosignerFormik.handleChange
                                                    }
                                                    value={
                                                      cosignerFormik.values
                                                        .cosigner_alternativeNumber
                                                    }
                                                    onInput={(e) => {
                                                      const inputValue =
                                                        e.target.value;
                                                      const numericValue =
                                                        inputValue.replace(
                                                          /\D/g,
                                                          ""
                                                        ); // Remove non-numeric characters
                                                      e.target.value =
                                                        numericValue;
                                                      cosignerFormik.values.cosigner_alternativeNumber =
                                                        numericValue;
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              <span
                                                className="fontstylerentr fontfamilysty"
                                                onClick={handleClick3}
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "14px",
                                                  color: "#26C22C",
                                                  paddingTop: "3%",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                <b
                                                  className="labelfontstyle"
                                                  style={{ fontSize: "20px" }}
                                                >
                                                  +
                                                </b>
                                                Add alternative Phone
                                              </span>
                                            </div>
                                          </div>
                                          <br />
                                          <div
                                            style={{
                                              // display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                flex: 1,
                                                // marginRight: "10px",
                                              }}
                                            >
                                              <label
                                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                htmlFor="input-email"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Email
                                              </label>
                                              <br />
                                              <Input
                                                style={{
                                                  boxShadow:
                                                    " 0px 4px 4px 0px #00000040 ",
                                                  borderRadius: "6px",
                                                  width: "100%",
                                                }}
                                                className="form-control-alternative fontstylerentr titleecolor"
                                                id="cosigner_email"
                                                placeholder="Enter email"
                                                type="text"
                                                name="cosigner_email"
                                                onBlur={
                                                  cosignerFormik.handleBlur
                                                }
                                                onChange={(e) =>
                                                  cosignerFormik.handleChange(e)
                                                }
                                                value={
                                                  cosignerFormik.values
                                                    .cosigner_email
                                                }
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <EmailIcon />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                              {cosignerFormik.errors &&
                                              cosignerFormik.errors
                                                .cosigner_email &&
                                              cosignerFormik.touched &&
                                              cosignerFormik.touched
                                                .cosigner_email ? (
                                                <div
                                                  className="requiredstylefont"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    cosignerFormik.errors
                                                      .cosigner_email
                                                  }
                                                </div>
                                              ) : null}
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              {rentincdropdownOpen4 && (
                                                <div
                                                  className="mt-2"
                                                  style={{
                                                    flex: 1,
                                                    // marginRight: "10px",
                                                  }}
                                                >
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="input-firstname"
                                                    style={{
                                                      paddingTop: "3%",
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Alternative Email
                                                  </label>
                                                  <br />
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                      marginRight: "10px",
                                                      flex: 1,
                                                      width: "100%",
                                                    }}
                                                    id="cosigner_alternativeEmail"
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    variant="standard"
                                                    type="text"
                                                    placeholder="Enter alternative email"
                                                    name="cosigner_alternativeEmail"
                                                    onBlur={
                                                      cosignerFormik.handleBlur
                                                    }
                                                    onChange={(e) =>
                                                      cosignerFormik.handleChange(
                                                        e
                                                      )
                                                    }
                                                    value={
                                                      cosignerFormik.values
                                                        .cosigner_alternativeEmail
                                                    }
                                                  />
                                                </div>
                                              )}
                                              <span
                                                className="fontstylerentr fontfamilysty"
                                                onClick={handleClick4}
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "14px",
                                                  color: "#26C22C",
                                                  paddingTop: "3%",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                <b
                                                  className="labelfontstyle"
                                                  style={{ fontSize: "20px" }}
                                                >
                                                  +
                                                </b>
                                                Add alternative Email
                                              </span>
                                            </div>
                                          </div>
                                          {/* <hr /> */}
                                          <br />
                                          <div>
                                            <label
                                              className="form-control-label labelfontstyle fontfamilysty textcolorblue"
                                              htmlFor="input-email"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Address
                                            </label>
                                          </div>

                                          <div
                                            className="mt-2"
                                            style={{
                                              display: "flex",
                                              alignItems: "start",
                                              flexDirection: "column",
                                            }}
                                          >
                                            {/* <FormGroup
                                              style={{ width: "100%" }} // {{5757}}
                                            > */}
                                            <label
                                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                              htmlFor="cosigner_adress"
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Street Address
                                            </label>
                                            <Input
                                              style={{
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                                width: "100%",
                                                // maxWidth: "25rem",
                                              }}
                                              className="form-control-alternative fontstylerentr titleecolor"
                                              id="cosigner_adress"
                                              placeholder="Enter street address"
                                              type="textarea"
                                              name="cosigner_address" // {{5757}}
                                              onBlur={cosignerFormik.handleBlur}
                                              onChange={(e) =>
                                                cosignerFormik.handleChange(e)
                                              }
                                              value={
                                                cosignerFormik.values
                                                  .cosigner_address // {{5757}}
                                              }
                                            />
                                            {/* </FormGroup> */}
                                          </div>
                                          <div className="mt-3">
                                            <Row>
                                              <Col lg="4">
                                                <FormGroup>
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="input-city"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    City
                                                  </label>
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                    }}
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    id="cosigner_city"
                                                    placeholder="Enter city..."
                                                    type="text"
                                                    name="cosigner_city"
                                                    onBlur={
                                                      cosignerFormik.handleBlur
                                                    }
                                                    onChange={(e) =>
                                                      cosignerFormik.handleChange(
                                                        e
                                                      )
                                                    }
                                                    value={
                                                      cosignerFormik.values
                                                        .cosigner_city
                                                    }
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col lg="4">
                                                <FormGroup>
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="input-country"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Country
                                                  </label>
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                    }}
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    id="cosigner_country"
                                                    placeholder="Enter country..."
                                                    type="text"
                                                    name="cosigner_country"
                                                    onBlur={
                                                      cosignerFormik.handleBlur
                                                    }
                                                    onChange={(e) =>
                                                      cosignerFormik.handleChange(
                                                        e
                                                      )
                                                    }
                                                    value={
                                                      cosignerFormik.values
                                                        .cosigner_country
                                                    }
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col lg="4">
                                                <FormGroup>
                                                  {" "}
                                                  <label
                                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                                    htmlFor="input-country"
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Postal code
                                                  </label>
                                                  <Input
                                                    style={{
                                                      boxShadow:
                                                        " 0px 4px 4px 0px #00000040 ",
                                                      borderRadius: "6px",
                                                    }}
                                                    className="form-control-alternative fontstylerentr titleecolor"
                                                    id="cosigner_postalcode"
                                                    placeholder="Postal code..."
                                                    type="text"
                                                    name="cosigner_postalcode"
                                                    onBlur={
                                                      cosignerFormik.handleBlur
                                                    }
                                                    onChange={(e) =>
                                                      cosignerFormik.handleChange(
                                                        e
                                                      )
                                                    }
                                                    value={
                                                      cosignerFormik.values
                                                        .cosigner_postalcode
                                                    }
                                                    onInput={(e) => {
                                                      const inputValue =
                                                        e.target.value;
                                                      const numericValue =
                                                        inputValue.replace(
                                                          /\D/g,
                                                          ""
                                                        );
                                                      e.target.value =
                                                        numericValue;
                                                    }}
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </div>
                                          <br />
                                        </div>
                                        <div className="ml-2">
                                          <Button
                                            type="submit"
                                            className="btn fontstylerentr fontfamilysty bgtextwhite background-colorsty"
                                            disabled={!cosignerFormik.isValid}
                                            onClick={() => {
                                              cosignerFormik.handleSubmit();
                                            }}
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            Add Cosigner
                                          </Button>
                                          <Button
                                            className="fontstylerentr rentrollbtncancel textcolorblue backgroundwhitesty fontfamilysty"
                                            onClick={() => {
                                              handleClose();
                                              cosignerFormik.resetForm();
                                            }}
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                          {/* Conditional message */}
                                          {!cosignerFormik.isValid && (
                                            <div
                                              className="fontstylerentr"
                                              style={{
                                                color: "red",
                                                marginTop: "10px",
                                              }}
                                            >
                                              Please fill in all fields
                                              correctly.
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </DialogContent>
                          </Dialog>
                          {/* 6002 end */}
                          <div>
                            {selectedTenants.length > 0 ? (
                              <>
                                <Row
                                  className="w-100 my-3 labelfontstyle fontfamilysty textcolorblue"
                                  style={{
                                    fontSize: "18px",
                                    textTransform: "capitalize",
                                    fontWeight: "600",
                                    borderBottom: "1px solid #ddd",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <Col>Tenants</Col>
                                </Row>
                                <div
                                  style={{
                                    overflowX: "auto",
                                    scrollbarWidth: "thin",
                                    maxHeight: "300px",
                                    marginTop: "2%",
                                  }}
                                >
                                  <table
                                    style={{
                                      borderCollapse: "collapse",
                                      width: "100%",
                                    }}
                                  >
                                    <thead>
                                      <tr
                                        style={{ background: "#f2f2f2" }}
                                        className="fontstylerentr"
                                      >
                                        <th
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          First Name
                                        </th>
                                        <th
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Last Name
                                        </th>
                                        <th
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Phone Number
                                        </th>
                                        <th
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Rent Share
                                        </th>
                                        <th
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedTenants.map((tenant, index) => (
                                        <tr
                                          key={index}
                                          className="fontstylerentr"
                                        >
                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {tenant.tenant_firstName}
                                          </td>
                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {tenant.tenant_lastName}
                                          </td>
                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {tenant.tenant_phoneNumber}
                                          </td>
                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            <Input
                                              style={{
                                                boxShadow:
                                                  "0px 4px 4px 0px #00000040",
                                                borderRadius: "6px",
                                                maxWidth: "100px",
                                                marginTop: "10px",
                                              }}
                                              className="form-control-alternative fontstylerentmodal rentsharerentroll"
                                              id="input-percentage"
                                              placeholder="Enter %"
                                              type="text"
                                              name="percentage"
                                              value={tenant.percentage}
                                              onChange={(e) => {
                                                const inputValue =
                                                  e.target.value;
                                                const numericValue =
                                                  inputValue.replace(/\D/g, "");
                                                handlePercentageChange(
                                                  index,
                                                  numericValue
                                                );
                                              }}
                                            />
                                          </td>
                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            <DeleteIcon
                                              className="tableiconrentroll"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setShowTenantTable(false);
                                                handleTenantDelete(tenant);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                {error && (
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      color: "red",
                                      textAlign: "left",
                                    }}
                                  >
                                    {error}
                                  </div>
                                )}
                              </>
                            ) : null}
                          </div>

                          {/* {tenantFormik.errors &&
                          tenantFormik.errors?.tenant_password &&
                          leaseFormik.submitCount > 0 ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_password}
                            </div>
                          ) : null} */}

                          <div>
                            {cosignerData &&
                              Object.keys(cosignerData)?.length > 0 && (
                                <>
                                  <Row
                                    className="w-100 my-3 labelfontstyle textcolorblue"
                                    style={{
                                      fontSize: "18px",
                                      textTransform: "capitalize",
                                      fontWeight: "600",
                                      borderBottom: "1px solid #ddd",
                                      paddingTop: "15px",
                                    }}
                                  >
                                    <Col>Cosigner</Col>
                                  </Row>
                                  <div
                                    style={{
                                      overflowX: "auto",
                                      scrollbarWidth: "thin",
                                      maxHeight: "300px",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <table
                                      style={{
                                        borderCollapse: "collapse",
                                        width: "100%",
                                      }}
                                    >
                                      <thead>
                                        <tr
                                          style={{ background: "#f2f2f2" }}
                                          className="fontstylerentr"
                                        >
                                          <th
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            First Name
                                          </th>
                                          <th
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Last Name
                                          </th>
                                          <th
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Phone Number
                                          </th>

                                          <th
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className="fontstylerentr">
                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {cosignerData.cosigner_firstName}
                                          </td>
                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {cosignerData.cosigner_lastName}
                                          </td>
                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {cosignerData.cosigner_phoneNumber}
                                          </td>

                                          <td
                                            style={{
                                              padding: "8px",
                                              textAlign: "left",
                                            }}
                                          >
                                            <EditIcon
                                              className="tableiconrentroll"
                                              style={{ cursor: "pointer" }}
                                              onClick={setOpenTenantsDialog}
                                            />
                                            <DeleteIcon
                                              className="tableiconrentroll"
                                              style={{ cursor: "pointer" }}
                                              onClick={handleCosignerDelete}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card
                  className="mt-3 mx-3 shadow cardstyborderradius"
                  style={{
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  <CardBody>
                    {/* rent charge */}
                    <h6
                      className=" mb-4 labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Rent
                    </h6>
                    <div className="">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Rent Cycle *
                            </label>
                            <FormGroup>
                              <Dropdown
                                className="dropdownfontsyle"
                                isOpen={rentCycleDropdownOpen}
                                toggle={toggle6}
                              >
                                <DropdownToggle
                                  caret
                                  className="propertydropp dropdownfontsyle titleecolor"
                                  style={{
                                    width: "100%",
                                    boxShadow: " 0px 4px 4px 0px #00000040",
                                    border: "1px solid #ced4da",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {selectedRentCycle
                                    ? selectedRentCycle
                                    : "Select"}
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdownfontsyle"
                                  style={{ width: "100%" }}
                                  name="rent_cycle"
                                  onBlur={rentChargeFormik.handleBlur}
                                >
                                  {rentOptions.map((option) => (
                                    <DropdownItem
                                      className="dropdownfontsyle"
                                      key={option}
                                      onClick={() =>
                                        handleselectedRentCycle(option)
                                      }
                                    >
                                      {option}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </FormGroup>
                            {rentChargeFormik.errors?.rent_cycle &&
                            rentChargeFormik.touched?.rent_cycle ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {rentChargeFormik.errors.rent_cycle}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Row>
                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-address"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Amount *
                                  </label>
                                  <br />
                                  <FormGroup>
                                    <Input
                                      style={{
                                        boxShadow: "0px 4px 4px 0px #00000040",
                                        borderRadius: "6px",
                                      }}
                                      className="form-control-alternative fontstylerentr inputwidthrent titleecolor"
                                      id="input-reserve"
                                      placeholder="Enter amount"
                                      type="text"
                                      name="amount"
                                      onBlur={rentChargeFormik.handleBlur}
                                      value={rentChargeFormik.values.amount}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        // Use a regular expression to allow digits and decimal points
                                        const numericValue = inputValue.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                        // Ensure there's only one decimal point
                                        const finalValue = numericValue.replace(
                                          /(\..*?)\..*/g,
                                          "$1"
                                        );
                                        rentChargeFormik.values.amount =
                                          finalValue;
                                        rentChargeFormik.handleChange({
                                          target: {
                                            name: "amount",
                                            value: finalValue,
                                          },
                                        });
                                      }}
                                    />
                                    {rentChargeFormik.errors &&
                                    rentChargeFormik.errors.amount &&
                                    rentChargeFormik.touched &&
                                    rentChargeFormik.touched.amount ? (
                                      <div
                                        className="requiredstylefont"
                                        style={{ color: "red" }}
                                      >
                                        {rentChargeFormik.errors.amount}
                                      </div>
                                    ) : null}
                                  </FormGroup>
                                </FormGroup>
                              </Col>

                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-unitadd9"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Next Due Date
                                  </label>
                                  <Input
                                    style={{
                                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                                      borderRadius: "6px",
                                    }}
                                    className="form-control-alternative fontstylerentr inputwidthrent titleecolor"
                                    id="input-unitadd9"
                                    placeholder="3000"
                                    type="date"
                                    name="date"
                                    onBlur={rentChargeFormik.handleBlur}
                                    onChange={(e) => {
                                      rentChargeFormik.handleChange(e);
                                      securityChargeFormik.handleChange(e);
                                      recurringFormink.handleChange(e);
                                      oneTimeFormik.handleChange(e);
                                    }}
                                    value={rentChargeFormik.values.date}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="memo"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Memo
                                  </label>
                                  <Input
                                    style={{
                                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                                      borderRadius: "6px",
                                    }}
                                    className="form-control-alternative fontstylerentr inputwidthrent titleecolor"
                                    id="memo"
                                    type="text"
                                    name="memo"
                                    onBlur={rentChargeFormik.handleBlur}
                                    onChange={rentChargeFormik.handleChange}
                                    value={rentChargeFormik.values.memo}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="mt-3 mx-3 shadow cardstyborderradius"
                  style={{
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  <CardBody>
                    {/* Security Deposite */}
                    <h6
                      className=" mb-2 labelfontstyle fontfamilysty textcolorblue"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Security Deposit (Optional)
                    </h6>
                    <div className="">
                      <FormGroup>
                        <br />
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                htmlFor="input-address"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Amount
                              </label>
                              <br />
                              <FormGroup>
                                <Input
                                  style={{
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    borderRadius: "6px",
                                  }}
                                  className="form-control-alternative fontstylerentr inputwidthrent titleecolor"
                                  id="input-reserve"
                                  placeholder="Enter amount here"
                                  type="text"
                                  name="amount"
                                  onBlur={securityChargeFormik.handleBlur}
                                  value={securityChargeFormik.values?.amount}
                                  onInput={(e) => {
                                    const inputValue = e.target.value;
                                    // Allow digits and a single decimal point
                                    const numericValue = inputValue.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    // Ensure only one decimal point is allowed
                                    const finalValue = numericValue.replace(
                                      /(\..*?)\..*/g,
                                      "$1"
                                    );
                                    securityChargeFormik.values.amount =
                                      finalValue;
                                    securityChargeFormik.handleChange({
                                      target: {
                                        name: "amount",
                                        value: finalValue,
                                      },
                                    });
                                  }}
                                />

                                {securityChargeFormik.errors &&
                                securityChargeFormik.errors.amount &&
                                securityChargeFormik.touched &&
                                securityChargeFormik.touched.amount ? (
                                  <div
                                    className="requiredstylefont"
                                    style={{ color: "red" }}
                                  >
                                    {securityChargeFormik.errors.amount}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </FormGroup>
                          </Col>

                          <Col lg="7" className="removemtrent ">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                htmlFor="input-unitadd10"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Don't forget to record the payment once you have
                                connected the deposit
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="mt-3 mx-3 shadow cardstyborderradius"
                  style={{
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  <CardBody>
                    <h6
                      className=" labelfontstyle mb-4 textcolorblue fontfamilysty"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Charges (Optional)
                    </h6>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-address"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Add Charges
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <span
                            className="fontstylerentr fontfamilysty"
                            onClick={handleClickOpenRecurring}
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#26C22C",
                            }}
                          >
                            <b
                              className="labelfontstyle"
                              style={{ fontSize: "20px" }}
                            >
                              +
                            </b>
                            Add Recurring Charge
                          </span>
                          {/* 6002 dialog fixing */}
                          <Dialog
                            open={openRecurringDialog}
                            onClose={handleClose}
                          >
                            <DialogTitle
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              Add Recurring content
                            </DialogTitle>

                            <div className="modalpaddingrent">
                              <div
                                className="p-4"
                                // style={{ marginLeft: "4%", marginRight: "4%" }}
                              >
                                <br />
                                <div className="grid-container resp-header">
                                  <div>
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="input-unitadd11"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Account*
                                    </label>
                                    <FormGroup>
                                      <Dropdown
                                        className="dropdownfontsyle"
                                        isOpen={rentincdropdownOpen5}
                                        toggle={toggle8}
                                      >
                                        <DropdownToggle
                                          className="dropdownfontsyle titleecolor "
                                          caret
                                          style={{
                                            width: "100%",
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040",
                                            border: "1px solid #ced4da",

                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          {recurringFormink.values.account
                                            ? recurringFormink.values.account
                                            : "Select"}
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdownfontsyle"
                                          style={{
                                            zIndex: 999,
                                            maxHeight: "280px",
                                            overflowY: "auto",
                                            width: "100%",
                                          }}
                                          name="account"
                                          onBlur={recurringFormink.handleBlur}
                                          onChange={(e) =>
                                            recurringFormink.handleChange(e)
                                          }
                                          value={
                                            recurringFormink.values.account ||
                                            ""
                                          }
                                        >
                                          {accountsData.map((account) =>
                                            account.charge_type ===
                                            "Recurring Charge" ? (
                                              <DropdownItem
                                                className="dropdownfontsyle"
                                                onClick={() => {
                                                  recurringFormink.setFieldValue(
                                                    "account",
                                                    account.account
                                                  );
                                                }}
                                                key={account.account_id}
                                              >
                                                {account.account}
                                              </DropdownItem>
                                            ) : (
                                              ""
                                            )
                                          )}
                                          <DropdownItem
                                            onClick={() =>
                                              AddNewAccountName(
                                                "Recurring Charge"
                                              )
                                            }
                                          >
                                            Add new account..
                                          </DropdownItem>
                                        </DropdownMenu>
                                        {recurringFormink.errors &&
                                        recurringFormink.errors.account &&
                                        recurringFormink.touched &&
                                        recurringFormink.touched.account ? (
                                          <div
                                            className="requiredstylefont"
                                            style={{ color: "red" }}
                                          >
                                            {recurringFormink.errors.account}
                                          </div>
                                        ) : null}
                                      </Dropdown>
                                    </FormGroup>
                                  </div>
                                  <FormGroup>
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="input-address"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Amount*
                                    </label>
                                    <br />
                                    <FormGroup>
                                      <Input
                                        style={{
                                          boxShadow:
                                            "0px 4px 4px 0px #00000040",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentr titleecolor"
                                        id="amount"
                                        placeholder="Enter amount"
                                        type="text"
                                        name="amount"
                                        onBlur={recurringFormink.handleBlur}
                                        value={
                                          recurringFormink.values.amount || ""
                                        }
                                        onChange={(e) =>
                                          recurringFormink.handleChange(e)
                                        }
                                        onInput={(e) => {
                                          const inputValue = e.target.value;
                                          // Allow digits and a single decimal point
                                          const numericValue =
                                            inputValue.replace(/[^0-9.]/g, "");
                                          // Ensure only one decimal point is allowed
                                          const finalValue =
                                            numericValue.replace(
                                              /(\..*?)\..*/g,
                                              "$1"
                                            );
                                          e.target.value = finalValue;
                                        }}
                                      />

                                      {recurringFormink.errors &&
                                      recurringFormink.errors.amount &&
                                      recurringFormink.touched &&
                                      recurringFormink.touched.amount ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {recurringFormink.errors.amount}
                                        </div>
                                      ) : null}
                                    </FormGroup>
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="memo"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Memo
                                    </label>
                                    <Input
                                      style={{
                                        boxShadow:
                                          " 0px 4px 4px 0px #00000040 ",
                                        borderRadius: "6px",
                                      }}
                                      className="form-control-alternative fontstylerentr titleecolor"
                                      id="memo"
                                      type="text"
                                      name="memo"
                                      onBlur={recurringFormink.handleBlur}
                                      onChange={(e) => {
                                        recurringFormink.values.memo =
                                          e.target.value;
                                        recurringFormink.handleChange(e);
                                      }}
                                      value={recurringFormink.values.memo || ""}
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="d-flex justify-content-start ml-3 mb-3">
                                <DialogActions>
                                  <Button
                                    className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                    type="submit"
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      recurringFormink.handleSubmit();
                                    }}
                                  >
                                    Add
                                  </Button>
                                  <Button
                                    className="fontstylerentr textcolorblue backgroundwhitesty"
                                    onClick={handleClose}
                                  >
                                    Cancel
                                  </Button>
                                </DialogActions>
                              </div>
                            </div>
                          </Dialog>
                          {/* 6002 end */}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <span
                            className="fontstylerentr fontfamilysty"
                            onClick={handleClickOpenOneTimeCharge}
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#26C22C",
                            }}
                          >
                            <b
                              className="labelfontstyle"
                              style={{ fontSize: "20px" }}
                            >
                              +
                            </b>
                            Add One Time Charge
                          </span>
                          <Dialog
                            open={openOneTimeChargeDialog}
                            onClose={handleClose}
                          >
                            <DialogTitle
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              Add One Time Charge Content
                            </DialogTitle>
                            <div className="pl-lg-3">
                              <div style={{ padding: "5%" }}>
                                <div className="grid-container resp-header">
                                  <div>
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="input-unitadd11"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Account*
                                    </label>
                                    <FormGroup>
                                      <Dropdown
                                        className="dropdownfontsyle"
                                        isOpen={rentincdropdownOpen6}
                                        toggle={toggle7}
                                      >
                                        <DropdownToggle
                                          className="dropdownfontsyle titleecolor"
                                          caret
                                          style={{
                                            width: "100%",
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040",
                                            border: "1px solid #ced4da",

                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          {oneTimeFormik.values.account
                                            ? oneTimeFormik.values.account
                                            : "Select"}
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdownfontsyle"
                                          style={{
                                            zIndex: 999,
                                            maxHeight: "280px",
                                            overflowY: "auto",
                                            width: "100%",
                                          }}
                                          name="account"
                                          onBlur={oneTimeFormik.handleBlur}
                                          onChange={oneTimeFormik.handleChange}
                                          value={oneTimeFormik.values.account}
                                        >
                                          {accountsData.map((account) =>
                                            account.charge_type ===
                                            "One Time Charge" ? (
                                              <DropdownItem
                                                className="dropdownfontsyle"
                                                onClick={() => {
                                                  oneTimeFormik.setFieldValue(
                                                    "account",
                                                    account.account
                                                  );
                                                }}
                                                key={account.account_id}
                                              >
                                                {account.account}
                                              </DropdownItem>
                                            ) : (
                                              ""
                                            )
                                          )}
                                          <DropdownItem
                                            className="dropdownfontsyle"
                                            onClick={() =>
                                              AddNewAccountName(
                                                "One Time Charge"
                                              )
                                            }
                                          >
                                            Add new account..
                                          </DropdownItem>
                                        </DropdownMenu>
                                        {oneTimeFormik.errors &&
                                        oneTimeFormik.errors.account &&
                                        oneTimeFormik.touched &&
                                        oneTimeFormik.touched.account ? (
                                          <div
                                            className="requiredstylefont"
                                            style={{ color: "red" }}
                                          >
                                            {oneTimeFormik.errors.account}
                                          </div>
                                        ) : null}
                                      </Dropdown>
                                    </FormGroup>
                                  </div>
                                  <FormGroup>
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="input-address"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Amount*
                                    </label>
                                    <br />
                                    <FormGroup>
                                      <Input
                                        style={{
                                          boxShadow:
                                            "0px 4px 4px 0px #00000040",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentr titleecolor"
                                        id="input-reserve"
                                        placeholder="Enter amount"
                                        type="text"
                                        name="amount"
                                        onBlur={oneTimeFormik.handleBlur}
                                        onChange={oneTimeFormik.handleChange}
                                        value={oneTimeFormik.values.amount}
                                        onInput={(e) => {
                                          const inputValue = e.target.value;
                                          // Allow digits and a single decimal point
                                          const numericValue =
                                            inputValue.replace(/[^0-9.]/g, "");
                                          // Ensure only one decimal point is allowed
                                          const finalValue =
                                            numericValue.replace(
                                              /(\..*?)\..*/g,
                                              "$1"
                                            );
                                          e.target.value = finalValue;
                                          oneTimeFormik.values.amount =
                                            finalValue;
                                        }}
                                      />

                                      {oneTimeFormik.errors &&
                                      oneTimeFormik.errors.amount &&
                                      oneTimeFormik.touched &&
                                      oneTimeFormik.touched.amount ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {oneTimeFormik.errors.amount}
                                        </div>
                                      ) : null}
                                    </FormGroup>
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="input-unitadd12"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Memo*
                                    </label>
                                    <Input
                                      style={{
                                        boxShadow:
                                          " 0px 4px 4px 0px #00000040 ",
                                        borderRadius: "6px",
                                      }}
                                      className="form-control-alternative fontstylerentr titleecolor"
                                      id="input-unitadd12"
                                      type="text"
                                      name="memo"
                                      onBlur={oneTimeFormik.handleBlur}
                                      onChange={oneTimeFormik.handleChange}
                                      value={oneTimeFormik.values.memo}
                                      onInput={(e) => {
                                        oneTimeFormik.values.memo =
                                          e.target.value;
                                      }}
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="d-flex justify-content-start ml-2 mb-3">
                                <DialogActions>
                                  <Button
                                    className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                    type="submit"
                                    onClick={() => {
                                      oneTimeFormik.handleSubmit();
                                    }}
                                  >
                                    Add
                                  </Button>
                                  <Button
                                    className="fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleClose}
                                  >
                                    Cancel
                                  </Button>
                                </DialogActions>
                              </div>
                            </div>
                          </Dialog>
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* //add new accounts */}
                    <AccountDialog
                      addBankAccountDialogOpen={addBankAccountDialogOpen}
                      setAddBankAccountDialogOpen={setAddBankAccountDialogOpen}
                      accountTypeName={accountTypeName}
                      adminId={accessType?.admin_id}
                      fetchAccounts={fetchAccounts}
                    />

                    {/* //Recurring Charges Data */}
                    <div>
                      {recurringData?.length > 0 ? (
                        <>
                          <Row
                            className="w-100 my-3 labelfontstyle textcolorblue"
                            style={{
                              fontSize: "18px",
                              textTransform: "capitalize",
                              fontWeight: "600",
                              borderBottom: "1px solid #ddd",
                              paddingTop: "15px",
                            }}
                          >
                            <Col>Recurring Information</Col>
                          </Row>

                          {/* <Row
                            className="w-100 mb-1"
                            style={{
                              fontSize: "17px",
                              // textTransform: "uppercase",
                              color: "#aaa",
                              fontWeight: "bold",
                            }}
                          >
                            <Col>Account</Col>
                            <Col>Amount</Col>
                            <Col>Action</Col>
                          </Row> */}
                          <div
                            style={{
                              overflowX: "auto",
                              scrollbarWidth: "thin",
                              maxHeight: "300px",
                              marginTop: "2%",
                            }}
                          >
                            <table
                              style={{
                                borderCollapse: "collapse",
                                width: "100%",
                              }}
                            >
                              <thead>
                                <tr
                                  style={{ background: "#f2f2f2" }}
                                  className="fontstylerentr"
                                >
                                  <th
                                    style={{
                                      width: "40%",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Account
                                  </th>
                                  <th
                                    style={{
                                      width: "30%",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Amount
                                  </th>
                                  <th
                                    style={{
                                      width: "30%",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {recurringData?.map((data, index) => (
                                  // <Row
                                  //   className="w-100 mt-1"
                                  //   style={{
                                  //     fontSize: "14px",
                                  //     textTransform: "capitalize",
                                  //     color: "#000",
                                  //   }}
                                  //   key={index} // Add a unique key to each iterated element
                                  // >
                                  //   <Col>{data.account}</Col>
                                  //   <Col>{data.amount}</Col>
                                  //   <Col>
                                  //     <EditIcon
                                  //       onClick={() => editeReccuring(index)}
                                  //     />
                                  //     <DeleteIcon
                                  //       onClick={() => handleRecurringDelete(index)}
                                  //     />
                                  //   </Col>
                                  // </Row>
                                  <tr className="fontstylerentr" key={index}>
                                    <td
                                      style={{
                                        width: "40%",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {data.account}
                                    </td>
                                    <td
                                      style={{
                                        width: "30%",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {data.amount}
                                    </td>
                                    <td
                                      style={{
                                        width: "30%",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      <EditIcon
                                        className="tableiconrentroll"
                                        onClick={() => editeReccuring(index)}
                                      />
                                      <DeleteIcon
                                        className="tableiconrentroll"
                                        onClick={() =>
                                          handleRecurringDelete(index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : null}
                    </div>

                    {/* one tme charges */}
                    <div>
                      {oneTimeData?.length > 0 ? (
                        <>
                          <Row
                            className="w-100 my-3 labelfontstyle textcolorblue"
                            style={{
                              fontSize: "18px",
                              textTransform: "capitalize",
                              fontWeight: "600",
                              borderBottom: "1px solid #ddd",
                              paddingTop: "15px",
                            }}
                          >
                            <Col>One Time Information</Col>
                          </Row>

                          {/* <Row
                            className="w-100 mb-1"
                            style={{
                              fontSize: "17px",
                              color: "#aaa",
                              fontWeight: "bold",
                            }}
                          >
                            <Col>Account</Col>
                            <Col>Amount</Col>
                            <Col>Action</Col>
                          </Row> */}

                          <div
                            style={{
                              overflowX: "auto",
                              scrollbarWidth: "thin",
                              maxHeight: "300px",
                              marginTop: "2%",
                            }}
                          >
                            <table
                              style={{
                                borderCollapse: "collapse",
                                width: "100%",
                              }}
                            >
                              <thead>
                                <tr
                                  style={{ background: "#f2f2f2" }}
                                  className="fontstylerentr"
                                >
                                  <th
                                    style={{
                                      width: "40%",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Account
                                  </th>
                                  <th
                                    style={{
                                      width: "30%",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Amount
                                  </th>
                                  <th
                                    style={{
                                      width: "30%",
                                      padding: "8px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {oneTimeData?.map((data, index) => (
                                  // <Row
                                  //   className="w-100 mt-1"
                                  //   style={{
                                  //     fontSize: "14px",
                                  //     textTransform: "capitalize",
                                  //     color: "#000",
                                  //   }}
                                  //   key={index}
                                  // >
                                  //   <Col>{data.account}</Col>
                                  //   <Col>{data.amount}</Col>
                                  //   <Col>
                                  //     <EditIcon onClick={() => editOneTime(index)} />
                                  //     <DeleteIcon
                                  //       onClick={() => handleOnetimeDelete(index)}
                                  //     />
                                  //   </Col>
                                  // </Row>
                                  <tr className="fontstylerentr" key={index}>
                                    <td
                                      style={{
                                        width: "40%",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {data.account}
                                    </td>
                                    <td
                                      style={{
                                        width: "30%",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {data.amount}
                                    </td>
                                    <td
                                      style={{
                                        width: "30%",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      <EditIcon
                                        className="tableiconrentroll"
                                        onClick={() => editOneTime(index)}
                                      />
                                      <DeleteIcon
                                        className="tableiconrentroll"
                                        onClick={() =>
                                          handleOnetimeDelete(index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="mt-3 mx-3 shadow cardstyborderradius"
                  style={{
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  <CardBody>
                    {/* uploaded file */}

                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                            htmlFor="input-address"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Upload Files (Maximum of 10)
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="d-flex">
                      <div className="file-upload-wrapper">
                        <TextField
                          type="file"
                          className="form-control-file d-none"
                          accept="file/*"
                          name="upload_file"
                          id="upload_file"
                          multiple
                          inputProps={{
                            multiple: true,
                            accept: "application/pdf",
                            max: 10,
                          }}
                          onChange={(e) => {
                            fileData(e.target.files);
                          }}
                        />
                        <label
                          htmlFor="upload_file"
                          className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          Upload
                        </label>
                      </div>
                      <div className="d-flex ">
                        {file?.length > 0 &&
                          file?.map((singleFile, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                marginLeft: "50px",
                              }}
                            >
                              {!lease_id ? (
                                <p
                                  onClick={() => {
                                    if (singleFile) {
                                    }
                                    window.open(
                                      URL.createObjectURL(singleFile),
                                      "_blank"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {singleFile?.name?.substr(0, 5)}
                                  {singleFile?.name?.length > 5 ? "..." : null}
                                </p>
                              ) : (
                                <p
                                  onClick={() => {
                                    window.open(
                                      `${imageGetUrl}/${singleFile}`,
                                      "_blank"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {singleFile?.substr(0, 5)}
                                  {singleFile?.length > 5 ? "..." : null}
                                </p>
                              )}
                              <CloseIcon
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  left: "64px",
                                  top: "-2px",
                                }}
                                onClick={() => deleteFile(index)}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="mt-3 mx-3 shadow cardstyborderradius"
                  style={{
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  <CardBody>
                    {/* uploaded file */}

                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                            htmlFor="input-address"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            E-Signature
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Row>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ToggleButtonGroup
                                  value={signatureOption}
                                  exclusive
                                  onChange={(e, newOption) =>
                                    handleSignatureChange(newOption)
                                  }
                                  aria-label="Signature Option"
                                  style={{ width: "100%" }}
                                >
                                  <ToggleButton
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    value="draw"
                                    onClick={() => setSignatureOption("draw")}
                                    style={{
                                      width: "15rem",
                                      textTransform: "capitalize",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Draw Signature
                                  </ToggleButton>
                                  <ToggleButton
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    value="type"
                                    onClick={() => setSignatureOption("type")}
                                    style={{
                                      width: "15rem",
                                      textTransform: "capitalize",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Type Signature
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            </Row>
                            <Row>
                              {signatureOption === "draw" && (
                                <Col lg="4" className="mt-3">
                                  <div className="p-3">
                                    <div
                                      className="d-flex justify-content-end"
                                      style={{}}
                                    >
                                      <Button
                                        className="textcolorblue  backgroundwhitesty"
                                        outline
                                        style={{
                                          padding: "2px 18px 2px 18px",
                                          fontSize: "14px",
                                          border: "1px solid #152B51",
                                        }}
                                        onClick={clearSignature}
                                      >
                                        Clear
                                      </Button>
                                    </div>
                                    <SignatureCanvas
                                      ref={(ref) => setSignatureRef(ref)}
                                      canvasProps={{
                                        width: 450,
                                        height: 130,
                                        className: "signature-canvas",
                                      }}
                                    />
                                    <div
                                      className="text-center p-2"
                                      style={{
                                        borderTop: "2px dashed #2A4F61",
                                        color: "#2A4F61",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Draw signature here
                                    </div>
                                  </div>
                                </Col>
                              )}
                              {signatureOption === "type" && (
                                <Col lg="4" className="mt-3">
                                  <FormControl
                                    placeholder="Type Signature"
                                    value={typedSignature}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      const limitedInput =
                                        inputValue.length <= 30
                                          ? inputValue
                                          : inputValue.slice(0, 30);
                                      setTypedSignature(limitedInput);
                                    }}
                                    style={{
                                      width: "100%",
                                      border: "1px solid #262B40",
                                      fontSize: "20px",
                                      padding: "10px",
                                      boxSizing: "border-box",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      borderBottomLeftRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                    }}
                                  />
                                  <div
                                    style={{
                                      height: "200px",
                                      borderBottomLeftRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                      border: "1px solid #262B40",
                                      borderTop: "none",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label
                                      id="typeSignatureLabel"
                                      style={{
                                        width: "100%",
                                        fontSize: "50px",
                                        textAlign: "center",
                                        padding: "13px",
                                        boxSizing: "border-box",
                                        fontFamily: "Dancing Script, cursive",
                                        color: "blue",
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {typedSignature}
                                    </label>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                {!lease_id ? (
                  <Card
                    className="mt-3 mx-3 shadow cardstyborderradius"
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040",
                      border: "1px solid #324567",
                    }}
                  >
                    <CardBody>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                              htmlFor="input-address"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Residents center Welcome Email
                            </label>

                            <FormLabel>
                              <Switch
                                className="custom-switch"
                                checked={
                                  leaseFormik.values.tenant_residentStatus
                                }
                                onChange={(e) => {
                                  leaseFormik.setFieldValue(
                                    "tenant_residentStatus",
                                    e.target.checked
                                  );
                                }}
                              />
                            </FormLabel>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="mb-3 fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-address"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              We send a welcome email to anyone without resident
                              center access
                            </label>
                          </FormGroup>
                        </Col>
                        {/* <FormControlLabel
                          control={
                            <CustomSwitch
                              checked={leaseFormik.values.tenant_residentStatus} // Ensure checked prop is set
                              onChange={(e) => {
                                leaseFormik.setFieldValue(
                                  "tenant_residentStatus",
                                  e.target.checked
                                );
                              }}
                              id="customChecked"
                            />
                          }
                          labelPlacement="end"
                        /> */}
                      </Row>

                      {/* <hr /> */}

                      {/* <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-property"
                        >
                          Select Payment Method *
                        </label>
                        <FormGroup>
                          <Dropdown
                            isOpen={paymentOptionDropdawnOpen}
                            toggle={paymentMethodtoggle}
                          >
                            <DropdownToggle caret style={{ width: "100%" }}>
                              {selectPaymentMethod
                                ? selectPaymentMethod
                                : "Select"}
                            </DropdownToggle>
                            <DropdownMenu
                              style={{ width: "100%" }}
                              name="paymentMethod"
                              onBlur={paymentFormik.handleBlur}
                              onChange={(e) => paymentFormik.handleChange(e)}
                              value={paymentFormik.values.paymentMethod}
                            >
                              {selectPaymentMethodData.map((option) => (
                                <DropdownItem
                                  key={option}
                                  onClick={() => {
                                    setSelectPaymentMethod(option);
                                    paymentFormik.setFieldValue(
                                      "paymentMethod",
                                      option
                                    );
                                  }}
                                >
                                  {option}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                          {paymentFormik.errors &&
                          paymentFormik.errors?.paymentMethod &&
                          paymentFormik.touched &&
                          paymentFormik.touched?.paymentMethod &&
                          paymentFormik.values.paymentMethod === "" ? (
                                                    <div className="requiredstylefont" style={{ color: "red" }}>

                              {paymentFormik.errors.paymentMethod}
                            </div>
                          ) : null}
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Col sm="12">
                    {selectPaymentMethod === "AutoPayment" ? (
                      <>
                        <Row className="mb-3">
                          <Col xs="12" sm="7">
                            <Row>
                              <Col xs="12" sm="5">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor={`card_number`}
                                  >
                                    Card Number *
                                  </label>
                                  <Input
                                    type="text"
                                    id={`card_number`}
                                    placeholder="0000 0000 0000 0000"
                                    className="no-spinner"
                                    name={`card_number`}
                                    value={paymentFormik.values.card_number}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      paymentFormik.setFieldValue(
                                        `card_number`,
                                        inputValue
                                      );
                                    }}
                                  />
                                  {paymentFormik.errors &&
                                  paymentFormik.errors.card_number ? (
                                                            <div className="requiredstylefont" style={{ color: "red" }}>

                                      {paymentFormik.errors.card_number}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="5">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor={`exp_date`}
                                  >
                                    Expiration Date *
                                  </label>
                                  <Input
                                    type="text"
                                    id={`exp_date`}
                                    name={`exp_date`}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      paymentFormik.setFieldValue(
                                        `exp_date`,
                                        inputValue
                                      );
                                    }}
                                    value={paymentFormik.values.exp_date}
                                    placeholder="MM/YYYY"
                                  />
                                  {paymentFormik.errors &&
                                  paymentFormik.errors.exp_date ? (
                                                            <div className="requiredstylefont" style={{ color: "red" }}>

                                      {paymentFormik.errors.exp_date}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Col> */}
                    </CardBody>
                  </Card>
                ) : null}
                <Row className="ml-3 mt-3 mb-4 ">
                  {loader ? (
                    <Button
                      type="submit"
                      className="btn fontstylerentr background-colorsty bgtextwhite"
                      style={{
                        cursor: "not-allowed",
                      }}
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : lease_id && !applicant_id ? (
                    <Button
                      type="submit"
                      className="btn fontstylerentr background-colorsty fontfamilysty bgtextwhite"
                      disabled={!!error}
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (selectedTenants?.length !== 0) {
                          if (
                            Object.keys(tenantFormik.errors)?.length !== 0 &&
                            Object.keys(rentChargeFormik.errors)?.length !==
                              0 &&
                            !error
                          ) {
                            return;
                          }
                          leaseFormik.handleSubmit();
                          return;
                        } else {
                          setDisplay(true);
                        }
                      }}
                    >
                      Update Lease
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty "
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      disabled={
                        !leaseFormik.isValid ||
                        !tenantFormik.isValid ||
                        Boolean(error)
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        if (selectedTenants?.length !== 0) {
                          if (
                            // Object.keys(tenantFormik.errors)?.length !== 0 &&
                            Object.keys(rentChargeFormik.errors)?.length !==
                              0 &&
                            !error
                          ) {
                            return;
                          }
                          leaseFormik.handleSubmit();
                          return;
                        } else {
                          setDisplay(true);
                        }
                      }}
                    >
                      Create Lease
                    </Button>
                  )}
                  <Button
                    // color="primary"
                    onClick={handleCloseButtonClick}
                    className="btn fontstylerentr backgroundwhitesty textcolorblue backgroundwhitesty fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Cancel
                  </Button>
                  {/* {tenantFormik.errors &&
                  leaseFormik.isValid &&
                  tenantFormik.errors?.tenant_password &&
                  leaseFormik.submitCount > 0 ? (
                    <div style={{ color: "red" }} className="fontstylerentr">
                      Tenant Password is missing
                    </div>
                  ) : null} */}

                  {!leaseFormik.isValid && (
                    <div
                      className="fontstylerentr"
                      style={{ color: "red", marginTop: "10px" }}
                    >
                      Please fill in all fields correctly.
                    </div>
                  )}
                </Row>
              </Form>
            )}
          </Col>
        </Row>
        {/* </div> */}
        <ToastContainer />
      </Container>
    </>
  );
};

export default RentRollLeaseing;
