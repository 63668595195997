// import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React, { useEffect, useState } from "react";
// import { Link, NavLink as NavLinkRRD, useLocation } from "react-router-dom";
// import { Nav, NavItem, NavLink } from "reactstrap";
// import routes from "routes";
// import "./style.css";
// import logoa from "../../assets/icons/websitelogo.svg";
// import { useMediaQuery } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import { useNavigate } from "react-router-dom";
// import { useCookies } from "react-cookie";
// import { checkstafftoken , getUserPermission } from "../../views/source/Functions/Userrolefunction";

// const StaffSidebar = ({
//   logo,
//   isCollapse,
//   toggleCollapse,
//   sideOpen,
//   setSideOpen,
// }) => {
//   const [accessType, setAccessType] = useState(null);
//   const [userroleData, setUserroleData] = useState(null);
//   const navigate = useNavigate();
//   const [cookies] = useCookies(['token']);
//   const location = useLocation();
//   const isMediumScreen = useMediaQuery("(max-width:991px)");
//   useEffect(() => {
//     const handleCheckToken = async () => {
//       const accessData = await checkstafftoken(cookies.token, navigate);
//       setAccessType(accessData);
//     };

//     if (cookies.token) {
//       handleCheckToken();
//     } else {
//       navigate("/auth/login");
//     }
//   }, [cookies.token, navigate]);

//   useEffect(() => {
//     const handleGetUserPermission = async () => {
//       if (accessType?.admin_id) {
//         const adminData = await getUserPermission(accessType.admin_id);
//         setUserroleData(adminData?.staff_permission);
//       }
//     };

//     if (accessType) {
//       handleGetUserPermission();
//     }
//   }, [accessType]);
//   const createLinks = () => {
//     const filteredRoutes = routes.filter(
//       (prop) =>
//         (prop.name === "DashBoard" ||
//           prop.name === "Profile" ||
//           prop.name === "Reports" ||
//           prop.name === "Applicants" ||

//           // (prop.name === "Properties" && prop.path === "/staffproperty") ||
//           // (prop.name === "Tenants" && prop.path === "/stafftenants") ||
//           // (prop.name === "Work Order" && prop.path === "/staffworktable")) &&
//           ((userroleData?.property_view && prop.name === "Properties") && prop.path === "/staffproperty")  || ((userroleData?.tenant_view && prop.name === "Tenants") && prop.path === "/stafftenants") ||
//           ((userroleData?.workorder_view && prop.name === "Work Order")  && prop.path === "/staffworktable") ||
//           ((userroleData?.lease_view && prop.name === "Rent Roll")  && prop.path === "/staffrentroll")) &&

//         prop.layout === "/staff"
//     );
//     return filteredRoutes.map((prop, key) => {
//       const path = prop.layout === "/staff" ? "/staff" : "/staff";
//       const isActive = location.pathname === path + prop.path;
//       return (
//         <NavItem key={key}>
//           <NavLink
//             to={path + prop.path}
//             tag={NavLinkRRD}
//             style={{ justifyContent: isCollapse && "center", width: "100%" }}
//             className="d-flex align-items-center"
//             onClick={() => setSideOpen(false)}
//           >
//             <span
//               style={{
//                 marginRight: !isCollapse && "20px",
//                 marginLeft: !isCollapse && "10px",
//               }}
//             >
//               {isActive ? (
//                 <img src={prop.icon2} width={isMediumScreen ? 15 : 20} />
//               ) : (
//                 <img src={prop.icon} width={isMediumScreen ? 15 : 20} />
//               )}
//             </span>
//             {!isCollapse && <>{prop.name}</>}
//           </NavLink>
//         </NavItem>
//       );
//     });
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (sideOpen && !!event.target.closest(".menub")) {
//         setSideOpen(true);
//       } else if (sideOpen && !event.target.closest(".collapse-sidebar")) {
//         setSideOpen(false);
//       }
//     };

//     document.addEventListener("click", handleClickOutside);

//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, [sideOpen]);

//   return (
//     <>
//       <div
//         className={
//           isMediumScreen
//             ? "collapse-sidebar stylemobileres"
//             : !isCollapse
//             ? "sidebar"
//             : "sidebar-active"
//         }
//         style={{
//           display: !sideOpen && isMediumScreen && "none",
//           borderTopRightRadius: isMediumScreen && "20px",
//           borderBottomRightRadius: isMediumScreen && "20px",
//         }}
//       >
//         <Nav vertical>
//           <Link
//             to="/staff/StaffdashBoard"
//             style={{
//               marginTop: "40px",
//               marginBottom: "30px",
//               justifyContent: isCollapse && "center",
//               padding: "15px",
//             }}
//           >
//             {isCollapse ? (
//               <img src={logo.imgSrc2} width={60} />
//             ) : (
//               <img src={logoa} width={!isMediumScreen ? 250 : "100%"} />
//             )}
//           </Link>
//           {createLinks()}
//         </Nav>
//         <span
//           className={isCollapse ? "collapse-btn-active" : "collapse-btn"}
//           style={{ display: isMediumScreen && "none" }}
//           onClick={(e) => {
//             toggleCollapse();
//           }}
//         >
//           {isCollapse ? (
//             <FontAwesomeIcon icon={faArrowRight} />
//           ) : (
//             <FontAwesomeIcon icon={faArrowLeft} />
//           )}
//         </span>
//       </div>
//       {isMediumScreen && (
//         <CloseIcon className="closebtnstylesidebar"
//           style={{
//             width: "8%",
//             color: "#000",
//             display: !sideOpen && "none",
//             position: "absolute",
//             top: "5px",
//             left: "52%",
//             zIndex: 9999,
//             padding: "0",
//             borderRadius: "50%",
//             backgroundColor: "#fff",
//           }}
//           onClick={() => {
//             setSideOpen(false);
//           }}
//         />
//       )}
//     </>
//   );
// };

// export default StaffSidebar;

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink as NavLinkRRD,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import routes from "routes3";
import "./style.css";
import logoa from "../../assets/icons/websitelogo.svg";
import { useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import {
  checkstafftoken,
  getUserPermission,
} from "../../views/source/Functions/Userrolefunction";

const StaffSidebar = ({
  logo,
  isCollapse,
  toggleCollapse,
  sideOpen,
  setSideOpen,
}) => {
  const [accessType, setAccessType] = useState(null);
  const [userroleData, setUserroleData] = useState(null);
  const [cookies] = useCookies(["token"]);
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();

  const isMediumScreen = useMediaQuery("(max-width:991px)");
  const [isOpen, setIsOpen] = useState("");

  const toggleDrawer = (name) => {
    if (isOpen === name) {
      setIsOpen("");
    } else {
      setIsOpen(name);
    }
  };

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await checkstafftoken(cookies.token, navigate);
      setAccessType(accessData);
    };

    if (cookies.token) {
      handleCheckToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    handleGetUserPermission();
  }, [accessType]);

  const createLinks = () => {
    // const filteredRoutes = routes.filter(
    //   (prop) => prop.is_display && prop.layout === "/staff"
    // );
    const filteredRoutes = [];
    // const filteredRoutes = routes.filter((prop) => {
    //   // Base level routes that should always be visible
    //   if (
    //     prop.name === "DashBoard" ||
    //     prop.name === "Profile" ||
    //     prop.name === "Reports"
    //   ) {
    //     return true;
    //   }

    //   // Property Type route
    //   if (prop.name === "Property Type") {
    //     return true;
    //   }

    //   // Rentals section
    //   if (prop.name === "Rentals") {
    //     return prop.childers.filter((child) => {
    //       if (userroleData?.property_view && child.name === "Properties") {
    //         return true;
    //       } else if (
    //         userroleData?.rentalowner_view &&
    //         child.name === "Rental Owner"
    //       ) {
    //         return true;
    //       } else if (userroleData?.tenant_view && child.name === "Tenants") {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     });
    //   }

    //   // Leasing section
    //   if (prop.name === "Leasing") {
    //     return prop.childers.filter((child) => {
    //       if (userroleData?.lease_view && child.name === "Rent Roll") {
    //         console.log(8882222);
    //         return true;
    //       } else if (
    //         userroleData?.applicant_view &&
    //         child.name === "Applicants"
    //       ) {
    //         console.log(8889999);
    //         return true;
    //       } else {
    //         console.log(8883333);
    //         return false;
    //       }
    //     });
    //   }

    //   // Maintenance section
    //   if (prop.name === "Maintenance") {
    //     return prop.childers.filter((child) => {
    //       return (
    //         (userroleData?.vendor_view && child.name === "Vendor") ||
    //         (userroleData?.workorder_view && child.name === "Work Order")
    //       );
    //     });
    //   }

    //   return false;
    // });

    for (const prop of routes) {
      if (
        prop.name === "DashBoard" ||
        prop.name === "Profile" ||
        prop.name === "Reports"
      ) {
        filteredRoutes.push(prop);
      } else if (
        userroleData?.propertytype_view &&
        prop.name === "Property Type"
      ) {
        filteredRoutes.push(prop);
      } else if (prop.name === "Rentals") {
        const data = [];
        for (const child of prop.childers) {
          if (
            (userroleData?.property_view && child.name === "Properties") ||
            (userroleData?.rentalowner_view && child.name === "Rental Owner") ||
            (userroleData?.tenant_view && child.name === "Tenants")
          ) {
            data.push(child);
          }
        }
        if (data.length > 0) {
          filteredRoutes.push({ ...prop, childers: data });
        }
      } else if (prop.name === "Leasing") {
        const data = [];
        for (const child of prop.childers) {
          if (
            (userroleData?.lease_view && child.name === "Rent Roll") ||
            (userroleData?.applicant_view && child.name === "Applicants")
          ) {
            data.push(child);
          }
        }
        if (data.length > 0) {
          filteredRoutes.push({ ...prop, childers: data });
        }
      } else if (prop.name === "Maintenance") {
        const data = [];
        for (const child of prop.childers) {
          if (
            (userroleData?.vendor_view && child.name === "Vendor") ||
            (userroleData?.workorder_view && child.name === "Work Order")
          ) {
            data.push(child);
          }
        }
        if (data.length > 0) {
          filteredRoutes.push({ ...prop, childers: data });
        }
      }
    }

    return filteredRoutes.map((prop, key) => {
      const path = prop.layout === "/staff" ? "/staff" : "/staff";
      const isActive = prop.is_dropdown
        ? prop.childers?.some((item) => {
            return location?.pathname?.includes(item?.path);
          })
        : location.pathname === path + prop.path;
      return (
        <React.Fragment key={key}>
          {prop.is_dropdown ? (
            <NavItem style={{ justifyContent: "center" }}>
              <div
                // tag={ ? NavLinkRRD : "div"}
                style={{ justifyContent: isCollapse && "center" }}
                className={`d-flex nav-link align-items-center ${
                  isActive ? "active" : ""
                }`}
                onClick={() => toggleDrawer(prop.name)}
              >
                <button
                  style={{
                    justifyContent: isCollapse && "center",
                    fontWeight: "500",
                  }}
                  className="w-100 d-flex m-0 text-left p-0 bg-transparent color-white border-0 align-items-center"
                  onClick={() => toggleDrawer(prop.name)}
                >
                  <span
                    style={{
                      marginRight: !isCollapse && "20px",
                      marginLeft: !isCollapse && "10px",
                    }}
                  >
                    {isActive ? (
                      <img src={prop.icon2} width={isMediumScreen ? 15 : 20} />
                    ) : (
                      <img src={prop.icon} width={isMediumScreen ? 15 : 20} />
                    )}
                  </span>
                  {!isCollapse && <>{prop.name}</>}
                </button>
              </div>
              {isOpen === prop.name && (
                <div
                  className="p-0 py-2 mx-3 px-3"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                    borderRadius: "6px",
                  }}
                >
                  {prop.childers &&
                    prop.childers.map((item, index) => (
                      <NavItem key={index} style={{ justifyContent: "center" }}>
                        <NavLink
                          to={path + item.path}
                          tag={NavLinkRRD}
                          style={{
                            justifyContent: isCollapse && "center",
                            fontSize: "14px",
                            padding: "10px",
                          }}
                          className="d-flex align-items-center my-2 siderbarfontss"
                          onClick={() => {
                            setSideOpen(false);
                          }}
                        >
                          <span
                            style={{
                              marginRight: !isCollapse && "20px",
                              marginLeft: !isCollapse && "10px",
                            }}
                          >
                            {location?.pathname?.includes(item?.path) ? (
                              <img
                                src={item.icon2}
                                width={isMediumScreen ? 15 : 20}
                              />
                            ) : (
                              <img
                                src={item.icon}
                                width={isMediumScreen ? 15 : 20}
                              />
                            )}
                          </span>
                          {!isCollapse && <>{item.name}</>}
                        </NavLink>
                      </NavItem>
                    ))}
                </div>
              )}
            </NavItem>
          ) : (
            <NavItem key={key} style={{ justifyContent: "center" }}>
              <NavLink
                to={path + prop.path}
                tag={NavLinkRRD}
                style={{ justifyContent: isCollapse && "center" }}
                className="d-flex align-items-center "
                onClick={() => setSideOpen(false)}
              >
                <span
                  style={{
                    marginRight: !isCollapse && "20px",
                    marginLeft: !isCollapse && "10px",
                  }}
                >
                  {isActive ? (
                    <img src={prop.icon2} width={isMediumScreen ? 15 : 20} />
                  ) : (
                    <img src={prop.icon} width={isMediumScreen ? 15 : 20} />
                  )}
                </span>
                {!isCollapse && <>{prop.name}</>}
              </NavLink>
            </NavItem>
          )}
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideOpen && !!event.target.closest(".menub")) {
        setSideOpen(true);
      } else if (sideOpen && !event.target.closest(".collapse-sidebar")) {
        setSideOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sideOpen]);

  return (
    <>
      <div
        className={
          isMediumScreen
            ? "collapse-sidebar stylemobileres"
            : !isCollapse
            ? "sidebar"
            : "sidebar-active"
        }
        style={{
          display: !sideOpen && isMediumScreen && "none",
          borderTopRightRadius: isMediumScreen && "20px",
          borderBottomRightRadius: isMediumScreen && "20px",
        }}
      >
        <Nav vertical>
          <Link
            to="/staff/StaffdashBoard"
            style={{
              marginTop: "40px",
              marginBottom: "30px",
              justifyContent: isCollapse || (isMediumScreen && "center"),
              padding: !isMediumScreen && "15px",
            }}
          >
            {isCollapse ? (
              <img src={logo.imgSrc2} width={60} />
            ) : (
              <img
                className="adminimagesidebar"
                src={logoa}
                width={!isMediumScreen ? 250 : "70%"}
              />
            )}
          </Link>
          <div>{createLinks()}</div>
        </Nav>
        <span
          className={isCollapse ? "collapse-btn-active" : "collapse-btn"}
          style={{ display: isMediumScreen && "none" }}
          onClick={(e) => {
            toggleCollapse();
          }}
        >
          {isCollapse ? (
            <FontAwesomeIcon icon={faArrowRight} />
          ) : (
            <FontAwesomeIcon icon={faArrowLeft} />
          )}
        </span>
      </div>
      {isMediumScreen && (
        <CloseIcon
          className="closebtnstylesidebar"
          style={{
            width: "8%",
            color: "#000",
            display: !sideOpen && "none",
            position: "absolute",
            top: "5px",
            left: "52%",
            zIndex: 9999,
            padding: "0",
            borderRadius: "50%",
            backgroundColor: "#fff",
          }}
          onClick={() => {
            setSideOpen(false);
          }}
        />
      )}
    </>
  );
};

export default StaffSidebar;
