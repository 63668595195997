import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { Formik } from "formik";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "components/Headers/Header";
import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useCookies } from "react-cookie";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import axiosInstance from "axiosInstance";
import AccountDialog from "components/AccountDialog";
import SearchDropdown from "components/inputfields/SearchDropdown";
import { verifyToken } from "components/Functions/Functions";

const MailConfigurationCard = ({ data }) => {
  return (
    <div className="col-lg-6 mb-4">
      <div className="card h-100">
        <div className="card-body">
          <h3
            className="card-title font-weight-bold"
            style={{ borderBottom: "1px solid #ccc" }}
          >
            Configuration Details
          </h3>
          <p className="card-text font-weight-bold">Host:</p>
          <p className="card-text">{data.host}</p>
          <p className="card-text font-weight-bold">Port:</p>
          <p className="card-text">{data.port}</p>
          <p className="card-text font-weight-bold">User:</p>
          <p className="card-text">{data.user}</p>
          <p className="card-text font-weight-bold">Label:</p>
          <p className="card-text">{data.from_name}</p>
        </div>
      </div>
    </div>
  );
};

const StaffSetting = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { admin } = useParams();
  let navigate = useNavigate();
  const location  = useLocation();
  const [accessType, setAccessType] = useState(null);
  let [loader, setLoader] = React.useState(false);

  // const [cookies] = useCookies(["token"]);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/staffmember/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  const [cookie, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [selectedOption, setSelectedOption] = useState("Surcharge");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  //Selected option Surcharge
  const [surcharge, setsurcharge] = useState(null);
  const [surcharge_id, setSurchargeId] = useState(null);
  const [achOption, setAchOption] = useState(null);

  const surchargeFormik = useFormik({
    initialValues: {
      surcharge_percent: "",
      surcharge_percent_debit: "",
      surcharge_percent_ACH: "",
    },
    validationSchema: yup.object({
      surcharge_percent: yup.number().required("Required"),
      surcharge_account: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    let Admin_Id = accessType?.admin_id;
    axiosInstance
      .get(`/surcharge/surcharge/getadmin/${Admin_Id}`)
      .then((response) => {
        const Data = response.data.data[0];
        setsurcharge(Data);
        setSurchargeId(Data.surcharge_id);
        setSelectedSurchargAccount(Data.surcharge_account);
        surchargeFormik.setValues({
          surcharge_percent: Data.surcharge_percent || "",
          surcharge_percent_debit: Data.surcharge_percent_debit || "",
          surcharge_percent_ACH: Data.surcharge_percent_ACH || "",
          surcharge_flat_ACH: Data.surcharge_flat_ACH || "",
          surcharge_account: Data.surcharge_account,
        });
        if (Data.surcharge_percent_ACH && Data.surcharge_flat_ACH) {
          setAchOption(3);
        } else if (Data.surcharge_percent_ACH) {
          setAchOption(1);
        } else if (Data.surcharge_flat_ACH) {
          setAchOption(2);
        }
      })
      .catch((error) => {
        console.error("Error fetching property type data:", error);
      });
  }, [accessType, selectedOption]);

  async function handleSubmit(values) {
    setSubmitLoader(true);
    try {
      const object = {
        admin_id: accessType?.admin_id,
        surcharge_percent: surchargeFormik.values.surcharge_percent,
        surcharge_percent_debit: surchargeFormik.values.surcharge_percent_debit,
        surcharge_percent_ACH:
          surchargeFormik.values.surcharge_percent_ACH || "",
        surcharge_flat_ACH: surchargeFormik.values.surcharge_flat_ACH || "",
        surcharge_account: surchargeFormik.values.surcharge_account,
      };

      if (!surcharge_id) {
        const res = await axiosInstance.post(`/surcharge/surcharge`, object);
        if (res.data.statusCode === 200) {
          toast.success("Surcharge Added", {
            position: "top-center",
            autoClose: 800,
          });
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/surcharge/surcharge/${surcharge_id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          toast.success("Surcharge Updated", {
            position: "top-center",
            autoClose: 800,
          });
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  //Selected option Latefee Charge
  const [lateFee, setLateFee] = useState(null);
  const [latefee_id, setLateFeeId] = useState(null);
  const [testLoader, setTestLoader] = useState(false);

  const latefeeFormik = useFormik({
    initialValues: {
      duration: "",
      late_fee: "",
    },
    validationSchema: yup.object({
      duration: yup.number().required("Required"),
      late_fee: yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      handleLateFeeSubmit(values);
    },
  });

  useEffect(() => {
    let Admin_Id = accessType?.admin_id;
    axiosInstance
      .get(`/latefee/latefee/${Admin_Id}`)
      .then((response) => {
        const Data = response.data.data;
        setLateFee(Data);
        setLateFeeId(Data.latefee_id);
        latefeeFormik.setValues({
          duration: Data.duration || "",
          late_fee: Data.late_fee || "",
        });
      })
      .catch((error) => {
        console.error("Error fetching late fee data:", error);
      });
  }, [accessType, selectedOption]);

  async function handleLateFeeSubmit(values) {
    setSubmitLoader(true);
    try {
      const object = {
        admin_id: accessType?.admin_id,
        duration: latefeeFormik.values.duration,
        late_fee: latefeeFormik.values.late_fee,
      };

      if (!latefee_id) {
        const res = await axiosInstance.post(`/latefee/latefee`, object);
        if (res.data.statusCode === 200) {
          toast.success("Late Fee Added", {
            position: "top-center",
            autoClose: 800,
          });
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/latefee/latefee/${latefee_id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          toast.success("Late Fee Updated", {
            position: "top-center",
            autoClose: 800,
          });
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  //Selected option NMI Account
  // const [nmiKey, setNmiKey] = useState(null);
  // const [key_id, setKeyId] = useState(null);
  // const [responseText, setResponseText] = useState(null);

  // const nmikeyFormik = useFormik({
  //   initialValues: {
  //     security_key: "",
  //     admin_id: "",
  //   },
  //   validationSchema: yup.object({
  //     security_key: yup.string().required("Required"),
  //   }),
  //   onSubmit: (values) => {
  //     handleNmiKeySubmit(values);
  //   },
  // });

  // useEffect(() => {
  //   let Admin_Id = accessType?.admin_id;
  //   axiosInstance
  //     .get(`/nmi-keys/nmi-keys/${Admin_Id}`)
  //     .then((response) => {
  //       const Data = response.data.data;
  //       setNmiKey(Data);
  //       setKeyId(Data.key_id);
  //       nmikeyFormik.setValues({
  //         security_key: Data.security_key || "",
  //         //  late_fee: Data.late_fee || "",
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching late fee data:", error);
  //     });
  // }, [accessType, selectedOption]);

  // async function handleNmiKeySubmit(values) {
  //   setSubmitLoader(true);
  //   try {
  //     const object = {
  //       admin_id: accessType?.admin_id,
  //       security_key: values.security_key,
  //     };

  //     if (!key_id) {
  //       const res = await axiosInstance.post(`/nmi-keys/nmi-keys`, object);
  //       if (res.data.statusCode === 200) {
  //         toast.success("Security Key Added", {
  //           position: "top-center",
  //           autoClose: 800,
  //           // onClose: () => navigate(`/${admin}/surcharge`),
  //         });
  //       } else if (res.data.statusCode === 201) {
  //         toast.error(res.data.message, {
  //           position: "top-center",
  //           autoClose: 1000,
  //         });
  //       }
  //     } else {
  //       const editUrl = `/nmi-keys/nmi-keys/${key_id}`;
  //       const res = await axiosInstance.put(editUrl, object);
  //       if (res.data.statusCode === 200) {
  //         toast.success("Security Key Updated", {
  //           position: "top-center",
  //           autoClose: 800,
  //           // onClose: () => navigate(`/${admin}/surcharge`),
  //         });
  //       } else if (res.data.statusCode === 400) {
  //         toast.error(res.data.message, {
  //           position: "top-center",
  //           autoClose: 1000,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     if (error.response) {
  //       console.error("Response Data:", error.response.data);
  //     }
  //   } finally {
  //     setSubmitLoader(false);
  //   }
  // }

  // async function handleTestKeyClick(values) {
  //   setTestLoader(true);
  //   try {
  //     const object = {
  //       first_name: accessType.first_name,
  //       last_name: accessType.last_name,
  //       email: accessType.email,
  //       security_key: values.security_key,
  //       cc_number: values.cc_number,
  //       cc_exp: values.cc_exp
  //     };

  //       const res = await axiosInstance.post(`/nmipayment/test_sale`, {paymentDetails:object});
  //       if (res.data.statusCode === 100) {
  //         toast.success(res.data.message, {
  //           position: "top-center",
  //           autoClose: 1000,
  //           // onClose: () => navigate(`/${admin}/surcharge`),
  //         });
  //         setResponseText(res.data.message)
  //       } else if (res.data.statusCode === 200) {
  //         toast.error(res.data.message, {
  //           position: "top-center",
  //           autoClose: 1000,
  //         });
  //         setResponseText(res.data.message)
  //       }

  //   } catch (error) {
  //     console.error("Error:", error);
  //     if (error.response) {
  //       console.error("Response Data:", error.response.data);
  //     }
  //   } finally {
  //     setTestLoader(false);
  //   }
  // }

  //Selected option Mail
  const [mailData, setMailData] = useState(null);
  const [click, setClick] = useState(false);
  const [isReminderEmail, setIsReminderEmail] = useState(false);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let Admin_Id = accessType?.admin_id;
    axiosInstance
      .get(`/mail_permission/${Admin_Id}`)
      .then((response) => {
        const Data = response.data.data;
        setMailData(Data);
        setIsReminderEmail(Data.remindermail);
        setDuration(Data.duration);
      })
      .catch((error) => {
        console.error("Error fetching property type data:", error);
      });
  }, [accessType, selectedOption]);

  const handleSwitchChange = (event) => {
    setIsReminderEmail(event.target.checked);
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const handleSubmitmailpermission = async (event) => {
    event.preventDefault();
    setLoader(true);
    try {
      const values = {
        admin_id: accessType?.admin_id,
        remindermail: isReminderEmail,
        duration: isReminderEmail ? duration : 0,
      };

      let res;
      if (mailData && mailData.admin_id) {
        res = await axiosInstance.put(
          `/mail_permission/${accessType?.admin_id}`,
          values
        );
      } else {
        res = await axiosInstance.post(`/mail_permission`, values);
      }

      if (res.data.statusCode === 200) {
        toast.success("Email permission updated", {
          position: "top-center",
          autoClose: 1000,
        });
        setMailData(res.data.permissions);
      } else {
        toast.error(res.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 1000,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleReset = () => {
    setIsReminderEmail(false);
    setDuration(0);
  };
  const CustomSwitch = withStyles({
    switchBase: {
      color: "#ffffff",
      "&$checked": {
        color: "#152B51",
      },
      "&$checked + $track": {
        backgroundColor: "#152B51",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageItem, setPageItem] = useState(5);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [accountsData, setAccountsData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedSurchargAccount, setSelectedSurchargAccount] = useState("");

  const getaccounts = async () => {
    let Admin_id = accessType?.admin_id;
    axiosInstance
      .get(`/accounts/accounts/${Admin_id}`)
      .then((response) => {
        if (response.data.data) {
          setAccountsData(response.data?.data);
          setTotalPages(Math.ceil(response.data.data.length / pageItem));
        }
      })
      .catch((error) => {
        console.error("Error fetching accounts type data:", error);
      });
  };

  useEffect(() => {
    getaccounts();
  }, [pageItem, accessType, selectedOption]);

  //onchange funtions
  const handleSurchargAccountSelect = async (account) => {
    try {
      setSelectedSurchargAccount(account.account);
      surchargeFormik.setFieldValue("surcharge_account", account.account);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  const paginatedData = accountsData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [openRecurringDialog, setOpenRecurringDialog] = useState(false);
  const [selectChargeDropDown, setSelectChargetDropDown] = useState(false);
  const [editAccountDialogOpen, setEditAccountDialogOpen] = useState(false);

  const toggles1 = () => setSelectChargetDropDown(!selectChargeDropDown);

  const AddNewAccountName = async (accountName) => {
    setSelectedAccount(null);
    toggleAddBankDialog();
    setAccountTypeName(accountName);
  };

  const handleClose = () => {
    setOpenRecurringDialog(false);
  };

  const handleClickOpenRecurring = () => {
    setOpenRecurringDialog(true);
  };

  const openEditDialog = (account) => {
    setSelectedAccount(account.account_id);
    setAddBankAccountDialogOpen(true);
  };

  const [accountTypeName, setAccountTypeName] = useState("");

  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] =
    useState(false);

  const toggleAddBankDialog = () => {
    setAddBankAccountDialogOpen((prevState) => !prevState);
  };

  const deleteaccount = async (account) => {
    // swal({
    //   title: "Are You Sure?",
    //   text: "",
    //   icon: "warning",
    //   buttons: ["Cancel", "Delete"],
    //   dangerMode: true,
    // }).then(async (willDelete) => {
    //   if (willDelete) {
    //     const res = await axiosInstance.delete(
    //       `/accounts/accounts/${account.account_id}`
    //     );
    //     if (res) {
    //       toast.success("Account deleted successfully", {
    //         position: "top-center",
    //         autoClose: 1000,
    //       });
    //       getaccounts();
    //     } else {
    //       toast.error("Failed to delete account", {
    //         position: "top-center",
    //         autoClose: 1000,
    //       });
    //     }
    //   }
    // });
    swal({
      title: "Are you sure?",
      text: "Please provide a reason for account deletion:",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason-input", // ID for easy access to the input field
        },
      },
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          closeModal: false,
          visible: true,
          className: "swal-button--confirm", // Class for targeting the confirm button
        },
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const inputValue = document.querySelector("#delete-reason-input").value;
  
        if (!inputValue.trim()) {
          swal("Error", "Reason for deletion is required!", "error");
          return;
        }
  
        try {
          const res = await axiosInstance.delete(`/accounts/accounts/${account.account_id}`, {
            data: { reason: inputValue }, // Send the input reason to the API
          });
  
          if (res) {
            toast.success("Account deleted successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            swal.close(); // Close the SweetAlert after successful deletion
            getaccounts(); // Refresh the accounts list after deletion
          } else {
            toast.error("Failed to delete account", {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } catch (error) {
          toast.error(error.message, {
            position: "top-center",
          });
          console.error("Error:", error.message);
        }
      }
    });
  
    // Disable "Delete" button initially
    const confirmButton = document.querySelector(".swal-button--confirm");
    confirmButton.disabled = true;
  
    // Add event listener to the input field to enable/disable the button
    const inputField = document.querySelector("#delete-reason-input");
    inputField.oninput = () => {
      confirmButton.disabled = inputField.value.trim() === "";
    };
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        className="stylecontainer "
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Row>
          {/* <Col xs="12" sm="6">
              <FormGroup className="">
                <h1 style={{ color: "#152B51" }}>Settings</h1>
              </FormGroup>
            </Col> */}
          <Col xs="12" lg="12" sm="12">
            {/* <FormGroup className="">
                <h1 style={{ color: "white" }}>Property Type</h1>
              </FormGroup> */}
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Setting
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            {/* <Card className="shadow mb-3" > */}
            <Card
              className="mb-3"
              style={{
                border: "1px solid #324567",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              {/* <CardHeader className="border-0">
                  <h3 className="mb-0">Settings</h3>
                </CardHeader> */}
              <CardBody>
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    className="settingpageecard"
                  >
                    <div style={{ border: "1px solid #ccc", height: "100%" }}>
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        <li style={{ borderBottom: "1px solid #ccc" }}>
                          <div
                            className="barforsummaryrent textcolorblue fontfamilysty"
                            color="link"
                            onClick={() => handleOptionClick("Surcharge")}
                            style={{
                              backgroundColor:
                                selectedOption === "Surcharge"
                                  ? "#D3D3D3"
                                  : "inherit",
                              cursor: "pointer",
                              fontWeight: "600",
                              padding: "20px 15px",
                            }}
                          >
                            Surcharge
                          </div>
                        </li>

                        <li style={{ borderBottom: "1px solid #ccc" }}>
                          <div
                            className="barforsummaryrent textcolorblue fontfamilysty"
                            color="link"
                            onClick={() => handleOptionClick("Late fee charge")}
                            style={{
                              backgroundColor:
                                selectedOption === "Late fee charge"
                                  ? "#D3D3D3"
                                  : "inherit",
                              cursor: "pointer",
                              fontWeight: "600",
                              padding: "20px 15px",
                            }}
                          >
                            Late Fee Charge
                          </div>
                        </li>
                        {/* <li style={{ borderBottom: "1px solid #ccc" }}>
                            <div
                              color="link"
                              onClick={() => handleOptionClick("nmi-account")}
                              style={{
                                backgroundColor:
                                  selectedOption === "nmi-account"
                                    ? "#D3D3D3"
                                    : "inherit",
                                color: "black",
                                cursor: "pointer",
                                fontWeight: "bold",
                                padding: "20px 15px",
                              }}
                            >
                              NMI Account
                            </div>
                          </li> */}
                        <li style={{ borderBottom: "1px solid #ccc" }}>
                          <div
                            className="barforsummaryrent textcolorblue fontfamilysty"
                            color="link"
                            onClick={() => handleOptionClick("Mail service")}
                            style={{
                              backgroundColor:
                                selectedOption === "Mail service"
                                  ? "#D3D3D3"
                                  : "inherit",
                              cursor: "pointer",
                              fontWeight: "600",
                              padding: "20px 15px",
                            }}
                          >
                            Mail Service
                          </div>
                        </li>
                        <li>
                          <div
                            className="barforsummaryrent textcolorblue fontfamilysty"
                            color="link"
                            onClick={() =>
                              handleOptionClick("payment-accounts")
                            }
                            style={{
                              backgroundColor:
                                selectedOption === "payment-accounts "
                                  ? "#D3D3D3"
                                  : "inherit",
                              color: "#152B51",
                              cursor: "pointer",
                              fontWeight: "600",
                              padding: "20px 15px",
                              fontFamily: "Poppins",
                            }}
                          >
                            Manage Accounts
                          </div>
                        </li>
                        {/* <li style={{ borderBottom: "1px solid #ccc" }}>
                            <div
                              color="link"
                              onClick={() => handleOptionClick("admin")}
                              style={{
                                backgroundColor:
                                  selectedOption === "admin"
                                    ? "#D3D3D3"
                                    : "inherit",
                                color: "black",
                                cursor: "pointer",
                                fontWeight: "bold",
                                padding: "20px 15px",
                              }}
                            >
                              Admin
                            </div>
                          </li>
                          <li
                            style={{
                              marginBottom: "10px",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            <div
                              color="link"
                              onClick={() => handleOptionClick("general")}
                              style={{
                                backgroundColor:
                                  selectedOption === "general"
                                    ? "#D3D3D3"
                                    : "inherit",
                                color: "black",
                                cursor: "pointer",
                                fontWeight: "bold",
                                padding: "20px 15px",
                              }}
                            >
                              General
                            </div>
                          </li> */}
                      </ul>
                    </div>
                  </Col>
                  {/* <Row>
  
                    </Row> */}
                  {/* <div>
                      <li style={{ borderBottom: "1px solid #ccc" }}>
                            <div className="barforsummaryrent"
                              color="link"
                              onClick={() => handleOptionClick("surcharge")}
                              style={{
                                backgroundColor:
                                  selectedOption === "surcharge"
                                    ? "#D3D3D3"
                                    : "inherit",
                                color: "#152B51",
                                cursor: "pointer",
                                fontWeight: "600",
                                padding: "20px 15px",
                                fontFamily: "Poppins",
                              }}
                            >
                              Surcharge
                            </div>
                          </li>
                          <li style={{ borderBottom: "1px solid #ccc" }}>
                            <div className="barforsummaryrent"
                              color="link"
                              onClick={() => handleOptionClick("latefee")}
                              style={{
                                backgroundColor:
                                  selectedOption === "latefee"
                                    ? "#D3D3D3"
                                    : "inherit",
                                color: "#152B51",
                                cursor: "pointer",
                                fontWeight: "600",
                                padding: "20px 15px",
                                fontFamily: "Poppins",
  
                              }}
                            >
                              Late Fee Charge
                            </div>
                          </li> */}
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    className="settingdisplaynone"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ToggleButtonGroup
                        // color="primary"

                        // value={alignment}
                        // exclusive
                        // onChange={(e) => {
                        //   handleChange(e.target.value);
                        // }}
                        aria-label="Platform"
                        style={{ width: "100%", display: "flex" }}
                      >
                        <ToggleButton
                          className="fontstylerentr fontfamilysty"
                          value="Surcharge"
                          onClick={() => handleOptionClick("Surcharge")}
                          style={{
                            width: "50%",
                            backgroundColor:
                              selectedOption === "Surcharge"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "Surcharge"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px",
                          }}
                        >
                          Surcharge
                        </ToggleButton>
                        <ToggleButton
                          className="fontstylerentr fontfamilysty"
                          value="Late fee charge"
                          onClick={() => handleOptionClick("Late fee charge")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "Late fee charge"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "Late fee charge"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                          }}
                        >
                          Late Fee Charge
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ToggleButtonGroup
                        // color="primary"

                        // value={alignment}
                        // exclusive
                        // onChange={(e) => {
                        //   handleChange(e.target.value);
                        // }}
                        aria-label="Platform"
                        style={{ width: "100%", display: "flex" }}
                      >
                        <ToggleButton
                          className="fontstylerentr fontfamilysty"
                          value="Mail service"
                          onClick={() => handleOptionClick("Mail service")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "Mail service"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "Mail service"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                          }}
                        >
                          Mail Service
                        </ToggleButton>
                        <ToggleButton
                          className="fontstylerentr fontfamilysty"
                          value="payment-accounts"
                          onClick={() => handleOptionClick("payment-accounts")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "payment-accounts"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "payment-accounts"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                          }}
                        >
                          Manage account
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Col>

                  {/* <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    className="settingdisplaynone"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        style={{ width: "100%" }}
                      >
                        <DropdownToggle
                          caret
                          style={{
                            width: "100%",
                            backgroundColor: "#152B51",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "500",
                            padding: "7px",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          }}
                        >
                          {selectedOption ? selectedOption : "Select an Option"}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "100%" }}>
                          <DropdownItem
                            className="fontstylerentr"
                            onClick={() => handleOptionClick("Surcharge")}
                            style={{
                              backgroundColor:
                                selectedOption === "Surcharge"
                                  ? "#152B51"
                                  : "inherit",
                              color:
                                selectedOption === "Surcharge"
                                  ? "#fff"
                                  : "#152B51",
                              cursor: "pointer",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                            }}
                          >
                            Surcharge
                          </DropdownItem>
                          <DropdownItem
                            className="fontstylerentr"
                            onClick={() => handleOptionClick("Late fee charge")}
                            style={{
                              backgroundColor:
                                selectedOption === "Late fee charge"
                                  ? "#152B51"
                                  : "inherit",
                              color:
                                selectedOption === "Late fee charge"
                                  ? "#fff"
                                  : "#152B51",
                              cursor: "pointer",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                            }}
                          >
                            Late Fee Charge
                          </DropdownItem>
                          <DropdownItem
                            className="fontstylerentr"
                            onClick={() => handleOptionClick("Mail service")}
                            style={{
                              backgroundColor:
                                selectedOption === "Mail service"
                                  ? "#152B51"
                                  : "inherit",
                              color:
                                selectedOption === "Mail service"
                                  ? "#fff"
                                  : "#152B51",
                              cursor: "pointer",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                            }}
                          >
                            Mail Service
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col> */}
                  {/* </div> */}

                  <Col>
                    <div>
                      <CardBody>
                        {selectedOption === "Surcharge" && (
                          <div>
                            <Row
                              style={
                                {
                                  // backgroundColor: "#152B51",
                                  // borderRadius: "6px",
                                  // height: "45px",
                                  // boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  // padding: "10px 7px 1px 21px",
                                }
                              }
                            >
                              <h1
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "22px",
                                }}
                              >
                                Surcharge
                              </h1>
                            </Row>
                            <Form onSubmit={surchargeFormik.handleSubmit}>
                              <div className="mt-3">
                                <Row>
                                  <span
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    You can set default surcharge percentage
                                    from here
                                  </span>
                                </Row>
                                <Row className="mt-3">
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Credit Card Surcharge Percent
                                      </label>
                                      <br />
                                      <div
                                        style={{
                                          position: "relative",
                                          width: "70%",
                                        }}
                                      >
                                        <Input
                                          style={{
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                          className="form-control-alternative fontstylerentmodal"
                                          id="input-protype"
                                          placeholder="Surcharge %"
                                          type="number"
                                          name="surcharge_percent"
                                          onBlur={surchargeFormik.handleBlur}
                                          onChange={
                                            surchargeFormik.handleChange
                                          }
                                          value={
                                            surchargeFormik.values
                                              .surcharge_percent
                                          }
                                          required
                                        />
                                        <span
                                          className="fontstylerentmodal"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                          }}
                                        >
                                          %
                                        </span>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup className="">
                                      <label
                                        className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Debit Card Surcharge Percent
                                      </label>
                                      <br />
                                      <div
                                        style={{
                                          position: "relative",
                                          width: "70%",
                                        }}
                                      >
                                        <Input
                                          style={{
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                          className="form-control-alternative fontstylerentmodal"
                                          id="input-protype"
                                          placeholder="Surcharge %"
                                          type="number"
                                          name="surcharge_percent_debit"
                                          onBlur={surchargeFormik.handleBlur}
                                          onChange={
                                            surchargeFormik.handleChange
                                          }
                                          value={
                                            surchargeFormik.values
                                              .surcharge_percent_debit
                                          }
                                        />
                                        <span
                                          className="fontstylerentmodal"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                          }}
                                        >
                                          %
                                        </span>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <span
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    You can set default ACH percentage or ACH
                                    flat fee or both from here
                                  </span>
                                </Row>

                                <Row className="mt-3">
                                  <Col lg="5">
                                    <FormGroup check>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        <Input
                                          type="radio"
                                          id="checked"
                                          name="achOption"
                                          checked={achOption === 1}
                                          onChange={() => setAchOption(1)}
                                        />{" "}
                                        Add ACH surcharge percentage
                                      </label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="mt-1">
                                  <Col lg="5">
                                    <FormGroup check>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        <Input
                                          type="radio"
                                          id="checked"
                                          name="achOption"
                                          checked={achOption === 2}
                                          onChange={() => setAchOption(2)}
                                        />{" "}
                                        Add ACH flat fee
                                      </label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="mt-1">
                                  <Col lg="6">
                                    <FormGroup check>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        <Input
                                          type="radio"
                                          id="checked"
                                          name="achOption"
                                          checked={achOption === 3}
                                          onChange={() => setAchOption(3)}
                                        />{" "}
                                        Add both ACH surcharge percentage and
                                        flat fee
                                      </label>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                {achOption === 1 && (
                                  <Row className="mt-3">
                                    <Col lg="4">
                                      <FormGroup>
                                        <label
                                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          ACH Surcharge Percent
                                        </label>
                                        <br />
                                        <div
                                          style={{
                                            position: "relative",
                                            width: "70%",
                                          }}
                                        >
                                          <Input
                                            style={{
                                              boxShadow:
                                                " 0px 4px 4px 0px #00000040 ",
                                              borderRadius: "6px",
                                            }}
                                            className="form-control-alternative fontstylerentmodal"
                                            id="input-protype"
                                            placeholder="Surcharge %"
                                            type="number"
                                            name="surcharge_percent_ACH"
                                            onBlur={surchargeFormik.handleBlur}
                                            onChange={
                                              surchargeFormik.handleChange
                                            }
                                            value={
                                              surchargeFormik.values
                                                .surcharge_percent_ACH
                                            }
                                            required
                                          />
                                          <span
                                            className="fontstylerentmodal"
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: "50%",
                                              transform: "translateY(-50%)",
                                            }}
                                          >
                                            %
                                          </span>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                )}

                                {achOption === 2 && (
                                  <Row className="mt-3">
                                    <Col lg="4">
                                      <FormGroup>
                                        <label
                                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          ACH Flat Fee
                                        </label>
                                        <br />
                                        <Input
                                          style={{ width: "70%" }}
                                          className="form-control-alternative fontstylerentmodal"
                                          id="input-protype"
                                          placeholder="Flat Fee"
                                          type="number"
                                          name="surcharge_flat_ACH"
                                          onBlur={surchargeFormik.handleBlur}
                                          onChange={
                                            surchargeFormik.handleChange
                                          }
                                          value={
                                            surchargeFormik.values
                                              .surcharge_flat_ACH
                                          }
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                )}

                                {achOption === 3 && (
                                  <>
                                    <Row className="mt-3">
                                      <Col lg="4">
                                        <FormGroup>
                                          <label
                                            className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                            htmlFor="input-property"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "16px",
                                            }}
                                          >
                                            ACH Surcharge Percent
                                          </label>
                                          <br />
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "70%",
                                            }}
                                          >
                                            <Input
                                              style={{
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                              }}
                                              className="form-control-alternative fontstylerentmodal"
                                              id="input-protype"
                                              placeholder="Surcharge %"
                                              type="number"
                                              name="surcharge_percent_ACH"
                                              onBlur={
                                                surchargeFormik.handleBlur
                                              }
                                              onChange={
                                                surchargeFormik.handleChange
                                              }
                                              value={
                                                surchargeFormik.values
                                                  .surcharge_percent_ACH
                                              }
                                              required
                                            />
                                            <span
                                              className="fontstylerentmodal"
                                              style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                              }}
                                            >
                                              %
                                            </span>
                                          </div>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="4">
                                        <FormGroup className="mt-2">
                                          <label
                                            className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                            htmlFor="input-property"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "16px",
                                            }}
                                          >
                                            ACH Flat Fee
                                          </label>
                                          <br />
                                          <Input
                                            style={{
                                              width: "70%",

                                              boxShadow:
                                                " 0px 4px 4px 0px #00000040 ",
                                              borderRadius: "6px",
                                            }}
                                            className="form-control-alternative fontstylerentmodal"
                                            id="input-protype"
                                            placeholder="Flat Fee"
                                            type="number"
                                            name="surcharge_flat_ACH"
                                            onBlur={surchargeFormik.handleBlur}
                                            onChange={
                                              surchargeFormik.handleChange
                                            }
                                            value={
                                              surchargeFormik.values
                                                .surcharge_flat_ACH
                                            }
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                                {/* changes start 8484 */}
                                {/* feature to add account to store surcharge */}
                                <Row className="mt-3">
                                  <Col lg="4" md="4" sm="6" xl="4">
                                    <label
                                      className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                      htmlFor="input-property"
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      Account to receive surcharges
                                    </label>
                                    <FormGroup className="mt-2">
                                      <SearchDropdown
                                        options={accountsData}
                                        value={
                                          accountsData.find(
                                            (p) =>
                                              p.account ===
                                              selectedSurchargAccount
                                          ) || null
                                        }
                                        // // inputValue={selectedProp}
                                        onChange={(event, value) =>
                                          handleSurchargAccountSelect(value)
                                        }
                                        placeholder="Select Account"
                                        getOptionLabel={(option) =>
                                          option.account || ""
                                        }
                                        filterOptions={(options, state) => {
                                          return options.filter((option) =>
                                            option.account
                                              .toLowerCase()
                                              .includes(
                                                state.inputValue.toLowerCase()
                                              )
                                          );
                                        }}
                                      />
                                      {surchargeFormik.errors &&
                                      surchargeFormik.errors?.account_id &&
                                      surchargeFormik.touched &&
                                      surchargeFormik.touched?.account_id ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {surchargeFormik.errors.account_id}
                                        </div>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                {/* end changes */}
                              </div>

                              <Row className="mt-3 my-row">
                                {submitLoader ? (
                                  <Button
                                    type="submit"
                                    className="btn btn-primary fontstylerentr background-colorsty bgtextwhite"
                                    disabled
                                  >
                                    Loading...
                                  </Button>
                                ) : (
                                  <Button
                                    type="submit"
                                    className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {surcharge_id ? "Update" : "Save"}
                                  </Button>
                                )}
                                <Button
                                  // color="primary"
                                  className="btn fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                  onClick={surchargeFormik.resetForm}
                                  style={{
                                    border: "1px solid #152B51",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                  }}
                                >
                                  Reset
                                </Button>
                              </Row>
                            </Form>
                          </div>
                        )}
                        {selectedOption === "Late fee charge" && (
                          <div>
                            <Row
                              style={
                                {
                                  // backgroundColor: "#152B51",
                                  // borderRadius: "6px",
                                  // // height: "45px",
                                  // boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  // padding: "10px 7px 1px 21px",
                                }
                              }
                            >
                              <h1
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "22px",
                                }}
                              >
                                Late Fee Charge
                              </h1>
                            </Row>
                            <Form onSubmit={latefeeFormik.handleSubmit}>
                              <div className="mt-3">
                                <Row>
                                  <span
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    You can set default Late fee charge from
                                    here
                                  </span>
                                </Row>
                                <Row className="mt-4">
                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Percentage
                                      </label>
                                      <br />
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal"
                                        id="input-protype"
                                        placeholder="Late Fee Charge"
                                        type="number"
                                        name="late_fee"
                                        onBlur={latefeeFormik.handleBlur}
                                        onChange={latefeeFormik.handleChange}
                                        value={latefeeFormik.values.late_fee}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Number Of Grace Period Days
                                      </label>
                                      <br />
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal"
                                        id="input-protype"
                                        placeholder="Late Fee Duration"
                                        type="number"
                                        name="duration"
                                        onBlur={latefeeFormik.handleBlur}
                                        onChange={latefeeFormik.handleChange}
                                        value={latefeeFormik.values.duration}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <br />
                              </div>
                              <Row className="mt-3 my-row">
                                {submitLoader ? (
                                  <Button
                                    type="submit"
                                    className="btn btn-primary fontstylerentr background-colorsty bgtextwhite"
                                    disabled
                                  >
                                    Loading...
                                  </Button>
                                ) : (
                                  <Button
                                    type="submit"
                                    className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {latefee_id ? "Update" : "Save"}
                                  </Button>
                                )}
                                <Button
                                  className="btn fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                  onClick={latefeeFormik.resetForm}
                                  style={{
                                    border: "1px solid #152B51",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                  }}
                                >
                                  Reset
                                </Button>
                              </Row>
                            </Form>
                          </div>
                        )}
                        {selectedOption === "Mail service" && (
                          <div>
                            <Row
                              style={
                                {
                                  // backgroundColor: "#152B51",
                                  // borderRadius: "6px",
                                  // // height: "45px",
                                  // boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  // padding: "10px 7px 1px 21px",
                                }
                              }
                            >
                              <h1
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "22px",
                                }}
                              >
                                Mail Service
                              </h1>
                            </Row>
                            <Form onSubmit={handleSubmitmailpermission}>
                              <Row>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      //  <Switch
                                      // style={{ color: "#152B51" }}
                                      // checked={isReminderEmail}
                                      // onChange={handleSwitchChange}

                                      //  />
                                      <CustomSwitch
                                        checked={isReminderEmail}
                                        onChange={handleSwitchChange}
                                      />
                                    }
                                    label="Rent due reminder email"
                                  />
                                </FormGroup>
                              </Row>

                              {isReminderEmail && (
                                <>
                                  <Row>
                                    <span
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                      }}
                                    >
                                      You can set a duration for send reminder
                                      email before rent due date to tenant
                                    </span>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col lg="6">
                                      <FormGroup>
                                        <label
                                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Duration
                                        </label>
                                        <br />
                                        <Input
                                          style={{
                                            boxShadow:
                                              "0px 4px 4px 0px #00000040",
                                            borderRadius: "6px",
                                          }}
                                          className="form-control-alternative fontstylerentmodal"
                                          id="input-protype"
                                          placeholder="reminder mail Duration"
                                          type="text"
                                          name="duration"
                                          value={duration}
                                          onChange={(e) => {
                                            if (
                                              /^\d*\.?\d*$/.test(e.target.value)
                                            ) {
                                              handleDurationChange(e);
                                            }
                                          }}
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </>
                              )}

                              <Row className="mt-3 my-row">
                                {submitLoader ? (
                                  <Button
                                    type="submit"
                                    className="btn btn-primary fontstylerentr background-colorsty bgtextwhite"
                                    disabled
                                  >
                                    Loading...
                                  </Button>
                                ) : (
                                  <Button
                                    type="submit"
                                    className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {mailData ? "Update" : "Save"}
                                  </Button>
                                )}
                                <Button
                                  className="btn fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                  onClick={handleReset}
                                  style={{
                                    border: "1px solid #152B51",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                  }}
                                >
                                  Reset
                                </Button>
                              </Row>
                            </Form>
                          </div>
                        )}
                        {selectedOption === "payment-accounts" && (
                          <div>
                            <Row
                              style={
                                {
                                  // backgroundColor: "#152B51",
                                  // borderRadius: "6px",
                                  // // height: "45px",
                                  // boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  // padding: "10px 7px 1px 21px",
                                }
                              }
                            >
                              <h1
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "22px",
                                }}
                              >
                                Manage Accounts
                              </h1>
                            </Row>
                            <Row>
                              <Col
                                xs="12"
                                lg="12"
                                sm="12"
                                className="text-right"
                              >
                                <Button
                                  className="tenantnewbtn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                  onClick={handleClickOpenRecurring}
                                  size="small"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    borderRadius: "4px",
                                    border: "0",
                                  }}
                                >
                                  Add Account
                                </Button>
                              </Col>
                            </Row>

                            <Dialog
                              open={openRecurringDialog}
                              onClose={handleClose}
                            >
                              <DialogTitle
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                              >
                                Select Account Type
                              </DialogTitle>

                              <div
                                className="pl-lg-3 modalpaddingrent"
                                style={{
                                  height: "190px",
                                  width: "380px",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "4%",
                                    marginRight: "4%",
                                  }}
                                >
                                  <br />
                                  <div
                                    className="formInput"
                                    // style={{ margin: "30px 10px" }}
                                  >
                                    <label
                                      className="form-control-label titleecolor fontstylerentr fontfamilysty"
                                      htmlFor="input-address"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Select Account Type
                                    </label>
                                    <br />
                                    <Dropdown
                                      className="dropdownfontsyle"
                                      isOpen={selectChargeDropDown}
                                      toggle={toggles1}
                                    >
                                      <DropdownToggle
                                        className="dropdownfontsyle titleecolor"
                                        caret
                                        style={{
                                          width: "100%",
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040",
                                          border: "1px solid #ced4da",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        Select
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdownfontsyle"
                                        style={{ width: "100%" }}
                                        name="rent_cycle"
                                        // onBlur={accountFormik.handleBlur}
                                        // onChange={accountFormik.handleChange}
                                        // value={accountFormik.values.charge_type}
                                      >
                                        <DropdownItem
                                          className="dropdownfontsyle"
                                          onClick={() => {
                                            AddNewAccountName(
                                              "Liability Account"
                                            );
                                            handleClose();
                                          }}
                                        >
                                          Liability Account
                                        </DropdownItem>
                                        <DropdownItem
                                          className="dropdownfontsyle"
                                          onClick={() => {
                                            AddNewAccountName(
                                              "Recurring Charge"
                                            );
                                            handleClose();
                                          }}
                                        >
                                          Recurring Charge
                                        </DropdownItem>
                                        <DropdownItem
                                          className="dropdownfontsyle"
                                          onClick={() => {
                                            AddNewAccountName(
                                              "One Time Charge"
                                            );
                                            handleClose();
                                          }}
                                        >
                                          One Time Charge
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                  <div className="d-flex justify-content-end align-items-end">
                                    <Button
                                      className="fontstylerentr mt-4 background-colorsty bgtextwhite fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleClose}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Dialog>
                            <AccountDialog
                              addBankAccountDialogOpen={
                                addBankAccountDialogOpen
                              }
                              setAddBankAccountDialogOpen={
                                setAddBankAccountDialogOpen
                              }
                              accountTypeName={accountTypeName}
                              adminId={accessType?.admin_id}
                              fetchAccounts={getaccounts}
                            />

                            <Table
                              className="align-items-center table-flush mt-3"
                              responsive
                              style={{ borderCollapse: "collapse" }}
                            >
                              <thead
                                className=""
                                style={{
                                  height: "45px",
                                  fontSize: "14px",
                                  fontFamily: "poppins",
                                  fontWeight: "600",
                                  boxShadow: " 0px 4px 4px 0px #00000040",
                                }}
                              >
                                <tr
                                  style={{
                                    border: "2px solid rgba(50, 69, 103, 1)",
                                  }}
                                >
                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                    style={{
                                      borderTopLeftRadius: "15px",
                                    }}
                                  >
                                    Account
                                  </th>

                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                    style={{
                                      borderTopLeftRadius: "15px",
                                    }}
                                  >
                                    Type
                                  </th>

                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                  >
                                    Charge Type
                                  </th>

                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                  >
                                    Fund Type
                                  </th>
                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                    style={{
                                      borderTopRightRadius: "15px",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              {paginatedData.length === 0 ? (
                                <tbody>
                                  <tr className="text-center">
                                    <td
                                      colSpan="8"
                                      style={{ fontSize: "15px" }}
                                    >
                                      No Accounts Added
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr
                                    style={{
                                      border: "none",
                                    }}
                                  >
                                    {/* Empty row */}
                                    <td colSpan="9"></td>
                                  </tr>
                                  {paginatedData.map((account) => (
                                    <>
                                      <tr
                                        key={account.account_id}
                                        style={{
                                          cursor: "pointer",
                                          border:
                                            "0.5px solid rgba(50, 69, 103, 1)",
                                          fontSize: "12px",
                                          height: "40px",
                                          fontFamily: "poppins",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <td className="bordertopintd tablebodyfont tabletextcolor">
                                          {account.account}
                                        </td>
                                        <td className="bordertopintd tablebodyfont tabletextcolor">
                                          {account.account_type}
                                        </td>
                                        <td className="bordertopintd tablebodyfont tabletextcolor">
                                          {account.charge_type}
                                        </td>
                                        <td className="bordertopintd tablebodyfont tabletextcolor">
                                          {account.fund_type}
                                        </td>

                                        <td className="bordertopintd">
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                            }}
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                deleteaccount(account);
                                              }}
                                            >
                                              <img
                                                src={deleicon}
                                                width={20}
                                                height={20}
                                              />
                                            </div>
                                            {/* <div
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openEditDialog(account);
                                              }}
                                            >
                                              <img
                                                src={editicon}
                                                width={20}
                                                height={20}
                                              />
                                            </div> */}
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              )}
                            </Table>
                            {/* <EditAccountDialog
                              editAccountDialogOpen={editAccountDialogOpen}
                              setEditAccountDialogOpen={
                                setEditAccountDialogOpen
                              }
                              accountData={selectedAccount}
                              adminId={admin}
                              fetchAccounts={getaccounts}
                            /> */}
                            {paginatedData.length > 0 ? (
                              <Row>
                                <Col className="text-right mt-3 mb-3">
                                  <Dropdown
                                    isOpen={leasedropdownOpen}
                                    toggle={toggle2}
                                  >
                                    <DropdownToggle
                                      className="paginationbtnstyle"
                                      caret
                                    >
                                      {pageItem}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(5);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        5
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(25);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        25
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(50);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        50
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(100);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        100
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                  <Button
                                    className="p-0"
                                    style={{ backgroundColor: "#d0d0d0" }}
                                    onClick={() =>
                                      handlePageChange(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-caret-left"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                                    </svg>
                                  </Button>
                                  <span>
                                    Page {currentPage} of {totalPages}
                                  </span>{" "}
                                  <Button
                                    className="p-0"
                                    style={{ backgroundColor: "#d0d0d0" }}
                                    onClick={() =>
                                      handlePageChange(currentPage + 1)
                                    }
                                    disabled={currentPage === totalPages}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-caret-right"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                    </svg>
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                        {/* {selectedOption === "nmi-account" && (
                            <div>
                              <h1>NMI Account Set Up</h1>
                              <Form onSubmit={nmikeyFormik.handleSubmit}>
                                <div className="pl-lg-4 mt-5">
                                  <Row>
                                    <span>
                                      You can set your NMI account security key
                                      from here,
                                    </span>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col lg="9">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-unitadd"
                                        >
                                          Security Key
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-unitadd"
                                          placeholder="Enter Security Key"
                                          type="text"
                                          name="security_key"
                                          onBlur={nmikeyFormik.handleBlur}
                                          onChange={nmikeyFormik.handleChange}
                                          value={nmikeyFormik.values.security_key}
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col lg="9">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-unitadd"
                                        >
                                          Test NMI Account
                                        </label>
                                        <p>
                                          To test whether the account system has
                                          been successfully linked to the NMI
                                          account or not, please click on the
                                          button that will initiate a $1.00
                                          transaction to this security key's
                                          account.
                                        </p>
                                        <Row className="mt-4">
                                    <Col lg="4">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-unitadd"
                                        >
                                          Card Number
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-unitadd"
                                          placeholder="Card Number"
                                          type="number"
                                          name="cc_number"
                                          onBlur={nmikeyFormik.handleBlur}
                                          onChange={nmikeyFormik.handleChange}
                                          value={nmikeyFormik.values.cc_number}
                                          
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-unitadd"
                                        >
                                          Expiration Date
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-unitadd"
                                          placeholder="Expiry Date"
                                          type="text"
                                          name="cc_exp"
                                          onBlur={nmikeyFormik.handleBlur}
                                          onChange={nmikeyFormik.handleChange}
                                          value={nmikeyFormik.values.cc_exp}                                        
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="mt-4">
                                  <Col lg="4">
                                  {testLoader ? (
                                    <Button
                                      type="submit"
                                      color="warning"
                                      className="btn btn-primary ml-4"
                                      style={{
                                        color: "white",
                                      }}
                                      disabled
                                    >
                                      Loading...
                                    </Button>
                                  ) : (
                                  <Button
                                          color="warning"
                                          // className="bg-warning text-white"
                                          type="submit"
                                          onClick={(e) => {
                                            handleTestKeyClick(nmikeyFormik.values);
                                            e.preventDefault();
                                          }}
                                        >
                                          Test Transaction
                                        </Button>
                                  )}
                                    </Col>
                                    <Col lg="6">
                                      <label>{responseText}</label>
                                    </Col>
                                  </Row>
                                     
                                      </FormGroup>
                                    </Col>
                                  </Row>
  
                                  <br />
                                </div>
                                <Row>
                                  {submitLoader ? (
                                    <Button
                                      type="submit"
                                      className="btn btn-primary ml-4"
                                      style={{
                                        background: "green",
                                        color: "white",
                                      }}
                                      disabled
                                    >
                                      Loading...
                                    </Button>
                                  ) : (
                                    <Button
                                      type="submit"
                                      className="btn btn-primary ml-5"
                                      style={{
                                        background: "green",
                                        color: "white",
                                      }}
                                    >
                                      {key_id ? "Update" : "Save"}
                                    </Button>
                                  )}
                                  <Button
                                    color="primary"
                                    className="btn btn-primary ml-4"
                                    onClick={nmikeyFormik.resetForm}
                                    style={{
                                      background: "white",
                                      color: "black",
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </Row>
                              </Form>
                            </div>
                          )} */}
                      </CardBody>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default StaffSetting;
