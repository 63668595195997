export const addTokenizationScript = (tokenizationKey) => {
  if (!tokenizationKey) {
    return;
  }

  // Check if the script is already loaded
  const existingScript = document.querySelector(`script[src="https://hms.transactiongateway.com/token/Collect.js"]`);
  
  if (existingScript) {
    console.log("Collect.js script is already loaded.");
    return Promise.resolve(); // Resolve immediately if script already exists
  }
  
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://hms.transactiongateway.com/token/Collect.js";
    script.dataset.tokenizationKey = tokenizationKey;
    script.dataset.theme = "material";

    script.onload = () => {
      console.log("Collect.js script loaded.");
      resolve();
    };

    script.onerror = () => {
      reject(new Error("Failed to load the tokenization script"));
    };

    document.body.appendChild(script);
  });
};

export const currencyFormatter = (amount) => {
  if (!amount) return 0.0;
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
