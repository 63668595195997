import {
    CardHeader,
    Table,
    Container,
    FormGroup,
    Row,
    Button,
    Input,
    Col,
    DropdownToggle,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    // UncontrolledTooltip,
  } from "reactstrap";
  // core components
  import TenantsHeader from "components/Headers/TenantsHeader";
  import * as React from "react";
  import { useCookies } from 'react-cookie';
  import { useState } from "react";
  import { useNavigate,useLocation, useParams } from "react-router-dom";
  import axios from "axios";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import { RotatingLines } from "react-loader-spinner";
  import { useEffect } from "react";
  import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
  import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
  import axiosInstance from "axiosInstance";
  import {
    getUserPermission,
  } from "../../views/source/Functions/Userrolefunction";
  import swal from "sweetalert";
import { verifyToken } from "components/Functions/Functions";
  
  
  const DocLeases = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
  
    // useEffect(() => {
    //   if (status) {
    //     setSearchQuery2(status);
    //   }
    // }, [status]);
    useEffect(() => {
      if (status === "Over Due") {
        setSearchQuery2("Over Due");
      }
    }, [status]);
  
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let navigate = useNavigate();
    const [workData, setWorkData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    let [loader, setLoader] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [pageItem, setPageItem] = React.useState(10);
    const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
    const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
    const [searchQuery2, setSearchQuery2] = useState("");
    const [search, setSearch] = React.useState(false);
    const toggle3 = () => setSearch((prevState) => !prevState);
  
    const [upArrow, setUpArrow] = useState([]);
    const [sortBy, setSortBy] = useState([]);
  
    const [accessType, setAccessType] = useState(null);
  
    // const [cookies] = useCookies(['token']);
    const [cookie, setCookie, removeCookie] = useCookies();
    const [userroleData, setUserroleData] = useState(null);
    const location = useLocation();


    // useEffect(() => {
    //   const checkToken = async () => {
    //     try {
    //       const response = await axiosInstance.post(`/tenant/token_check`, {
    //         token: cookies.token
    //       });
    //       setAccessType(response.data);
  
    //       if ("Admin Not Found" === response.data.message) {
    //         navigate("/auth/login");
    //       }
    //     } catch (error) {
    //       console.error("Error checking token:", error);
    //       // Handle error here, maybe redirect to login page or show an error message
    //     }
    //   };
  
    //   if (cookies.token) {
    //     checkToken();
    //   } else {
    //     navigate("/auth/login");
    //   }
    // }, [cookies.token, navigate]);
    useEffect(() => {
      const fetchData = async () => {
        const data = await verifyToken(navigate, location, removeCookie);
        setAccessType(data);
      };
      fetchData();
    }, []);
   
  
    const getRentalData = async () => {
      if (accessType?.tenant_id) {
        try {
          const response = await axiosInstance.get(
            `/tenant/tenantleases/${accessType?.tenant_id}`
          );
          setWorkData(response.data.data);
          setTotalPages(Math.ceil(response.data?.data?.length / pageItem));
        } catch (error) {
          console.error("Error fetching work order data:", error);
        }
        finally {
          setLoader(false);
        }
      }
    };
    

    useEffect(() => {
      getRentalData();
    }, [accessType, pageItem, upArrow, sortBy]);
  
    const navigateToDetails = (tenantId) => {
      navigate(`/tenant/tenant/get/${tenantId}`);
    };
    
    const startIndex = (currentPage - 1) * pageItem;
    const endIndex = currentPage * pageItem;
    var paginatedData;
    if (workData) {
      paginatedData = workData.slice(startIndex, endIndex);
    }
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    const filterRentRollsBySearch = () => {
      let filteredData = workData;
  
      if (searchQuery) {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        filteredData = filteredData.filter((tenant) => {
          const name = `${tenant.tenant_firstName} ${tenant.tenant_lastName}`;
          return (
            tenant?.rental_adress?.toLowerCase().includes(lowerCaseSearchQuery) ||
            tenant?.start_date?.toLowerCase().includes(lowerCaseSearchQuery) ||
            tenant?.end_date?.toLowerCase().includes(lowerCaseSearchQuery) ||
            // tenant?.rent_cycle?.toLowerCase().includes(lowerCaseSearchQuery) ||
            name?.toLowerCase()?.includes(lowerCaseSearchQuery)
          );
        });
      }
  
      if (upArrow.length > 0) {
        const sortingArrows = upArrow.length > 0 ? upArrow : null;
        sortingArrows.forEach((value) => {
          switch (value) {
            case "rental_adress":
              filteredData.sort((a, b) => {
                const comparison = a.rental_adress?.localeCompare(b.rental_adress);
                return upArrow.includes("rental_adress")
                  ? comparison
                  : -comparison;
              });
              break;
            case "lease_type":
              filteredData.sort((a, b) =>
                a.lease_type?.localeCompare(b.lease_type)
              );
              break;
            case "tenant_firstName":
              filteredData.sort((a, b) => {
                const firstNameA = a.tenantNames?.split(", ")[0].split(" ")[0];
                const firstNameB = b.tenantNames?.split(", ")[0].split(" ")[0];
                return firstNameA?.localeCompare(firstNameB);
              });
              break;
            case "start_date":
              filteredData.sort(
                (a, b) => new Date(a.start_date) - new Date(b.start_date)
              );
              break;
            case "end_date":
              filteredData.sort(
                (a, b) => new Date(a.end_date) - new Date(b.end_date)
              );
              break;
            case "rent_cycle":
              filteredData.sort((a, b) =>
                a.rent_cycle?.localeCompare(b.rent_cycle)
              );
              break;
            case "rent_duedate":
              filteredData.sort((a, b) =>
                a.rent_duedate?.localeCompare(b.rent_duedate)
              );
              break;
            case "amount":
              filteredData.sort((a, b) => a.amount - b.amount);
              break;
            case "deposite":
              filteredData.sort(
                (a, b) => new Date(a.deposite) - new Date(b.deposite)
              );
              break;
            case "recurringCharge":
              filteredData.sort(
                (a, b) =>
                  new Date(a.recurringCharge) - new Date(b.recurringCharge)
              );
              break;
            case "createdAt":
              filteredData.sort((a, b) => {
                const comparison = new Date(a.createdAt) - new Date(b.createdAt);
                return upArrow.includes("createdAt") ? comparison : -comparison;
              });
              break;
            default:
              // filteredData.slice(startIndex, endIndex);
              break;
          }
        });
      }
  
      return filteredData;
    };
  
    const filterTenantsBySearchAndPage = () => {
      const filteredData = filterRentRollsBySearch();
      const paginatedData = filteredData.slice(startIndex, endIndex);
      return paginatedData;
    };
  
    const sortData = (value) => {
      if (!sortBy.includes(value)) {
        setSortBy([...sortBy, value]);
        setUpArrow([...upArrow, value]);
        filterTenantsBySearchAndPage();
      } else {
        setSortBy(sortBy.filter((sort) => sort !== value));
        setUpArrow(upArrow.filter((sort) => sort !== value));
        filterTenantsBySearchAndPage();
      }
    };

  

    useEffect(() => {
      const handleGetUserPermission = async () => {
        if (accessType?.admin_id) {
          const adminData = await getUserPermission(accessType.admin_id);
          setUserroleData(adminData?.tenant_permission);
        }
      };
      if (accessType) {
        handleGetUserPermission();
      }
    }, [accessType]);

    return (
      <>
        <TenantsHeader />
        {/* Page content */}
        <Container className="stylecontainer" fluid style={{ marginTop: "4rem", }}>
          {/* Table */}
    
          <CardHeader
            className=" mt-3 mb-3 headproptypebar background-colorsty "
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="mb-0 headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Leases
            </h2>
          </CardHeader>
  
          <Row>
            <div className="col">
              {/* <Card className="shadow"> */}
              <CardHeader className="border-0 p-0 mt-3 mb-3">
                <Row className="d-flex mx-2">
                  {/* <Col xs="12" sm="6"> */}
                  <FormGroup className="mr-sm-2 searchspace searchstyle">
                    <Input
                      className="filterh"
                      fullWidth
                      type="text"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setSearchQuery2("");
                      }}
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        minWidth: "200px",
                        border: "1px solid #ced4da",
  
                      }}
                    />
                  </FormGroup>
                 
                  {/* </Col> */}
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive style={{ borderCollapse: "collapse" }}>
                {loader ? (
                  <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="50"
                      visible={loader}
                    />
                  </div>
                ) :  (
                  <>
                    <thead className="fontfamilysty" style={{
                      height: "45px",
                      fontSize: "14px",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}>
                      <tr style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}>
                        <th className="tablefontstyle textcolorblue" scope="col" style={{
                          borderTopLeftRadius: "15px",
                        }}>
                          Lease
                          {sortBy.includes("rental_adress") ? (
                            upArrow.includes("rental_adress") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("rental_adress")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("rental_adress")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          )}
                        </th>
                        <th className="tablefontstyle textcolorblue " scope="col">
  
                          Lease Start
                          {sortBy.includes("start_date") ? (
                            upArrow.includes("start_date") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("start_date")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("start_date")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("start_date")}
                            />
                          )}
                        </th>
                        <th className="tablefontstyle textcolorblue" scope="col">
  
                          Lease End
                          {sortBy.includes("end_date") ? (
                            upArrow.includes("end_date") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("end_date")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("end_date")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("end_date")}
                            />
                          )}
                        </th>
                        <th className="tablefontstyle textcolorblue" scope="col">
                          Rent Cycle</th>
                        <th className="tablefontstyle textcolorblue" scope="col">
                         Rent start</th>
                        <th className="tablefontstyle textcolorblue" scope="col" style={{ borderTopRightRadius: "15px", }}>
                          Rent</th>
                        <th className="tablefontstyle textcolorblue" scope="col" style={{ borderTopRightRadius: "15px", }}>
                          Deposits Held</th>
                        <th className="tablefontstyle textcolorblue" scope="col" style={{ borderTopRightRadius: "15px", }}>
                          Charges</th>
                        <th className="tablefontstyle textcolorblue" scope="col" style={{ borderTopRightRadius: "15px", }}>
                          Created At</th>
                        <th className="tablefontstyle textcolorblue" scope="col" style={{ borderTopRightRadius: "15px", }}>
                          Last Updated</th>
                         
                      </tr>
                    </thead>
  
                    <tbody>
                      <tr style={{
                        border: "none",
                      }}>
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage()?.length > 0 ? (
                       filterTenantsBySearchAndPage()?.map((rental) => (
                        <>
                        <tr
                          key={rental?.workOrder_id}
                        //   onClick={() =>
                        //     navigateToDetails(rental?.tenant_id)
                        //   }
                          style={{
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental.rental_adress}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental?.start_date}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental?.end_date}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental?.rent_cycle}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental?.rent_duedate}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental?.lease_amount}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental?.deposite}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental?.recurringCharge}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental?.createdAt}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{rental.updatedAt ? rental.updatedAt : "-"}</td>
                         
                        </tr>
                        </>
                      ))
                    ) : (
                      <tr style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}>
                        <td colSpan={7} className="text-center bordertopintd tablebodyfont">
                          No data available.
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </>
                )}
              </Table>
              {workData?.length > 0 ? (
                <Row>
                  <Col className="text-right mt-3 mb-3">
                    <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                      <DropdownToggle className="paginationbtnstyle" caret>{pageItem}</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setPageItem(10);
                            setCurrentPage(1);
                          }}
                        >
                          10
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setPageItem(25);
                            setCurrentPage(1);
                          }}
                        >
                          25
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setPageItem(50);
                            setCurrentPage(1);
                          }}
                        >
                          50
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setPageItem(100);
                            setCurrentPage(1);
                          }}
                        >
                          100
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Button
                      className="p-0"
                      style={{ backgroundColor: "#d0d0d0" }}
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-caret-left"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                      </svg>
                    </Button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>{" "}
                    <Button
                      className="p-0"
                      style={{ backgroundColor: "#d0d0d0" }}
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-caret-right"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                      </svg>
                    </Button>{" "}
                  </Col>
                </Row>
              ) : null}
  
              {/* </Card> */}
            </div>
          </Row>
  
  
          <br />
          <br />
          <ToastContainer />
        </Container>
      </>
    );
  };
  
  export default DocLeases;
  
  