import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export const checktenantToken = async (token, navigate) => {
  try {
    const response = await axiosInstance.post(`/tenant/token_check`, {
      token: token,
    });

    if ("Tenant Not Found" === response.data.message) {
      navigate("/auth/login");
      return null;
    }

    return response.data;
  } catch (error) {
    console.error("Error checking token:", error);
    // Handle error here, maybe redirect to login page or show an error message
    navigate("/auth/login");
    return null;
  }
};

export const checkstafftoken = async (token, navigate) => {
  try {
    const response = await axiosInstance.post(`/staffmember/token_check`, {
      token: token,
    });

    if ("Staffmember Not Found" === response.data.message) {
      navigate("/auth/login");
      return null;
    }

    return response.data;
  } catch (error) {
    console.error("Error checking token:", error);
    // Handle error here, maybe redirect to login page or show an error message
    navigate("/auth/login");
    return null;
  }
};
export const checkvendortoken = async (token, navigate) => {
  try {
    const response = await axiosInstance.post(`/vendor/token_check`, {
      token: token,
    });

    if ("Vendor Not Found" === response.data.message) {
      navigate("/auth/login");
      return null;
    }

    return response.data;
  } catch (error) {
    console.error("Error checking token:", error);
    // Handle error here, maybe redirect to login page or show an error message
    navigate("/auth/login");
    return null;
  }
};
export const getUserPermission = async (adminId) => {
  try {
    const response = await axiosInstance.get(
      `/permission/permission/${adminId}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching user permissions:", error);
    return null;
  }
};
