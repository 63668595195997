import React, { useEffect, useRef, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import routes from "routes.js";
import TenantSidebar from "components/Sidebar/TenantSidebar";
import TenantNavbar from "components/Navbars/TenantNavbar";
import { useMediaQuery } from "@mui/material";

const Tenant = (props) => {
  const mainContent = useRef(null);
  const location = useLocation();
  const isMediumScreen = useMediaQuery("(max-width:991px)");

  const [isCollapse, setIsCollapse] = useState(false);
  const toggleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/tenant") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const [sideOpen, setSideOpen] = useState(false);
  const toggleOpenSidebar = () => {
    setSideOpen(!sideOpen);
  };

  return (
    <div style={{ backgroundColor: "#fff", height: "100%" }}>
      <TenantSidebar
        routes={routes}
        logo={{
          innerLink: "/tenant/tenantdashboard",
          imgSrc: require("../assets/icons/websitelogo.svg"),
          imgSrc2: require("../assets/icons/site-logo-1 7.png"),
          imgAlt: "...",
        }}
        isCollapse={isCollapse}
        setIsCollapse={setIsCollapse}
        toggleCollapse={toggleCollapse}
        sideOpen={sideOpen}
        setSideOpen={setSideOpen}
      />
      <div
        className={
          isMediumScreen
            ? "collapse-content"
            : !isCollapse
            ? `content`
            : `content-active`
        }
        ref={mainContent}
        style={{ backgroundColor: sideOpen && "rgba(21, 43, 81, 0.16)" }}
      >
        <TenantNavbar
          {...props}
          brandText={getBrandText(props?.location?.pathname)}
          logo={require("../assets/icons/site-logo-1 7.png")}
          toggleOpenSidebar={toggleOpenSidebar}
        />
        <Routes>
          {getRoutes(routes)}
          <Route
            path="*"
            element={<Navigate to={`/tenant/tenantdashboard`} replace />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Tenant;
