import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CardContent, Typography, IconButton } from "@mui/material";
import { Card } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "axiosInstance";
import swal from "sweetalert";
import AddCardForm from "../../components/Payments/AddCardForm";
import { getCardType } from "plugins/ApiHandler";
import { getCardLogo } from "plugins/ApiHandler";
import Dropdown from "components/ReusableComponents/Dropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import CardTemplate from "components/Payments/CardTemplate";
import interceptor from "plugins/axios";
import Loader from "components/ReusableComponents/Loader";
import { addCardDetails } from "plugins/ApiHandler";

function CreditCardForm(props) {
  const { closeModal, admin_id } = props;
  const { lease_id } = useParams();
  const [tenantId, setTenantId] = useState("");
  const [customervault, setCustomervault] = useState([]);
  const [cardDetalis, setCardDetails] = useState([]);
  const [vaultId, setVaultId] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [getCardLoading, setGetCardLoading] = useState(false);
  const [getCardError, setGetCardError] = useState("");
  const [selectedRec, setSelectedRec] = useState("Select Resident");

  const [preFillData, setPreFillData] = useState({
    address1: props.rentalAddress,
  });

  const handleRecieverSelection = (tenant) => {
    setPreFillData({
      first_name: tenant.tenant_firstName,
      last_name: tenant.tenant_lastName,
      email: tenant.tenant_email,
      phone: tenant.tenant_phoneNumber,
      city: "",
      country: "",
      state: "",
      zip: "",
    });
    setSelectedRec(`${tenant.tenant_firstName} ${tenant.tenant_lastName}`);
    setTenantId(tenant.tenant_id);
  };

  const fetchTenant = async () => {
    if (lease_id) {
      try {
        const res = await axiosInstance.get(`/leases/lease_tenant/${lease_id}`);
        if (res.data.statusCode === 200) {
          setTenantData(res.data.data.tenants);
        }
      } catch (error) {}
    } else {
    }
  };

  useEffect(() => {
    fetchTenant();
  }, [lease_id]);

  const addCardInfo = async (data) => {
    data.tenant_id = tenantId;
    data.admin_id = admin_id;

    try {
      await addCardDetails(data);
      toast.success("Card details added successfully");
      setTimeout(() => {
        closeModal();
      }, 2000);
    } catch (error) {
      toast.error(error);
    }
  };

  const getCreditCard = async () => {
    if (tenantId) {
      setGetCardLoading(true);
      setGetCardError("");

      try {
        const response = await axiosInstance.get(
          `/creditcard/getCreditCards/${tenantId}`
        );
        if (response.status === 200) {
          setCustomervault(response.data);
          setVaultId(response.data.customer_vault_id);
          await getMultipleCustomerVault(response.data.customer_vault_id);
        }
      } catch (error) {
        setGetCardError(error.message);
      } finally {
        setGetCardLoading(false);
      }
    }
  };

  const getMultipleCustomerVault = async (customerVaultIds) => {
    try {
      setCardDetails([]);

      const response = await interceptor.post(
        `/nmipayment/get-billing-customer-vault`,
        {
          customer_vault_id: customerVaultIds,
          admin_id: admin_id,
        }
      );

      // Check if customer.billing is an array
      let billingArray = [];
      const billingData = response.data?.data?.customer;
      if (!billingData) return;

      if (Array.isArray(billingData?.billing))
        billingArray = billingData?.billing;
      else billingArray.push(billingData?.billing);

      const extractedData = billingArray?.map((item) => ({
        billing_id: item["@attributes"].id,
        cardholderName: `${item.first_name} ${item.last_name}`,
        first_name: item.first_name,
        last_name: item.last_name,
        cc_number: item.cc_number,
        cc_exp: item.cc_exp,
        cc_type: item.cc_type,
        cc_bin: item.cc_bin,
        customer_vault_id: item.customer_vault_id,
      }));

      for (const card of extractedData) {
        try {
          const cardType = await getCardType(card.cc_bin);
          card.card_type = cardType;
          const cardLogo = await getCardLogo(card.cc_type);
          card.card_logo = cardLogo;
        } catch (error) {
          throw error;
        }
      }
      setCardDetails(extractedData);
      return;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getCreditCard();
  }, [admin_id, tenantId]);

  useEffect(() => {
    // Extract customer_vault_id values from cardDetails
    const customerVaultIds =
      customervault?.card_detail?.map((card) => card.billing_id) || [];

    if (customerVaultIds?.length > 0) {
      // Call the API to get multiple customer vault records
      getMultipleCustomerVault(customerVaultIds);
    }
  }, [customervault]);

  // const handleDeleteCard = async (customerVaultId, billingId) => {
  //   try {
  //     let deleteRecordResponse, deleteNMIResponse;

  //     if (customervault.card_detail.length > 1) {
  //       // If billingId is provided, call the new APIs
  //       [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
  //         axiosInstance.delete(`/creditcard/deleteCreditCard/${billingId}`),
  //         axiosInstance.post(`/nmipayment/delete-customer-billing`, {
  //           customer_vault_id: customerVaultId,
  //           billing_id: billingId,
  //           admin_id: admin_id,
  //           tenant_id: selectedRec,
  //         }),
  //       ]);
  //     } else {
  //       // If billingId is not provided, call the existing API
  //       [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
  //         axiosInstance.delete(
  //           `/creditcard/deleteCardVault/${customerVaultId}`
  //         ),
  //         axiosInstance.post(`/nmipayment/delete-customer-vault`, {
  //           customer_vault_id: customerVaultId,
  //           admin_id: admin_id,
  //           tenant_id: selectedRec,
  //         }),
  //       ]);
  //     }

  //     // Check the responses
  //     if (
  //       deleteRecordResponse.status === 200 &&
  //       deleteNMIResponse.status === 200
  //     ) {
  //       toast.success("Card deleted successfully", {
  //         position: "top-center",
  //         autoClose: 500,
  //       });

  //       setTimeout(() => {
  //         closeModal();
  //       }, 2000);
  //     } else {
  //       toast.error("Card deletion failed", {
  //         position: "top-center",
  //         autoClose: 500,
  //       });
  //       console.error(
  //         "Delete card failed:",
  //         deleteRecordResponse.statusText,
  //         deleteNMIResponse.statusText
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error deleting card:", error.message);
  //     toast.error("Error deleting card", {
  //       position: "top-center",
  //       autoClose: 500,
  //     });
  //   }
  // };

  const handleDeleteCard = async (customerVaultId, billingId) => {
    try {
      // Show SweetAlert confirmation before proceeding
      const willDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this card!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      // If the user confirms, proceed with the deletion
      if (willDelete) {
        let deleteRecordResponse, deleteNMIResponse;

        if (customervault.card_detail.length > 1) {
          // If billingId is provided, call the new APIs
          [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
            axiosInstance.delete(`/creditcard/deleteCreditCard/${billingId}`, {
              data: { tenant_id: tenantId },
            }),
            axiosInstance.post(`/nmipayment/delete-customer-billing`, {
              customer_vault_id: customerVaultId,
              billing_id: billingId,
              admin_id: admin_id,
              tenant_id: selectedRec,
            }),
          ]);
        } else {
          // If billingId is not provided, call the existing API
          [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
            axiosInstance.delete(
              `/creditcard/deleteCardVault/${customerVaultId}`,
              { data: { tenant_id: tenantId } }
            ),
            axiosInstance.post(`/nmipayment/delete-customer-vault`, {
              customer_vault_id: customerVaultId,
              admin_id: admin_id,
              tenant_id: selectedRec,
            }),
          ]);
        }

        // Check the responses
        if (
          deleteRecordResponse.status === 200 &&
          deleteNMIResponse.status === 200
        ) {
          toast.success("Card deleted successfully", {
            position: "top-center",
            autoClose: 500,
          });

          setTimeout(() => {
            closeModal();
          }, 2000);
        } else {
          toast.error("Card deletion failed", {
            position: "top-center",
            autoClose: 500,
          });
          console.error(
            "Delete card failed:",
            deleteRecordResponse.statusText,
            deleteNMIResponse.statusText
          );
        }
      } else {
        // User canceled the action
        toast.success("Card deletion canceled", {
          position: "top-center",
          autoClose: 500,
        });
      }
    } catch (error) {
      console.error("Error deleting card:", error.message);
      toast.error("Error deleting card", {
        position: "top-center",
        autoClose: 500,
      });
    }
  };

  return (
    <div style={{ maxHeight: "600px", overflowY: "auto", overflowX: "hidden" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        {/* Formik Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            maxWidth: "500px",
          }}
        >
          <Dropdown
            style={{ width: "100%" }}
            options={tenantData}
            onSelect={(property) => handleRecieverSelection(property)}
            labelKey="tenant_firstName"
            showStar
            label="Select a tenant"
          ></Dropdown>
          <AddCardForm
            addCardInfo={addCardInfo}
            preFillData={preFillData}
            onHide={closeModal}
          />
        </div>
        {/* Card Details Section */}
        <Card
          className="mt-1 creditcardrent"
          style={{
            background: "#fff",
            width: "400px",
            borderRadius: "6px",
            boxShadow: " 0px 2px 2px 0px #00000040",
          }}
        >
          <CardContent>
            <h4>Cards</h4>

            {/* Card Details */}
            {tenantId.length === 0 ? (
              <p style={{ padding: "20px", textAlign: "center" }}>
                Please Select a Tenant
              </p>
            ) : getCardLoading ? (
              <Loader />
            ) : getCardError ? (
              <p style={{ padding: "20px", textAlign: "center", color: "red" }}>
                {getCardError}
              </p>
            ) : (
              <>
                {cardDetalis && cardDetalis.length > 0 ? (
                  <>
                    {cardDetalis.map((card, index) => (
                      <div
                        className="row d-flex align-items-center justify-content-around"
                        key={index}
                      >
                        <CardTemplate card={card} />
                        <IconButton
                          color="error"
                          aria-label="add to shopping cart"
                          onClick={() =>
                            handleDeleteCard(
                              card.customer_vault_id,
                              card.billing_id
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                  </>
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    No cards added.
                  </Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreditCardForm;
