import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StaffHeader from "components/Headers/StaffHeader";
import {
  Card,
  Container,
  CardHeader,
  Row,
  Col,
} from "reactstrap"; // Import other necessary components from 'reactstrap'
import { useCookies } from 'react-cookie';
import "./Staffdashboard.css";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import axiosInstance from "axiosInstance";


const StaffProfile = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [staffDetails, setStaffDetails] = useState({});
  let [loader, setLoader] = React.useState(true);

  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const [accessType, setAccessType] = useState(null);

  const [cookies] = useCookies(['token']);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/staffmember/token_check`, {
          token: cookies.token
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  const getStaffData = async () => {
    if (accessType?.staffmember_id) {
      try {
        if (accessType?.staffmember_id) {
          const response = await axiosInstance.get(
            `/staffmember/staffmember_profile/${accessType?.staffmember_id}`
          );
          setStaffDetails(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching staff details:", error.message);
        setError(error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getStaffData();
  }, [accessType]);

  return (
    <>
      {/* <StaffHeader /> */}
      {/* Page content */}
      <Container className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem", }}
      >
        <CardHeader
          className=" mt-3 mb-3 mx-3 headproptypebar"
          style={{
            backgroundColor: "#152B51",
            borderRadius: "10px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
          }}
        >
          <h2
            className="mb-0 headproptypee"
            style={{
              color: "#ffffff",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Personal Details
          </h2>
        </CardHeader>
        <div>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>

              <div className="my-table mx-3" style={{ overflowX: "auto" }}>
                <table className="data" style={{ width: "100%" }}>
                  <tr>
                    <th className="tablefontstyle staffsheadfont">Name</th>
                    <th className="tablefontstyle staffsheadfont">Designation</th>
                    <th className="tablefontstyle staffsheadfont">Phone Number</th>
                    <th className="tablefontstyle staffsheadfont">Email</th>
                  </tr>
                  <tr>
                    <td className="bordertopintd tablebodyfont staffsbodyfont">{staffDetails?.staffmember_name}
                    </td>
                    <td className="bordertopintd tablebodyfont staffsbodyfont">{staffDetails?.staffmember_designation}
                    </td>
                    <td className="bordertopintd tablebodyfont staffsbodyfont">{staffDetails?.staffmember_phoneNumber}</td>
                    <td className="bordertopintd tablebodyfont staffsbodyfont">{staffDetails?.staffmember_email}</td>
                  </tr>

                </table>
              </div>
            </>
          )}
        </div>
        {/* <Table
                className="align-items-center table-flush"
                responsive
                style={{ width: "100%" }}
              >
                {loading ? (
                  <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="50"
                      visible={loading}
                    />
                  </div>
                ) : (
                  <>
                    <tbody
                      className="tbbody p-0 m-0"
                      style={{
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        borderBottomRightRadius: "5px",
                      }}
                    >
                      <tr className="header">
                        <th>First name</th>
                        <th>Designation</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                      </tr>
                      <>
                        <>
                          {!staffDetails ? (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="8" style={{ fontSize: "15px" }}>
                                  No Data Added
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tr className="body">
                              <td>{staffDetails?.staffmember_name}</td>
                              <td>{staffDetails?.staffmember_designation}</td>
                              <td>{staffDetails?.staffmember_phoneNumber}</td>
                              <td>{staffDetails?.staffmember_email}</td>
                            </tr>
                          )}
                        </>
                      </>
                    </tbody>
                  </>
                )}
              </Table> */}
        {/* <table className="rwd-table">
                <tbody> */}
        {/* <Row
              className="mx-3 py-0 mt-3"
              style={{
                border: ".5px solid rgba(50, 69, 103, 1)",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                height: "45px",
                alignItems: "center",
                borderBottom:"0px",
                color:"#152B51",
                

              }}
            >
              <Col
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
               Name
              </Col>
              <Col
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Designation
              </Col>
              <Col
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Phone Number
              </Col>
              <Col
                style={{
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Email
              </Col>
            </Row>
            <Row
              className="mx-3 py-0"
              style={{
                border: ".5px solid rgba(50, 69, 103, 1)",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                height: "45px",
                alignItems: "center",
                color:"#152B51",
                boxShadow: "0px 4px 4px 0px #00000040"
              }}
            >
              <Col
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  fontSize:"12px"
                }}
              >
                {staffDetails?.staffmember_name}
              </Col>
              <Col
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  fontSize:"12px"
                }}
              >
                {staffDetails?.staffmember_designation}
              </Col>
              <Col
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  fontSize:"12px"
                }}
              >
                {staffDetails?.staffmember_phoneNumber}
              </Col>
              <Col
                style={{
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  fontSize:"12px"
                }}
              >
                {staffDetails?.staffmember_email}
              </Col>
            </Row>
         
          </div>
              </Row>*/}
      </Container>
    </>
  );
};

export default StaffProfile;
