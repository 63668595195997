import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useFormik } from "formik";
import Checkbox from "@mui/material/Checkbox";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from 'react-cookie';
import axiosInstance from "axiosInstance";

function CreditCardForm(props) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const {  closeModal2, admin_id} = props;
  const { id, admin } = useParams();
  const [isMultiUnit, setIsMultiUnit] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState("");

  const paymentSchema = yup.object({
    card_number: yup
      .number(),
    exp_date: yup
      .string()
      .matches(/^(0[1-9]|1[0-2])\/[0-9]{4}$/, "Invalid date format (MM/YYYY)")
      .required("Required"),
  });

  useEffect(() => {
    if (id) {
      axiosInstance
        .get(`/propertytype/property/type/${id}`)
        .then((response) => {
          const propertyData = response.data.data[0];
          setpropertyType(propertyType);
          setIsMultiUnit(propertyData.is_multiunit);

          setSelectedProperty(propertyData.property_type || "Select");

          propertyFormik.setValues({
            property_type: propertyData?.property_type || "",
            propertysub_type: propertyData?.propertysub_type || "",
            isMultiUnit: propertyData?.is_multiunit,
          });
        })
        .catch((error) => {
          console.error("Error fetching property type data:", error);
        });
    }
  }, [id]);

  const propertyFormik = useFormik({
    initialValues: {
      property_type: "",
      propertysub_type: "",
      isMultiUnit: false,
    },
    validationSchema: yup.object({
      property_type: yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const object = {
        admin_id: admin_id,
        property_type: propertyFormik.values.property_type,
        propertysub_type: propertyFormik.values.propertysub_type,
        is_multiunit: isMultiUnit,
        };
        try { 
        if (id === undefined) {
          const res = await axiosInstance.post(
            `/propertytype/property_type`,
            object
          );
          if (res.data.statusCode === 200) {
            handleResponse(res);
          } else if (res.data.statusCode === 201) {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } else {
          const editUrl = `/propertytype/property_type/${id}`;
          const res = await axiosInstance.put(editUrl, object);
          if (res.data.statusCode === 200) {
            handleResponse(res);
          } else if (res.data.statusCode === 400) {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        }
        closeModal2()
      } catch (error) {
        console.error("Error:", error);
        if (error.response) {
          console.error("Response Data:", error.response.data);
        }
      }
    },
  });
  const handlePropertySelection = (value) => {
    setSelectedProperty(value);
    localStorage.setItem("property", value);
  };
  const toggle = () => setproDropdownOpen((prevState) => !prevState);
  const [prodropdownOpen, setproDropdownOpen] = useState(false);

  const handleChangecheck = (e) => {
    setIsMultiUnit(e.target.checked);
  };

  const [propertyType, setpropertyType] = useState(null);
  
  const navigate = useNavigate();

  async function handleSubmit(values) {
    try {
      const object = {
        admin_id: admin_id,
        property_type: propertyFormik.values.property_type,
        propertysub_type: propertyFormik.values.propertysub_type,
        is_multiunit: isMultiUnit,
      };

      if (id === undefined) {
        const res = await axiosInstance.post(
          `/propertytype/property_type`,
          object
        );
        if (res.data.statusCode === 200) {
          handleResponse(res);
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/propertytype/property_type/${id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          handleResponse(res);
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    }
  }
  
  function handleResponse(response) {
    const successMessage = id
      ? "Property Type updated successfully"
      : "Property Type added successfully";
    const errorMessage = response.data.message;

    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 2000,
        
        // onClose: () => navigate(`/${admin}/PropertyType`),
      });
      setTimeout(() => {
        closeModal2();
      }, 2000);
    } else {
      // Show an error toast
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }
 
  return (
    <div>
      <Formik
        initialValues={{ card_number: "", exp_date: "" }}
        validationSchema={paymentSchema}
        onSubmit={(values, { resetForm }) => {
          if (paymentSchema.isValid) {
            handleSubmit(values);
            resetForm();
          }
        }}
      >
        <Form onSubmit={propertyFormik.handleSubmit}>
          <Row className="mb-3">
            <Col xs="12" sm="12">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label fontstylerentr titleecolor"
                      htmlFor="input-property"
                      style={{ fontFamily: "Poppins", fontSize: "16px", fontweight: "500",  }}
                    >
                      Property Type *
                    </label>
                    <br />
                    <Dropdown className="fontstylerentmodal" isOpen={prodropdownOpen} toggle={toggle}>
                      <DropdownToggle className="fontstylerentmodal titleecolor" caret style={{
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        border: "1px solid #ced4da",
                        maxWidth: "200px",
                        minWidth: "200px",
                        backgroundColor: "transparent",
                      }}>
                        {propertyFormik.values.property_type || "Property Type"}
                      </DropdownToggle >
                      <DropdownMenu className="fontstylerentmodal">
                        <DropdownItem className="fontstylerentmodal"
                          onClick={() =>
                            propertyFormik.handleChange({
                              target: {
                                name: "property_type",
                                value: "Residential",
                              },
                            })
                          }
                        >
                          Residential
                        </DropdownItem>
                        <DropdownItem className="fontstylerentmodal"
                          onClick={() =>
                            propertyFormik.handleChange({
                              target: {
                                name: "property_type",
                                value: "Commercial",
                              },
                            })
                          }
                        >
                          Commercial
                        </DropdownItem>
                      </DropdownMenu>
                      {propertyFormik.touched.property_type &&
                        propertyFormik.errors.property_type ? (
                        <div style={{ color: "red", marginBottom: "10px" }}>
                          {propertyFormik.errors.property_type}
                        </div>
                      ) : null}
                    </Dropdown>
                  </FormGroup>
                </Col>
              </Row>
              <br />

              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label fontstylerentr titleecolor"
                      htmlFor="input-property"
                      style={{ fontFamily: "Poppins", fontSize: "16px", fontweight: "500",  }}
                    >
                      Property Sub Type *
                    </label>
                    <br />
                    <Input
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      className="form-control-alternative fontstylerentmodal propertysubtypeinput titleecolor"
                      id="input-protype"
                      placeholder="Townhome"
                      type="text"
                      name="propertysub_type"
                      onBlur={propertyFormik.handleBlur}
                      onChange={propertyFormik.handleChange}
                      value={propertyFormik.values.propertysub_type.trim()}
                      required
                    />
                    <br></br>
                    <Checkbox
                      onChange={handleChangecheck}
                      checked={isMultiUnit}
                      style={{ marginRight: "10px", color: "#152B51" }}
                    />
                    <label className="form-control-label fontstylerentr titleecolor">Multi unit</label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Button
              type="submit"
              className="btn  ml-4 fontstylerentr"
              style={{ background: "#152B51", color: "white", fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", cursor: "pointer", }}

            >
              {id ? "Update Property Type" : "Add Property Type"}
            </Button>
          </Row>
        </Form>
      </Formik>
      <ToastContainer />
    </div>
  );
}

export default CreditCardForm;
