import { Table, TableBody } from "@mui/material";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CardHeader, Col, Row } from "reactstrap";

const AuditLogs = () => {
  const { admin } = useParams();
  // const [cookies] = useCookies(["token"]);
  const [cookie, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const location = useLocation();

  const [loader, setLoader] = useState(true);
  const [accessType, setAccessType] = useState(undefined);
  const [logsData, setLogsData] = useState([]);

  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/admin/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const data = await axiosInstance.get(
          `/activity/${accessType.admin_id}`
        );
        if (data.data.statusCode === 200) {
          setLogsData(data.data.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
      }
    };
    if (accessType) {
      fetchLogs();
    }
  }, [accessType]);

  return (
    <>
      <Row className="mx-1 mt-5">
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Activity
            </h2>
          </CardHeader>
        </Col>
      </Row>
      <div
        className="m-4 mt-5 text-center"
        responsive
        style={{ borderCollapse: "collapse" }}
      >
        {loader ? (
          <>Loading...</>
        ) : logsData && logsData.length > 0 ? (
          <Table
            className="align-items-center table-flush"
            style={{
              borderTopLeftRadius: "20px",
            }}
          >
            <thead
              style={{
                height: "45px",
                fontSize: "14px",
                fontFamily: "poppins",
                fontWeight: "600",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderTopLeftRadius: "20px",
              }}
            >
              <tr
                style={{
                  border: "1px solid rgba(50, 69, 103, 1)",
                  borderTopLeftRadius: "20px",
                }}
              >
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{
                    color: "#152B51",
                    width: "20%",
                    borderTopLeftRadius: "15px",
                  }}
                >
                  Activity By
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{ color: "#152B51", width: "15%" }}
                >
                  ACTION
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{ color: "#152B51", width: "50%" }}
                >
                  Details
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{
                    borderTopRightRadius: "15px",
                    color: "#152B51",
                    width: "20%",
                  }}
                >
                  Date & Time
                </th>
              </tr>
            </thead>
            <tbody style={{ overflow: "auto" }}>
              <tr
                style={{
                  border: "none",
                  height: "20px",
                }}
              >
                <td colSpan="9"></td>
              </tr>
              {logsData.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    border: "0.5px solid rgba(50, 69, 103, 1)",
                    fontSize: "12px",
                    height: "40px",
                    fontFamily: "poppins",
                    fontWeight: "600",
                  }}
                >
                  <td className="bordertopintd tablebodyfont px-3">
                    {item.activity_by_username} ({item.activity_by})
                  </td>
                  <td className="bordertopintd tablebodyfont px-3">
                    {item.action}
                  </td>
                  <td className="bordertopintd tablebodyfont px-3">
                    {item.activity?.description}
                    {item.reason ? (
                      <>
                        <br />
                        reason: {item.reason}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="bordertopintd tablebodyfont px-3">
                    {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3>Activities not found!</h3>
        )}
      </div>
    </>
  );
};

export default AuditLogs;
