import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  Label,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import * as React from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import { useState } from "react";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./tenantstyle.css";
import Img from "../../assets/img/theme/site-logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { verifyToken } from "components/Functions/Functions";

const ExpiringLease = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let [tenantsData, setTenantsData] = useState([]);
  const [clickReport, setClickReport] = useState(false);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  let [expireData, setExpireData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let [loader, setLoader] = useState(true);
  const { admin } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageItem, setPageItem] = useState(10);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  // const [properties, setProperties] = useState('All rentals');
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [accessType, setAccessType] = useState(null);
  let navigate = useNavigate();
  const location  = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies();
  React.useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);
  // const [cookies] = useCookies(["token"]);
  // React.useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/admin/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);

  let getTenantsData = async () => {
    if (accessType?.admin_id) {
      try {
        let response = await axiosInstance.get(
          `/tenantinsurance/report/${accessType?.admin_id}`
        );
        if (response.data.statusCode === 200) {
          let data = response.data.data;
          setTenantsData(data);
          setTotalPages(Math.ceil(data.length / pageItem));
          // setLoader(false);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching tenants data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    getTenantsData();
    //  getTenantsLimit();
  }, [pageItem, accessType, upArrow, sortBy]);

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, [accessType]);

  const handleRunReport = async () => {
    setClickReport(true);
    try {
      let response = await axiosInstance.get(
        `/leases/expire/${accessType?.admin_id}?from_date=${fromDate}&to_date=${toDate}`
      );
      if (response.data.statusCode === 200) {
        let data = response.data.data;
        setExpireData(data);
        setTotalPages(Math.ceil(data.length / pageItem));
      } else {
        console.log("Error fetching report:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  const paginatedData = tenantsData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filterTenantsBySearch = () => {
    let filteredData = [...expireData];

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((lease) => {
        if (!lease) {
          return false;
        }

        return (
          lease.tenantNames.toLowerCase().includes(lowerCaseSearchQuery) ||
          lease.rental_unit.toLowerCase().includes(lowerCaseSearchQuery) ||
          lease.rental_address.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    }

    if (upArrow.length > 0) {
      const sortingArrows = upArrow;
      sortingArrows.forEach((sort) => {
        switch (sort) {
          case "rental_address":
            filteredData.sort((a, b) =>
              a.rental_address?.localeCompare(b.rental_address)
            );
            break;
          case "rental_unit":
            filteredData.sort((a, b) => a.rental_unit - b.rental_unit);
            break;
          case "tenantNames":
            filteredData.sort((a, b) =>
              a.tenantNames?.localeCompare(b.tenantNames)
            );
            break;
          case "start_date":
            filteredData.sort(
              (a, b) => new Date(a.start_date) - new Date(b.start_date)
            );
            break;
          case "end_date":
            filteredData.sort(
              (a, b) => new Date(a.end_date) - new Date(b.end_date)
            );
            break;
          default:
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
  };

  //pdf generate
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  // Update the handleOptionClick function
  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  // Function to generate PDF from table data
  const generatePDF = (response) => {
    const doc = new jsPDF();

    // Centering the logo at the top with increased width
    const img1Width = 15; // Increased width for Img1
    const img1Height = 13; // Adjust height if needed
    doc.addImage(
      Img,
      "JPEG",
      10, // X position (10 units from the left edge)
      15, // Y position (10 units from the top edge)
      img1Width,
      img1Height
    );

    // Add the title "Renters Insurance"
    const titleYPosition = 10 + 10; // 10 is the top position, img1Height is the height of the image, 10 is additional space
    doc.setFontSize(20);
    doc.text(
      "Expiring Lease",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    // Get the current date and format it
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    const subtitleYPosition = titleYPosition + 10; // Adjust space after the title
    doc.setFontSize(12);
    doc.text(
      `As of ${formattedDate}`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    // Add the contact information on the right
    const contactInfo = [
      userProfile?.company_name || "",
      userProfile?.company_address || "",
      [userProfile?.city, userProfile?.state, userProfile?.country]
        .filter(Boolean)
        .join(", "),
      userProfile?.postal_code || "",
    ]
      .filter(Boolean)
      .join("\n");

    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20; // Margin from the top
    const lineHeight = 4; // Adjust this value to increase or decrease the line height

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight // Starting 10 units from the top, adjust line height
      );
    });

    // Reset font size for table and other content
    doc.setFontSize(12);

    const tableData = expireData.map((lease) => [
      lease.rental_address || "N/A",
      lease.rental_unit || "N/A",
      lease.tenantNames || "N/A",
      `$${lease.amount || "0.00"}`,
      `$${lease.recurring || "0.00"}`,
      lease.start_date || "N/A",
      lease.end_date || "N/A",
      lease.status || "N/A",
    ]);

    // Draw the table
    doc.autoTable({
      startY: subtitleYPosition + 20,
      head: [
        [
          "Property",
          "Unit",
          "Tenant",
          "Rent",
          "Non-rent",
          "Lease Start",
          "Lease End",
          "Status",
        ],
      ],

      body: tableData,
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 11,
        fontStyle: "bold",
      },
    });

    doc.save("expiring-lease.pdf");
  };
  // Function to generate Excel from table data
  const generateExcel = () => {
    const wb = XLSX.utils.book_new();

    const worksheetData = [
      [
        "Property",
        "Unit",
        "Tenant",
        "Rent",
        "Non-rent",
        "Lease Start",
        "Lease End",
        "Status",
      ],
      ...expireData.map((lease) => [
        lease.rental_address || "N/A",
        lease.rental_unit || "N/A",
        lease.tenantNames || "N/A",
        `$${lease.amount || "0.00"}`,
        `$${lease.recurring || "0.00"}`,
        lease.start_date || "N/A",
        lease.end_date || "N/A",
        lease.status || "N/A",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    const wscols = [
      { wch: 30 },
      { wch: 10 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, "Expiring Lease");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "expiring-lease.xlsx"
    );
  };

  // Function to generate CSV from table data
  const generateCSV = () => {
    const worksheetData = [
      [
        "Property",
        "Unit",
        "Tenant",
        "Rent",
        "Non-rent",
        "Lease Start",
        "Lease End",
        "Status",
      ],
      ...expireData.map((lease) => [
        lease.rental_address || "N/A",
        lease.rental_unit || "N/A",
        lease.tenantNames || "N/A",
        `$${lease.amount || "0.00"}`,
        `$${lease.recurring || "0.00"}`,
        lease.start_date || "N/A",
        lease.end_date || "N/A",
        lease.status || "N/A",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    saveAs(blob, "expiring-lease.csv");
  };

  return (
    <>
      <Header />
      {/* <Container className="mt--8" fluid> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Expiring Lease
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                <Card
                  className="cardbordertenantdetail mb-5"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    border: "0.5px solid #324567",
                    boxShadow: " 0px 4px 4px 0px #00000040 ",
                    padding: "10px",
                  }}
                >
                  <CardBody>
                    <Form inline>
                      <Row>
                        <Col sm={6}>
                          <Label
                            className="labelfontstyle textcolorblue"
                            style={{
                              fontSize: "18px",
                              textTransform: "capitalize",
                              fontWeight: "600",
                            }}
                            for="fromDate"
                            sm={2}
                          >
                            From
                          </Label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative fontstylerentmodal"
                            type="date"
                            name="fromDate"
                            id="fromDate"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                          />
                        </Col>

                        <Col sm={6}>
                          <Label
                            className="labelfontstyle textcolorblue"
                            style={{
                              fontSize: "18px",
                              textTransform: "capitalize",
                              fontWeight: "600",
                            }}
                            for="toDate"
                            sm={2}
                          >
                            To
                          </Label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative fontstylerentmodal"
                            type="date"
                            name="toDate"
                            id="toDate"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <div className="ml-auto">
                        <Button color="success" onClick={handleRunReport}>
                          Show Leases
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>

                {clickReport &&
                  (expireData.length > 0 ? (
                    <>
                      <Row className="mb-3">
                        <Col xs="6" sm="6">
                          <FormGroup className="searchspace searchstyle">
                            <Input
                              className="filterh"
                              // fullWidth
                              type="text"
                              placeholder="Search here..."
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              style={{
                                width: "100%",
                                maxWidth: "200px",
                                minWidth: "200px",
                                fontWeight: "500",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                border: "1px solid #ced4da",
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="d-flex justify-content-end addtotnstyle">
                          <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggleDropdown}
                          >
                            <DropdownToggle
                              className="background-colorsty bgtextwhite fontfamilysty"
                              caret
                              style={{
                                marginBottom: "12px",
                                fontSize: "16px",
                                border: "1px solid #152B5180",
                                boxShadow: "0px 4px 4px 0px #00000040",
                                fontWeight: "500",
                              }}
                            >
                              Exports
                            </DropdownToggle>
                            <DropdownMenu style={{ minWidth: "120px" }}>
                              <DropdownItem
                                className="textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                                onClick={() => handleOptionClick("PDF")}
                              >
                                PDF
                              </DropdownItem>
                              <DropdownItem
                                className="textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                                onClick={() => handleOptionClick("XLSX")}
                              >
                                XLSX
                              </DropdownItem>
                              <DropdownItem
                                className="textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                                onClick={() => handleOptionClick("CSV")}
                              >
                                CSV
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                      </Row>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                        style={{ borderCollapse: "collapse" }}
                      >
                        <thead
                          className=""
                          style={{
                            height: "45px",
                            fontSize: "14px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                            boxShadow: " 0px 4px 4px 0px #00000040",
                          }}
                        >
                          <tr
                            style={{
                              border: "2px solid rgba(50, 69, 103, 1)",
                            }}
                          >
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                              style={{
                                borderTopLeftRadius: "15px",
                              }}
                            >
                              Property
                              {sortBy.includes("rental_address") ? (
                                upArrow.includes("rental_address") ? (
                                  <ArrowDropUpIcon
                                    onClick={() => sortData("rental_address")}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    onClick={() => sortData("rental_address")}
                                  />
                                )
                              ) : (
                                <ArrowDropDownIcon
                                  onClick={() => sortData("rental_address")}
                                />
                              )}
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                              style={{
                                borderTopLeftRadius: "15px",
                              }}
                            >
                              Unit
                              {sortBy.includes("rental_unit") ? (
                                upArrow.includes("rental_unit") ? (
                                  <ArrowDropUpIcon
                                    onClick={() => sortData("rental_unit")}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    onClick={() => sortData("rental_unit")}
                                  />
                                )
                              ) : (
                                <ArrowDropDownIcon
                                  onClick={() => sortData("rental_unit")}
                                />
                              )}
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                              style={{
                                borderTopLeftRadius: "15px",
                              }}
                            >
                              Tenant name
                              {sortBy.includes("tenantNames") ? (
                                upArrow.includes("tenantNames") ? (
                                  <ArrowDropUpIcon
                                    onClick={() => sortData("tenantNames")}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    onClick={() => sortData("tenantNames")}
                                  />
                                )
                              ) : (
                                <ArrowDropDownIcon
                                  onClick={() => sortData("tenantNames")}
                                />
                              )}
                            </th>

                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                              style={{
                                borderTopLeftRadius: "15px",
                              }}
                            >
                              Rent
                            </th>

                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Non-rent
                            </th>

                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Lease Start
                              {sortBy.includes("start_date") ? (
                                upArrow.includes("start_date") ? (
                                  <ArrowDropUpIcon
                                    onClick={() => sortData("start_date")}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    onClick={() => sortData("start_date")}
                                  />
                                )
                              ) : (
                                <ArrowDropDownIcon
                                  onClick={() => sortData("start_date")}
                                />
                              )}
                            </th>

                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Lease End
                              {sortBy.includes("end_date") ? (
                                upArrow.includes("end_date") ? (
                                  <ArrowDropUpIcon
                                    onClick={() => sortData("end_date")}
                                  />
                                ) : (
                                  <ArrowDropDownIcon
                                    onClick={() => sortData("end_date")}
                                  />
                                )
                              ) : (
                                <ArrowDropDownIcon
                                  onClick={() => sortData("end_date")}
                                />
                              )}
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        {expireData.length === 0 ? (
                          <tbody>
                            <tr className="text-center">
                              <td colSpan="8" style={{ fontSize: "15px" }}>
                                No Renters Insurance Added
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            <tr
                              style={{
                                border: "none",
                              }}
                            >
                              <td colSpan="9"></td>
                            </tr>
                            {filterTenantsBySearchAndPage().map((tenant) => (
                              <tr
                                key={tenant.tenant_id}
                                //   onClick={() =>
                                //     navigateToTenantsDetails(tenant.tenant_id)
                                //   }
                                style={{
                                  cursor: "pointer",
                                  border: "0.5px solid rgba(50, 69, 103, 1)",
                                  fontSize: "12px",
                                  height: "40px",
                                  fontFamily: "poppins",
                                  fontWeight: "600",
                                }}
                              >
                                <td className="bordertopintd tablebodyfont tabletextcolor">
                                  {tenant.rental_address}
                                </td>

                                <td className="bordertopintd tablebodyfont tabletextcolor">
                                  {tenant.rental_unit || "-"}
                                </td>
                                <td className="bordertopintd tablebodyfont tabletextcolor ">
                                  {tenant.tenantNames || "-"}
                                </td>
                                <td className="bordertopintd tablebodyfont tabletextcolor">
                                  ${tenant.amount || "0.00"}
                                </td>
                                <td className="bordertopintd tablebodyfont tabletextcolor">
                                  ${tenant.recurring || "0.00"}
                                </td>
                                <td className="bordertopintd tablebodyfont tabletextcolor">
                                  {tenant.start_date || "-"}
                                </td>
                                <td className="bordertopintd tablebodyfont tabletextcolor">
                                  {tenant.end_date || "-"}
                                </td>
                                <td className="bordertopintd tablebodyfont tabletextcolor">
                                  {tenant.status || "-"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>
                      {paginatedData.length > 0 ? (
                        <Row>
                          <Col className="text-right mt-3 mb-3">
                            <Dropdown
                              isOpen={leasedropdownOpen}
                              toggle={toggle2}
                            >
                              <DropdownToggle
                                className="paginationbtnstyle"
                                caret
                              >
                                {pageItem}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    setPageItem(10);
                                    setCurrentPage(1);
                                  }}
                                >
                                  10
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setPageItem(25);
                                    setCurrentPage(1);
                                  }}
                                >
                                  25
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setPageItem(50);
                                    setCurrentPage(1);
                                  }}
                                >
                                  50
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setPageItem(100);
                                    setCurrentPage(1);
                                  }}
                                >
                                  100
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                            <Button
                              className="p-0"
                              style={{ backgroundColor: "#d0d0d0" }}
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-caret-left"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                              </svg>
                            </Button>
                            <span>
                              Page {currentPage} of {totalPages}
                            </span>{" "}
                            <Button
                              className="p-0"
                              style={{ backgroundColor: "#d0d0d0" }}
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-caret-right"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                              </svg>
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <span
                        className="labelfontstyle d-flex justify-content-center"
                        style={{
                          fontSize: "18px",
                          textTransform: "capitalize",
                          color: "#152B51",
                          fontWeight: "600",
                        }}
                      >
                        No Data Available
                      </span>
                    </>
                  ))}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default ExpiringLease;
