import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import "../../assets/css/argon-dashboard-react.min.css";
import "../../assets/css/argon-dashboard-react.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import { values } from "pdf-lib";
import { verifyToken } from "components/Functions/Functions";

const Profile = () => {
  const navigate = useNavigate();
  const location  = useLocation();
  const { admin } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState(null);
  const [loader, setLoader] = useState(true);
  const [userProfile, setUserProfile] = useState();

  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);
  // const [cookies] = useCookies(["token"]);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/admin/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getUserProfile();
  }, [accessType]);

  const validationSchema = yup.object({
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    email: yup.string().required("Required").email("Invalid email address"),
    phone_number: yup
      .string()
      .required("Required")
      .matches(/^\d+$/, "Phone Number must be a valid number"),
    company_name: yup.string().required("Required"),
  });

  const userFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      company_name: "",
      company_address: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      phone_number: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (userProfile) {
      userFormik.setValues({
        first_name: userProfile.first_name || "",
        last_name: userProfile.last_name || "",
        email: userProfile.email || "",
        company_name: userProfile.company_name || "",
        company_address: userProfile.company_address || "",
        city: userProfile.city || "",
        state: userProfile.state || "",
        country: userProfile.country || "",
        postal_code: userProfile.postal_code || "",
        phone_number: userProfile.phone_number || "",
      });
    }
  }, [userProfile]);

  async function handleSubmit(values) {
    try {
      const res = await axiosInstance.put(
        `/admin/admin_edit/${accessType.admin_id}`,
        values
      );
      if (res.data.statusCode === 200) {
        toast.success("Profile updated successfully", {
          position: "top-center",
          autoClose: 900,
        });
        setTimeout(() => {
          navigate(`/${admin}/`);
        }, 1900);
      } else {
        toast.error(res.data.message, {
          position: "top-center",
        });
      }
      getUserProfile();
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
      });
      console.error("Error: ", error.message);
    }
  }

  // const [editData, setEditData] = useState([
  //   { key: "fname", value: "" },
  //   { key: "lname", value: "" },
  //   { key: "email", value: "" },
  //   { key: "cname", value: "" },
  //   { key: "phone", value: "" },
  //   { key: "ccity", value: "" },
  //   { key: "cstate", value: "" },
  //   { key: "ccountry", value: "" },
  //   { key: "cpostalcode", value: "" },
  //   { key: "caddress", value: "" },
  // ]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const isAnyValueNotEmpty = editData.some(
  //     (data) => data.value.trim() !== ""
  //   );
  //   if (!isAnyValueNotEmpty) {
  //     toast.warn("Please add all field", {
  //       position: "top-center",
  //       autoClose: 1000,
  //     });
  //   } else {
  //     try {
  //       const object = {
  //         first_name: userProfile?.first_name || "",
  //         last_name: userProfile?.last_name || "",
  //         email: userProfile?.email || "",
  //         company_name: userProfile?.company_name || "",
  //         company_address: userProfile?.company_address || "",
  //         city: userProfile?.city || "",
  //         state: userProfile?.state || "",
  //         country: userProfile?.country || "",
  //         postal_code: userProfile?.postal_code || "",
  //         phone_number: userProfile?.phone_number || "",
  //       };

  //       editData.forEach((data) => {
  //         switch (data.key) {
  //           case "fname":
  //             if (data.value !== "") {
  //               object["first_name"] = data.value.trim();
  //             }
  //             break;
  //           case "lname":
  //             if (data.value !== "") {
  //               object["last_name"] = data.value.trim();
  //             }
  //             break;
  //           case "email":
  //             if (data.value !== "") {
  //               object["email"] = data.value.trim();
  //             }
  //             break;
  //           case "cname":
  //             if (data.value !== "") {
  //               object["company_name"] = data.value.trim();
  //             }
  //             break;
  //           case "caddress":
  //             if (data.value !== "") {
  //               object["company_address"] = data.value.trim();
  //             }
  //             break;
  //           case "ccity":
  //             if (data.value !== "") {
  //               object["city"] = data.value.trim();
  //             }
  //             break;
  //           case "cstate":
  //             if (data.value !== "") {
  //               object["state"] = data.value.trim();
  //             }
  //             break;
  //           case "ccountry":
  //             if (data.value !== "") {
  //               object["country"] = data.value.trim();
  //             }
  //             break;
  //           case "cpostalcode":
  //             if (data.value !== "") {
  //               object["postal_code"] = data.value.trim();
  //             }
  //             break;
  //           case "phone":
  //             if (data.value !== "") {
  //               object["phone_number"] = data.value.trim();
  //             }
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //       const res = await axiosInstance.put(
  //         `/admin/admin_edit/${accessType.admin_id}`,
  //         object
  //       );
  //       if (res.data.statusCode === 200) {
  //         toast.success("Profile updated successfully", {
  //           position: "top-center",
  //           autoClose: 900,
  //         });
  //       } else {
  //         toast.error(res.data.message, {
  //           position: "top-center",
  //         });
  //       }
  //       getUserProfile();
  //     } catch (error) {
  //       toast.error(error.message, {
  //         position: "top-center",
  //       });
  //       console.error("Error: ", error.message);
  //     }
  //   }
  // };

  return (
    <>
      <Container className="" fluid style={{ marginTop: "4rem" }}>
        {loader ? (
          <Row>
            <Col>
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <div
                className="card"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <div
                      className="profilecirclewidth background-colorsty bgtextwhite fontfamilysty"
                      style={{
                        borderRadius: "50%",
                        padding: "20px",
                        width: "90px",
                        fontSize: "25px",
                        fontWeight: "600",
                      }}
                    >
                      {`${userProfile?.first_name
                        ?.slice(0, 1)
                        .toUpperCase()}${userProfile?.last_name
                        ?.slice(0, 1)
                        .toUpperCase()}`}
                    </div>

                    <div className="mt-3">
                      <h4
                        className="labelfontstyle textcolorblue fontfamilysty"
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        {userProfile?.first_name}&nbsp;{userProfile?.last_name}
                      </h4>
                      <p
                        className="fontstylerentr textcolorblue fontfamilysty"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {userProfile?.email}
                      </p>
                      <p
                        className="fontstylerentr textcolorblue fontfamilysty"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {userProfile?.phone_number}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card
                className="mb-3"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardHeader
                  className=" mx-4 mt-3 text-white border-0 profilecardheaderstyle background-colorsty"
                  style={{
                    borderRadius: "6px",
                    height: "45px",
                    boxShadow: " 0px 4px 4px 0px #00000040 ",
                    padding: "6px 7px 1px 21px",
                    color: "white",
                  }}
                >
                  <span
                    className=" align-items-center barforsummaryrent bgtextwhite fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "22px",
                    }}
                  >
                    My Account
                  </span>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={userFormik.handleSubmit}>
                    <h6
                      className="mb-4 labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                      User information
                    </h6>
                    {/* <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-first-name"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              First Name *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.first_name}
                              id="input-first-name"
                              placeholder="First Name"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "fname") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-last-name"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              Last Name *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.last_name}
                              id="input-last-name"
                              placeholder="Last Name"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "lname") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-email"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Email Address *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.email}
                              id="input-email"
                              placeholder="Email Address"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "email") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-first-name"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Phone Number *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.phone_number}
                              id="input-first-name"
                              placeholder="Phone Number"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "phone") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-Compnay-Name"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Company Name *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.company_name}
                              id="input-Compnay-Name"
                              placeholder="Company Name"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "cname") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-username"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Created Date
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.createdAt}
                              id="input-last-name"
                              placeholder="Created Date"
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-Company-Address"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Company Address
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.company_address}
                              id="input-Company-Address"
                              placeholder="Company Address"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "caddress") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-Postal_Code"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Postal Code
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.postal_code}
                              id="input-Postal_Code"
                              placeholder="Postal Code"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "cpostalcode") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-city"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              City
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.city}
                              id="input-city"
                              placeholder="City"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "ccity") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-State"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              State
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.state}
                              id="input-State"
                              placeholder="State"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "cstate") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-Country"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Country
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.country}
                              id="input-Country"
                              placeholder="Country"
                              type="text"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEditData((prevState) => {
                                  return prevState.map((item) => {
                                    if (item.key === "ccountry") {
                                      return { ...item, value: newValue };
                                    }
                                    return item;
                                  });
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3">
                          <Button
                            className=" text-white fontstylerentr"
                            style={{ backgroundColor: "#152B51" }}
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            className="fontstylerentr profilebtnmtstyle"
                            onClick={() => navigate(`/${admin}/`)}
                            size="small"
                            style={{ background: "#152B51", color: "#fff" }}
                          >
                            Back
                          </Button>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div> */}
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="first_name"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              First Name *
                            </label>
                            <Input
                              id="first_name"
                              name="first_name"
                              placeholder="First Name"
                              type="text"
                              value={userFormik.values.first_name}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.first_name &&
                                userFormik.errors.first_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.first_name &&
                            userFormik.errors.first_name ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.first_name}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="last_name"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              Last Name *
                            </label>
                            <Input
                              id="last_name"
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              value={userFormik.values.last_name}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.last_name &&
                                userFormik.errors.last_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.last_name &&
                            userFormik.errors.last_name ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.last_name}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="email"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Email Address *
                            </label>
                            <Input
                              id="email"
                              name="email"
                              placeholder="Email Address"
                              type="text"
                              value={userFormik.values.email}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              readOnly
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.email &&
                                userFormik.errors.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                            {userFormik.touched.email &&
                            userFormik.errors.email ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.email}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="phone_number"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Phone Number *
                            </label>
                            <Input
                              id="phone_number"
                              name="phone_number"
                              placeholder="Phone Number"
                              type="text"
                              value={userFormik.values.phone_number}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.phone_number &&
                                userFormik.errors.phone_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.phone_number &&
                            userFormik.errors.phone_number ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.phone_number}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="company_name"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Company Name *
                            </label>
                            <Input
                              id="company_name"
                              name="company_name"
                              placeholder="Company Name"
                              type="text"
                              value={userFormik.values.company_name}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.company_name &&
                                userFormik.errors.company_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.company_name &&
                            userFormik.errors.company_name ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.company_name}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-username"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Created Date
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.createdAt}
                              id="input-last-name"
                              placeholder="Created Date"
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="company_address"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Company Address
                            </label>
                            <Input
                              id="company_address"
                              name="company_address"
                              placeholder="Company Address"
                              type="text"
                              value={userFormik.values.company_address}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.company_address &&
                                userFormik.errors.company_address
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.company_address &&
                            userFormik.errors.company_address ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.company_address}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="postal_code"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Postal Code
                            </label>
                            <Input
                              id="postal_code"
                              name="postal_code"
                              placeholder="Postal Code"
                              type="text"
                              value={userFormik.values.postal_code}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.postal_code &&
                                userFormik.errors.postal_code
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.postal_code &&
                            userFormik.errors.postal_code ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.postal_code}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="city"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              City
                            </label>
                            <Input
                              id="city"
                              name="city"
                              placeholder="City"
                              type="text"
                              value={userFormik.values.city}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.city &&
                                userFormik.errors.city
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.city &&
                            userFormik.errors.city ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.city}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="state"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              State
                            </label>
                            <Input
                              id="state"
                              name="state"
                              placeholder="State"
                              type="text"
                              value={userFormik.values.state}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.state &&
                                userFormik.errors.state
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.state &&
                            userFormik.errors.state ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.state}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="country"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Country
                            </label>
                            <Input
                              id="country"
                              name="country"
                              placeholder="Country"
                              type="text"
                              value={userFormik.values.country}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.country &&
                                userFormik.errors.country
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.country &&
                            userFormik.errors.country ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.country}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3">
                          <Button
                            className="text-white fontstylerentr background-colorsty"
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            className="fontstylerentr profilebtnmtstyle background-colorsty bgtextwhite"
                            onClick={() => navigate(`/${admin}/`)}
                            size="small"
                          >
                            Back
                          </Button>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <ToastContainer />
    </>
  );
};

export default Profile;
