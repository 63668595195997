import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./Styles/global.scss";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Tenant from "layouts/Tenant";
import Agent from "layouts/Agent";
import Staff from "layouts/Staff";
import Vendor from "layouts/Vendor";
import Trial from "layouts/Trial";
import Plan from "layouts/Plan";

// ==========================  Super Admin ===================================================
import SuperAdmin from "../src/components/superadmin/layouts/SuperAdmin";
import NotFound from "views/source/404NotFound";
import AuthCheckAdmin from "views/source/AuthCheckAdmin";
import ApplicantForm from "views/source/ApplicantForm";
import Plans from "views/source/Plans";
import Planpurches from "views/source/Planpurches";
import ExpressPayment from "views/source/ExpressPayment";

import { store } from "./Stores/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Suspense fallback={<p>loading..</p>}>
      <BrowserRouter>
        <div style={{ backgroundColor: "#fff" }}>
          <Routes>
            <Route path="/:admin/*" element={<AdminLayout />} />
            <Route
              path="/:admin/applicant-form/:id"
              element={<ApplicantForm />}
            />
            <Route
              path="/"
              element={<Navigate to="/authentication" replace />}
            />
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/tenant/*" element={<Tenant />} />
            <Route path="/agent/*" element={<Agent />} />
            <Route path="/staff/*" element={<Staff />} />
            <Route path="/vendor/*" element={<Vendor />} />
            <Route path="/trial/*" element={<Trial />} />
            <Route path="/plan/*" element={<Plan />} />
            <Route path="/superadmin/*" element={<SuperAdmin />} />
            <Route path="/:admin/404" element={<NotFound />} />
            <Route path="/authentication" element={<AuthCheckAdmin />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/payment/:token" element={<ExpressPayment />} />
            <Route path="/plan/Planpurchases" element={<Planpurches />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Suspense>
  </Provider>
);

console.error = () => {};
console.warn = () => {};
