import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CardContent, Typography } from "@mui/material";
import {
  Col,
  FormGroup,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
  Input,
} from "reactstrap";
import * as yup from "yup";
import axios from "axios";
import valid from "card-validator";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import deleicon from "../../assets/img/icons/common/delete.svg";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axiosInstance from "axiosInstance";

function CreditCardForm(props) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { tenantId, closeModal, admin_id, tenantName } = props;
  const [isSubmitting, setSubmitting] = useState(false);
  const [cardLogo, setCardLogo] = useState("");
  const navigate = useNavigate();
  const [accessType, setAccessType] = useState(null);
  const [paymentLoader, setPaymentLoader] = useState(false);

  // const [cookies] = useCookies(['token']);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/tenant/token_check`, {
  //         token: cookies.token
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);

  const fetchCardLogo = async (cardType) => {
    try {
      if (!cardType) {
        throw new Error("Card type is undefined");
      }

      const response = await axios.get(
        `https://logo.clearbit.com/${cardType.toLowerCase()}.com`
      );
      setCardLogo(response.config.url);
    } catch (error) {
      // Handle error (e.g., card type not found)
      console.error("Error fetching card logo:", error);
      setCardLogo(""); // Set to a default logo or leave it empty
    }
  };

  useEffect(() => {
    fetchCardLogo();
  }, []);

  const [customervault, setCustomervault] = useState([]);
  const [cardDetalis, setCardDetails] = useState([]);
  const [isBilling, setIsBilling] = useState(false);
  const [vaultId, setVaultId] = useState(false);

  const getCreditCard = async () => {
    if (tenantId) {
      try {
        const response = await axiosInstance.get(
          `/creditcard/getCreditCards/${tenantId}`
        );
        setCustomervault(response.data);
        setVaultId(response.data.customer_vault_id);
        getMultipleCustomerVault(response.data.customer_vault_id);

        const hasCustomerVaultId = "customer_vault_id" in response.data;
        if (hasCustomerVaultId) {
          setIsBilling(true);
        } else {
          setIsBilling(false);
        }
      } catch (error) {
        console.error("Error fetching credit card details:", error);
        setIsBilling(false);
      }
    }
  };
  const getMultipleCustomerVault = async (customerVaultIds) => {
    try {
      setPaymentLoader(true);
      if (customerVaultIds?.length === 0) {
        setCardDetails([]);
        return;
      }

      const response = await axiosInstance.post(
        `/nmipayment/get-billing-customer-vault`,
        {
          customer_vault_id: customerVaultIds,
          admin_id: admin_id,
        }
      );

      if (Object.keys(response.data.data).length === 0) {
        setCardDetails([]); 
        setPaymentLoader(false);
        return;
      }
      // Check if customer.billing is an array
      const billingData = response.data?.data?.customer?.billing;
      if (billingData && Array.isArray(billingData)) {
        const extractedData = billingData.map((item) => ({
          billing_id: item["@attributes"].id,
          first_name: item.first_name,
          last_name: item.last_name,
          cc_number: item.cc_number,
          cc_exp: item.cc_exp,
          cc_type: item.cc_type,
          cc_bin: item.cc_bin,
          customer_vault_id: item.customer_vault_id,
        }));

        for (const card of extractedData) {
          const options = {
            method: "POST",
            url: `https://bin-ip-checker.p.rapidapi.com/?bin=${card.cc_bin}`,

            headers: {
              "content-type": "application/json",
              "X-RapidAPI-Key":
                "1bd772d3c3msh11c1022dee1c2aep1557bajsn0ac41ea04ef7",
              "X-RapidAPI-Host": "bin-ip-checker.p.rapidapi.com",
            },
            // data: {bin: '448590'}
          };

          try {
            const response = await axios.request(options);
            card.binType = response.data.BIN.type;
          } catch (error) {
            console.error(error, "error in bin check");
          }
        }

        setPaymentLoader(false);
        setCardDetails(extractedData);
      } else if (billingData) {
        // If there's only one record, create an array with a single item
        const extractedData = [
          {
            billing_id: billingData["@attributes"].id,
            first_name: billingData.first_name,
            last_name: billingData.last_name,
            cc_number: billingData.cc_number,
            cc_exp: billingData.cc_exp,
            cc_type: billingData.cc_type,
            cc_bin: billingData.cc_bin,
            customer_vault_id: billingData.customer_vault_id,
          },
        ];

        for (const card of extractedData) {
          const options = {
            method: "POST",
            url: `https://bin-ip-checker.p.rapidapi.com/?bin=${card.cc_bin}`,

            headers: {
              "content-type": "application/json",
              "X-RapidAPI-Key":
                "1bd772d3c3msh11c1022dee1c2aep1557bajsn0ac41ea04ef7",
              "X-RapidAPI-Host": "bin-ip-checker.p.rapidapi.com",
            },
            // data: {bin: '448590'}
          };

          try {
            const response = await axios.request(options);
            card.binType = response.data.BIN.type;
          } catch (error) {
            console.error(error, "error in bin check");
          }
        }

        setPaymentLoader(false);
        setCardDetails(extractedData);
      } else {
        // console.error(
        //   "Invalid response structure - customer.billing is not an array"
        // );
        console.error(
          "Invalid response structure - billingData is not an array or is undefined/null"
        );
      }
    } catch (error) {
      console.error("Error fetching multiple customer vault records:", error);
      // setPaymentLoader(false);
      throw error;
    }
  };

  useEffect(() => {
    getCreditCard();
  }, [tenantId, admin_id]);

  useEffect(() => {
    // Extract customer_vault_id values from cardDetails
    const customerVaultIds =
      customervault?.card_detail?.map((card) => card.billing_id) || [];

    if (customerVaultIds?.length > 0) {
      // Call the API to get multiple customer vault records
      getMultipleCustomerVault(customerVaultIds);
    }
  }, [customervault]);

  const paymentSchema = yup.object({
    card_number: yup
      .string()
      .required("Card Number is required")
      .matches(/^\d+$/, "Card Number must contain only digits")
      .test("is-valid-size", "Card Number must be a valid length", (val) => {
        const length = val?.length;
        return length >= 14 && length <= 16;
      })
      .test("is-valid-card", "Invalid Card Number", (val) =>
        validateCardNumber(val)
      ),
    exp_date: yup
      .string()
      .matches(/^(0[1-9]|1[0-2])\/[0-9]{4}$/, "Invalid date format (MM/YYYY)")
      .required("Required"),
    card_type: yup.string(),
    first_name: yup.string().required("Required"),
    // last_name: yup.string().required("Required"),
    address1: yup.string().required("Required"),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string(),
    country: yup.string(),
    phone: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    company: yup.string(),
    address2: yup.string(),
  });
  const formatExpirationDate = (dateString) => {
    if (dateString.length !== 4) return dateString;
    const month = dateString.slice(0, 2);
    const year = dateString.slice(2, 4);
    return `${month}/${year}`;
  };
  const validateCardNumber = (cardNumber) => {
    const number = cardNumber.toString().replace(/\D/g, "");
    let sum = 0;
    let shouldDouble = false;

    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number[i]);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  };

  function generateRandomNumber(length) {
    let randomNumber = "";
    for (let i = 0; i < length; i++) {
      randomNumber += Math.floor(Math.random() * 9) + 1;
    }
    return randomNumber;
  }

  const handleSubmit = async (values) => {
    const isValidCard = validateCardNumber(values.card_number);

    // Example: Generate a random number with length 10
    const random10DigitNumber = generateRandomNumber(10);

    const requestBody = isBilling
      ? {
          tenant_id: tenantName,
          first_name: values.first_name,
          last_name: values.last_name,
          ccnumber: values.card_number,
          ccexp: values.exp_date,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          zip: values.zip,
          country: values.country,
          company: values.company,
          phone: values.phone,
          email: values.email,
          admin_id: admin_id,
          customer_vault_id: vaultId,
          billing_id: random10DigitNumber,
        }
      : {
          tenant_id: tenantName,
          first_name: values.first_name,
          last_name: values.last_name,
          ccnumber: values.card_number,
          ccexp: values.exp_date,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          zip: values.zip,
          country: values.country,
          company: values.company,
          phone: values.phone,
          email: values.email,
          admin_id: admin_id,
          billing_id: random10DigitNumber,
        };

    if (!isValidCard) {
      toast.error("Invalid Card Number", {
        position: "top-center",
        autoClose: 500,
      });
      return;
    }

    try {
      setSubmitting(true);

      const url = isBilling
        ? `/nmipayment/create-customer-billing`
        : `/nmipayment/create-customer-vault`;
      // Call the first API
      const customerVaultResponse = await axiosInstance.post(url, requestBody);

      if (customerVaultResponse.data && customerVaultResponse.data.data) {
        // Extract customer_vault_id from the first API response
        const customerVaultId =
          customerVaultResponse.data.data.customer_vault_id;
        const vaultResponse = customerVaultResponse.data.data.response_code;

        // Call the second API using the extracted customer_vault_id
        const creditCardResponse = await axiosInstance.post(
          `/creditcard/addCreditCard`,
          {
            tenant_id: tenantId,
            customer_vault_id: isBilling ? vaultId : customerVaultId,
            response_code: vaultResponse,
            billing_id: random10DigitNumber,
          }
        );

        if (
          (creditCardResponse.status === 200 ||
            creditCardResponse.status === 201) &&
          customerVaultResponse.status === 200
        ) {
          toast.success("Card Added Successfully", {
            position: "top-center",
            autoClose: 100,
          });

          setTimeout(() => {
            closeModal();
          }, 2000);
          getCreditCard();
          // getMultipleCustomerVault();
        } else {
          toast.error(creditCardResponse.data.message, {
            position: "top-center",
            autoClose: 500,
          });
        }
      } else {
        // Handle the case where the response structure is not as expected
        toast.error(
          "Unexpected response format from create-customer-vault API",
          {
            position: "top-center",
            autoClose: 500,
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong!", {
        position: "top-center",
        autoClose: 500,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteCard = async (customerVaultId, billingId) => {
    try {
      let deleteRecordResponse, deleteNMIResponse;

      if (customervault.card_detail.length > 1) {
        // If billingId is provided, call the new APIs
        [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
          axiosInstance.delete(`/creditcard/deleteCreditCard/${billingId}`),
          axiosInstance.post(`/nmipayment/delete-customer-billing`, {
            customer_vault_id: customerVaultId,
            billing_id: billingId,
            admin_id: admin_id,
            tenant_id: tenantName,
          }),
        ]);
      } else {
        // If billingId is not provided, call the existing API
        [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
          axiosInstance.delete(
            `/creditcard/deleteCardVault/${customerVaultId}`
          ),
          axiosInstance.post(`/nmipayment/delete-customer-vault`, {
            customer_vault_id: customerVaultId,
            admin_id: admin_id,
            tenant_id: tenantName,
          }),
        ]);
      }

      // Check the responses
      if (
        deleteRecordResponse.status === 200 &&
        deleteNMIResponse.status === 200
      ) {
        toast.success("Card deleted successfully", {
          position: "top-center",
          autoClose: 500,
        });
        getCreditCard();
      } else {
        toast.error("Card deletion failed", {
          position: "top-center",
          autoClose: 500,
        });
        console.error(
          "Delete card failed:",
          deleteRecordResponse.statusText,
          deleteNMIResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error deleting card:", error.message);
      toast.error("Error deleting card", {
        position: "top-center",
        autoClose: 500,
      });
    }
  };



  return (
    <div style={{ maxHeight: "600px", overflowY: "auto", overflowX: "hidden" }}>
      <Row>
        {/* Formik Section */}
        <Col xs="12" sm="6">
          <Formik
            initialValues={{
              card_number: "",
              exp_date: "",
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              address1: "",
              city: "",
              state: "",
              zip: "",
              country: "",
              company: "",
            }}
            validationSchema={paymentSchema}
            onSubmit={(values, { resetForm }) => {
              if (paymentSchema.isValid()) {
                // Rest of your code
                handleSubmit(values);
                resetForm();
              } else {
                console.log("Form not submitted - validation failed");
              }
            }}
          >
            {/* {({ isSubmitting }) => ( */}
            <Form>
              <Row className="mb--2">
                <Col xs="12" sm="6">
                  <FormGroup>
                    <label
                      className="dropdownfontsyle titleecolor"
                      htmlFor="card_number"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Card Number *
                    </label>
                    <Input
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="card_number"
                      placeholder="0000 0000 0000 0000"
                      className="no-spinner dropdownfontsyle titleecolor"
                      name="card_number"
                      tag={Field}
                      required
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                      }}
                    />
                    <ErrorMessage
                      name="card_number"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Col>

                <Col xs="12" sm="6">
                  <FormGroup>
                    <label
                      className="dropdownfontsyle titleecolor"
                      htmlFor="exp_date"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Expiration Date *
                    </label>
                    {/* <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="exp_date"
                      name="exp_date"
                      placeholder="MM/YYYY"
                      tag={Field}
                      required
                    /> */}
                    <Field name="exp_date">
                      {({ field }) => (
                        <InputMask {...field} mask="99/9999" maskChar={null}>
                          {(inputProps) => (
                            <Input
                              {...inputProps}
                              className="dropdownfontsyle"
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              type="text"
                              placeholder="MM/YYYY"
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                    <ErrorMessage
                      name="exp_date"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb--2">
                <Col xs="12" sm="6">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="first_name"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      First Name *
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder="Enter first name..."
                      tag={Field}
                      required
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="last_name"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Last Name *
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="last_name"
                      name="last_name"
                      placeholder="Enter last name"
                      tag={Field}
                      required
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb--2">
                <Col xs="12" sm="7">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="email"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Email *
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Enter email..."
                      tag={Field}
                      required
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="5">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="phone"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Phone *
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Enter phone..."
                      tag={Field}
                      required
                    />
                    {/* <ErrorMessage
                        name="phone"
                        component="div"
                        style={{ color: "red" }}
                      /> */}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb--2">
                <Col xs="12" sm="10">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="address1"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Address *
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="textarea"
                      id="address1"
                      name="address1"
                      placeholder="Enter address..."
                      tag={Field}
                      required
                    />
                    {/* <ErrorMessage
                        name="address1"
                        component="div"
                        style={{ color: "red" }}
                      /> */}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb--2">
                <Col xs="12" sm="4">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="city"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      City
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="city"
                      name="city"
                      placeholder="Enter city..."
                      tag={Field}
                    />
                    {/* <ErrorMessage
                        name="city"
                        component="div"
                        style={{ color: "red" }}
                      /> */}
                  </FormGroup>
                </Col>
                <Col xs="12" sm="4">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="state"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      State
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="state"
                      name="state"
                      placeholder="Enter state..."
                      tag={Field}
                    />
                    {/* <ErrorMessage
                        name="state"
                        component="div"
                        style={{ color: "red" }}
                      /> */}
                  </FormGroup>
                </Col>
                <Col xs="12" sm="4">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="country"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Country
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="country"
                      name="country"
                      placeholder="Enter country..."
                      tag={Field}
                    />
                    {/* <ErrorMessage
                        name="country"
                        component="div"
                        style={{ color: "red" }}
                      /> */}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb--2">
                <Col xs="12" sm="4">
                  <FormGroup>
                    <label
                      className="mt-2 dropdownfontsyle titleecolor"
                      htmlFor="zip"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Zip
                    </label>
                    <Input
                      className="dropdownfontsyle titleecolor"
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="zip"
                      name="zip"
                      placeholder="Enter zip..."
                      tag={Field}
                    />
                    {/* <ErrorMessage
                        name="zip"
                        component="div"
                        style={{ color: "red" }}
                      /> */}
                  </FormGroup>
                </Col>

                {/* <Col xs="12" sm="7">
                  <FormGroup>
                    <label className="mt-2" htmlFor="company" style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "500", color: "#8A95A8" }}
                    >Company</label>
                    <Input
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      type="text"
                      id="company"
                      name="company"
                      placeholder="Enter company"
                      tag={Field}
                    />
                    
                  </FormGroup>
                </Col> */}
              </Row>

              {/* Form Buttons */}
              <div
                style={{
                  display: "flex",
                  marginTop: "14px",
                }}
              >
                <button
                  type="submit"
                  className="btn dropdownfontsyle"
                  style={{
                    backgroundColor: "#152B51",
                    color: "white",
                    cursor: paymentSchema.isValid ? "pointer" : "not-allowed",
                  }}
                  disabled={!paymentSchema.isValid || isSubmitting}
                >
                  {isSubmitting ? "Loading..." : "Add Card"}
                </button>

                <button
                  type="reset"
                  className="btn dropdownfontsyle"
                  onClick={closeModal}
                  style={{
                    background: "#fff",
                    cursor: "pointer",
                    color: "#152B51",
                  }}
                >
                  Cancel
                </button>
              </div>
            </Form>
            {/* )} */}
          </Formik>
        </Col>
        {/* Card Details Section */}
        <Col xs="12" sm="6">
          <Card
            className="mt-1 creditcardrent"
            style={{
              background: "#fff",
              maxWidth: "500px",
              borderRadius: "6px",
              boxShadow: " 0px 4px 4px 0px #00000040",
              border: "0.5px solid #324567",
              height: "530px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <CardContent>
              <Typography
                className="dropdownfontsyle"
                sx={{
                  fontSize: 18,
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  textTransform: "capitalize",
                  marginRight: "10px",
                  color: "#152B51",
                }}
                // color="text.secondary"
                // gutterBottom
              >
                Cards
              </Typography>

              {/* Card Details */}
              {paymentLoader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={paymentLoader}
                  />
                </div>
              ) : cardDetalis && cardDetalis?.length > 0 ? (
                <>
                  {cardDetalis.map((item, index) => (
                    <div className="row d-flex align-items-center justify-content-around">
                      <Card
                        className="col-9"
                        style={{
                          boxShadow: "0px 4px 4px 0px #00000040 ",
                          borderRadius: "10px",
                          width: "75%",
                          height: "auto",
                          background: (() => {
                            if (
                              item.cc_type.toLowerCase() === "mastercard" ||
                              item.cc_type.toLowerCase() === "discover"
                            ) {
                              return "linear-gradient(103.24deg, #121E2E 0.63%, #3A6194 99.37%)";
                            } else if (
                              item.cc_type.toLowerCase() === "visa" ||
                              item.cc_type.toLowerCase() === "jcb"
                            ) {
                              return "linear-gradient(103.66deg, #000000 1.66%, #666666 98.66%)";
                            } else {
                              return "linear-gradient(103.23deg, #949BA5 1.73%, #393B3F 99.21%)";
                            }
                          })(),
                          marginTop: "15px",
                        }}
                      >
                        <CardBody className="creditcardbodysty">
                          <Row className="d-flex justify-content-space-between">
                            <Col className="d-flex justify-content-start">
                              <img
                                className="creditcardimagestyle"
                                src={`https://logo.clearbit.com/${item.cc_type
                                  .replace(/[-\s]/g, "")
                                  .toLowerCase()}.com`}
                                alt={`${item.cc_type} Logo`}
                                style={{ width: "50px", height: "50px" }}
                              />
                            </Col>
                            <Col
                              className="d-flex justify-content-end fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {item.binType} CARD
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-center mt-4">
                            <Col>
                              <Typography
                                className="fontstylerentmodal"
                                sx={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",

                                  textTransform: "capitalize",
                                  marginRight: "10px",
                                }}
                                color="text.secondary"
                                gutterBottom
                              >
                                {item.cc_number.replace(/(.{4})/g, "$1 ")}
                              </Typography>
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-space-between mt-3">
                            <Col
                              className="d-flex justify-content-start fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              Card Holder
                            </Col>
                            <Col
                              className="d-flex justify-content-end fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              Valid Thru
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-space-between">
                            <Col
                              className="d-flex justify-content-start fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {item.first_name + " " + item.last_name}
                            </Col>
                            <Col
                              className="d-flex justify-content-end fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {formatExpirationDate(item.cc_exp)}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <img
                        className="creditdeleterent"
                        src={deleicon}
                        width={30}
                        height={30}
                        onClick={() =>
                          handleDeleteCard(
                            item.customer_vault_id,
                            item.billing_id
                          )
                        }
                        style={{
                          cursor: "pointer",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  No cards added.
                </Typography>
              )}

              {/* {paymentLoader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={paymentLoader}
                  />
                </div>
              ) : cardDetalis && cardDetalis.length > 0 ? (
                <Table responsive style={{ overflowX: "hidden" }}>
                  <tbody>
                    <tr style={{ fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", color: "#8A95A8" }}
                    >
                      <th>Card Number</th>
                      <th>Card Type</th>
                      <th></th>
                    </tr>

                    {cardDetalis.map((item, index) => (
                      <tr key={index} style={{ marginBottom: "10px" }}>
                        <td>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontStyle: "italic",
                              fontFamily: "Arial",
                              textTransform: "capitalize",
                              marginRight: "10px",
                            }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {item.cc_number}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            sx={{
                              fontSize: 14,
                              marginRight: "10px",
                            }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {item.binType}
                            {item.cc_type && (
                              <img
                                src={`https://logo.clearbit.com/${item.cc_type.toLowerCase()}.com`}
                                alt={`${item.cc_type} Logo`}
                                style={{ width: "40%", marginLeft: "10%" }}
                              />
                            )}
                          </Typography>
                        </td>
                        <td>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={deleicon} width={30} height={30} onClick={() =>
                              handleDeleteCard(
                                item.customer_vault_id,
                                item.billing_id
                              )
                            } style={{
                              cursor: "pointer",
                              marginRight: "5px",
                            }} />

                           
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  No cards added.
                </Typography>
              )} */}
            </CardContent>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
}

export default CreditCardForm;
