import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

const ListMapComponent = ({ addresses }) => {
//   const [markers, setMarkers] = useState([]);


//     const fetchLatLng = async () => {
//       try {
//         const geocoder = new window.google.maps.Geocoder();
//         const newMarkers = [];

//         for (let i = 0; i < addresses.length; i++) {
//           const address = addresses[i];
//           const addressString = address.join(', '); // Join address components into a string
//           const response = await new Promise((resolve, reject) => {
//             geocoder.geocode({ address: addressString }, (results, status) => {
//               if (status === "OK") {
//                 resolve(results[0].geometry.location);
//               } else {
//                 reject(status);
//               }
//             });
//           });
//           newMarkers.push(response);
//         }
//         setMarkers(newMarkers);
//       } catch (error) {
//         console.error("Error fetching coordinates:", error);
//       }
//     };
//     useEffect(() => {
//         setTimeout(() => {
//           if (window.google && window.google.maps) {
//             fetchLatLng();
//           } else {
//             // alert("meet");
//             // fetchLatLng();
//           }
//         }, 500);
//       }, [addresses]);

//   return (
//     <div style={{ height: "400px" }}>
//       <LoadScript googleMapsApiKey="api_key">
//         <GoogleMap mapContainerStyle={{ height: "100%", width: "100%" }} zoom={4} center={{ lat: 38.00000000, lng: -97.00000000}}>
//           {markers.map((position, index) => (
//             <Marker key={index} position={position} />
//           ))}
//         </GoogleMap>
//       </LoadScript>
//     </div>
//   );
 };

export default ListMapComponent;