import {
  Card,
  Container,
  Row,
  Col, CardHeader
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from "axios";
import TenantsHeader from "components/Headers/TenantsHeader";
import { RotatingLines } from "react-loader-spinner";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

const TenantProfile = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [tenantDetails, setTenantDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const [accessType, setAccessType] = useState(null);

  // const [cookies] = useCookies(['token']);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/tenant/token_check`, {
  //         token: cookies.token
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  const location  = useLocation();
  const [cookie, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const getTenantData = async () => {
    if (accessType?.tenant_id) {
      try {
        const response = await axiosInstance.get(
          `/tenant/tenant_profile/${accessType?.tenant_id}`
        );
        setTenantDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getTenantData();
  }, [accessType]);

  function formatDateWithoutTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }
  return (
    <>
      <TenantsHeader />
      <Container className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem", }}>
        {loading ? (
          <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={loading}
            />
          </div>
        ) : (
          <>
            <Row>
              <div className="col">
                <CardHeader
                  className=" mt-3 mb-3 mx-3 headproptypebar background-colorsty"
                  style={{
                    borderRadius: "10px",
                    boxShadow: " 0px 4px 4px 0px #00000040 ",
                  }}
                >
                  <h2
                    className="mb-0 headproptypee bgtextwhite fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "26px",
                    }}
                  >
                    Personal Details
                  </h2>
                </CardHeader>

                {/* <Row
                  className="mx-3 py-0 mt-3"
                  style={{
                    border: ".5px solid rgba(50, 69, 103, 1)",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    height: "45px",
                    alignItems: "center",
                    borderBottom: "0px",
                    color: "#152B51",


                  }}
                >
                  <Col
                    style={{
                      borderRight: ".5px solid rgba(50, 69, 103, 1)",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Name
                  </Col>
                  <Col
                    style={{
                      borderRight: ".5px solid rgba(50, 69, 103, 1)",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Designation
                  </Col>
                  <Col
                    style={{
                      borderRight: ".5px solid rgba(50, 69, 103, 1)",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Phone Number
                  </Col>
                  <Col
                    style={{
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Email
                  </Col>
                </Row>
                <Row
                  className="mx-3 py-0"
                  style={{
                    border: ".5px solid rgba(50, 69, 103, 1)",
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                    height: "45px",
                    alignItems: "center",
                    color: "#152B51",
                    boxShadow: "0px 4px 4px 0px #00000040"
                  }}
                >
                  <Col
                    style={{
                      borderRight: ".5px solid rgba(50, 69, 103, 1)",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                      fontSize: "12px"
                    }}
                  >
                    {tenantDetails?.tenant_firstName ||
                      "N/A"}
                  </Col>
                  <Col
                    style={{
                      borderRight: ".5px solid rgba(50, 69, 103, 1)",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                      fontSize: "12px"
                    }}
                  >
                    {tenantDetails?.tenant_lastName ||
                      "N/A"}
                  </Col>
                  <Col
                    style={{
                      borderRight: ".5px solid rgba(50, 69, 103, 1)",
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                      fontSize: "12px"
                    }}
                  >
                    {tenantDetails?.tenant_phoneNumber ||
                      "N/A"}
                  </Col>
                  <Col
                    style={{
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                      fontSize: "12px"
                    }}
                  >
                    {tenantDetails?.tenant_email || "N/A"}
                  </Col>
                </Row> */}
                {/* </tbody>
              </table> */}
              </div>
            </Row>
            <div className="my-table mx-3">
              <table className="data" style={{ width: "100%" }}>
                <tr>
                  <th className="tablefontstyle tablevendorheadtext textcolorblue">Name</th>
                  <th className="tablefontstyle tablevendorheadtext textcolorblue">Phone Number</th>
                  <th className="tablefontstyle tablevendorheadtext textcolorblue">Email</th>
                </tr>
                <tr>
                  <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">{(tenantDetails?.tenant_firstName ||
                    "N/A") + " " + (tenantDetails?.tenant_lastName ||
                      "N/A")}</td>
                  <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">{tenantDetails?.tenant_phoneNumber ||
                    "N/A"}</td>
                  <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">{tenantDetails?.tenant_email || "N/A"}</td>
                </tr>

              </table>
            </div>
            {tenantDetails?.leaseData &&
            <Row>
              <div className="col">
                <CardHeader
                  className=" mt-3 mb-3 mx-3 headproptypebar"
                  style={{
                    backgroundColor: "#152B51",
                    borderRadius: "10px",
                    boxShadow: " 0px 4px 4px 0px #00000040 ",
                  }}
                >
                  <h2
                    className="mb-0 headproptypee"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "26px",
                    }}
                  >
                    Lease Details
                  </h2>
                </CardHeader>

                <div className="my-table mx-3" style={{ overflowX: "auto" }}>
                  <table className="data" style={{ width: "100%" }}>
                    <tr>
                      <th className="tablefontstyle staffsheadfont textcolorblue">Property</th>
                      <th className="tablefontstyle staffsheadfont textcolorblue">Lease Type</th>
                      <th className="tablefontstyle staffsheadfont textcolorblue">Start Date</th>
                      <th className="tablefontstyle staffsheadfont textcolorblue">End Date</th>
                      <th className="tablefontstyle staffsheadfont textcolorblue">Rent Cycle</th>
                      <th className="tablefontstyle staffsheadfont textcolorblue">Rent Amount</th>
                      <th className="tablefontstyle staffsheadfont textcolorblue">Next Due Date</th>
                    </tr>
                    <tr>
                      <td className="bordertopintd tablebodyfont staffsbodyfont tabletextcolor">{tenantDetails?.leaseData?.rental_adress || "N/A"}
                      </td>
                      <td className="bordertopintd tablebodyfont staffsbodyfont tabletextcolor">{tenantDetails?.leaseData?.lease_type || "N/A"}
                      </td>
                      <td className="bordertopintd tablebodyfont staffsbodyfont tabletextcolor">{formatDateWithoutTime(
                        tenantDetails?.leaseData?.start_date
                      ) || "N/A"}</td>
                      <td className="bordertopintd tablebodyfont staffsbodyfont tabletextcolor">{formatDateWithoutTime(
                        tenantDetails?.leaseData?.end_date
                      ) || "N/A"}</td>
                      <td className="bordertopintd tablebodyfont staffsbodyfont tabletextcolor">{tenantDetails?.leaseData?.rent_cycle || "N/A"}
                      </td>
                      <td className="bordertopintd tablebodyfont staffsbodyfont tabletextcolor">{tenantDetails?.leaseData?.amount || "N/A"}
                      </td>
                      <td className="bordertopintd tablebodyfont staffsbodyfont tabletextcolor"> {formatDateWithoutTime(
                        tenantDetails?.leaseData?.date
                      ) || "N/A"}</td>
                    </tr>

                  </table>
                </div>
                {/* </tbody>
              </table> */}
              </div>
            </Row>
}
          </>
        )}
      </Container >
    </>
  );
};

export default TenantProfile;
