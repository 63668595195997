import {
  Card,
  CardHeader,
  Button,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import * as React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import { useState } from "react";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./tenantstyle.css";
import Img from "../../assets/img/theme/site-logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const StaffRentersInsurance = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let [tentalsData, setTenantsDate] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  let [loader, setLoader] = React.useState(true);
  const { admin } = useParams();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [upArrow, setUpArrow] = React.useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [accessType, setAccessType] = React.useState(null);
  let navigate = useNavigate();

  const [cookies] = useCookies(["token"]);
  React.useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/staffmember/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  let getTenantsData = async () => {
    if (accessType?.admin_id) {
      try {
        let response = await axiosInstance.get(
          `/tenantinsurance/report/${accessType?.admin_id}`
        );
        if (response.data.statusCode === 200) {
          let data = response.data.data;
          setTenantsDate(data);
          setTotalPages(Math.ceil(data.length / pageItem));
          // setLoader(false);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching tenants data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    getTenantsData();
    //   getTenantsLimit();
  }, [pageItem, accessType, upArrow, sortBy]);

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, [accessType]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  const paginatedData = tentalsData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filterTenantsBySearch = () => {
    let filteredData = [...tentalsData];

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((lease) => {
        if (!lease) {
          return false;
        }

        return (
          lease.tenants.some(
            (tenant) =>
              tenant.tenant_name.toLowerCase().includes(lowerCaseSearchQuery) ||
              tenant.tenantInsurance.Provider.toLowerCase().includes(
                lowerCaseSearchQuery
              ) ||
              tenant.tenantInsurance.EffectiveDate.toLowerCase().includes(
                lowerCaseSearchQuery
              ) ||
              tenant.tenantInsurance.ExpirationDate.toLowerCase().includes(
                lowerCaseSearchQuery
              ) ||
              tenant.tenantInsurance.policy_id
                .toLowerCase()
                .includes(lowerCaseSearchQuery)
          ) || lease.rental_address.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    }

    if (upArrow.length > 0) {
      const sortingArrows = upArrow;
      sortingArrows.forEach((sort) => {
        switch (sort) {
          case "tenant_name":
            filteredData.sort((a, b) => {
              const aTenantNames = a.tenants
                .map((t) => t.tenant_name)
                .join(", ");
              const bTenantNames = b.tenants
                .map((t) => t.tenant_name)
                .join(", ");
              return aTenantNames?.localeCompare(bTenantNames);
            });
            break;
          case "Provider":
            filteredData.sort((a, b) => {
              const aProviders = a.tenants
                .map((t) => t.tenantInsurance.Provider)
                .join(", ");
              const bProviders = b.tenants
                .map((t) => t.tenantInsurance.Provider)
                .join(", ");
              return aProviders?.localeCompare(bProviders);
            });
            break;
          case "policy_id":
            filteredData.sort((a, b) => {
              const aPolicyIds = a.tenants
                .map((t) => t.tenantInsurance.policy_id)
                .join(", ");
              const bPolicyIds = b.tenants
                .map((t) => t.tenantInsurance.policy_id)
                .join(", ");
              return aPolicyIds?.localeCompare(bPolicyIds);
            });
            break;
          case "EffectiveDate":
            filteredData.sort((a, b) => {
              const aCreated = Math.min(
                ...a.tenants.map(
                  (t) => new Date(t.tenantInsurance.EffectiveDate)
                )
              );
              const bCreated = Math.min(
                ...b.tenants.map(
                  (t) => new Date(t.tenantInsurance.EffectiveDate)
                )
              );
              return aCreated - bCreated;
            });
            break;
          case "updatedAt":
            filteredData.sort((a, b) => {
              const aUpdated = Math.max(
                ...a.tenants.map((t) => new Date(t.tenantInsurance.updatedAt))
              );
              const bUpdated = Math.max(
                ...b.tenants.map((t) => new Date(t.tenantInsurance.updatedAt))
              );
              return aUpdated - bUpdated;
            });
            break;
          default:
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
  };

  //pdf generate
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  // Update the handleOptionClick function
  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  // Function to generate PDF from table data
  const generatePDF = (response) => {
    const doc = new jsPDF();

    // Centering the logo at the top with increased width
    const img1Width = 15; // Increased width for Img1
    const img1Height = 13; // Adjust height if needed
    doc.addImage(
      Img,
      "JPEG",
      10, // X position (10 units from the left edge)
      15, // Y position (10 units from the top edge)
      img1Width,
      img1Height
    );

    // Add the title "Renters Insurance"
    const titleYPosition = 10 + 10; // 10 is the top position, img1Height is the height of the image, 10 is additional space
    doc.setFontSize(20);
    doc.text(
      "Renters Insurance",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    // Get the current date and format it
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    const subtitleYPosition = titleYPosition + 10; // Adjust space after the title
    doc.setFontSize(12);
    doc.text(
      `As of ${formattedDate}`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    // Add the contact information on the right
    const contactInfo =
      // "302 Properties, LLC\n250 Corporate Blvd., Suite L\nNewark, DE 19702\n(302)525-4302";
      `${userProfile?.company_name}
        ${userProfile?.company_address}
        ${userProfile?.city}, ${userProfile?.state}, ${userProfile?.country}
        ${userProfile?.postal_code} `;
    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20; // Margin from the top
    const lineHeight = 4; // Adjust this value to increase or decrease the line height

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight // Starting 10 units from the top, adjust line height
      );
    });

    // Reset font size for table and other content
    doc.setFontSize(12);

    // Prepare table data dynamically from API response
    const tableData = tentalsData
      .map((lease) => {
        const leaseHeader = [
          {
            content: lease.rental_address,
            colSpan: 8,
            styles: { halign: "left", fontStyle: "bold" },
          },
        ];

        const tenantsData = lease.tenants.map((tenant) => [
          tenant.unit_details,
          tenant.tenant_name,
          tenant.tenantInsurance.Provider,
          tenant.tenantInsurance.policy_id,
          `$${tenant.tenantInsurance.LiabilityCoverage}`,
          tenant.tenantInsurance.EffectiveDate,
          tenant.tenantInsurance.ExpirationDate,
        ]);

        return [leaseHeader, ...tenantsData];
      })
      .flat();

    // Draw the table
    doc.autoTable({
      startY: subtitleYPosition + 20,
      head: [
        [
          "Unit",
          "Tenant",
          "Insurance Provider",
          "Policy ID",
          "Liability Coverage",
          "Effective Date",
          "Expiration Date",
        ],
      ],
      body: tableData,
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 11,
        fontStyle: "bold",
      },
    });

    doc.save("renters-insurance.pdf");
  };
  // Function to generate Excel from table data
  const generateExcel = () => {
    const wb = XLSX.utils.book_new();

    // Create the worksheet data with column headers
    const worksheetData = [
      [
        "Unit",
        "Tenant",
        "Insurance Provider",
        "Policy ID",
        "Liability Coverage",
        "Effective Date",
        "Expiration Date",
      ],
    ];

    // Add lease address as a full-row header followed by tenant data
    tentalsData.forEach((lease) => {
      worksheetData.push([lease.rental_address, "", "", "", "", "", ""]);

      lease.tenants.forEach((tenant) => {
        worksheetData.push([
          tenant.unit_details,
          tenant.tenant_name,
          tenant.tenantInsurance.Provider,
          tenant.tenantInsurance.policy_id,
          `$${tenant.tenantInsurance.LiabilityCoverage}`,
          tenant.tenantInsurance.EffectiveDate,
          tenant.tenantInsurance.ExpirationDate,
        ]);
      });
    });

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    const wscols = [
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
    ];

    ws["!cols"] = wscols;

    worksheetData.forEach((row, rowIndex) => {
      if (rowIndex % 2 === 0 && rowIndex > 0) {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: 0 });
        if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
        ws[cellAddress].s = {
          font: {
            bold: true,
          },
        };
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, "Renters Insurance");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "renters-insurance.xlsx"
    );
  };

  // Function to generate CSV from table data
  const generateCSV = () => {
    // Create the worksheet data with column headers
    const worksheetData = [
      [
        "Unit",
        "Tenant",
        "Insurance Provider",
        "Policy ID",
        "Liability Coverage",
        "Effective Date",
        "Expiration Date",
      ],
    ];

    tentalsData.forEach((lease) => {
      worksheetData.push([lease.rental_address]);

      lease.tenants.forEach((tenant) => {
        worksheetData.push([
          tenant.unit_details,
          tenant.tenant_name,
          tenant.tenantInsurance.Provider,
          tenant.tenantInsurance.policy_id,
          `$${tenant.tenantInsurance.LiabilityCoverage}`,
          tenant.tenantInsurance.EffectiveDate,
          tenant.tenantInsurance.ExpirationDate,
        ]);
      });
    });

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ",", RS: "\n", strip: true });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    saveAs(blob, "renters-insurance.csv");
  };
  return (
    <>
      <Header />
      {/* <Container className="mt--8" fluid> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Renters Insurance
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                <Row className="mb-3">
                  <Col xs="6" sm="6">
                    <FormGroup className="searchspace searchstyle">
                      <Input
                        className="filterh"
                        fullWidth
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end addtotnstyle">
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        caret
                        style={{
                          marginBottom: "12px",
                          fontSize: "16px",
                          border: "1px solid #152B5180",
                          boxShadow: "0px 4px 4px 0px #00000040",
                          color: "#fff",
                          backgroundColor: "#152B51",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Exports
                      </DropdownToggle>
                      <DropdownMenu style={{ minWidth: "120px" }}>
                        <DropdownItem
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#152B51",
                          }}
                          onClick={() => handleOptionClick("PDF")}
                        >
                          PDF
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#152B51",
                          }}
                          onClick={() => handleOptionClick("XLSX")}
                        >
                          XLSX
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#152B51",
                          }}
                          onClick={() => handleOptionClick("CSV")}
                        >
                          CSV
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                          color: "#152B51",
                        }}
                      >
                        Unit
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                          color: "#152B51",
                        }}
                      >
                        Tenant name
                        {sortBy.includes("tenant_name") ? (
                          upArrow.includes("tenant_name") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("tenant_name")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("tenant_name")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("tenant_name")}
                          />
                        )}
                      </th>

                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                          color: "#152B51",
                        }}
                      >
                        Insurance Provider
                        {sortBy.includes("Provider") ? (
                          upArrow.includes("Provider") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("Provider")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("Provider")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("Provider")}
                          />
                        )}
                      </th>

                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Policy Id
                      </th>

                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Liability Coverage
                      </th>

                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Effective Date
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Expiration Date
                      </th>
                    </tr>
                  </thead>
                  {tentalsData.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="8" style={{ fontSize: "15px" }}>
                          No Renters Insurance Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage().map((property) => (
                        <>
                          <tr
                            style={{
                              cursor: "pointer",
                              border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "800",
                              color: "blue",
                            }}
                          >
                            <td
                              className="bordertopintd tablebodyfont"
                              colSpan={6}
                            >
                              {property.rental_address}
                            </td>
                          </tr>
                          {property.tenants.map((tenant) => (
                            <tr
                              key={tenant.tenant_id}
                              style={{
                                cursor: "pointer",
                                border: "0.5px solid rgba(50, 69, 103, 1)",
                                fontSize: "12px",
                                height: "40px",
                                fontFamily: "poppins",
                                fontWeight: "600",
                              }}
                            >
                              <td className="bordertopintd tablebodyfont ">
                                {tenant.unit_details || "-"}
                              </td>
                              <td className="bordertopintd tablebodyfont ">
                                {tenant.tenant_name || "-"}
                              </td>
                              <td className="bordertopintd tablebodyfont">
                                {tenant.tenantInsurance?.Provider || "-"}
                              </td>
                              <td className="bordertopintd tablebodyfont">
                                {tenant.tenantInsurance?.policy_id || "-"}
                              </td>
                              <td className="bordertopintd tablebodyfont ">
                                $
                                {tenant.tenantInsurance?.LiabilityCoverage ||
                                  "0.00"}
                              </td>
                              <td className="bordertopintd tablebodyfont ">
                                {tenant.tenantInsurance?.EffectiveDate || "-"}
                              </td>
                              <td className="bordertopintd tablebodyfont ">
                                {tenant.tenantInsurance?.ExpirationDate || "-"}
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  )}
                </Table>

                {paginatedData.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default StaffRentersInsurance;
