import React, { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Label,
} from "reactstrap";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import StaffHeader from "components/Headers/StaffHeader";
import { useCookies } from "react-cookie";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import { FaCheck, FaTimes } from "react-icons/fa";
import ListMapComponent from "../../components/ListMapComponent";
import { Checkbox } from "@mui/material";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyToken } from "components/Functions/Functions";

const StaffWorkTable = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");

  useEffect(() => {
    if (status === "Over Due") {
      setSearchQuery2("Over Due");
    } else if (status === "New") {
      setSearchQuery2("New");
    }
  }, [status]);

  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const location  = useLocation();
  const [workData, setWorkData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [mapDisplay, setMapDisplay] = useState(false);

  const handleCheckboxChange = (event) => {
    setMapDisplay(event.target.checked);
  };
  const [searchQuery2, setSearchQuery2] = useState("");

  const [search, setSearch] = React.useState(false);
  const toggle3 = () => setSearch((prevState) => !prevState);

  const [accessType, setAccessType] = useState(null);
  const [userroleData, setUserroleData] = useState(null);
  // const [cookies] = useCookies(["token"]);
  const [cookie, setCookie, removeCookie] = useCookies();
  const [sortedColumn, setSortedColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [hoveredColumn, setHoveredColumn] = useState("createdAt");
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/staffmember/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  const paginatedData = workData.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getWorkData = async () => {
    if (accessType?.staffmember_id) {
      try {
        const response = await axiosInstance.get(
          `/work-order/work-orders/${accessType.admin_id}`
        );
        setWorkData(response.data.data);
        setTotalPages(Math.ceil(response.data.data.length / pageItem) || 1);
      } catch (error) {
        console.error("Error fetching work order data:", error);
      } finally {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    getWorkData();
  }, [pageItem]);

  React.useEffect(() => {
    if (accessType) {
      getWorkData();
    }
  }, [accessType]);

  const navigateToDetails = (workorder_id) => {
    navigate(`/staff/staffworkdetails/${workorder_id}`, {
      state: { workorder_id: workorder_id },
    });
  };

  // const sortData = (value) => {
  //   if (!sortBy.includes(value)) {
  //     setSortBy([...sortBy, value]);
  //     setUpArrow([...upArrow, value]);
  //     filterTenantsBySearchAndPage();
  //   } else {
  //     setSortBy(sortBy.filter((sort) => sort !== value));
  //     setUpArrow(upArrow.filter((sort) => sort !== value));
  //     filterTenantsBySearchAndPage();
  //   }
  // };

  // Handle sorting when a column header is clicked
  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(column, isAsc ? "desc" : "asc");
  };

  const sortData = (column, order) => {
    let sortedData = [...workData];
    switch (column) {
      case "rental_adress":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.rental_adress.localeCompare(b.rental_adress)
            : b.rental_adress.localeCompare(a.rental_adress)
        );
        break;
      case "work_subject":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_subject.localeCompare(b.work_subject)
            : b.work_subject.localeCompare(a.work_subject)
        );
        break;
      case "work_category":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_category.localeCompare(b.work_category)
            : b.work_category.localeCompare(a.work_category)
        );
        break;
      case "date":
        sortedData.sort((a, b) =>
          order === "asc"
            ? new Date(a?.workOrderData?.date) -
              new Date(b?.workOrderData?.date)
            : new Date(b?.workOrderData?.date) -
              new Date(a?.workOrderData?.date)
        );
        break;
      case "createdAt":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? new Date(a?.workOrderData?.createdAt) -
              new Date(b?.workOrderData?.createdAt)
            : new Date(b?.workOrderData?.createdAt) -
              new Date(a?.workOrderData?.createdAt)
        );
        break;
      default:
        break;
    }
    setWorkData(sortedData || []);
  };
  const filterRentalsBySearch = () => {
    let filteredData = [...workData];

    if (searchQuery2 && !searchQuery) {
      if (searchQuery2 === "All") {
        return workData;
      }

      if (searchQuery2 === "Over Due") {
        return workData.filter((rental) => {
          let currentDate = new Date();
          let rentalDate = new Date(rental?.workOrderData?.date);
          return (
            rentalDate < currentDate &&
            rental?.workOrderData?.status !== "Completed"
          );
        });
      }

      return workData.filter((rental) => {
        const lowerCaseQuery = searchQuery2.toLowerCase();
        return rental?.workOrderData?.status
          .toLowerCase()
          .includes(lowerCaseQuery);
      });
    }

    if (searchQuery) {
      return workData.filter((rental) => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const isUnitAddress = (
          rental?.rentalUnit?.unit_no +
          " " +
          rental?.rentalAddress?.rental_adress
        )
          .toLowerCase()
          .includes(lowerCaseQuery);
        const hasStaffMemberName =
          rental?.staffMember?.staffmember_name &&
          rental?.staffMember?.staffmember_name
            .toLowerCase()
            .includes(lowerCaseQuery);
        return (
          rental?.workOrderData?.work_subject
            .toLowerCase()
            .includes(lowerCaseQuery) ||
          rental?.workOrderData?.work_category
            .toLowerCase()
            .includes(lowerCaseQuery) ||
          rental?.workOrderData?.status
            .toLowerCase()
            .includes(lowerCaseQuery) ||
          isUnitAddress ||
          hasStaffMemberName
        );
      });
    }

    // if (upArrow.length > 0) {
    //   const filteredData = workData;
    //   const sortingArrows = upArrow;
    //   sortingArrows.forEach((value) => {
    //     switch (value) {
    //       case "rental_adress":
    //         filteredData.sort((a, b) =>
    //           a?.rentalAddress?.rental_adress?.localeCompare(
    //             b?.rentalAddress?.rental_adress
    //           )
    //         );
    //         break;
    //       case "work_subject":
    //         filteredData.sort((a, b) =>
    //           a?.workOrderData?.work_subject?.localeCompare(
    //             b?.workOrderData?.work_subject
    //           )
    //         );
    //         break;
    //       case "work_category":
    //         filteredData.sort((a, b) =>
    //           a?.workOrderData?.work_category?.localeCompare(
    //             b?.workOrderData?.work_category
    //           )
    //         );
    //         break;
    //       case "staffmember_name":
    //         filteredData.sort((a, b) =>
    //           a?.staffMember?.staffmember_name?.localeCompare(
    //             b?.workOrderData?.staffmember_name
    //           )
    //         );
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    //   return filteredData;
    // }
    if (!sortedColumn) {
      // If no sorting column is specified, sort by createdAt in descending order
      filteredData?.sort(
        (a, b) =>
          new Date(b?.workOrderData?.createdAt) -
          new Date(a?.workOrderData?.createdAt)
      );
    }
    if (!searchQuery) {
      return workData;
    }
    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterRentalsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };

  const editWorkOrder = (id) => {
    navigate(`/staff/addworkorder/${id}`);
  };

  const deleteWorkorder = (id) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "Once deleted, you will not be able to recover this work order!",
    //   icon: "warning",
    //   buttons: ["Cancel", "Delete"],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     axiosInstance
    //       .delete(`/work-order/delete_workorder/${id}`)
    //       .then((response) => {
    //         if (response.data.statusCode === 200) {
    //           toast.success("Work Order deleted successfully", {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //           getWorkData();
    //         } else if (response.data.statusCode === 203) {
    //           toast.warn(response.data.message, {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //         } else {
    //           toast.error(response.data.message, {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Error deleting work order:", error);
    //       });
    //   } else {
    //     toast.success("Work Orer is safe!", {
    //       position: "top-center",
    //       autoClose: 1000,
    //     });
    //   }
    // });
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this work order!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
  
            const deleteButton = document.querySelector(".swal-button--confirm");
            deleteButton.disabled = reason.trim() === "";
          },
        },
      },
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deletionReason = document.getElementById("delete-reason").value;
  
        try {
          const response = await axiosInstance.delete(`/work-order/delete_workorder/${id}`, {
            data: {
              reason: deletionReason,
            },
          });
  
          if (response.data.statusCode === 200) {
            toast.success("Work Order deleted successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            getWorkData();
          } else if (response.data.statusCode === 203) {
            toast.warn(response.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          } else {
            toast.error(response.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } catch (error) {
          console.error("Error deleting work order:", error);
          toast.error("Error deleting work order", {
            position: "top-center",
          });
        }
      } else {
        toast.success("Work Order is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0); 
  };

  return (
    <>
      <StaffHeader />
      <Container className="stylecontainer" fluid style={{ marginTop: "3rem" }}>
        <Row>
          {userroleData?.workorder_add && (
            <Col className="text-right">
              <Button
                className="fontstylerentr background-colorsty bgtextwhite"
                // color="primary"
                //  href="#rms"
                onClick={() => navigate("/staff/addworkorder")}
                size="small"
              >
                Add Work Order
              </Button>
            </Col>
          )}
        </Row>
        <CardHeader
          className="mt-3 headproptypebar background-colorsty"
          style={{
            borderRadius: "10px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
          }}
        >
          <h2
            className="headproptypee bgtextwhite fontfamilysty"
            style={{
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Work Orders
          </h2>
        </CardHeader>

        <Row>
          <Col>
            <CardHeader className="border-0 p-0 mt-3 mb-3">
              <Row>
                <Col xs="12" sm="12" className="d-flex">
                  <FormGroup className="searchspace searchstyle">
                    <Input
                      className="filterh"
                      fullWidth
                      type="text"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        minWidth: "200px",
                        border: "1px solid #ced4da",
                      }}
                    />
                  </FormGroup>
                  <Dropdown isOpen={search} toggle={toggle3} className="mx-2">
                    <DropdownToggle
                      className="selecttypee typeselectprop titleecolor"
                      caret
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        border: "1px solid #ced4da",
                        maxWidth: "200px",
                        minWidth: "200px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {searchQuery2
                        ? searchQuery
                          ? "Select Status"
                          : searchQuery2
                        : "Select Status"}
                    </DropdownToggle>
                    <DropdownMenu className="dropdownmeunprop">
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("New");
                          setSearchQuery("");
                        }}
                      >
                        New
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("In Progress");
                          setSearchQuery("");
                        }}
                      >
                        In Progress
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("On Hold");
                          setSearchQuery("");
                        }}
                      >
                        On Hold
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("Completed");
                          setSearchQuery("");
                        }}
                      >
                        Completed
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("Over Due");
                          setSearchQuery("");
                        }}
                      >
                        Over Due
                      </DropdownItem>
                      {/* 6002 */}
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("Closed");
                          setSearchQuery("");
                        }}
                      >
                        Closed
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("All");
                          setSearchQuery("");
                        }}
                      >
                        All
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {/* <Checkbox
                          checked={mapDisplay}
                          onChange={handleCheckboxChange}
                          name="map"
                        />
                        <Label
                          size="small"
                          style={{ color: "#152B51", marginTop: "13px",fontFamily: "poppins",
                          fontWeight: "600"}}
                        >
                          View Map
                        </Label> */}
                </Col>
              </Row>
            </CardHeader>
            {/* </Row> */}
            {/* {mapDisplay ? (
                  <>
                    <div
                      className="mb-3"
                      style={{ borderRadius: "20px", overflow: "hidden" }}
                    >
                      <ListMapComponent
                        addresses={workData.map((rental) => [
                          rental.rental_data.rental_adress,
                          rental.rental_data.rental_city,
                          rental.rental_data.rental_state,
                          rental.rental_data.rental_country,
                          rental.rental_data.rental_postcode,
                        ])}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )} */}
            <Table
              className="align-items-center table-flush "
              responsive
              style={{ borderCollapse: "collapse" }}
            >
              {loader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={loader}
                  />
                </div>
              ) : workData.length === 0 ? (
                <>
                  <tbody>
                    <tr className="text-center">
                      <td colSpan="8" style={{ fontSize: "15px" }}>
                        No Work Order Added
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <>
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Work Order
                        {/* {sortBy.includes("work_subject") ? (
                          upArrow.includes("work_subject") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("work_subject")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("work_subject")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("work_subject")}
                          />
                        )} */}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Property
                        {/* {sortBy.includes("rental_adress") ? (
                          upArrow.includes("rental_adress") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rental_adress")}
                          />
                        )} */}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Category
                        {/* {sortBy.includes("work_category") ? (
                          upArrow.includes("work_category") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("work_category")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("work_category")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("work_category")}
                          />
                        )} */}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Billable
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Status
                      </th>

                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        onClick={() => handleSort("createdAt")}
                        onMouseEnter={() => setHoveredColumn("createdAt")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Created At
                        {hoveredColumn === "createdAt" ||
                        sortedColumn === "createdAt" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        onClick={() => handleSort("date")}
                        onMouseEnter={() => setHoveredColumn("date")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Due Date
                        {hoveredColumn === "date" || sortedColumn === "date" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      {/*                      <th className="tablefontstyle textcolorblue" scope="col">

                        Updated At
                      </th> */}
                      {(userroleData?.workorder_edit ||
                        userroleData?.workorder_delete) && (
                          <th
                          className="tablefontstyle textcolorblue"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                          }}
                        >
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{
                        border: "none",
                      }}
                    >
                      {/* Empty row */}
                      <td colSpan="9"></td>
                    </tr>
                    {filterTenantsBySearchAndPage().map((vendor) => (
                      <tr
                        key={vendor?.workOrderData.workOrder_id}
                        onClick={() => {
                          navigateToDetails(
                            vendor?.workOrderData?.workOrder_id
                          );
                        }}
                        style={{
                          cursor: "pointer",
                          border: "0.5px solid rgba(50, 69, 103, 1)",
                          fontSize: "12px",
                          height: "40px",
                          fontFamily: "poppins",
                          fontWeight: "600",
                        }}
                      >
                        <td
                          className="bordertopintd tablebodyfont tabletextcolor"
                          style={{
                            minWidth: "300px",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {vendor?.workOrderData?.work_subject}
                        </td>
                        <td className="bordertopintd tablebodyfont tabletextcolor">
                          {vendor?.rentalAddress?.rental_adress}
                          {vendor?.rentalUnit?.rental_unit
                            ? " - " + vendor?.rentalUnit?.rental_unit
                            : null}
                        </td>
                        <td className="bordertopintd tablebodyfont tabletextcolor">
                          {vendor?.workOrderData?.work_category}
                        </td>
                        <td className="bordertopintd tablebodyfont tabletextcolor">
                          {vendor?.workOrderData?.is_billable ? (
                            <FaCheck style={{ color: "#152B51" }} />
                          ) : (
                            // <FaTimes style={{ color: "#152B51" }} />
                            " "
                          )}
                        </td>
                        <td className="bordertopintd tablebodyfont tabletextcolor">
                          {vendor?.workOrderData?.status}
                        </td>

                        <td className="bordertopintd tablebodyfont tabletextcolor">
                          {vendor?.workOrderData?.createdAt}
                        </td>
                        <td className="bordertopintd tablebodyfont tabletextcolor">
                          {vendor?.workOrderData?.date || "-"}
                        </td>
                        {/* <td className="bordertopintd tablebodyfont tabletextcolor">
                          {vendor?.workOrderData?.updatedAt || "-"}
                        </td> */}
                        {(userroleData?.workorder_edit ||
                          userroleData?.workorder_delete) && (
                          <td className="bordertopintd tablebodyfont">
                            <div style={{ display: "flex", gap: "5px" }}>
                              {userroleData?.workorder_delete && (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteWorkorder(
                                      vendor?.workOrderData?.workOrder_id
                                    );
                                  }}
                                >
                                  <img src={deleicon} width={20} height={20} />
                                </div>
                              )}
                              {userroleData?.workorder_edit && (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    editWorkOrder(
                                      vendor?.workOrderData?.workOrder_id
                                    );
                                  }}
                                >
                                  <img src={editicon} width={20} height={20} />
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </Table>
            {workData.length > 0 ? (
              <Row>
                <Col className="text-right mt-3 mb-3">
                  <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                    <DropdownToggle className="paginationbtnstyle" caret>
                      {pageItem}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(10);
                          setCurrentPage(1);
                        }}
                      >
                        10
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(25);
                          setCurrentPage(1);
                        }}
                      >
                        25
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(50);
                          setCurrentPage(1);
                        }}
                      >
                        50
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(100);
                          setCurrentPage(1);
                        }}
                      >
                        100
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>{" "}
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default StaffWorkTable;
