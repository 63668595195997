import {
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Input,
  Col,
  DropdownToggle,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownMenu,
  DropdownItem,
  // UncontrolledTooltip,
} from "reactstrap";

 
// core components
import TenantsHeader from "components/Headers/TenantsHeader";
import * as React from "react";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import swal from "sweetalert";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import PolicyDialog from "../../components/PolicyDialog";

const DocInsurance = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");

  // useEffect(() => {
  //   if (status) {
  //     setSearchQuery2(status);
  //   }
  // }, [status]);
  useEffect(() => {
    if (status === "Over Due") {
      setSearchQuery2("Over Due");
    }
  }, [status]);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate();
  const [workData, setWorkData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let [loader, setLoader] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [search, setSearch] = React.useState(false);
  const toggle3 = () => setSearch((prevState) => !prevState);

  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);

  const [accessType, setAccessType] = useState(null);

  const [cookies] = useCookies(["token"]);
  const [userroleData, setUserroleData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [TenantInsuranceId, setTenantInsuranceId] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/tenant/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  // var paginatedData;
  // if (workData) {
  //   paginatedData = workData.slice(startIndex, endIndex);
  // }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getRentalData = async () => {
    if (accessType?.tenant_id) {
      try {
        const response = await axiosInstance.get(
          `/tenantinsurance/docinsurance/${accessType?.tenant_id}`
        );
        setWorkData(response.data.data);
        setTotalPages(Math.ceil(response.data?.data?.length / pageItem));
      } catch (error) {
        console.error("Error fetching insurance data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getRentalData();
  }, [accessType, pageItem, upArrow, sortBy]);

  const navigateToDetails = (tenantId) => {
    navigate(`/tenant/tenant/get/${tenantId}`);
  };

  const filterRentalsBySearch = () => {
    let filteredData = workData;

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((tenant) => {
        return (
          tenant?.Provider?.toLowerCase().includes(lowerCaseSearchQuery) ||
          tenant?.policy_id?.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    }

    if (upArrow.length > 0) {
      const sortingArrows = upArrow.length > 0 ? upArrow : null;
      sortingArrows.forEach((value) => {
        switch (value) {
          case "Provider":
            filteredData.sort((a, b) => {
              const comparison = a.Provider?.localeCompare(b.Provider);
              return upArrow.includes("Provider") ? comparison : -comparison;
            });
            break;
          case "lease_type":
            filteredData.sort((a, b) =>
              a.lease_type?.localeCompare(b.lease_type)
            );
            break;
          case "tenant_firstName":
            filteredData.sort((a, b) => {
              const firstNameA = a.tenantNames?.split(", ")[0].split(" ")[0];
              const firstNameB = b.tenantNames?.split(", ")[0].split(" ")[0];
              return firstNameA?.localeCompare(firstNameB);
            });
            break;
          case "start_date":
            filteredData.sort(
              (a, b) => new Date(a.start_date) - new Date(b.start_date)
            );
            break;
          case "end_date":
            filteredData.sort(
              (a, b) => new Date(a.end_date) - new Date(b.end_date)
            );
            break;
          case "policy_id":
            filteredData.sort((a, b) =>
              a.policy_id?.localeCompare(b.policy_id)
            );
            break;
          case "rent_duedate":
            filteredData.sort((a, b) =>
              a.rent_duedate?.localeCompare(b.rent_duedate)
            );
            break;
          case "LiabilityCoverage":
            filteredData.sort(
              (a, b) => a.LiabilityCoverage - b.LiabilityCoverage
            );
            break;
          case "deposite":
            filteredData.sort(
              (a, b) => new Date(a.deposite) - new Date(b.deposite)
            );
            break;
          case "recurringCharge":
            filteredData.sort(
              (a, b) =>
                new Date(a.recurringCharge) - new Date(b.recurringCharge)
            );
            break;
          case "createdAt":
            filteredData.sort((a, b) => {
              const comparison = new Date(a.createdAt) - new Date(b.createdAt);
              return upArrow.includes("createdAt") ? comparison : -comparison;
            });
            break;
          default:
            // filteredData.slice(startIndex, endIndex);
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterRentalsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
  };

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.tenant_permission);
      }
    };
    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  const handleAdd = async () => {
    if (workData.some((item) => item.status === "ACTIVE")) {
      swal({
        title: "Are you sure?",
        text: "If you add new renters insurance then older one get expired!",
        icon: "warning",
        buttons: ["Cancel", "Yes"],
        dangerMode: true,
      }).then(async (willAdd) => {
        if (willAdd) {
          setIsModalOpen(true);
        } else {
          toast.success("Renters Insurance is safe!", {
            position: "top-center",
            autoClose: 800,
          });
        }
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    getRentalData();
  };

    const handlePolicyDelete = async (id) => {
      try {
        const apiUrl = `/tenantinsurance/tenantinsurance/${id}`;
        const response = await axiosInstance.delete(apiUrl);
        if (response.data.statusCode === 200) {
          toast.success("Insurance Deleted successfully!", {
            position: "top-center",
            autoClose: 2000,
            onClose: closeModal,
          });
          getRentalData();
        } else {
          toast.error(response.data.message, {
            position: "top-center",
          });
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
        });
        console.error("Error:", error.message);
      }
    };

    return (
      <>
        <TenantsHeader />
        {/* Page content */}
        <Container className="stylecontainer" fluid style={{ marginTop: "4rem", }}>
          {/* Table */}
          <Row>
          {userroleData?.workorder_add && (<Col className="text-right">
              <Button
                className="fontstylerentr"
                color="primary"
                href="#rms"
                onClick={() => handleAdd()}
                size="small"
                style={{ background: "#152B51", color: "white" }}
              >
                Add Insurance
              </Button>
            </Col>
          )}
        </Row>
        <CardHeader
          className=" mt-3 mb-3 headproptypebar"
          style={{
            backgroundColor: "#152B51",
            borderRadius: "10px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
          }}
        >
          <h2
            className="mb-0 headproptypee"
            style={{
              color: "#ffffff",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Insurance
          </h2>
        </CardHeader>

        <Row>
          <div className="col">
            {/* <Card className="shadow"> */}
            <CardHeader className="border-0 p-0 mt-3 mb-3">
              <Row className="d-flex mx-2">
                {/* <Col xs="12" sm="6"> */}
                <FormGroup className="mr-sm-2 searchspace searchstyle">
                  <Input
                    className="filterh"
                    fullWidth
                    type="text"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setSearchQuery2("");
                    }}
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                      minWidth: "200px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </FormGroup>

                {/* </Col> */}
              </Row>
            </CardHeader>

            {/* <Row
                className="mx-2  d-flex align-items-center py-1"
                style={{
                  borderRadius: "10px", height: "auto",
                  // boxShadow: " 0px 4px 4px 0px #00000040",
  
                }}
              >
                <Col>
                  <Row
                    className="mx-1 d-flex align-items-center"
                    style={{
                      border: "2px solid rgba(50, 69, 103, 1)",
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: "0px 4px 4px 0px #00000040",
                    }}
                  >
                    <Col>Work Order</Col>
                    <Col>Property</Col>
                    <Col>Category</Col>
                    <Col>Assigned</Col>
                    <Col>Status</Col>
                    <Col>Created At</Col>
                    <Col>Updated At</Col>
  
                  </Row>
                  <Row
                    className="mx-1 mt-3"
                    style={{
                      border: "0.5px solid rgba(50, 69, 103, 1)",
                      borderBottomLeftRadius: "12px",
                      borderBottomRightRadius: "12px",
                      overflow: "hidden",
                      fontSize: "16px",
                      fontWeight: "600",
                      // lineHeight: "19.12px",
                    }}
                  >
                    <Col>
                      {filterTenantsBySearchAndPage()?.map((rental) => (
                        <Row
                          key={rental?.workOrder_id}
                          className="d-flex align-items-center"
                          onClick={() =>
                            navigateToDetails(rental?.workOrder_id)
                          }
                          style={{
                            cursor: "pointer",
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <Col>{rental?.work_subject}</Col>
                          <Col>
                            {rental?.rental_adress}{" "}
                            {rental?.rental_unit
                              ? " - " + rental?.rental_unit
                              : null}
                          </Col>
                          <Col>{rental?.work_category}</Col>
                          <Col>{rental?.staffmember_name}</Col>
                          <Col>{rental?.status}</Col>
                          <Col>{rental?.createdAt}</Col>
                          <Col>{rental?.updatedAt || "-"}</Col>
  
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Col>
              </Row>  */}
            <Table
              className="align-items-center table-flush"
              responsive
              style={{ borderCollapse: "collapse" }}
            >
              {loader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={loader}
                  />
                </div>
              ) : (
                <>
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",

                          color: "#152B51",
                        }}
                      >
                        Insurance Company
                        {sortBy.includes("Provider") ? (
                          upArrow.includes("Provider") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("Provider")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("Provider")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("Provider")}
                          />
                        )}
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Policy Id
                        {sortBy.includes("policy_id") ? (
                          upArrow.includes("policy_id") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("policy_id")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("policy_id")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("policy_id")}
                          />
                        )}
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Liability Coverage
                        {sortBy.includes("LiabilityCoverage") ? (
                          upArrow.includes("LiabilityCoverage") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("LiabilityCoverage")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("LiabilityCoverage")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("LiabilityCoverage")}
                          />
                        )}
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Status
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Effective Date
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{
                          borderTopRightRadius: "15px",
                          color: "#152B51",
                        }}
                      >
                        Expiration Date
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{
                          borderTopRightRadius: "15px",
                          color: "#152B51",
                        }}
                      >
                        ACTIONS
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      style={{
                        border: "none",
                      }}
                    >
                      <td colSpan="9"></td>
                    </tr>
                    {filterTenantsBySearchAndPage()?.length > 0 ? (
                      filterTenantsBySearchAndPage()?.map((rental) => (
                        <>
                          <tr
                            key={rental?.workOrder_id}
                            // onClick={() =>
                            //   navigateToDetails(rental?.tenant_id)
                            // }
                            style={{
                              cursor: "pointer",
                              border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                            }}
                          >
                            <td className="bordertopintd tablebodyfont">
                              {rental?.Provider}
                            </td>
                            <td className="bordertopintd tablebodyfont">
                              {rental?.policy_id}
                            </td>
                            <td className="bordertopintd tablebodyfont">
                              {rental?.LiabilityCoverage}
                            </td>
                            <td className="bordertopintd tablebodyfont">
                              {rental?.status || "-"}
                            </td>
                            <td className="bordertopintd tablebodyfont">
                              {rental?.EffectiveDate}
                            </td>
                            <td className="bordertopintd tablebodyfont">
                              {rental?.ExpirationDate}
                            </td>
                            <td className="bordertopintd tablebodyfont">
                              <div style={{ display: "flex", gap: "5px" }}>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handlePolicyDelete(
                                      rental.TenantInsurance_id
                                    );
                                  }}
                                >
                                  <img src={deleicon} width={20} height={20} />
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setTenantInsuranceId(
                                      rental.TenantInsurance_id
                                    );
                                  }}
                                >
                                  <img src={editicon} width={20} height={20} />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr
                        style={{
                          border: "2px solid rgba(50, 69, 103, 1)",
                        }}
                      >
                        <td
                          colSpan={7}
                          className="text-center bordertopintd tablebodyfont"
                        >
                          No data available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </Table>
            {workData?.length > 0 ? (
              <Row>
                <Col className="text-right mt-3 mb-3">
                  <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                    <DropdownToggle className="paginationbtnstyle" caret>
                      {pageItem}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(10);
                          setCurrentPage(1);
                        }}
                      >
                        10
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(25);
                          setCurrentPage(1);
                        }}
                      >
                        25
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(50);
                          setCurrentPage(1);
                        }}
                      >
                        50
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(100);
                          setCurrentPage(1);
                        }}
                      >
                        100
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>{" "}
                </Col>
              </Row>
            ) : null}

            {/* </Card> */}
          </div>
        </Row>

        <br />
        <br />
        <Modal
          isOpen={isModalOpen}
          toggle={closeModal}
          style={{ maxWidth: "600px", borderRadius: "20px" }}
        >
          <ModalHeader
            className=" mx-3 mt-5 text-white headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "6px",
              height: "45px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
              padding: "10px 7px 1px 21px",
              color: "white",
            }}
          >
            <span
              className=" align-items-center headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "22px",
              }}
            >
              Add Insurance Policy
            </span>
          </ModalHeader>
          <ModalBody>
            <PolicyDialog
              id={accessType?.tenant_id}
              closeModal={closeModal}
              TenantInsuranceId={TenantInsuranceId}
              admin_id={accessType?.admin_id}
            />
          </ModalBody>
        </Modal>
        <ToastContainer />
      </Container>
    </>
  );
};

export default DocInsurance;
