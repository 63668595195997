import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Table,
} from "reactstrap";
import * as yup from "yup";
import { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import { OpenImageDialog } from "components/OpenImageDialog";
import { Checkbox } from "@mui/material";
import "./addworkorder.css";
import { Grid, Typography, Radio, FormControlLabel } from "@mui/material";
import OutstandingBalance from "./OutstandingBalance";
import axiosInstance from "axiosInstance";
import SearchDropdown from "components/inputfields/SearchDropdown";
import { verifyToken } from "components/Functions/Functions";

const AddStaffWorkorder = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { id } = useParams();

  const [propdropdownOpen, setpropdropdownOpen] = useState(false);
  const [categorydropdownOpen, setcategorydropdownOpen] = useState(false);
  const [vendordropdownOpen, setvendordropdownOpen] = useState(false);
  const [tenantdownOpen, settenantdownOpen] = useState(false);
  const [entrydropdownOpen, setentrydropdownOpen] = useState(false);
  const [userdropdownOpen, setuserdropdownOpen] = useState(false);
  const [statusdropdownOpen, setstatusdropdownOpen] = useState(false);
  const [isFromPropety, setIsFromPropety] = useState(false);
  const [isFromDetailPropety, setIsFromDetailProperty] = useState(false);
  const [isFromWorkOrderDetail, setIsFromWorkOrderDetail] = useState(false);
  const [selectedProp, setSelectedProp] = useState("Select here...");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Select here...");
  const [selectedVendor, setSelectedVendor] = useState("Select here...");
  const [selectedCharge, setSelectedCharge] = useState("Select here...");
  const [selectedTenant, setSelectedTenant] = useState("Select here...");
  const [selectedEntry, setSelectedEntry] = useState("Select here...");
  const [selecteduser, setSelecteduser] = useState("Select here...");
  const [selectedStatus, setSelectedStatus] = useState("New");
  const [unitData, setUnitData] = useState([]);
  const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);
  const WorkFormik = useFormik({
    initialValues: {
      work_subject: "",
      rental_adress: "",
      rental_unit: "",
      rental_id: "",
      work_category: "",
      vendor_name: "",
      vendor_id: "",
      unit_id: "",
      tenant_name: "",
      tenant_id: "",
      invoice_number: "",
      work_charge: "",
      entry_allowed: "",
      detail: "",
      entry_contact: "",
      work_performed: "",
      vendor_note: "",
      staffmember_name: "",
      staffmember_id: "",
      status: "New",
      due_date: "",
      priority: "",
      final_total_amount: "",
      workOrderImage: [],
      statusUpdatedBy: "Admin",
      is_billable: "",
      entries: [
        {
          part_qty: "",
          account_type: "",
          description: "",
          part_price: "",
          charge_type: "Workorder Charge",
          total_amount: "",
          dropdownOpen: false,
        },
      ],
    },

    validationSchema: yup.object({
      rental_adress: yup.string().required("Required"),
      vendor_name: yup.string().required("Required"),
      staffmember_name: yup.string().required("Required"),
      work_category: yup.string().required("Required"),
      status: yup.string().required("Required"),
      due_date: yup.string().required("Required"),
    }),

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const toggle1 = () => setpropdropdownOpen((prevState) => !prevState);
  const toggle2 = () => setcategorydropdownOpen((prevState) => !prevState);
  const toggle3 = () => setvendordropdownOpen((prevState) => !prevState);
  const toggle4 = () => setentrydropdownOpen((prevState) => !prevState);
  const toggle5 = () => setuserdropdownOpen((prevState) => !prevState);
  const toggle6 = () => setstatusdropdownOpen((prevState) => !prevState);
  const toggle8 = () => settenantdownOpen((prevState) => !prevState);
  const toggle11 = () => {
    setUnitDropdownOpen((prevState) => !prevState);
  };
  const toggleDropdown = (index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    updatedEntries[index].dropdownOpen = !updatedEntries[index].dropdownOpen;
    WorkFormik.setValues({
      ...WorkFormik.values,
      entries: updatedEntries,
    });
  };

  const [accessType, setAccessType] = useState(null);
  // const [cookies] = useCookies(["token"]);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/staffmember/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  const [cookie, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.rental_id) {
      const data = {
        rental_id: location?.state?.rental_id,
        rental_adress: location?.state?.rental_adress,
      };
      setIsFromPropety(true);
      handlePropertyTypeSelect(data);
    } else if (location?.state?.Property) {
      setIsFromDetailProperty(true);
    } else if (location?.state?.Workorder) {
      setIsFromWorkOrderDetail(true);
    }
  }, [location?.state]);

  const [propertyData, setPropertyData] = useState([]);
  const [staffData, setstaffData] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [allVendors, setAllVendors] = useState([]);
  const [workOrderImage, setWorkOrderImage] = useState([]);

  const fetchUnitsByProperty = async (propertyType) => {
    if (propertyType) {
      try {
        const response = await axiosInstance.get(
          `/unit/rental_unit_dropdown/${propertyType}`
        );
        return response?.data?.data || [];
      } catch (error) {
        console.error("Error fetching units:", error);
        return [];
      }
    }
  };

  const handleAccountSelection = (value, index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    if (updatedEntries[index]) {
      updatedEntries[index].account_type = value;
      WorkFormik.setValues({
        ...WorkFormik.values,
        entries: updatedEntries,
      });
    } else {
      console.error(`Entry at index ${index} is undefined.`);
    }
  };

  const handlePropertyTypeSelect = async (property) => {
    if (!property) return;

    const { rental_adress, rental_id } = property;

    setSelectedProp(rental_adress);
    WorkFormik.setFieldValue("rental_adress", rental_adress);
    WorkFormik.setFieldValue("rental_id", rental_id);

    setSelectedUnit("");
    WorkFormik.setFieldValue("rental_unit", "");
    setUnitData([]);

    try {
      const units = await fetchUnitsByProperty(rental_id);
      setUnitData(units || []);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };
  const handleUnitSelect = (selectedUnit, unitId) => {
    setSelectedUnit(selectedUnit);
    WorkFormik.values.rental_unit = selectedUnit;
    WorkFormik.setFieldValue("unit_id", unitId);
  };

  const handleCategorySelection = (value) => {
    setSelectedCategory(value);
    setcategorydropdownOpen(true);
    if (value === "Other") {
      WorkFormik.values.work_category = "";
    } else {
      WorkFormik.values.work_category = value;
    }
  };

  const handleVendorSelect = (value) => {
    setSelectedVendor(value.vendor_name);
    WorkFormik.values.vendor_name = value?.vendor_name;
    WorkFormik.values.vendor_id = value?.vendor_id;
  };

  const handleEntrySelect = (value) => {
    setSelectedEntry(value);
    WorkFormik.values.entry_allowed = value;
  };

  const handleChargeSelect = (value) => {
    setSelectedCharge(value);
    WorkFormik.values.work_charge = value;
  };

  const handleStaffSelect = (staff) => {
    setSelecteduser(staff.staffmember_name);
    WorkFormik.values.staffmember_name = staff?.staffmember_name;
    WorkFormik.values.staffmember_id = staff?.staffmember_id;
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
    WorkFormik.values.status = status;
  };

  const handlePriorityChange = (event) => {
    setSelectedPriority(event.target.value);
    WorkFormik.values.priority = event.target.value;
  };

  let navigate = useNavigate();

  const handleCancel = () => {
    if (isFromPropety) {
      navigate(`/staff/staffpropertydetail/${location?.state?.rental_id}`, {
        state: { tab: "Work Order" },
      });
    } else if (isFromWorkOrderDetail) {
      navigate(`/staff/staffworkdetails/${id}`, {
        state: { workorder_id: id },
      });
    } else if (isFromDetailPropety) {
      navigate(`/staff/staffworkdetails/${id}`, {
        state: { rental_id: workOrderData?.rental_id },
      });
    } else {
      navigate(`/staff/staffworktable`);
    }
  };
  const handleAddRow = () => {
    const newEntry = {
      part_qty: "",
      account_type: "",
      description: "",
      part_price: "",
      total_amount: "",
      dropdownOpen: false,
    };
    if (WorkFormik.values.entries) {
      WorkFormik.setValues({
        ...WorkFormik.values,
        entries: [...WorkFormik.values.entries, newEntry],
      });
    }
  };

  const handleRemoveRow = (index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    updatedEntries.splice(index, 1);
    WorkFormik.setValues({
      ...WorkFormik.values,
      entries: updatedEntries,
    });
  };

  const setVendorsName = () => {
    axiosInstance
      .get(`/vendor/vendors/${accessType?.admin_id}`)
      .then((res) => {
        setAllVendors(res.data?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [workOrderData, setWorkOrderData] = useState(null);
  const [vid, setVid] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isBillable, setIsBillable] = useState(false);

  const handleChange = () => {
    setIsBillable(!isBillable);
    handleChargeSelect("Tenant");
  };

  const handleSubmitNavigate = () => {
    if (isFromPropety) {
      navigate(`/staff/staffpropertydetail/${location.state.rental_id}`, {
        state: { tab: "Work Order" },
      });
    } else if (isFromWorkOrderDetail) {
      navigate(`/staff/staffworkdetails/${id}`, {
        state: { Workorder: "Workorder" },
      });
    } else if (isFromDetailPropety) {
      navigate(`/staff/staffworkdetails/${id}`, {
        state: { rental_id: workOrderData.rental_id },
      });
    } else {
      navigate(`/staff/staffworktable`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await axiosInstance.get(
            `/work-order/workorder_details/${id}`
          );
          const {
            partsandcharge_data,
            property_data,
            unit_data,
            staff_data,
            vendor_data,
            tenant_data,
          } = response?.data?.data;
          setWorkOrderData(response?.data?.data);

          const formattedDueDate = response?.data?.data?.date
            ? new Date(response?.data?.data?.date).toISOString().split("T")[0]
            : "";

          setVid(response?.data?.data?.workOrder_id);

          try {
            const units = await fetchUnitsByProperty(property_data?.rental_id);
            setUnitData(units);
          } catch (error) {
            console.error(error, "error");
          }
          setIsBillable(response?.data?.data?.is_billable);
          setSelectedUnit(unit_data?.rental_unit || "Select");
          setSelectedProp(property_data?.rental_adress || "Select");
          setSelectedCategory(response?.data?.data?.work_category || "Select");
          setSelectedVendor(vendor_data?.vendor_name || "Select");
          setSelectedCharge(response?.data?.data?.work_charge_to || "Select");
          setSelectedEntry(
            response?.data?.data?.entry_allowed === true
              ? "Yes"
              : "No" || "Select"
          );
          setSelecteduser(staff_data?.staffmember_name || "Select");
          setSelectedStatus(response?.data?.data?.status || "Select");
          setSelectedPriority(response?.data?.data?.priority || "Select");
          setWorkOrderImage(response?.data?.data?.workOrder_images || []);
          setSelectedFiles(response?.data?.data?.workOrder_images || []);
          setSelectedTenant(
            tenant_data?.tenant_firstName +
              " " +
              tenant_data?.tenant_lastName || "Select"
          );

          WorkFormik.setValues({
            invoice_number: response?.data?.data?.invoice_number || "",
            work_charge_to: response?.data?.data?.work_charge_to || "",
            detail: response?.data?.data?.detail || "",
            entry_contact: response?.data?.data?.entry_contact || "",
            final_total_amount: response?.data?.data?.final_total_amount || "",

            work_subject: response?.data?.data?.work_subject || "",
            rental_adress: property_data?.rental_adress || "",
            rental_unit: unit_data?.rental_unit || "",
            rental_id: property_data?.rental_id || "",
            work_category: response?.data?.data?.work_category || "",
            vendor_name: vendor_data?.vendor_name || "",
            vendor_id: vendor_data?.vendor_id || "",
            unit_id: unit_data?.unit_id || "",
            tenant_id: tenant_data?.tenant_id || "",
            tenant_name:
              tenant_data?.tenant_firstName +
                " " +
                tenant_data?.tenant_lastName || "",
            invoice_number: "",
            work_charge: response?.data?.data?.work_charge_to || "",
            entry_allowed:
              response?.data?.data?.entry_allowed === true ? "Yes" : "No",
            detail: "",
            entry_contact: "",
            work_performed: response?.data?.data?.work_performed || "",
            vendor_note: response?.data?.data?.vendor_notes || "",
            staffmember_name: staff_data?.staffmember_name || "",
            staffmember_id: staff_data?.staffmember_id || "",
            status: response?.data?.data?.status || "",
            due_date: formattedDueDate || "",
            priority: response?.data?.data?.priority || "",
            workOrderImage: response?.data?.data?.workOrder_images || "",
            final_total_amount: "",
            statusUpdatedBy: "Admin",
            entries: partsandcharge_data?.map((part) => ({
              part_qty: part?.parts_quantity || "",
              parts_id: part?.parts_id || "",
              account_type: part?.account || "Select",
              description: part?.description || "",
              charge_type: "Workorder Charge",
              part_price: part?.parts_price || "",
              total_amount: part?.amount || "",
              dropdownOpen: false,
            })),
          });
        } catch (error) {
          console.error("Error fetching vendor data:", error);
        }
      }
    };

    fetchData();
  }, [id]);

  const [loader, setLoader] = useState(false);

  const handleSubmit = async (values) => {
    setLoader(true);
    let image = [];

    if (selectedFiles) {
      try {
        const uploadPromises = selectedFiles.map(async (fileItem, i) => {
          if (fileItem instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem);

              const res = await axios.post(`${imageUrl}/images/upload`, form);

              if (
                res &&
                res.data &&
                res.data?.files &&
                res.data?.files.length > 0
              ) {
                fileItem = res.data?.files[0].filename;
                image[i] = res.data?.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            image[i] = fileItem;
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    const object = {
      workOrder: {
        admin_id: accessType?.admin_id || "",
        rental_id: WorkFormik.values.rental_id || "",
        unit_id: WorkFormik.values.unit_id || "",
        vendor_id: WorkFormik.values.vendor_id || "",
        tenant_id: WorkFormik.values.tenant_id || "",
        staffmember_id: WorkFormik.values.staffmember_id || "",
        work_subject: WorkFormik.values.work_subject || "",
        work_category: WorkFormik.values.work_category || "",
        entry_allowed: WorkFormik.values.entry_allowed === "Yes" ? true : false,
        work_performed: WorkFormik.values.work_performed || "",
        workOrder_images: image || "",
        vendor_notes: WorkFormik.values.vendor_note || "",
        priority: WorkFormik.values.priority || "",
        work_charge_to: WorkFormik.values.work_charge || "",
        status: WorkFormik.values.status || "",
        date: WorkFormik.values.due_date || "",
        statusUpdatedBy: "Admin",
        is_billable: isBillable,
      },
      parts: WorkFormik.values.entries.map((item) => {
        return {
          parts_quantity: item.part_qty || 0,
          account: item.account_type || "",
          description: item.description || "",
          charge_type: "Workorder Charge",
          parts_price: item.part_price || 0,
          amount: item.total_amount || 0,
        };
      }),
    };

    try {
      const res = await axiosInstance.post(`/work-order/work-order`, object);
      if (res.status === 200) {
        if (res.data?.statusCode === 200) {
          toast.success("Work Order Added Successfully.", {
            position: "top-center",
            autoClose: 1000,
            // onClose: () => navigate(`/${admin}/Workorder`),
          });
          setTimeout(() => {
            handleSubmitNavigate();
          }, 2000);
        } else {
          toast.error(res.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
    setLoader(false);
  };

  const clearSelectedPhoto = (index, name) => {
    if (name === "propertyres_image") {
      const filteredImage = workOrderImage.filter((item, i) => i !== index);
      const filteredImage2 = selectedFiles.filter((item, i) => i !== index);
      setSelectedFiles(filteredImage2);
      setWorkOrderImage(filteredImage);
    }
  };

  const fetchPropertyData = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/rentals/rentals/${accessType?.admin_id}`
        );
        if (res.data?.statusCode === 200) {
          setPropertyData(res.data?.data);
        } else if (res.data?.statusCode === 201) {
          setPropertyData([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchStaffData = () => {
    axiosInstance
      .get(`/staffmember/staff_member/${accessType?.admin_id}`)
      .then((response) => {
        const data = response.data;
        if (data?.statusCode === 200) {
          setstaffData(data?.data);
        } else {
          console.error("Error:", data?.message);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  useEffect(() => {
    fetchPropertyData();
    setVendorsName();
    fetchStaffData();
  }, [accessType?.admin_id]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileData = (e, type) => {
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...e.target.files,
    ]);

    const newFiles = [
      ...workOrderImage,
      ...Array.from(e.target.files).map((file) => URL.createObjectURL(file)),
    ];

    setWorkOrderImage(newFiles);
  };

  const isBlobURL = (url) => {
    return url.startsWith("blob:");
  };

  const handleQuantityChange = (e, index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    updatedEntries[index].part_qty = e.target.value;
    const quantity = parseFloat(e.target.value);
    const price = parseFloat(updatedEntries[index].part_price);
    updatedEntries[index].total_amount =
      isNaN(quantity) || isNaN(price) ? "" : (quantity * price).toFixed(2);
    WorkFormik.setValues({
      ...WorkFormik.values,
      entries: updatedEntries,
    });
  };

  const handlePriceChange = (e, index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    updatedEntries[index].part_price = e.target.value;
    const quantity = parseFloat(updatedEntries[index].part_qty);
    const price = parseFloat(e.target.value);
    updatedEntries[index].total_amount =
      isNaN(quantity) || isNaN(price) ? "" : (quantity * price).toFixed(2);
    WorkFormik.setValues({
      ...WorkFormik.values,
      entries: updatedEntries,
    });
  };

  let final_total_amount = 0;
  WorkFormik.values.entries.forEach((entries) => {
    if (entries.total_amount) {
      final_total_amount += parseFloat(entries.total_amount);
    }
  });

  const editworkorder = async (vid) => {
    setLoader(true);
    let image = [];

    if (selectedFiles) {
      try {
        const uploadPromises = selectedFiles.map(async (fileItem, i) => {
          if (fileItem instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem);

              const res = await axios.post(`${imageUrl}/images/upload`, form);

              if (
                res &&
                res.data &&
                res.data?.files &&
                res.data?.files.length > 0
              ) {
                fileItem = res.data?.files[0].filename;
                image[i] = res.data?.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            image[i] = fileItem;
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    const object = {
      workOrder: {
        admin_id: accessType?.admin_id || "",
        workOrder_id: id,
        rental_id: WorkFormik.values.rental_id || "",
        unit_id: WorkFormik.values.unit_id || "",
        vendor_id: WorkFormik.values.vendor_id || "",
        tenant_id: isBillable === true ? WorkFormik.values.tenant_id : "",
        staffmember_id: WorkFormik.values.staffmember_id || "",
        staffmember_name: WorkFormik.values.staffmember_name || "",
        work_subject: WorkFormik.values.work_subject || "",
        work_category: WorkFormik.values.work_category || "",
        entry_allowed: WorkFormik.values.entry_allowed === "Yes" ? true : false,
        work_performed: WorkFormik.values.work_performed || "",
        workOrder_images: image || "",
        vendor_notes: WorkFormik.values.vendor_note || "",
        priority: WorkFormik.values.priority || "",
        work_charge_to:
          isBillable === true ? WorkFormik.values.work_charge : "",
        status: WorkFormik.values.status || "",
        date: WorkFormik.values.due_date || "",
        updated_by: { staffmember_id_id: accessType?.staffmember_id },
        statusUpdatedBy: "Staffmember",
        is_billable: isBillable,
      },
      parts: WorkFormik.values.entries.map((item) => {
        return {
          parts_quantity: item.part_qty || 0,
          parts_id: item.parts_id,
          account: item.account_type || "",
          description: item.description || "",
          workOrder_id: id,
          charge_type: "Workorder Charge",
          parts_price: item.part_price || 0,
          amount: item.total_amount || 0,
        };
      }),
    };
    try {
      const res = await axiosInstance.put(
        `/work-order/work-order/${id}`,
        object
      );
      if (res.data?.statusCode === 200) {
        toast.success("Workorder Updated Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          handleSubmitNavigate(id);
        }, 2000);
      } else {
        toast.warning(res.data?.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {}
    setLoader(false);
  };

  const [isDisplay, setIsDisplay] = useState("false");
  useEffect(() => {
    if (WorkFormik?.values?.entries[0]?.account_type) {
      setIsDisplay("true");
    } else {
      setIsDisplay("false");
    }
  }, [WorkFormik]);

  const [tenantsDetails, setTenantsDetails] = useState([]);
  const getPropertyData = async (rental_id, unit_id) => {
    if (rental_id && unit_id) {
      setTenantsDetails([]);
      try {
        const response = await axiosInstance.get(
          `/leases/get_tenants/${rental_id}/${unit_id}`
        );
        setTenantsDetails(response?.data?.data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }
    }
  };

  const getTenantData = async () => {
    if (
      selectedCharge === "Tenant" &&
      WorkFormik.values.rental_id &&
      WorkFormik.values.unit_id
    ) {
      const rental_id = WorkFormik.values.rental_id;
      const unit_id = WorkFormik.values.unit_id;
      getPropertyData(rental_id, unit_id);
    } else {
      setTenantsDetails([]);
    }
  };

  useEffect(() => {
    getTenantData();
  }, [selectedCharge, selectedUnit, selectedProp]);

  return (
    <>
      {/* <AddWorkorderHeader /> */}

      <Container
        className="containerremovespace stylecontainer"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className=" headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {id ? "Edit Work Order" : "Add Work Order"}
            </h2>
          </CardHeader>
        </Col>
        <Form onSubmit={WorkFormik.handleSubmit}>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius "
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <div className="">
                    <Row>
                      <Col lg="4" md="4" sm="4" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Subject *
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              // width: "60%",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="work_subject"
                            placeholder="Enter subject "
                            type="text"
                            name="work_subject"
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              WorkFormik.handleChange(e);
                            }}
                            value={WorkFormik.values.work_subject}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <FormGroup
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Photo
                          </label>
                          <span
                            className="textcolorblue"
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              fontFamily: "monospace",
                            }}
                          >
                            <input
                              type="file"
                              className="form-control-file d-none"
                              accept="image/*"
                              multiple
                              id={`workOrderImage`}
                              name={`workOrderImage`}
                              onChange={(e) => fileData(e)}
                            />
                            <label
                              className="d-flex justify-content-center fontstylerentmodal background-colorsty bgtextwhite fontfamilysty"
                              htmlFor={`workOrderImage`}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "6px",
                                padding: "6px",
                                width: "100px",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                              }}
                            >
                              {/* <b style={{ fontSize: "20px" }}>+</b> Add */}
                              Upload
                            </label>
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        paddingLeft: "10px",
                      }}
                    >
                      <div
                        className=" d-flex"
                        style={{
                          justifyContent: "center",
                          flexWrap: "wrap",
                          overflow: "auto",
                        }}
                      >
                        <div className="d-flex">
                          {workOrderImage &&
                            workOrderImage?.length > 0 &&
                            workOrderImage.map((unitImg, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  width: "100px",
                                  height: "100px",
                                  display: "flex",
                                  flexDirection: "column",
                                  marginRight: "20px",
                                }}
                              >
                                <img
                                  src={
                                    !isBlobURL(unitImg)
                                      ? `${imageGetUrl}/${unitImg}`
                                      : unitImg
                                  }
                                  alt=""
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    borderRadius: "10px",
                                  }}
                                  onClick={() => {
                                    setSelectedImage(unitImg);
                                    setOpen(true);
                                  }}
                                />
                                <ClearIcon
                                  style={{
                                    cursor: "pointer",
                                    alignSelf: "flex-start",
                                    position: "absolute",
                                    top: "-6px",
                                    right: "-12px",
                                  }}
                                  onClick={() =>
                                    clearSelectedPhoto(
                                      index,
                                      "propertyres_image"
                                    )
                                  }
                                />
                              </div>
                            ))}
                        </div>
                        {open && (
                          <OpenImageDialog
                            open={open}
                            setOpen={setOpen}
                            selectedImage={selectedImage}
                          />
                        )}
                      </div>
                    </FormGroup>
                  </div>

                  <div className="d-flex flex-column flex-md-row justify-content-start">
                    <FormGroup className="mb-4 mb-md-0">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-desg"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Property *
                      </label>
                      {/* <FormGroup>
                        <Dropdown
                          className="fontstylerentmodal"
                          isOpen={propdropdownOpen}
                          toggle={toggle1}
                          onBlur={WorkFormik.handleBlur}
                          disabled={isFromPropety}
                        >
                          <DropdownToggle
                            className="fontstylerentmodal titleecolor"
                            caret
                            style={{
                              width: "100%",
                              boxShadow: "0px 4px 4px 0px #00000040",
                              border: "1px solid #ced4da",
                              backgroundColor: "transparent",
                            }}
                          >
                            {selectedProp
                              ? selectedProp.length > 12
                                ? selectedProp.substring(0, 12) + "..."
                                : selectedProp
                              : "Select a property..."}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </DropdownToggle>
                          <DropdownMenu
                            className="fontstylerentmodal"
                            style={{
                              width: "max-content",
                              maxHeight: "200px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            {propertyData.map((property, index) => (
                              <DropdownItem
                                className="fontstylerentmodal"
                                key={index}
                                onClick={() => {
                                  handlePropertyTypeSelect(property);
                                }}
                              >
                                {property.rental_adress}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                          {WorkFormik.errors &&
                          WorkFormik.errors?.rental_adress &&
                          WorkFormik.touched &&
                          WorkFormik.touched?.rental_adress &&
                          WorkFormik.values.rental_adress === "" ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.rental_adress}
                            </div>
                          ) : null}
                        </Dropdown>
                      </FormGroup> */}
                      <SearchDropdown
                        options={propertyData}
                        value={
                          propertyData.find(
                            (p) => p.rental_adress === selectedProp
                          ) || null
                        }
                        // inputValue={selectedProp}
                        onChange={(event, value) =>
                          handlePropertyTypeSelect(value)
                        }
                        placeholder="Select Property"
                        getOptionLabel={(option) => option.rental_adress || ""}
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            option.rental_adress
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          );
                        }}
                      />
                      {WorkFormik.errors &&
                      WorkFormik.errors?.rental_adress &&
                      WorkFormik.touched &&
                      WorkFormik.touched?.rental_adress &&
                      WorkFormik.values.rental_adress === "" ? (
                        <div
                          className="requiredstylefont"
                          style={{ color: "red" }}
                        >
                          {WorkFormik.errors.rental_adress}
                        </div>
                      ) : null}
                    </FormGroup>
                    {selectedProp &&
                      unitData &&
                      unitData[0] &&
                      unitData[0].rental_unit && (
                        <FormGroup className="ml-md-4">
                          <label
                            className="form-control-label fontstylerentr addworkstylemargin titleecolor fontfamilysty"
                            htmlFor="input-unit"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Unit *
                          </label>
                          <FormGroup>
                            <Dropdown
                              className="fontstylerentmodal"
                              isOpen={unitDropdownOpen}
                              toggle={toggle11}
                            >
                              <DropdownToggle
                                className="fontstylerentmodal titleecolor"
                                caret
                                style={{
                                  width: "100%",
                                  boxShadow: " 0px 4px 4px 0px #00000040",
                                  border: "1px solid #ced4da",

                                  backgroundColor: "transparent",
                                }}
                              >
                                {selectedUnit ? selectedUnit : "Select Unit"}
                              </DropdownToggle>
                              <DropdownMenu className="fontstylerentmodal">
                                {unitData.length > 0 ? (
                                  unitData.map((unit) => (
                                    <DropdownItem
                                      className="fontstylerentmodal"
                                      key={unit.unit_id}
                                      onClick={() =>
                                        handleUnitSelect(
                                          unit.rental_unit,
                                          unit.unit_id
                                        )
                                      }
                                    >
                                      {unit.rental_unit}
                                    </DropdownItem>
                                  ))
                                ) : (
                                  <DropdownItem disabled>
                                    No units available
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                              {WorkFormik.errors &&
                              WorkFormik.errors?.rental_unit &&
                              WorkFormik.touched &&
                              WorkFormik.touched?.rental_unit &&
                              WorkFormik.values.rental_unit === "" ? (
                                <div
                                  className="requiredstylefont"
                                  style={{ color: "red" }}
                                >
                                  {WorkFormik.errors.rental_unit}
                                </div>
                              ) : null}
                            </Dropdown>
                          </FormGroup>
                        </FormGroup>
                      )}
                  </div>

                  <div className="d-flex flex-column flex-md-row justify-content-start mt-2">
                    <FormGroup className="mb-4 mb-md-0">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-desg"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Category *
                      </label>
                      <br />
                      <Dropdown
                        className="fontstylerentmodal"
                        isOpen={categorydropdownOpen}
                        toggle={toggle2}
                      >
                        <DropdownToggle
                          className=" fontstylerentmodal titleecolor"
                          caret
                          style={{
                            width: "100%",
                            boxShadow: " 0px 4px 4px 0px #00000040",
                            border: "1px solid #ced4da",
                            backgroundColor: "transparent",
                          }}
                        >
                          {selectedCategory
                            ? selectedCategory.length > 12
                              ? selectedCategory.substring(0, 12) + "..."
                              : selectedCategory
                            : "Select a category..."}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </DropdownToggle>
                        <DropdownMenu
                          className=" fontstylerentmodal"
                          style={{ width: "100%" }}
                        >
                          <DropdownItem
                            className=" fontstylerentmodal"
                            onClick={() => handleCategorySelection("Complaint")}
                          >
                            Complaint
                          </DropdownItem>
                          <DropdownItem
                            className=" fontstylerentmodal"
                            onClick={() =>
                              handleCategorySelection("Contribution Request")
                            }
                          >
                            Contribution Request
                          </DropdownItem>
                          <DropdownItem
                            className=" fontstylerentmodal"
                            onClick={() =>
                              handleCategorySelection("Feedback/Suggestion")
                            }
                          >
                            Feedback/Suggestion
                          </DropdownItem>
                          <DropdownItem
                            className=" fontstylerentmodal"
                            onClick={() =>
                              handleCategorySelection("General Inquiry")
                            }
                          >
                            General Inquiry
                          </DropdownItem>
                          <DropdownItem
                            className=" fontstylerentmodal"
                            onClick={() =>
                              handleCategorySelection("Maintenance Request")
                            }
                          >
                            Maintenance Request
                          </DropdownItem>
                          <DropdownItem
                            className=" fontstylerentmodal"
                            onClick={() => handleCategorySelection("Other")}
                          >
                            Other
                          </DropdownItem>
                        </DropdownMenu>
                        {WorkFormik.errors &&
                        WorkFormik.errors?.work_category &&
                        WorkFormik.touched &&
                        WorkFormik.touched?.work_category &&
                        WorkFormik.values.work_category === "" ? (
                          <div
                            className="requiredstylefont"
                            style={{ color: "red" }}
                          >
                            {WorkFormik.errors.work_category}
                          </div>
                        ) : null}
                      </Dropdown>
                    </FormGroup>

                    <FormGroup className="ml-md-4">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-desg"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Vendor *
                      </label>
                      <br />
                      <Dropdown
                        className="fontstylerentmodal"
                        isOpen={vendordropdownOpen}
                        toggle={toggle3}
                      >
                        <DropdownToggle
                          className="fontstylerentmodal titleecolor"
                          caret
                          style={{
                            width: "100%",
                            boxShadow: " 0px 4px 4px 0px #00000040",
                            border: "1px solid #ced4da",
                            backgroundColor: "transparent",
                          }}
                        >
                          {selectedVendor} &nbsp;&nbsp;&nbsp;&nbsp;
                        </DropdownToggle>
                        <DropdownMenu
                          className="fontstylerentmodal"
                          style={{ width: "100%" }}
                        >
                          {allVendors?.map((vendor, index) => (
                            <DropdownItem
                              className="fontstylerentmodal"
                              key={index}
                              onClick={() => handleVendorSelect(vendor)}
                            >
                              {vendor.vendor_name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                        {WorkFormik.errors &&
                        WorkFormik.errors?.vendor_name &&
                        WorkFormik.touched &&
                        WorkFormik.touched?.vendor_name &&
                        WorkFormik.values.vendor_name === "" ? (
                          <div
                            className="requiredstylefont"
                            style={{ color: "red" }}
                          >
                            {WorkFormik.errors.vendor_name}
                          </div>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                    <FormGroup
                      className="ml-md-4"
                      style={
                        selectedCategory === "Other"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-member"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Other Category
                      </label>
                      <br />

                      <Input
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                          // width: "60%",
                        }}
                        className="form-control-alternative fontstylerentmodal titleecolor"
                        id="input-work-subject"
                        placeholder="Enter Other Category"
                        type="text"
                        name="work_category"
                        onBlur={WorkFormik.handleBlur}
                        onChange={(e) => {
                          WorkFormik.setFieldValue(
                            "work_category",
                            e.target.value
                          );
                        }}
                        value={WorkFormik.values.work_category}
                      />
                    </FormGroup>
                  </div>

                  <div className="d-flex flex-column flex-md-row justify-content-start">
                    <FormGroup className="mb-4 mb-md-0">
                    <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-desg"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Entry Allowed
                      </label>
                      <br />
                      <Dropdown
                        className="fontstylerentmodal"
                        isOpen={entrydropdownOpen}
                        toggle={toggle4}
                      >
                        <DropdownToggle
                          className="fontstylerentmodal titleecolor"
                          caret
                          style={{
                            width: "100%",
                            boxShadow: " 0px 4px 4px 0px #00000040",
                            border: "1px solid #ced4da",
                            backgroundColor: "transparent",
                          }}
                        >
                          {selectedEntry} &nbsp;&nbsp;&nbsp;&nbsp;
                        </DropdownToggle>
                        <DropdownMenu
                          className="fontstylerentmodal"
                          style={{ width: "100%" }}
                        >
                          <DropdownItem
                            className="fontstylerentmodal"
                            onClick={() => handleEntrySelect("Yes")}
                          >
                            Yes
                          </DropdownItem>
                          <DropdownItem
                            className="fontstylerentmodal"
                            onClick={() => handleEntrySelect("No")}
                          >
                            No
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>

                    <FormGroup className="ml-md-4">
                    <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-desg"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Assigned To *
                      </label>
                      <br />
                      <FormGroup>
                        <Dropdown
                          className="fontstylerentmodal "
                          isOpen={userdropdownOpen}
                          toggle={toggle5}
                        >
                          <DropdownToggle
                            className="fontstylerentmodal titleecolor"
                            caret
                            style={{
                              width: "100%",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                              border: "1px solid #ced4da",
                              backgroundColor: "transparent",
                            }}
                          >
                            {selecteduser ? selecteduser : "Select"}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </DropdownToggle>
                          <DropdownMenu
                            className="fontstylerentmodal"
                            style={{
                              width: "100%",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            <DropdownItem
                              className="fontstylerentmodal"
                              header
                              style={{ color: "blue" }}
                            >
                              Staff
                            </DropdownItem>
                            {staffData.map((user) => (
                              <DropdownItem
                                className="fontstylerentmodal"
                                key={user._id}
                                onClick={() => handleStaffSelect(user)}
                              >
                                {user.staffmember_name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                          {WorkFormik.errors &&
                          WorkFormik.errors?.staffmember_name &&
                          WorkFormik.touched &&
                          WorkFormik.touched?.staffmember_name ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.staffmember_name}
                            </div>
                          ) : null}
                        </Dropdown>
                      </FormGroup>
                    </FormGroup>
                  </div>

                  <div className="">
                    <Row className="mt-2">
                      <Col lg="6">
                        <FormGroup>
                        <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Work To Be Performed
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-name"
                            placeholder="Enter here..."
                            type="textarea"
                            name="work_performed"
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              WorkFormik.handleChange(e);
                            }}
                            value={WorkFormik.values.work_performed}
                          />
                          {WorkFormik.touched.work_performed &&
                          WorkFormik.errors.work_performed ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.work_performed}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card
                className="bg-white shadow mt-3 mb-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <div className="">
                  <label
                      className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                      htmlFor="input-desg"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Parts and Labor
                    </label>
                    <Col lg="12">
                      <FormGroup>
                        <div className="table-responsive">
                          <Table
                            className="table table-bordered"
                            responsive
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid #152B51",
                              overflow: "hidden",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                            }}
                          >
                            <thead className="">
                            <tr
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  border: "1px solid #152B51",
                                }}
                              >
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Qty
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Account
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Description
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Price
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {WorkFormik.values.entries?.map(
                                (entry, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal inputqtyworkorder"
                                        id="input-unitadd"
                                        placeholder="Quantity"
                                        type="text"
                                        name={`entries[${index}].part_qty`}
                                        onChange={(e) =>
                                          handleQuantityChange(e, index)
                                        }
                                        value={entry.part_qty}
                                      />
                                      {WorkFormik.touched.entries &&
                                      WorkFormik.touched.entries[index] &&
                                      WorkFormik.errors.entries &&
                                      WorkFormik.errors.entries[index] &&
                                      WorkFormik.errors.entries[index]
                                        .part_qty ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            WorkFormik.errors.entries[index]
                                              .part_qty
                                          }
                                        </div>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Dropdown
                                        className="fontstylerentmodal"
                                        isOpen={entry.dropdownOpen}
                                        toggle={() => toggleDropdown(index)}
                                      >
                                        <DropdownToggle
                                          className="fontstylerentmodal titleecolor"
                                          caret
                                          style={{
                                            width: "100%",
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040",
                                            border: "1px solid #ced4da",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          {entry.account_type || "Select"}{" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="fontstylerentmodal"
                                          style={{
                                            width: "100%",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Advertising",
                                                index
                                              )
                                            }
                                          >
                                            Advertising
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Association Fees",
                                                index
                                              )
                                            }
                                          >
                                            Association Fees
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Auto and Travel",
                                                index
                                              )
                                            }
                                          >
                                            Auto and Travel
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Bank Fees",
                                                index
                                              )
                                            }
                                          >
                                            Bank Fees
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Cleaning and Maintenance",
                                                index
                                              )
                                            }
                                          >
                                            Cleaning and Maintenance
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Commissions",
                                                index
                                              )
                                            }
                                          >
                                            Commissions
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Depreciation Expense",
                                                index
                                              )
                                            }
                                          >
                                            Depreciation Expense
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Insurance",
                                                index
                                              )
                                            }
                                          >
                                            Insurance
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Legal and Professional Fees",
                                                index
                                              )
                                            }
                                          >
                                            Legal and Professional Fees
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Licenses and Permits",
                                                index
                                              )
                                            }
                                          >
                                            Licenses and Permits
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Management Fees",
                                                index
                                              )
                                            }
                                          >
                                            Management Fees
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Mortgage Interest",
                                                index
                                              )
                                            }
                                          >
                                            Mortgage Interest
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Other Expenses",
                                                index
                                              )
                                            }
                                          >
                                            Other Expenses
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Other Interest Expenses",
                                                index
                                              )
                                            }
                                          >
                                            Other Interest Expenses
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Postage and Delivery",
                                                index
                                              )
                                            }
                                          >
                                            Postage and Delivery
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Repairs",
                                                index
                                              )
                                            }
                                          >
                                            Repairs
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Insurance",
                                                index
                                              )
                                            }
                                          >
                                            Other Expenses
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal"
                                        id="input-unitadd"
                                        placeholder="Description"
                                        type="text"
                                        name={`entries[${index}].description`}
                                        onBlur={WorkFormik.handleBlur}
                                        onChange={WorkFormik.handleChange}
                                        value={entry.description}
                                      />
                                      {WorkFormik.touched.entries &&
                                      WorkFormik.touched.entries[index] &&
                                      WorkFormik.errors.entries &&
                                      WorkFormik.errors.entries[index] &&
                                      WorkFormik.errors.entries[index]
                                        .description ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            WorkFormik.errors.entries[index]
                                              .description
                                          }
                                        </div>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal inputpriceworkorder"
                                        id="input-unitadd"
                                        placeholder="Price"
                                        type="text"
                                        name={`entries[${index}].part_price`}
                                        onChange={(e) =>
                                          handlePriceChange(e, index)
                                        }
                                        value={entry.part_price}
                                        onInput={(e) => {
                                          const inputValue = e.target.value;
                                          const numericValue =
                                            inputValue.replace(/\D/g, "");
                                          e.target.value = numericValue;
                                        }}
                                      />
                                      {WorkFormik.touched.entries &&
                                      WorkFormik.touched.entries[index] &&
                                      WorkFormik.errors.entries &&
                                      WorkFormik.errors.entries[index] &&
                                      WorkFormik.errors.entries[index]
                                        .part_price ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            WorkFormik.errors.entries[index]
                                              .part_price
                                          }
                                        </div>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal inputpriceworkorder"
                                        id="input-unitadd"
                                        placeholder="Total"
                                        type="number"
                                        name={`entries[${index}].total_amount`}
                                        onBlur={WorkFormik.handleBlur}
                                        onChange={WorkFormik.handleChange}
                                        value={entry.total_amount}
                                        disabled // Disable the input
                                      />
                                      {WorkFormik.touched.entries &&
                                      WorkFormik.touched.entries[index] &&
                                      WorkFormik.errors.entries &&
                                      WorkFormik.errors.entries[index] &&
                                      WorkFormik.errors.entries[index]
                                        .total_amount ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            WorkFormik.errors.entries[index]
                                              .total_amount
                                          }
                                        </div>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <ClearIcon
                                        className="addpaymenticonremove"
                                        type="button"
                                        style={{
                                          cursor: "pointer",
                                          padding: 0,
                                        }}
                                        onClick={() => handleRemoveRow(index)}
                                      >
                                        Remove
                                      </ClearIcon>
                                    </td>
                                  </tr>
                                )
                              )}
                              <tr
                                style={{
                                  border: "1px solid #152B51",
                                }}
                              >
                                  <th
                                  className="fontstylerentmodal textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Total
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                ></th>
                                <th
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                ></th>
                                <th
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                ></th>
                                <th
                                  className="fontstylerentmodal textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  {final_total_amount.toFixed(2)}
                                </th>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td
                                  colSpan="4"
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  <Button
                                    type="button"
                                    className="btn fontstylerentmodal background-colorsty bgtextwhite"
                                    onClick={handleAddRow}
                                  >
                                    Add Row
                                  </Button>
                                </td>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </FormGroup>
                    </Col>
                  </div>
                  {/* <div className="pl-lg-2">

                    <Button
                      type="button"
                      className="btn"
                      onClick={handleAddRow}
                      style={{ color: "white", backgroundColor: "#152B51" }}
                    >
                      Add Row
                    </Button>
                  </div> */}
                  <div>
                    <Row className="mt-3">
                      <Col lg="6">
                        <FormGroup>
                        <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Vendor Notes
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-name"
                            placeholder="Enter here..."
                            type="textarea"
                            name="vendor_note"
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              WorkFormik.handleChange(e);
                            }}
                            value={WorkFormik.values.vendor_note}
                          />
                          {WorkFormik.touched.vendor_note &&
                          WorkFormik.errors.vendor_note ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.vendor_note}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <div className="staffworkordermb">
                    <Row>
                      {/* {isDisplay === "true" ? ( 
                        // <Col lg="4">
                        //   <FormGroup>
                        //     <label
                        //       className="form-control-label"
                        //       htmlFor="input-desg"
                        //       style={{
                        //         // marginBottom: "10px",
                        //         fontWeight: "500",
                        //         fontFamily: "Poppins",
                        //         fontSize: "16px",
                        //         color: "#8A95A8",
                        //       }}
                        //     >
                        //       Charge Work To
                        //     </label>
                        //     <br />
                        //     <Dropdown
                        //       isOpen={chargedropdownOpen}
                        //       toggle={toggle7}
                        //     >
                        //       <DropdownToggle
                        //         caret
                        //         style={{
                        //           width: "100%",
                        //           boxShadow: " 0px 4px 4px 0px #00000040",
                        //           border: "1px solid #ced4da",

                        //           backgroundColor: "transparent",
                        //           color: "#A7A7A7",
                        //         }}
                        //       >
                        //         {selectedCharge} &nbsp;&nbsp;&nbsp;&nbsp;
                        //       </DropdownToggle>
                        //       <DropdownMenu style={{ width: "100%" }}>
                        //         <DropdownItem
                        //           onClick={() => handleChargeSelect("Property")}
                        //         >
                        //           Property
                        //         </DropdownItem>
                        //         <DropdownItem
                        //           onClick={() => handleChargeSelect("Tenant")}
                        //         >
                        //           Tenant
                        //         </DropdownItem>
                        //       </DropdownMenu>
                        //       {WorkFormik.touched.work_charge &&
                        //       WorkFormik.errors.work_charge ? (
                        //                                 <div className="requiredstylefont" style={{ color: "red" }}>

                        //           {WorkFormik.errors.work_charge}
                        //         </div>
                        //       ) : null}
                        //     </Dropdown>
                        //   </FormGroup>
                      // </Col>*/}
                      <Col lg="5">
                        <FormGroup
                          className="checkboxbillable"
                          style={{ alignItems: "center" }}
                        >
                            <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="billable"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            <Checkbox
                              id="billable"
                              className="checkboxx"
                              style={{ height: "4px" }}
                              checked={isBillable === true}
                              onChange={handleChange}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#152B51",
                                },
                              }}
                            />
                            Billable To Tenant
                          </label>
                        </FormGroup>
                      </Col>
                      {/* ) : null} */}
                      {isBillable && tenantsDetails.length > 0 ? (
                        <>
                          <Col lg="4">
                            <FormGroup>
                            <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                htmlFor="input-desg"
                                style={{
                                  // marginBottom: "10px",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Tenant
                              </label>
                              <br />
                              <Dropdown
                                className="fontstylerentmodal"
                                isOpen={tenantdownOpen}
                                toggle={toggle8}
                              >
                                <DropdownToggle
                                  className="fontstylerentmodal titleecolor"
                                  caret
                                  style={{
                                    width: "100%",
                                    boxShadow: " 0px 4px 4px 0px #00000040",
                                    border: "1px solid #ced4da",

                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {selectedTenant} &nbsp;&nbsp;&nbsp;&nbsp;
                                </DropdownToggle>
                                <DropdownMenu
                                  className="fontstylerentmodal"
                                  style={{ width: "100%" }}
                                >
                                  {tenantsDetails?.map((item) => (
                                    <DropdownItem
                                      className="fontstylerentmodal"
                                      key={item?.tenant_id}
                                      onClick={() => {
                                        setSelectedTenant(
                                          item?.tenant_firstName +
                                            " " +
                                            item?.tenant_lastName
                                        );
                                        WorkFormik.setFieldValue(
                                          "tenant_name",
                                          item?.tenant_firstName +
                                            " " +
                                            item?.tenant_lastName
                                        );
                                        WorkFormik.setFieldValue(
                                          "tenant_id",
                                          item?.tenant_id
                                        );
                                      }}
                                    >
                                      {item?.tenant_firstName +
                                        " " +
                                        item?.tenant_lastName}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </FormGroup>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                    <Typography
                        className="fontstylerentr titleecolor fontfamilysty"
                        variant="subtitle1"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Priority
                      </Typography>
                      <Grid container spacing={1} justifyContent="flex-start">
                        <Grid item xs={12} sm={3} lg="3" md="2">
                          <FormControlLabel
                            className="addworkordermuilable"
                            control={
                              <Radio
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                                checked={selectedPriority === "High"}
                                onChange={handlePriorityChange}
                                value="High"
                                name="priority"
                              />
                            }
                            label={
                              <Typography
                                sx={{ fontWeight: 600, fontSize: "15px" }}
                              >
                                High
                              </Typography>
                            }
                            sx={{
                              color:
                                selectedPriority === "High"
                                  ? "#152B51"
                                  : "#67758e",
                              fontSize: "14px",
                              "& .Mui-checked": {
                                color:
                                  selectedPriority === "High"
                                    ? "#152B51"
                                    : "#67758e",
                              },
                            }}
                            id="checked"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} lg="4" md="2">
                          <FormControlLabel
                            className="addworkordermuilable"
                            control={
                              <Radio
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                                checked={selectedPriority === "Normal"}
                                onChange={handlePriorityChange}
                                value="Normal"
                                name="priority"
                                id="checked"
                              />
                            }
                            label={
                              <Typography
                                sx={{ fontWeight: 600, fontSize: "15px" }}
                              >
                                Normal
                              </Typography>
                            }
                            sx={{
                              color:
                                selectedPriority === "Normal"
                                  ? "#152B51"
                                  : "#67758e",
                              fontSize: "14px",
                              "& .Mui-checked": {
                                color:
                                  selectedPriority === "Normal"
                                    ? "#152B51"
                                    : "#67758e",
                              },
                            }}
                            id="checked"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} lg="4" md="2">
                          <FormControlLabel
                            className="addworkordermuilable"
                            control={
                              <Radio
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                                checked={selectedPriority === "Low"}
                                onChange={handlePriorityChange}
                                value="Low"
                                name="priority"
                              />
                            }
                            label={
                              <Typography
                                sx={{ fontWeight: 600, fontSize: "15px" }}
                              >
                                Low
                              </Typography>
                            }
                            sx={{
                              color:
                                selectedPriority === "Low"
                                  ? "#152B51"
                                  : "#67758e",
                              fontSize: "14px",
                              "& .Mui-checked": {
                                color:
                                  selectedPriority === "Low"
                                    ? "#152B51"
                                    : "#67758e",
                              },
                            }}
                            id="checked"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <div className="d-flex flex-column flex-md-row justify-content-start">
                    {" "}
                    <FormGroup className="mb-4 mb-md-0">
                    <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-desg"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Status *
                      </label>
                      <br />
                      <FormGroup>
                        <Dropdown
                          className="fontstylerentmodal"
                          isOpen={statusdropdownOpen}
                          toggle={toggle6}
                        >
                          <DropdownToggle
                            className="fontstylerentmodal titleecolor"
                            caret
                            style={{
                              width: "100%",
                              boxShadow: "0px 4px 4px 0px #00000040",
                              border: "1px solid #ced4da",
                              backgroundColor: "transparent",
                            }}
                          >
                            {selectedStatus ? selectedStatus : "Select"}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </DropdownToggle>
                          <DropdownMenu
                            className="fontstylerentmodal"
                            style={{
                              width: "100%",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => handleStatusSelect("New")}
                            >
                              New
                            </DropdownItem>
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => handleStatusSelect("In Progress")}
                            >
                              In Progress
                            </DropdownItem>
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => handleStatusSelect("On Hold")}
                            >
                              On Hold
                            </DropdownItem>
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => handleStatusSelect("Completed")}
                            >
                              Completed
                            </DropdownItem>
                            {/* 6002 */}
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => handleStatusSelect("Closed")}
                            >
                              Closed
                            </DropdownItem>
                          </DropdownMenu>
                          {WorkFormik.errors &&
                          WorkFormik.errors?.status &&
                          WorkFormik.touched &&
                          WorkFormik.touched?.status &&
                          WorkFormik.values.status === "" ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.status}
                            </div>
                          ) : null}
                        </Dropdown>
                      </FormGroup>
                    </FormGroup>{" "}
                    <FormGroup className="ml-md-4">
                    <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-unitadd"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Due Date
                      </label>
                      <br />
                      <Input
                        style={{
                          boxShadow: "0px 4px 4px 0px #00000040",
                          borderRadius: "6px",
                          // maxWidth: "60%", // 60% width on larger screens
                          // margin: "0 auto", // Center align on larger screens
                        }}
                        className="form-control-alternative  duedatestyle fontstylerentmodal titleecolor"
                        id="input-unitadd"
                        type="date"
                        name="due_date"
                        onBlur={WorkFormik.handleBlur}
                        onChange={WorkFormik.handleChange}
                        value={WorkFormik.values.due_date}
                      />
                      {WorkFormik.touched.due_date &&
                      WorkFormik.errors.due_date ? (
                        <div
                          className="requiredstylefont"
                          style={{ color: "red" }}
                        >
                          {WorkFormik.errors.due_date}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>

                  {/* Conditional message */}
                  {!WorkFormik.isValid && (
                    <div
                      className="fontstylerentr"
                      style={{ color: "red", marginTop: "10px" }}
                    >
                      Please fill in all fields correctly.
                    </div>
                  )}

                  <br />
                </CardBody>
              </Card>

              <div
                className=" ml-4 btnstyleworkorder"
                style={{ marginTop: "28px", marginBottom: "20px" }}
              >
                {loader ? (
                  <Button
                  type="submit"
                  className="btn btnwidthworkorder fontstylerentr background-colorsty bgtextwhite"
                  style={{
                    cursor: "not-allowed",
                  }}
                  disabled
                >
                    Loading...
                  </Button>
                ) : id ? (
                  <Button
                  type="submit"
                  className="btn   fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  style={{
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                    onClick={(e) => {
                      e.preventDefault();
                      editworkorder(vid);
                    }}
                  >
                    Update Work Order
                  </Button>
                ) : (
                  <Button
                  type="submit"
                  className="btn  fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  style={{
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                  disabled={!WorkFormik.isValid}
                >
                    Add Work Order
                  </Button>
                )}
                <Button
                  // color="primary"
                    className="btn  fontstylerentr textcolorblue backgroundwhitesty fontfamilysty"
                  onClick={() => {
                    handleCancel();
                  }}
                  size="small"
                  style={{
                    fontWeight: "500",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <ToastContainer />
      </Container>
    </>
  );
};

export default AddStaffWorkorder;
