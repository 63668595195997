import React, { useEffect, useState } from "react";
import StaffHeader from "../../components/Headers/StaffHeader";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { useCookies } from "react-cookie";
import { RotatingLines } from "react-loader-spinner";
import moment from "moment";
import "./Staffdashboard.css";
import ListMapComponent from "../../components/ListMapComponent";
import { Checkbox } from "@mui/material";
import axiosInstance from "axiosInstance";
import editicon from "../../assets/img/icons/common/editicon.svg";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import deleicon from "../../assets/img/icons/common/delete.svg";

const StaffPropertyDashboard = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [rental_adress, setRentalAddress] = useState([]);

  const [search, setSearch] = React.useState(false);
  const [searchQuery2, setSearchQuery2] = useState("");
  let [loader, setLoader] = React.useState(true);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [userroleData, setUserroleData] = useState(null);
  const [upArrow, setUpArrow] = React.useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [mapDisplay, setMapDisplay] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const handleCheckboxChange = (event) => {
    setMapDisplay(event.target.checked);
  };
  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (propertyDetails) {
    paginatedData = propertyDetails.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [accessType, setAccessType] = useState(null);

  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/staffmember/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);
  const getTenantData = async () => {
    if (accessType?.staffmember_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/rentals/${accessType?.admin_id}`
        );
        setPropertyDetails(response.data.data);
        setTotalPages(Math.ceil(response.data.data.length / pageItem) || 1);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
        setLoader(false);
      } finally {
        setLoader(false);
      }
    }
  };
  const [countRes, setCountRes] = useState("");
  const getRentalsLimit = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/limitation/${accessType?.admin_id}`
        );
        setCountRes(response.data);
      } catch (error) {
        console.error("Error fetching rental data:", error);
      }
    }
  };
  useEffect(() => {
    getTenantData();
    getRentalsLimit();
  }, [accessType, pageItem]);

  const navigate = useNavigate();

  const filterTenantsBySearch = () => {
    let filteredData = [...propertyDetails];

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((Rental) => {
        const address = `${Rental?.rental_adress} ${Rental?.rental_city}`;
        return (
          Rental?.rental_adress?.toLowerCase().includes(lowerCaseSearchQuery) ||
          Rental?.property_type_data.property_type
            .toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.property_type_data.propertysub_type
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.rental_city?.toLowerCase().includes(lowerCaseSearchQuery) ||
          Rental?.rental_owner_data.rentalOwner_name
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.rental_owner_data.rentalOwner_companyName
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.rental_owner_data.rentalOwner_primaryEmail
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          address?.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    }
    if (searchQuery2) {
      if (searchQuery2 === "All") {
        return filteredData;
      }
      const lowerCaseSearchQuery = searchQuery2.toLowerCase();
      filteredData = filteredData.filter((property) => {
        const isPropertyTypeMatch = property?.property_type_data?.property_type
          .toLowerCase()
          .includes(lowerCaseSearchQuery);
        const isPropertySubTypeMatch =
          property?.property_type_data?.property_type
            .toLowerCase()
            .includes(lowerCaseSearchQuery);
        return isPropertyTypeMatch || isPropertySubTypeMatch;
      });
    }
    if (upArrow.length > 0) {
      const sortingArrows = upArrow.length > 0 ? upArrow : null;
      sortingArrows.forEach((sort) => {
        switch (sort) {
          case "rental_adress":
            filteredData.sort((a, b) => {
              const comparison = a.rental_adress?.localeCompare(b.rental_adress);
              return upArrow.includes("rental_adress")
                ? comparison
                : -comparison;
            });
            break;
          case "type":
            filteredData.sort((a, b) => {
              const comparison =
                a.property_type_data.property_type?.localeCompare(
                  b.property_type_data.property_type
                );
              return upArrow.includes("type") ? comparison : -comparison;
            });
            break;
          case "property_type":
            filteredData.sort((a, b) => {
              const comparison =
                a.property_type_data.propertysub_type?.localeCompare(
                  b.property_type_data.propertysub_type
                );
              return upArrow.includes("property_type")
                ? comparison
                : -comparison;
            });
            break;
          case "rental_city":
            filteredData.sort((a, b) => {
              const comparison = a.rental_city?.localeCompare(b.rental_city);
              return upArrow.includes("rental_city") ? comparison : -comparison;
            });
            break;
          case "rentalOwner_name":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_name?.localeCompare(
                  b.rental_owner_data.rentalOwner_name
                );
              return upArrow.includes("rentalOwner_name")
                ? comparison
                : -comparison;
            });
            break;
          case "rentalOwner_companyName":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_companyName?.localeCompare(
                  b.rental_owner_data.rentalOwner_companyName
                );
              return upArrow.includes("rentalOwner_companyName")
                ? comparison
                : -comparison;
            });
            break;
          case "rentalOwner_primaryEmail":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_primaryEmail?.localeCompare(
                  b.rental_owner_data.rentalOwner_primaryEmail
                );
              return upArrow.includes("rentalOwner_primaryEmail")
                ? comparison
                : -comparison;
            });
            break;
          case "rentalOwner_phoneNumber":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_phoneNumber -
                b.rental_owner_data.rentalOwner_phoneNumber;
              return upArrow.includes("rentalOwner_phoneNumber")
                ? comparison
                : -comparison;
            });
            break;
          case "createdAt":
            filteredData.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              const comparison = dateA - dateB;
              return upArrow.includes("createdAt") ? comparison : -comparison;
            });

            break;
          case "updatedAt":
            filteredData.sort((a, b) => {
              const comparison = new Date(a.updatedAt) - new Date(b.updatedAt);
              return upArrow.includes("updatedAt") ? comparison : -comparison;
            });
            break;
          case "-rental_adress":
            filteredData.sort((a, b) => {
              const comparison = a.rental_adress?.localeCompare(b.rental_adress);
              return upArrow.includes("-rental_adress")
                ? -comparison
                : comparison;
            });
            break;
          case "-type":
            filteredData.sort((a, b) => {
              const comparison =
                a.property_type_data.property_type?.localeCompare(
                  b.property_type_data.property_type
                );
              return upArrow.includes("-type") ? -comparison : comparison;
            });
            break;
          case "-property_type":
            filteredData.sort((a, b) => {
              const comparison =
                a.property_type_data.propertysub_type?.localeCompare(
                  b.property_type_data.propertysub_type
                );
              return upArrow.includes("-property_type")
                ? -comparison
                : comparison;
            });
            break;
          case "-rental_city":
            filteredData.sort((a, b) => {
              const comparison = a.rental_city?.localeCompare(b.rental_city);
              return upArrow.includes("-rental_city")
                ? -comparison
                : comparison;
            });
            break;
          case "-rentalOwner_name":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_name?.localeCompare(
                  b.rental_owner_data.rentalOwner_name
                );
              return upArrow.includes("-rentalOwner_name")
                ? -comparison
                : comparison;
            });
            break;
          case "-rentalOwner_companyName":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_companyName?.localeCompare(
                  b.rental_owner_data.rentalOwner_companyName
                );
              return upArrow.includes("-rentalOwner_companyName")
                ? -comparison
                : comparison;
            });
            break;
          case "-rentalOwner_primaryEmail":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_primaryEmail?.localeCompare(
                  b.rental_owner_data.rentalOwner_primaryEmail
                );
              return upArrow.includes("-rentalOwner_primaryEmail")
                ? -comparison
                : comparison;
            });
            break;
          case "-rentalOwner_phoneNumber":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_phoneNumber -
                b.rental_owner_data.rentalOwner_phoneNumber;
              return upArrow.includes("-rentalOwner_phoneNumber")
                ? -comparison
                : comparison;
            });
            break;
          case "-createdAt":
            filteredData.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              const comparison = dateA - dateB;
              return upArrow.includes("-createdAt") ? -comparison : comparison;
            });

            break;
          case "-updatedAt":
            filteredData.sort((a, b) => {
              const comparison = new Date(a.updatedAt) - new Date(b.updatedAt);
              return upArrow.includes("-updatedAt") ? -comparison : comparison;
            });
            break;
          default:
            break;
        }
      });
    }

    return filteredData;
  };
  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };
  // const sortData = (value) => {
  //   if (!sortBy.includes(value)) {
  //     setSortBy([...sortBy, value]);
  //     setUpArrow([...upArrow, value]);
  //     filterTenantsBySearchAndPage();
  //   } else {
  //     setSortBy(sortBy.filter((sort) => sort !== value));
  //     setUpArrow(upArrow.filter((sort) => sort !== value));
  //     filterTenantsBySearchAndPage();
  //   }
  // };

  // React.useEffect(() => {
  //   getTenantData();
  // }, [upArrow, sortBy]);

  const sortData = (value) => {
    const isSorting = sortBy.includes(value);

    if (!isSorting) {
      // If not sorting, add the value to both arrays in ascending order
      setSortBy([value]);
      setUpArrow([value]);
    } else {
      // If already sorting, toggle the direction in upArrow array
      const index = upArrow.indexOf(value);
      const newUpArrow = index !== -1 ? [] : [value];

      // If sorting in descending order, add a hyphen to the value
      if (newUpArrow.length === 0) {
        newUpArrow[0] = `-${value}`;
      }

      setUpArrow(newUpArrow);
    }

    filterTenantsBySearchAndPage();
  };

  useEffect(() => {
    getTenantData();
  }, [upArrow, sortBy]);
  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  const deleteRentals = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this rental property!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance
          .delete(`/rentals/rental/${id}`, {
            params: {
              company_name: accessType.company_name,
            },
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Property deleted successfully!", {
                position: "top-center",
                autoClose: 500,
              });
              getTenantData();
              getRentalsLimit();
            } else if (response.data.statusCode === 201) {
              toast.warning("Property already assigned to Tenant!", {
                position: "top-center",
                autoClose: 500,
              });
              getTenantData();
              getRentalsLimit();
            } else {
              toast.error(response.data.message, {
                position: "top-center",
                autoClose: 500,
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting rental property:", error);
          });
      } else {
        toast.success("Property is safe :)", {
          position: "top-center",
          autoClose: 500,
        });
      }
    });
  };

  return (
    <>
      <StaffHeader />
      <Container className="stylecontainer" fluid style={{ marginTop: "4rem" }}>
        <Row>
          {userroleData?.property_add && (
            <Col xs="12" lg="12" sm="12" className="text-right">
              <Button
                className="btnaddstaff"
                // color="primary"
                //  href="#rms"
                onClick={() => {
                  if (countRes.statusCode === 201) {
                    swal(
                      "Plan Limitation",
                      "The limit for adding properties according to the plan has been reached.",
                      "warning"
                    );
                  } else {
                    navigate("/staff/staffpropertyadd");
                  }
                }}
                size="small"
                style={{
                  background: "#152B51",
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Add Property
              </Button>
            </Col>
          )}
          <div className="col">
            <CardHeader
              className=" mt-3 mb-4 headproptypebar "
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="mb-0 headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Properties
              </h2>
            </CardHeader>

            <Row className="d-flex ml-0 mb-3 propertiesaddtotalsty">
              {/* <Col lg="8"> */}
              {/* <Row> */}
              <FormGroup
                className="mr-sm-2 searchspace searchstyle"
                style={{ paddingRight: "10px" }}
              >
                <Input
                  className="filterh"
                  fullWidth
                  type="text"
                  placeholder="Search here..."
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setSearchQuery2("");
                  }}
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    minWidth: "200px",
                    fontWeight: "500",
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                  }}
                />
              </FormGroup>
              <Col className="d-flex justify-content-end addtotnstyle ">
                <FormGroup className="addtotal">
                  <p
                    className="addedtotalstyle fontaddstyle"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Added :{" "}
                    <b style={{ color: "#152B51", fontWeight: 1000 }}>
                      {countRes.rentalCount}
                    </b>{" "}
                    {" / "}
                    Total :{" "}
                    <b style={{ color: "#152B51", fontWeight: 1000 }}>
                      {countRes.propertyCountLimit}
                    </b>
                  </p>
                </FormGroup>
              </Col>
            </Row>
            {/* <Row
              className=" mt-3 d-flex align-items-center py-1"
              style={{ borderRadius: "10px" }}
            >
              <CardHeader className="border-0">
                <Row>
                  <Col xs="12" sm="12" className="d-flex">
                    <FormGroup className="">
                      <Input
                        fullWidth
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          marginTop: "20px",
                          boxShadow: "0px 4px 4px 0px #00000040",
                        }}
                      />
                    </FormGroup>
                    <Checkbox
                      checked={mapDisplay}
                      onChange={handleCheckboxChange}
                      name="map"
                    />
                    <Label
                      size="small"
                      style={{
                        color: "#152B51", marginTop: "24px", fontFamily: "poppins",
                        fontWeight: "600"
                      }}
                    >
                      View Map
                    </Label>
                  </Col>
                </Row>
              </CardHeader>
            </Row>
            {mapDisplay ? (
              <>
                <div
                  className="mb-3"
                  style={{ borderRadius: "20px", overflow: "hidden" }}
                >
                  <ListMapComponent
                    addresses={propertyDetails.map((rental) => [
                      rental.rental_adress,
                      rental.rental_city,
                      rental.rental_state,
                      rental.rental_country,
                      rental.rental_postcode,
                    ])}
                  />
                </div>
              </>
            ) : (
              <></>
            )} */}
            {!loader || rental_adress?.length > 0 ? (
              <div className="table-responsive mt-3">
                <>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    style={{ borderCollapse: "collapse" }}
                    // style={{
                    //   width: "100%",
                    //   border: "1px solid #e5e5e5",
                    //   borderRadius: "8px",
                    //   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    // }}
                  >
                    <thead
                      className=""
                      style={{
                        height: "45px",
                        fontSize: "14px",
                        fontFamily: "poppins",
                        fontWeight: "600",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                      }}
                    >
                      <tr
                        style={{
                          border: "2px solid rgba(50, 69, 103, 1)",
                        }}
                      >
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{
                            borderTopLeftRadius: "15px",
                            color: "#152B51",
                          }}
                        >
                          Property{" "}
                          {sortBy.includes("rental_adress") ? (
                            upArrow.includes("rental_adress") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("rental_adress")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("rental_adress")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{ color: "#152B51" }}
                        >
                          Property Types{" "}
                          {sortBy.includes("type") ? (
                            upArrow.includes("type") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("type")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("type")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon onClick={() => sortData("type")} />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{ color: "#152B51" }}
                        >
                          Property Sub Type{" "}
                          {sortBy.includes("property_type") ? (
                            upArrow.includes("property_type") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("property_type")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("property_type")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("property_type")}
                            />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{ color: "#152B51" }}
                        >
                          Rental Owners Name{" "}
                          {sortBy.includes("rentalOwner_name") ? (
                            upArrow.includes("rentalOwner_name") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("rentalOwner_name")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("rentalOwner_name")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rentalOwner_name")}
                            />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{ color: "#152B51" }}
                        >
                          Rental Company Name{" "}
                          {sortBy.includes("rentalOwner_companyName") ? (
                            upArrow.includes("rentalOwner_companyName") ? (
                              <ArrowDropUpIcon
                                onClick={() =>
                                  sortData("rentalOwner_companyName")
                                }
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() =>
                                  sortData("rentalOwner_companyName")
                                }
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() =>
                                sortData("rentalOwner_companyName")
                              }
                            />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{ color: "#152B51" }}
                        >
                          Locality{" "}
                          {sortBy.includes("rental_city") ? (
                            upArrow.includes("rental_city") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("rental_city")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("rental_city")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_city")}
                            />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{ color: "#152B51" }}
                        >
                          Primary Email{" "}
                          {sortBy.includes("rentalOwner_primaryEmail") ? (
                            upArrow.includes("rentalOwner_primaryEmail") ? (
                              <ArrowDropUpIcon
                                onClick={() =>
                                  sortData("rentalOwner_primaryEmail")
                                }
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() =>
                                  sortData("rentalOwner_primaryEmail")
                                }
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() =>
                                sortData("rentalOwner_primaryEmail")
                              }
                            />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{ color: "#152B51" }}
                        >
                          Phone Number
                          {sortBy.includes("rentalOwner_phoneNumber") ? (
                            upArrow.includes("rentalOwner_phoneNumber") ? (
                              <ArrowDropUpIcon
                                onClick={() =>
                                  sortData("rentalOwner_phoneNumber")
                                }
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() =>
                                  sortData("rentalOwner_phoneNumber")
                                }
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() =>
                                sortData("rentalOwner_phoneNumber")
                              }
                            />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{ color: "#152B51" }}
                        >
                          Created At{" "}
                          {sortBy.includes("createdAt") ? (
                            upArrow.includes("createdAt") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("createdAt")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("createdAt")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("createdAt")}
                            />
                          )}
                        </th>
                        <th
                          className="tablefontstyle"
                          style={{ color: "#152B51" }}
                        >
                          Last Updated At{" "}
                          {sortBy.includes("updatedAt") ? (
                            upArrow.includes("updatedAt") ? (
                              <ArrowDropUpIcon
                                onClick={() => sortData("updatedAt")}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => sortData("updatedAt")}
                              />
                            )
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("updatedAt")}
                            />
                          )}
                        </th>
                        {(userroleData?.property_edit ||
                        userroleData?.property_delete) && (
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                            color: "#152B51",
                          }}
                        >
                          ACTION
                        </th>
                        )}
                      </tr>
                    </thead>
                    {propertyDetails?.length === 0 ? (
                      <tbody>
                        <tr className="text-center">
                          <td colSpan="8" style={{ fontSize: "15px" }}>
                            No Property Added
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr
                          style={{
                            border: "none",
                          }}
                        >
                          {/* Empty row */}
                          <td colSpan="9"></td>
                        </tr>
                        {filterTenantsBySearchAndPage().map(
                          (address, index) => (
                            <>
                              <tr
                                key={index}
                                onClick={() => {
                                    navigate(
                                      // `/staff/staffpropertydetail/1708425958731`
                                      `/staff/staffpropertydetail/${address?.rental_id}`
                                    );
                                }}
                                style={{
                                  cursor: "pointer",
                                  border: "0.5px solid rgba(50, 69, 103, 1)",
                                  fontSize: "12px",
                                  height: "40px",
                                  fontFamily: "poppins",
                                  fontWeight: "600",
                                }}
                              >
                                <td className="bordertopintd tablebodyfont">
                                  {address?.rental_adress}{" "}
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {address?.property_type_data?.property_type}
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {
                                    address?.property_type_data
                                      ?.propertysub_type
                                  }
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {address.rental_owner_data.rentalOwner_name}
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {
                                    address.rental_owner_data
                                      .rentalOwner_companyName
                                  }
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {address?.rental_city}
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {
                                    address.rental_owner_data
                                      .rentalOwner_primaryEmail
                                  }
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {
                                    address.rental_owner_data
                                      .rentalOwner_phoneNumber
                                  }
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {moment(address?.createdAt).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>
                                <td className="bordertopintd tablebodyfont">
                                  {address.updatedAt
                                    ? moment(address.updatedAt).format(
                                        "YYYY-MM-DD"
                                      )
                                    : "-"}
                                </td>
                                {(userroleData?.property_edit ||
                                  userroleData?.property_delete) && (
                                  <td className="bordertopintd tablebodyfont">
                                    <div
                                      style={{ display: "flex", gap: "5px" }}
                                    >
                                      {userroleData?.property_delete && (
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            deleteRentals(address?.rental_id);
                                          }}
                                        >
                                          <img
                                            src={deleicon}
                                            width={20}
                                            height={20}
                                          />
                                        </div>
                                      )}
                                      {userroleData?.property_edit && (
                                        <div
                                          className=""
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(
                                              `/staff/staffpropertyadd/${address?.rental_id}`
                                            );
                                          }}
                                        >
                                          <img
                                            src={editicon}
                                            width={20}
                                            height={20}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            </>
                          )
                        )}
                      </tbody>
                    )}
                  </Table>
                </>
              </div>
            ) : (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            )}
            {paginatedData.length > 0 ? (
              <Row>
                <Col className="text-right mt-3 mb-3">
                  <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                    <DropdownToggle className="paginationbtnstyle" caret>
                      {pageItem}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(10);
                          setCurrentPage(1);
                        }}
                      >
                        10
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(25);
                          setCurrentPage(1);
                        }}
                      >
                        25
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(50);
                          setCurrentPage(1);
                        }}
                      >
                        50
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(100);
                          setCurrentPage(1);
                        }}
                      >
                        100
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>{" "}
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default StaffPropertyDashboard;
