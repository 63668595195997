import StaffDashBoard from "views/source/StaffDashBoard";
import StaffProfile from "views/source/StaffProfile";
import StaffPropertyDashboard from "views/source/StaffPropertyDashboard";
import StaffPropertyDetail from "views/source/StaffPropertyDetail";
import StaffWorkTable from "views/source/StaffWorkTable";
import Staffpropertyadd from "views/source/Staffpropertyadd";
import Staffrentroll from "views/source/Staffrentroll";
import Staffaddrentroll from "views/source/Staffaddrentroll";
import Staffrentrolldetail from "views/source/Staffrentrolldetail";
import Staffaddpayment from "views/source/Staffaddpayment";
import Staffaddcharge from "views/source/Staffaddcharge";
import Staffreports from "views/source/Staffreports";
import StaffRentersInsurance from "views/source/StaffRentersInsurance";
import StaffExpiringLease from "views/source/StaffExpiringLease";
import StaffDelinquentTenants from "views/source/StaffDelinquentTenants";
import StaffOpenWorkReport from "views/source/StaffOpenWorkReport";
import StaffCompletedWorkReport from "views/source/StaffCompletedWorkReport";
import StaffApplicantTable from "views/source/StaffApplicantTable";
import StaffApplicantSummary from "views/source/StaffApplicantSummary";
import StaffTenantDetail from "views/source/StaffTenantDetail";
import StaffEditTenant from "views/source/StaffEditTenant";
import StaffWorkDetails from "views/source/StaffWorkDetails";
import AddStaffWorkorder from "views/source/AddStaffWorkorder";
import StaffTenants from "views/source/StaffTenants";
import StaffPropertytypetable from "views/source/StaffPropertytypetable";
import Staffaddpropertytype from "views/source/Staffaddpropertytype";
import StaffRentalownerTable from "views/source/StaffRentalownerTable";
import StaffAddRentalOwner from "views/source/StaffAddRentalOwner";
import StaffRentalOwnerDetail from "views/source/StaffRentalOwnerDetail";
import StaffVendortable from "views/source/StaffVendortable";
import StaffAddVendor from "views/source/StaffAddVendor";
import StaffSetting from "views/source/StaffSetting";


// ==========================  Super Admin ===================================================

import ResetPassword from "views/source/Resetpassword";
import CreatePassword from "views/source/CreatePassword";

//  ====================== admin sidebar icons ======================
import Dashboard from "./assets/icons/AdminSidebar/dashboard.svg";
import Dashboard2 from "./assets/icons/AdminSidebar/dashboard2.svg";
import Property from "./assets/icons/AdminSidebar/Property.svg";
import Property2 from "./assets/icons/AdminSidebar/Property2.svg";
import Staffmember from "./assets/icons/AdminSidebar/Staffmember.svg";
import Staffmember2 from "./assets/icons/AdminSidebar/Staffmember2.svg";

import Admin from "./assets/icons/Admin.svg";
import admin2 from "./assets/icons/admin2.svg";
import Work from "./assets/icons/Work.svg";
import Work2 from "./assets/icons/Work Light.svg";
import Key from "./assets/icons/AdminSidebar/Key.svg";
import Thumb from "./assets/icons/AdminSidebar/Thumb.svg";
import Maintenance from "./assets/icons/AdminSidebar/Maintenance.svg";
import Key1 from "./assets/icons/AdminSidebar/Key2.svg";
import Thumb1 from "./assets/icons/AdminSidebar/thumbs.svg";
import Maintenance1 from "./assets/icons/AdminSidebar/Maintenancess.svg";
import Building from "./assets/icons/AdminSidebar/Building.svg";
import Building1 from "./assets/icons/AdminSidebar/Building1.svg";
import Owner from "./assets/icons/AdminSidebar/owner.svg";
import Owner1 from "./assets/icons/AdminSidebar/owner1.svg";
import User from "./assets/icons/AdminSidebar/User.svg";
import User1 from "./assets/icons/AdminSidebar/User1.svg";
import Lease from "./assets/icons/AdminSidebar/Lease.svg";
import Lease1 from "./assets/icons/AdminSidebar/Lease1.svg";
import Applicant from "./assets/icons/AdminSidebar/Applicant.svg";
import Applicant1 from "./assets/icons/AdminSidebar/Applicant1.svg";
import Vendors from "./assets/icons/AdminSidebar/Vendor.svg";
import Vendors1 from "./assets/icons/AdminSidebar/Vendor1.svg";
import Works from "./assets/icons/AdminSidebar/Work.svg";
import Works1 from "./assets/icons/AdminSidebar/Work1.svg";
import Report from "./assets/icons/AdminSidebar/Report.svg";
import Report1 from "./assets/icons/AdminSidebar/Report1.svg";

import StaffTenant2 from "./assets/icons/AdminDashboard/vendor-icon.svg";
import StaffTenant1 from "./assets/icons/AdminDashboard/vendor-icon2.svg";
import Staffdailytransactionreport from "views/source/Staffdailytransactionreport";
import Staffrentalownerreport from "views/source/Staffrentalownerreport";
import StaffUpcomingRenewal from "views/source/StaffUpcomingRenewal";
import Upcomingicon from "./assets/icons/AdminSidebar/upcoming renewal.svg"
import Upcomingicon1 from "./assets/icons/AdminSidebar/upcoming white.svg"

var routes = [
  {
    path: "/StaffdashBoard",
    name: "DashBoard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <StaffDashBoard />,
    layout: "/staff",
    is_display: true,
  },
  {
    path: "/staffprofile",
    name: "Profile",
    icon: Admin,
    icon2: admin2,
    component: <StaffProfile />,
    layout: "/staff",
    is_display: true,
  },
  {
    path: "/staffpropertytype",
    name: "Property Type",
    icon: Property,
    icon2: Property2,
    component: <StaffPropertytypetable />,
    layout: "/staff",
    is_display: true,
  },

  {
    name: "Rentals",
    icon: Key,
    icon2: Key1,
    layout: "/staff",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        path: "/staffrentals",
        name: "Properties",
        icon: Building,
        icon2: Building1,
        component: <StaffPropertyDashboard />,
        layout: "/staff",
        is_display: true,
      },
      {
        path: "/staffrentalownertable",
        name: "Rental Owner",
        icon: Owner,
        icon2: Owner1,
        component: <StaffRentalownerTable />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Tenants",
        path: "/stafftenanttable",
        icon: User,
        icon2: User1,
        component: <StaffTenants />,
        layout: "/staff",
        is_display: true,
      },
    ],
  },
  {
    name: "Leasing",
    icon: Thumb,
    icon2: Thumb1,
    layout: "/staff",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        name: "Rent Roll",
        path: "/staffrentroll",
        icon: Lease,
        icon2: Lease1,
        component: <Staffrentroll />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Applicants",
        path: "/staffapplicanttable",
        icon: Applicant,
        icon2: Applicant1,
        component: <StaffApplicantTable />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Upcoming renewal",
        path: "/staffupcomingrenewal",
        icon: Upcomingicon,
        icon2: Upcomingicon1,
        component: <StaffUpcomingRenewal />,
        layout: "/staff",
        is_display: true,
      },
    ],
  },
  {
    name: "Maintenance",
    icon: Maintenance,
    icon2: Maintenance1,
    layout: "/staff",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        name: "Vendor",
        path: "/staffvendor",
        icon: Vendors,
        icon2: Vendors1,
        component: <StaffVendortable />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Work Order",
        path: "/staffworktable",
        icon: Works,
        icon2: Works1,
        component: <StaffWorkTable />,
        layout: "/staff",
        is_display: true,
      },
    ],
  },
  {
    path: "/staffreports",
    name: "Reports",
    icon: Report,
    icon2: Report1,
    component: <Staffreports />,
    layout: "/staff",
    is_display: true,
  },

  {
    path: "/staffaddrentroll",
    name: "StaffAddRentRoll",
    component: <Staffaddrentroll />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentroll/:lease_id",
    name: "StaffAddRentRoll",
    component: <Staffaddrentroll />,
    layout: "/staff",
  },
  {
    path: "/staffrentrolldetail/:lease_id",
    name: "Staffrentrolldetail",
    component: <Staffrentrolldetail />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentroll/:lease_id/:applicant_id",
    name: "StaffAddRentRoll",
    component: <Staffaddrentroll />,
    layout: "/staff",
  },
  {
    path: "/staffapplicantsummary/:id",
    name: "StaffApplicantsummary",
    component: <StaffApplicantSummary />,
    layout: "/staff",
  },
  {
    path: "/stafftenantdetail/:tenant_id",
    name: "Tenant Detail",
    component: <StaffTenantDetail />,
    layout: "/staff",
  },
  {
    path: "/staffedittenant/:tenant_id",
    name: "Tenant Edit",
    component: <StaffEditTenant />,
    layout: "/staff",
  },
  {
    path: "/staffaddtenant",
    name: "Tenant Add",
    component: <StaffEditTenant />,
    layout: "/staff",
  },
  {
    path: "/staffpropertyadd",
    name: "Staff Property Add",
    component: <Staffpropertyadd />,
    layout: "/staff",
  },
  {
    path: "/staffaddpropertytype",
    name: "Staff Add Property Type",
    component: <Staffaddpropertytype />,
    layout: "/staff",
  },
  {
    path: "/staffaddpropertytype/:id",
    name: "Staff Add Property Type",
    component: <Staffaddpropertytype />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentalowner",
    name: "Staff Add Rental Owner",
    component: <StaffAddRentalOwner />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentalowner/:id",
    name: "Staff Add Rental Owner",
    component: <StaffAddRentalOwner />,
    layout: "/staff",
  },
  {
    path: "/staffrentalownerdetail",
    name: "Staff Rental Owner Detail",
    component: <StaffRentalOwnerDetail />,
    layout: "/staff",
  },
  {
    path: "/staffrentalownerdetail/:id",
    name: "Staff Rental Owner Detail",
    component: <StaffRentalOwnerDetail />,
    layout: "/staff",
  },
  {
    path: "/stafftenantdetail/:tenant_id",
    name: "Tenant Detail",
    component: <StaffTenantDetail />,
    layout: "/staff",
  },
  {
    path: "/staffedittenant/:tenant_id",
    name: "Tenant Edit",
    component: <StaffEditTenant />,
    layout: "/staff",
  },
  {
    path: "/staffaddtenant",
    name: "Tenant Add",
    component: <StaffEditTenant />,
    layout: "/staff",
  },
  {
    path: "/staffaddvendor",
    name: "Staff Add Vendor",
    component: <StaffAddVendor />,
    layout: "/staff",
  },
  {
    path: "/staffaddvendor/:vendor_id",
    name: "Staff Add Vendor",
    component: <StaffAddVendor />,
    layout: "/staff",
  },
  {
    path: "/staffpropertyadd/:rental_id",
    name: "Staff Property Edit",
    component: <Staffpropertyadd />,
    layout: "/staff",
  },
  {
    path: "/staffaddpayment/:lease_id",
    name: "Staff Add Payment",
    component: <Staffaddpayment />,
    layout: "/staff",
  },
  {
    path: "/staffaddcharge/:lease_id",
    name: "Staff Add Charge",
    component: <Staffaddcharge />,
    layout: "/staff",
  },
  {
    path: "/staffaddcharge/:lease_id/:charge_id",
    name: "Staff Add Charge",
    component: <Staffaddcharge />,
    layout: "/staff",
  },
  {
    path: "/staffaddpayment/:lease_id/:payment_id",
    name: "Staff Add Payment",
    component: <Staffaddpayment />,
    layout: "/staff",
  },
  {
    path: "/staffpropertydetail/:rental_id",
    name: "Property",
    component: <StaffPropertyDetail />,
    layout: "/staff",
  },
  {
    path: "/staffworktable?status",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <StaffWorkTable />,
    layout: "/staff",
  },
  {
    name: "Staff Renters Insurance",
    path: "/staffrentersinsurance",
    component: <StaffRentersInsurance />,
    layout: "/staff",
  },
  {
    name: "Staff Delinquent Tenants",
    path: "/staffdelinquenttenants",
    component: <StaffDelinquentTenants />,
    layout: "/staff",
  },
  {
    name: "Staff Expiring Lease",
    path: "/staffexpiringleases",
    component: <StaffExpiringLease />,
    layout: "/staff",
  },
  {
    name: "Staff Open Work Orders",
    path: "/staffopenworkorders",
    component: <StaffOpenWorkReport />,
    layout: "/staff",
  },
  {
    name: "Staff Completed Work Orders",
    path: "/staffcompletedWorkorders",
    component: <StaffCompletedWorkReport />,
    layout: "/staff",
  },
  {
    name: "Staff Daily Transaction Report",
    path: "/staffdailytransactionreport",
    component: <Staffdailytransactionreport />,
    layout: "/staff",
  },
  {
    name: "Staff Rental Owner Report",
    path: "/staffrentalownerreport",
    component: <Staffrentalownerreport />,
    layout: "/staff",
  },
  {
    path: "/addworkorder/:id",
    component: <AddStaffWorkorder />,
    layout: "/staff",
  },
  {
    path: "/addworkorder",
    component: <AddStaffWorkorder />,
    layout: "/staff",
  },
  {
    path: "/staffworkdetails/:workorder_id",
    name: "Work Order Detail",
    component: <StaffWorkDetails />,
    layout: "/staff",
  },
  {
    path: "/staffsetting",
    name: "Staff Setting",
    component: <StaffSetting />,
    layout: "/staff",
  },
];

export default routes;
