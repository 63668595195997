import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faHome,
  faBriefcase,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import deleicon from "../../assets/img/icons/common/delete.svg";
import "./rentalowner.css";
import axiosInstance from "axiosInstance";

const StaffAddRentalOwner = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id, admin } = useParams();
  let navigate = useNavigate();

  const [submitLoader, setSubmitLoader] = useState(false);

  const [propertyData, setPropertyData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [isFromDetails, setIsFromDetails] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const location = useLocation();

  const [accessType, setAccessType] = useState(null);

  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/staffmember/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  useEffect(() => {
    if (location?.state?.id) {
      setIsFromDetails(true);
    }
  }, [location.state]);

  const handleCloseButtonClick = () => {
    if (isFromDetails) {
      navigate(`/staff/staffrentalownerdetail/${location.state.id}`);
    } else {
      navigate("/staff/staffrentalownertable");
    }
  };

  useEffect(() => {
    const fetchPropertyData = async () => {
      if (accessType?.admin_id) {
        try {
          const response = await axiosInstance.get(
            `/rentals/rentals/${accessType?.admin_id}`
          );

          setPropertyData(response.data.data);
        } catch (error) {
          console.error("Error fetching countries:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchPropertyData();
  }, []);

  function handleResponse(response) {
    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(
        id
          ? "Rental Owner Updated Successfully"
          : "Rental Owner Added Successfully",
        {
          position: "top-center",
          autoClose: 1000,
        }
      );
      setTimeout(() => {
        navigate(`/staff/staffrentalownertable`);
      }, 2000);
    } else {
      // Show an error toast
      toast.error(response.data.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  let rentalsFormik = useFormik({
    initialValues: {
      rentalOwner_name: "",
      rentalOwner_companyName: "",
      // birth_date: "",
      start_date: "",
      end_date: "",
      rentalOwner_primaryEmail: "",
      rentalOwner_alternateEmail: "",
      rentalOwner_phoneNumber: "",
      rentalOwner_homeNumber: "",
      rentalOwner_businessNumber: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      rentalOwner_comments: "",
      processor_list: [
        {
          processor_id: "",
        },
      ],
      text_identityType: "",
      texpayer_id: "",
      rentalOwner_properties: [],
    },
    validationSchema: yup.object({
      rentalOwner_name: yup.string().required("Required"),
      rentalOwner_primaryEmail: yup.string().required("Required"),
      rentalOwner_phoneNumber: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [processorIds, setProcessorIds] = useState([""]);
  const addProcessorIdInput = () => {
    setProcessorIds([...processorIds, ""]);
    const updatedProcessorList = [
      ...rentalsFormik.values.processor_list,
      { processor_id: "" },
    ];
    rentalsFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const removeProcessorIdInput = (index) => {
    const updatedProcessorIds = [...processorIds];
    updatedProcessorIds.splice(index, 1);
    setProcessorIds(updatedProcessorIds);
    const updatedProcessorList = [...rentalsFormik.values.processor_list];
    updatedProcessorList.splice(index, 1);
    rentalsFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const handleProcessorIdChange = (index, value) => {
    const updatedProcessorIds = [...processorIds];
    updatedProcessorIds[index] = value;
    setProcessorIds(updatedProcessorIds);
    const updatedProcessorList = [...rentalsFormik.values.processor_list];
    updatedProcessorList[index] = { processor_id: value };
    rentalsFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const [rentalOwnerData, setRentalOwnerData] = useState(null);

  useEffect(() => {
    if (id) {
      axiosInstance
        .get(`/rental_owner/rentalowner_details/${id}`)
        .then((response) => {
          const rentalOwnerdata = response.data.data[0];
          setRentalOwnerData(rentalOwnerData);
          setIsLoading(false);
          setSelectedState(rentalOwnerdata.country || "Select");

          rentalsFormik.setValues({
            rentalOwner_name: rentalOwnerdata.rentalOwner_name || "",
            rentalOwner_companyName:
              rentalOwnerdata.rentalOwner_companyName || "",
            // birth_date: formatDate(rentalOwnerdata.birth_date),
            // start_date: formatDate(rentalOwnerdata?.start_date),
            // end_date: formatDate(rentalOwnerdata?.end_date),
            rentalOwner_primaryEmail:
              rentalOwnerdata.rentalOwner_primaryEmail || "",
            rentalOwner_alternateEmail:
              rentalOwnerdata.rentalOwner_alternateEmail || "",
            rentalOwner_phoneNumber:
              rentalOwnerdata.rentalOwner_phoneNumber || "",
            rentalOwner_homeNumber:
              rentalOwnerdata.rentalOwner_homeNumber || "",
            rentalOwner_businessNumber:
              rentalOwnerdata.rentalOwner_businessNumber || "",
            street_address: rentalOwnerdata.street_address || "",
            city: rentalOwnerdata.city || "",
            state: rentalOwnerdata.state || "",
            postal_code: rentalOwnerdata.postal_code || "",
            country: rentalOwnerdata.country || "",
            rentalOwner_comments: rentalOwnerdata.rentalOwner_comments || "",
            text_identityType: rentalOwnerdata.text_identityType || "",
            texpayer_id: rentalOwnerdata.texpayer_id || "",
            rentalOwner_properties:
              rentalOwnerdata.rentalOwner_properties || "",
            processor_list: rentalOwnerdata.processor_list || [],
          });

          const processorIds = rentalOwnerdata.processor_list
            ? rentalOwnerdata.processor_list.map((item) => item.processor_id)
            : [];
          setProcessorIds(processorIds);
        })
        .catch((error) => {
          console.error("Error fetching rental owner data:", error);
          setIsLoading(false);
        });
    }
  }, [id]);

  async function handleSubmit(values) {
    setSubmitLoader(true);
    try {
      values["admin_id"] = accessType?.admin_id;
      const processorIds = values.processor_id;

      if (processorIds) {
        // If processorIds is defined, proceed with processing it
        delete values.processor_id;
        values.processor_list = processorIds.map((processor_id) => ({
          processor_id,
        }));
      }

      if (id === undefined) {
        const res = await axiosInstance.post(
          `/rental_owner/rental_owner`,
          values
        );
        handleResponse(res);
      } else {
        const editUrl = `/rental_owner/rental_owner/${id}`;
        const res = await axiosInstance.put(editUrl, values);
        handleResponse(res);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  return (
    <>
      <style>
        {`
    .custom-date-picker {
      background-color: white;
    }
  `}
      </style>
      {/* <RentalownerHeder /> */}
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3  headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {id ? "Edit Rental Owner" : "Add Rental Owner"}
            </h2>
          </CardHeader>
        </Col>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="bg-white shadow mt-3 mx-3 cardstyborderradius "
              style={{
                boxShadow: " 0px 4px 4px 0px #00000040",
                border: "1px solid #324567",
              }}
            >
              <CardBody>
                <Form role="form">
                  <h6 className="heading-small text-muted mb-1"></h6>

                  <div className="mt-2">
                    <FormGroup>
                      <label
                        className="form-control-label labelfontstyle"
                        htmlFor="input-property"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                        }}
                      >
                        Personal Information
                      </label>
                      <br></br>

                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Contact Name
                            </label>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "60%",
                              }}
                              type="text"
                              id="rentalOwner_name"
                              placeholder="Enter name"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.rentalOwner_name}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_name &&
                          rentalsFormik.errors.rentalOwner_name ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {rentalsFormik.errors.rentalOwner_name}
                            </div>
                          ) : null}
                        </Col>
                        {/* <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                color: "#8A95A8",
                              }}
                            >
                              Last Name
                            </label>
                            <Input
                              className="inputstyle fontstylerentmodal"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "60%",
                              }}
                              type="text"
                              id="rentalOwner_lastName"
                              placeholder="Enter last name"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.rentalOwner_lastName}
                            />
                            {rentalsFormik.touched.rentalOwner_lastName &&
                              rentalsFormik.errors.rentalOwner_lastName ? (
                              <div className="requiredstylefont" style={{ color: "red" }}>
                                {rentalsFormik.errors.rentalOwner_lastName}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col> */}
                      </Row>
                      <Row className="mt-2">
                        <Col lg={4}>
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-address"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Company Name
                            </label>

                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "60%",
                              }}
                              type="text"
                              id="rentalOwner_companyName"
                              placeholder="Enter company name"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={rentalsFormik.handleChange}
                              value={
                                rentalsFormik.values.rentalOwner_companyName
                              }
                            />
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_companyName &&
                          rentalsFormik.errors.rentalOwner_companyName ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {rentalsFormik.errors.rentalOwner_companyName}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      {/* <Row className="mt-3">
                        <Col lg="3">
                          <FormGroup
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label
                              className="form-control-label fontstylerentr"
                              htmlFor="input-unitadd"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                color: "#8A95A8",
                              }}
                            >
                              Date of Birth*
                            </label>
                            <Input
                              className="inputstyle fontstylerentmodal"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              id="birth_date"
                              placeholder="Date Of Birth"
                              type="date"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.birth_date}
                            />
                            {rentalsFormik.touched.birth_date &&
                              rentalsFormik.errors.birth_date ? (
                              <div className="requiredstylefont" style={{ color: "red" }}>
                                {rentalsFormik.errors.birth_date}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row> */}
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="bg-white shadow mt-3 mx-3 cardstyborderradius"
              style={{
                boxShadow: " 0px 4px 4px 0px #00000040",
                border: "1px solid #324567",
              }}
            >
              <CardBody>
                <Form role="form">
                  <h6 className="heading-small text-muted mb-1"></h6>
                  <div className="">
                    <FormGroup>
                      <label
                        className="form-control-label labelfontstyle"
                        htmlFor="input-address"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                        }}
                      >
                        Merchant ID
                      </label>
                      <br />
                      <label
                        className="form-label fontstylerentr titleecolor"
                        htmlFor={`input-unitadd`}
                        style={{
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                        }}
                      >
                        Processor Id
                      </label>
                      <br />
                      {/* {processorIds.map((processorId, index) => ( */}
                      {/* <div
                          className="d-flex flex-row justify-content-start align-items-center"
                          key={index}
                        >
                          <FormGroup>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              id={`processor_id_${index}`}
                              placeholder="Enter processor id"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={(e) =>
                                handleProcessorIdChange(index, e.target.value)
                              }
                              value={processorId}
                            />
                          </FormGroup>
                          {index >= 0 && (
                            <div
                              style={{ cursor: "pointer" }}
                              className="mx-2 mb-2"
                              onClick={() => removeProcessorIdInput(index)}
                            >
                              <img
                                className="propertyiconsrentals"
                                src={deleicon}
                                width={20}
                                height={20}
                              />
                            </div>
                          )}
                          {/* </Col> */}
                      {/* </div>  */}
                      {/* // ))} */}
                      {processorIds.length <= 1 ? (
                        <div className="d-flex flex-row justify-content-start align-items-center">
                          <FormGroup style={{ width: "20%" }}>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              placeholder="Enter processor id"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={(e) =>
                                rentalsFormik.setFieldValue(
                                  "processor_list",
                                  [
                                    {
                                      processor_id: e.target.value,
                                    },
                                  ]
                                )
                              }
                           value={rentalsFormik.values.processor_list[0]?.processor_id || ""}
                            />
                          </FormGroup>
                        </div>
                      ) : (
                        processorIds.map((processorId, index) => (
                          <div
                            className="d-flex flex-row justify-content-start align-items-center"
                            key={index}
                          >
                            <FormGroup
                              style={{ width: "20%", marginRight: "10px" }}
                            >
                              <Input
                                className="inputstyle fontstylerentmodal titleecolor"
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                  width: "80%",
                                }}
                                id={`processor_id_${index}`}
                                placeholder="Enter processor id"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={(e) =>
                                  handleProcessorIdChange(index, e.target.value)
                                }
                                value={processorId}
                              />
                            </FormGroup>
                            <div
                              style={{ cursor: "pointer" }}
                              className="mx-2 mb-2"
                              onClick={() => removeProcessorIdInput(index)}
                            >
                              <img
                                className="propertyiconsrentals"
                                src={deleicon}
                                width={20}
                                height={20}
                                alt="Delete Icon"
                              />
                            </div>
                          </div>
                        ))
                      )}
                      {/* <Button
                        className="fontstylerentr"
                        style={{
                          background: "#152B51",
                          color: "white",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        size="sm"
                        onClick={addProcessorIdInput}
                      >
                        Add Another
                      </Button> */}
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="bg-white shadow mt-3 mx-3 cardstyborderradius"
              style={{
                boxShadow: " 0px 4px 4px 0px #00000040",
                border: "1px solid #324567",
              }}
            >
              <CardBody>
                <Form role="form">
                  <h6 className="heading-small text-muted mb-1"></h6>
                  <div className="">
                    <FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label labelfontstyle"
                          htmlFor="input-address"
                          style={{
                            // marginBottom: "10px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#152B51",
                          }}
                        >
                          Managment Agreement
                        </label>
                        <br></br>
                        <Row>
                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr titleecolor"
                                htmlFor="input-unitadd"
                                style={{
                                  // marginBottom: "10px",
                                  fontWeight: "500",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                }}
                              >
                                Start Date
                              </label>
                              <br />

                              <Input
                                className="inputstyle fontstylerentmodal titleecolor"
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                  width: "80%",
                                }}
                                id="start_date"
                                placeholder="Start Date"
                                type="date"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={rentalsFormik.values.start_date}
                              />
                            </FormGroup>
                            {rentalsFormik.touched.start_date &&
                            rentalsFormik.errors.start_date ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {rentalsFormik.errors.start_date}
                              </div>
                            ) : null}
                          </Col>

                          <Col lg="2">
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-unitadd"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              End Date
                            </label>
                            <br />
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              id="end_date"
                              placeholder="End Date"
                              type="date"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.end_date}
                            />
                            {rentalsFormik.touched.end_date &&
                            rentalsFormik.errors.end_date ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {rentalsFormik.errors.end_date}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </FormGroup>
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="bg-white shadow mt-3 mx-3 cardstyborderradius"
              style={{
                boxShadow: " 0px 4px 4px 0px #00000040",
                border: "1px solid #324567",
              }}
            >
              <CardBody>
                <Form role="form">
                  <h6 className="heading-small text-muted mb-1"></h6>
                  <div className="">
                    <FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label labelfontstyle"
                          htmlFor="input-address"
                          style={{
                            // marginBottom: "10px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            color: "#152B51",
                          }}
                        >
                          Contact Information
                        </label>
                        <br></br>
                      </FormGroup>
                      <Row>
                        <Col lg="3">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Primary E-mail *
                            </label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </FormGroup>

                          <FormGroup
                            className="inputstyle "
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_primaryEmail"
                                placeholder="Enter primary email"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={
                                  rentalsFormik.values.rentalOwner_primaryEmail
                                }
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_primaryEmail &&
                          rentalsFormik.errors.rentalOwner_primaryEmail ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {rentalsFormik.errors.rentalOwner_primaryEmail}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="3" className="rentalownerstyleinput">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Alternative E-mail
                            </label>
                            &nbsp;
                          </FormGroup>

                          <FormGroup
                            className="inputstyle"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_alternateEmail"
                                placeholder="Enter alternative email"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={
                                  rentalsFormik.values
                                    .rentalOwner_alternateEmail
                                }
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_alternateEmail &&
                          rentalsFormik.errors.rentalOwner_alternateEmail ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {rentalsFormik.errors.rentalOwner_alternateEmail}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <Col></Col>
                      <Row className="mt-2">
                        <Col lg="3">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Phone Number *
                            </label>
                          </FormGroup>
                          <FormGroup
                            className="inputstyle"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faPhone} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_phoneNumber"
                                placeholder="Enter phone number"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={
                                  rentalsFormik.values.rentalOwner_phoneNumber
                                }
                                // onInput={(e) => {
                                //   const inputValue = e.target.value;
                                //   const numericValue = inputValue.replace(
                                //     /\D/g,
                                //     ""
                                //   ); // Remove non-numeric characters
                                //   e.target.value = numericValue;
                                // }}
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_phoneNumber &&
                          rentalsFormik.errors.rentalOwner_phoneNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.rentalOwner_phoneNumber}
                            </div>
                          ) : null}
                        </Col>
                        {/* <br /> */}
                        <Col lg="3" className="rentalownerstyleinput">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-homenumber"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Home Number
                            </label>
                          </FormGroup>
                          <FormGroup
                            className="inputstyle"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faHome} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_homeNumber"
                                placeholder="Enter home number"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={
                                  rentalsFormik.values.rentalOwner_homeNumber
                                }
                                // onInput={(e) => {
                                //   const inputValue = e.target.value;
                                //   const numericValue = inputValue.replace(
                                //     /\D/g,
                                //     ""
                                //   ); // Remove non-numeric characters
                                //   e.target.value = numericValue;
                                // }}
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_homeNumber &&
                          rentalsFormik.errors.rentalOwner_homeNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.rentalOwner_homeNumber}
                            </div>
                          ) : null}
                        </Col>
                        {/* <br /> */}
                        <Col lg="3" className="rentalownerstyleinput">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Office Number
                            </label>
                          </FormGroup>
                          <FormGroup
                            className="inputstyle "
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faBriefcase} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_businessNumber"
                                placeholder="Enter office number"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={
                                  rentalsFormik.values
                                    .rentalOwner_businessNumber
                                }
                                // onInput={(e) => {
                                //   const inputValue = e.target.value;
                                //   const numericValue = inputValue.replace(
                                //     /\D/g,
                                //     ""
                                //   ); // Remove non-numeric characters
                                //   e.target.value = numericValue;
                                // }}
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_businessNumber &&
                          rentalsFormik.errors.rentalOwner_businessNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.rentalOwner_businessNumber}
                            </div>
                          ) : null}
                        </Col>
                        {/* <br /> */}
                      </Row>
                      {/* </FormGroup> */}

                      <Row className="mt-2">
                        <Col lg="3">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Street Address
                            </label>
                          </FormGroup>
                          <Input
                            className="inputstyle fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            type="textarea"
                            id="street_address"
                            placeholder="Enter address "
                            onBlur={rentalsFormik.handleBlur}
                            onChange={rentalsFormik.handleChange}
                            value={rentalsFormik.values.street_address}
                          />
                          {rentalsFormik.touched.street_address &&
                          rentalsFormik.errors.street_address ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.street_address}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <br></br>

                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-city"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              City
                            </label>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              type="text"
                              id="city"
                              placeholder="Enter city"
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.city}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.city &&
                          rentalsFormik.errors.city ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.city}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-state"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              State
                            </label>

                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              type="text"
                              id="state"
                              placeholder="Enter state"
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.state}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.state &&
                          rentalsFormik.errors.state ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.state}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-country"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Country
                            </label>

                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              type="text"
                              id="country"
                              placeholder="Enter country"
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.country}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.country &&
                          rentalsFormik.errors.country ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.country}
                            </div>
                          ) : null}
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor"
                              htmlFor="input-zip"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Postal Code
                            </label>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              type="text"
                              id="postal_code"
                              placeholder="Enter postal code"
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.postal_code}
                              onInput={(e) => {
                                const inputValue = e.target.value;
                                const numericValue = inputValue.replace(
                                  /\D/g,
                                  ""
                                );
                                e.target.value = numericValue;
                              }}
                              // style={{ width: "235px" }}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.postal_code &&
                          rentalsFormik.errors.postal_code ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.postal_code}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="bg-white shadow mt-3 mx-3 cardstyborderradius mb-2"
              style={{
                boxShadow: " 0px 4px 4px 0px #00000040",
                border: "1px solid #324567",
              }}
            >
              <CardBody>
                <Form role="form">
                  <div className="">
                    <FormGroup>
                      <label
                        className="form-control-label labelfontstyle"
                        htmlFor="input-address"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                        }}
                      >
                        Tax Payer Information
                      </label>
                      <br></br>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col lg="3">
                          <label
                            className="form-control-label fontstylerentr titleecolor"
                            htmlFor="input-tax"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                            }}
                          >
                            Tax Identity Type
                          </label>

                          <Input
                            className="inputstyle fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            type="text"
                            id="text_identityType"
                            placeholder="Enter tax identify type"
                            onChange={rentalsFormik.handleChange}
                            value={rentalsFormik.values.text_identityType}
                          />
                          {rentalsFormik.touched.text_identityType &&
                          rentalsFormik.errors.text_identityType ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.text_identityType}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="3">
                          <label
                            className="form-control-label spacetop fontstylerentr titleecolor"
                            htmlFor="input-taxpayer_id"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                            }}
                          >
                            Taxpayer Id
                          </label>

                          <Input
                            className="inputstyle fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            type="text"
                            id="texpayer_id"
                            placeholder="Enter SSN or EIN"
                            onChange={rentalsFormik.handleChange}
                            value={rentalsFormik.values.texpayer_id}
                          />
                          {rentalsFormik.touched.texpayer_id &&
                          rentalsFormik.errors.texpayer_id ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.texpayer_id}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                  <br />
                  {/* <FormGroup>
                    <Row>
                      <Col lg="4" className="ml-lg-4">
                        <label
                          className="form-control-label"
                          htmlFor="input-taxpayer_id"
                        >
                          Taxpayer Id
                        </label>

                        <Input
                          type="text"
                          id="texpayer_id"
                          placeholder="Enter SSN or EIN....."
                          onChange={rentalsFormik.handleChange}
                          value={rentalsFormik.values.texpayer_id}
                        />
                        {rentalsFormik.touched.texpayer_id &&
                          rentalsFormik.errors.texpayer_id ? (
                          <div
                            style={{
                              color: "red",
                            }}
                          >
                            {rentalsFormik.errors.texpayer_id}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup> */}
                  {/* <hr />
                  <Col>
                    <FormGroup>
                      <div className="pl-lg-2">
                        <label
                          className="form-control-label"
                          htmlFor="input-rental properties"
                        >
                          Rental properties owned
                        </label>
                        <br />
                        Select the Propertits owned by this rental owner:
                      </div>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <div className="pl-lg-2">
                        <label
                          className="form-control-label"
                          htmlFor="input-property"
                        >
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=" Search"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </div>
                        </label>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <div className="pl-lg-2">
                        <input
                          type="checkbox"
                          style={{
                            transform: "scale(1.5)",
                            marginRight: "10px",
                          }}
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                        />
                        <label
                          className="form-control-label ml-2"
                          htmlFor="input-select"
                        >
                          Select All
                        </label>
                      </div>
                    </FormGroup>
                  </Col>

                  <Row>
                    <Col lg="4" className="ml-lg-4">
                      <div className="pl-lg-2">
                        <FormGroup>
                          {filterRentalsBySearch().length > 0 ? (
                            filterRentalsBySearch().map((property) => (
                              <div key={property.rental_id}>
                                <label>
                                  <input
                                    type="checkbox"
                                    style={{
                                      transform: "scale(1.5)",
                                      marginRight: "10px",
                                    }}
                                    value={property.rental_id}
                                    checked={selectedProperties.includes(
                                      property.rental_id
                                    )}
                                    onChange={() =>
                                      handlePropertyCheckboxChange(
                                        property.rental_id
                                      )
                                    }
                                    onBlur={rentalsFormik.handleBlur}
                                  />
                                  {property.rental_adress}{" "}
                                  {property?.property_type_data?.property_type
                                    ? `(${property?.property_type_data?.property_type})`
                                    : ""}
                                </label>
                              </div>
                            ))
                          ) : (
                            <p>Loading...</p>
                          )}
                        </FormGroup>
                      </div>
                    </Col>

                    <Col lg="4" className="ml-lg-4">
                      <div className="selected-properties">
                        {selectedProperties.length > 0 ? (
                          <label
                            className="form-control-label"
                            htmlFor="input-property"
                          >
                            Selected Properties:
                          </label>
                        ) : null}
                        <ul>
                          {selectedProperties.map((selectedPropertyId) => {
                            const selectedProperty = propertyData.find(
                              (property) =>
                                property.rental_id === selectedPropertyId
                            );
                            return (
                              <li key={selectedPropertyId}>
                                {selectedProperty?.rental_adress}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Col>
                  </Row> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="ml-4 mt-3 mb-3">
          <Col>
            <Row>
              {submitLoader ? (
                <Button
                  type="submit"
                  className="btn bottomspace fontstylerentr"
                  style={{ background: "#152B51", color: "white" }}
                  disabled
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  style={{
                    background: "#152B51",
                    color: "white",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  className="btn bottomspace fontstylerentr"
                  onClick={(e) => {
                    e.preventDefault();
                    rentalsFormik.handleSubmit();
                  }}
                  disabled={!rentalsFormik.isValid}
                >
                  {id ? "Update Rental Owner" : "Add Rental Owner"}
                </Button>
              )}
              <Button
                className="btn fontstylerentr"
                onClick={() => {
                  handleCloseButtonClick();
                }}
                size="small"
                style={{ background: "white", color: "#152B51" }}
              >
                Cancel
              </Button>
              <div className="pl-lg-4">
                {!rentalsFormik.isValid && (
                  <div
                    className="fontstylerentr"
                    style={{ color: "red", marginTop: "10px" }}
                  >
                    Please fill in all fields correctly.
                  </div>
                )}
              </div>
            </Row>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default StaffAddRentalOwner;
