import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useFormik } from "formik";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from 'react-cookie';
import axiosInstance from "axiosInstance";

function CreditCardForm(props) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { closeModal, admin_id } = props;
  const { id, admin } = useParams();
  const navigate = useNavigate();

  const StaffMemberFormik = useFormik({
    initialValues: {
      staffmember_name: "",
      staffmember_designation: "",
      staffmember_phoneNumber: "",
      staffmember_email: "",
      staffmember_password: "",
    },
    validationSchema: yup.object({
      staffmember_name: yup.string().required("Name is Required"),
      staffmember_designation: yup.string().required("Designation Required"),
      staffmember_email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      staffmember_phoneNumber: yup.number().required("Number is Required"),
      staffmember_password: yup
        .string()
        .required("No Password Provided")
        .min(8, "Password is too short")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must contain one uppercase, one lowercase, one number, and one special character"
        ),
    }),
    onSubmit: async (values) => {
      const object = {
        admin_id: admin_id,
        staffmember_name: values.staffmember_name,
        staffmember_designation: values.staffmember_designation,
        staffmember_phoneNumber: values.staffmember_phoneNumber,
        staffmember_email: values.staffmember_email,
        staffmember_password: values.staffmember_password,
      };
      try {
        if (id === undefined) {
          const res = await axiosInstance.post(
            `/staffmember/staff_member`,
            object
          );
          if (res.data.statusCode === 200) {
            handleResponse(res);
          } else if (res.data.statusCode === 201) {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } else {
          const editUrl = `/staffmember/staff_member/${id}`;
          const res = await axiosInstance.put(editUrl, object);
          if (res.data.statusCode === 200) {
            handleResponse(res);
          } else if (res.data.statusCode === 400) {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        }
        closeModal(); // Added closeModal() function call to close the modal after successful form submission.
      } catch (error) {
        console.error("Error:", error);
        if (error.response) {
          console.error("Response Data:", error.response.data);
        }
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  async function handleSubmit(values) {
    const object = {
      admin_id: admin_id,
      staffmember_name: values.staffmember_name,
      staffmember_designation: values.staffmember_designation,
      staffmember_phoneNumber: values.staffmember_phoneNumber,
      staffmember_email: values.staffmember_email,
      staffmember_password: values.staffmember_password,
    };
    try {
      if (id === undefined) {
        const res = await axiosInstance.post(
          `/staffmember/staff_member`,
          object
        );
        if (res.data.statusCode === 200) {
          handleResponse(res);
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/staffmember/staff_member/${id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          handleResponse(res);
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
      // Handle the error and display an error message to the user if necessary.
    }
  }

  function handleResponse(response) {
    const successMessage = id
      ? "Staff Member updated successfully"
      : "Staff Member added successfully";
    const errorMessage = response.data.message;

    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 1000,
        // onClose: () => navigate(`/${admin}/PropertyType`),
      });
    } else {
      // Show an error toast
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  const paymentSchema = yup.object({
    card_number: yup
      .number(),
    exp_date: yup
      .string()
      .matches(/^(0[1-9]|1[0-2])\/[0-9]{4}$/, "Invalid date format (MM/YYYY)")
      .required("Required"),
  });

  return (
    <div>
      <Formik
        initialValues={{ card_number: "", exp_date: "" }}
        validationSchema={paymentSchema}
        onSubmit={(values, { resetForm }) => {
          if (paymentSchema.isValid) {
            handleSubmit(values);
            resetForm();
          }
        }}
      >
        <Form onSubmit={StaffMemberFormik.handleSubmit}>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label fontstylerentr titleecolor" htmlFor="input-member" style={{ fontFamily: "Poppins", fontSize: "16px", fontweight: "500",  }}
                  >
                    Staff Member Name *
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    id="input-staffmember-name"
                    placeholder="Enter staff member name"
                    type="text"
                    name="staffmember_name"
                    //name="nput-staffmember-name"
                    onBlur={StaffMemberFormik.handleBlur}
                    onChange={(e) => {
                      // Update the state or Formik values with the new input value
                      StaffMemberFormik.handleChange(e);
                    }}
                    value={StaffMemberFormik.values.staffmember_name.trim()}
                  //required
                  />
                  {StaffMemberFormik.touched.staffmember_name &&
                    StaffMemberFormik.errors.staffmember_name ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>

                      {StaffMemberFormik.errors.staffmember_name}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="pl-lg-4">
            <Row className="mt-2">
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label fontstylerentr titleecolor" htmlFor="input-desg" style={{ fontFamily: "Poppins", fontSize: "16px", fontweight: "500", }}
                  >
                    Designation
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    id="input-staffmember-desg"
                    placeholder="Enter Designation"
                    type="text"
                    name="staffmember_designation"
                    onBlur={StaffMemberFormik.handleBlur}
                    onChange={StaffMemberFormik.handleChange}
                    value={StaffMemberFormik.values.staffmember_designation.trim()}
                  />
                  {StaffMemberFormik.touched.staffmember_designation &&
                    StaffMemberFormik.errors.staffmember_designation ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>

                      {StaffMemberFormik.errors.staffmember_designation}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label fontstylerentr titleecolor" htmlFor="input-desg" style={{ fontFamily: "Poppins", fontSize: "16px", fontweight: "500", }}>
                    Phone Number *
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    id="staffmember_phoneNumber"
                    placeholder="Enter phone number"
                    type="text"
                    name="staffmember_phoneNumber"
                    onBlur={StaffMemberFormik.handleBlur}
                    onChange={StaffMemberFormik.handleChange}
                    value={StaffMemberFormik.values.staffmember_phoneNumber}
                    //required
                    onInput={(e) => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
                      e.target.value = numericValue;
                    }}
                  />
                  {StaffMemberFormik.touched.staffmember_phoneNumber &&
                    StaffMemberFormik.errors.staffmember_phoneNumber ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>

                      {StaffMemberFormik.errors.staffmember_phoneNumber}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="pl-lg-4">
            <Row className="mt-2">
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label fontstylerentr titleecolor" htmlFor="input-desg" style={{ fontFamily: "Poppins", fontSize: "16px", fontweight: "500", }}>
                    Email *
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    id="staffmember_email"
                    placeholder="Enter email"
                    type="email"
                    name="staffmember_email"
                    onBlur={StaffMemberFormik.handleBlur}
                    onChange={StaffMemberFormik.handleChange}
                    value={StaffMemberFormik.values.staffmember_email.toLowerCase()}
                  //required
                  />
                  {StaffMemberFormik.touched.staffmember_email &&
                    StaffMemberFormik.errors.staffmember_email ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>

                      {StaffMemberFormik.errors.staffmember_email}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label fontstylerentr titleecolor" htmlFor="input-desg" style={{ fontFamily: "Poppins", fontSize: "16px", fontweight: "500", }}>
                    Password *
                  </label>
                  <div style={{ display: "flex" }}>
                    <Input
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                      className="form-control-alternative fontstylerentmodal titleecolor"
                      id="staffmember_password"
                      placeholder="Password"
                      name="staffmember_password"
                      type={showPassword ? "text" : "password"}
                      onBlur={StaffMemberFormik.handleBlur}
                      onChange={StaffMemberFormik.handleChange}
                      value={StaffMemberFormik.values.staffmember_password}
                    //required
                    />
                    <Button
                      type="button"
                      style={{ padding: "7px" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {<VisibilityIcon />}
                    </Button>
                  </div>
                  {StaffMemberFormik.touched.staffmember_password &&
                    StaffMemberFormik.errors.staffmember_password ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>

                      {StaffMemberFormik.errors.staffmember_password}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Row className="mt-3 ml-4 staffrentalsbtn">
            <Button
              type="submit"
              className="btn fontstylerentr "
              style={{ background: "#152B51", color: "white", fontFamily: "Poppins", fontWeight: "400", fontSize: "14px", cursor: "pointer", }}

            >
              {id ? "Update Staff Member" : "Add Staff Member"}
            </Button>
            <Button
              // color="primary"
              //  href="#rms"
              className="btn fontstylerentr "
              onClick={closeModal}
              size="small"
              style={{
                background: "white", color: "#152B51",
                cursor: "pointer",
              }}
            >
              Cancel
            </Button>
          </Row>
          {/* <Row>
            <Button
              type="submit"
              className="btn btn-primary ml-4"
              style={{ background: "green", color: "white" }}
            >
              {id ? "Update Property Type" : "Add Property Type"}
            </Button>
          </Row> */}
        </Form>
      </Formik>
      <ToastContainer />
    </div>
  );
}

export default CreditCardForm;
