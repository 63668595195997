import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import AddVendorHeader from "components/Headers/AddVendorHeader";
import Cookies from "universal-cookie";
import { useCookies } from "react-cookie";
import "./vendor.css";
import axiosInstance from "axiosInstance";

const StaffAddVendor = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  // Initialize variables and state
  const navigate = useNavigate();
  const { vendor_id, admin } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [vendorData, setVendorData] = useState(null);

  // Define validation schema for form fields
  const validationSchema = yup.object({
    vendor_password: yup
      .string()
      .min(8, "Password is too short")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password is required"),

    vendor_email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  // Initialize formik form
  const VendorFormik = useFormik({
    initialValues: {
      admin_id: "",
      vendor_name: vendorData?.vendor_name || "",
      vendor_phoneNumber: vendorData?.vendor_phoneNumber || "",
      vendor_email: vendorData?.vendor_email || "",
      vendor_password: vendorData?.vendor_password || "",
    },
    validationSchema: yup.object({
      vendor_name: yup.string().required("Requied"),
      vendor_phoneNumber: yup.number().required("Requied"),
      vendor_email: yup
        .string()
        .required("Requied")
        .email("Invalid email address")
        .required("Email is required"),
      vendor_password: yup
        .string()
        .min(8, "Password is too short")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain One Uppercase, One Lowercase, One Number, and one special case Character"
        ),
      // .required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  // State to hold vendor data fetched from the API
  const [isLoading, setIsLoading] = useState(true);
  const [accessType, setAccessType] = useState(null);

  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/staffmember/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  // Fetch vendor data if editing an existing vendor
  useEffect(() => {
    if (vendor_id) {
      axiosInstance
        .get(`/vendor/get_vendor/${vendor_id}`)
        .then((response) => {
          const vendorData = response.data.data;
          setVendorData(vendorData);
          setIsLoading(false);

          // Initialize the form with fetched data
          VendorFormik.setValues({
            vendor_name: vendorData?.vendor_name || "",
            vendor_phoneNumber: vendorData?.vendor_phoneNumber || "",
            vendor_email: vendorData?.vendor_email || "",
            vendor_password: vendorData?.vendor_password || "",
          });
        })
        .catch((error) => {
          console.error("Error fetching vendor data:", error);
          setIsLoading(false);
        });
    }
  }, [vendor_id]);

  // Handle form submission
  async function handleSubmit(values) {
    setSubmitLoader(true);
    values.admin_id = accessType?.admin_id;
    try {
      if (vendor_id === undefined) {
        const res = await axiosInstance.post(`/vendor/vendor`, values);
        handleResponse(res);
      } else {
        const editUrl = `/vendor/update_vendor/${vendor_id}`;
        const res = await axiosInstance.put(editUrl, values);
        handleResponse(res);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  // Handle API response and navigation
  // function handleResponse(response) {
  //   if (response.status === 200) {
  //     navigate("/"+admin+"/vendor");
  //
  //   } else {
  //     alert(response.data.message);
  //   }
  // }

  function handleResponse(response) {
    const successMessage = vendor_id
      ? "Vendor Updated Successfully"
      : "Vendor Added Successfully";
    const errorMessage = response.data.message;

    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 1000,
        onClose: () => navigate(`/staff/staffvendor`),
      });
    } else {
      // Show an error toast
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  // Handle cancel button click
  const handleCloseButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      {/* <AddVendorHeader /> */}
      {/* Page content */}
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Property Type</h1>
            </FormGroup> */}
          <CardHeader
            className=" mt-3 headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {vendor_id ? "Edit Vendor" : "Add Vendor"}
            </h2>
          </CardHeader>
        </Col>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="bg-white shadow mt-3 mx-3 mb-3 cardstyborderradius"
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
                border: "1px solid #324567",
              }}
            >
              <CardBody>
                <Form onSubmit={VendorFormik.handleSubmit}>
                  <div className="">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor"
                            htmlFor="input-staffmember-name"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                            }}
                          >
                            Vendor Name *
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "60%",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-staffmember-name"
                            placeholder="Enter vendor name "
                            type="text"
                            name="vendor_name"
                            onBlur={VendorFormik.handleBlur}
                            onChange={VendorFormik.handleChange}
                            value={VendorFormik.values.vendor_name}
                          />
                          {VendorFormik.touched.vendor_name &&
                          VendorFormik.errors.vendor_name ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {VendorFormik.errors.vendor_name}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="">
                    <Row className="mt-2">
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor"
                            htmlFor="staffmember_phoneNumber"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                            }}
                          >
                            Phone Number *
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "60%",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="staffmember_phoneNumber"
                            placeholder="Enter phone number"
                            type="text"
                            name="vendor_phoneNumber"
                            onBlur={VendorFormik.handleBlur}
                            onChange={VendorFormik.handleChange}
                            value={VendorFormik.values.vendor_phoneNumber}
                            onInput={(e) => {
                              const inputValue = e.target.value;
                              const numericValue = inputValue.replace(
                                /\D/g,
                                ""
                              );
                              e.target.value = numericValue;
                            }}
                          />
                          {VendorFormik.touched.vendor_phoneNumber &&
                          VendorFormik.errors.vendor_phoneNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {VendorFormik.errors.vendor_phoneNumber}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="">
                    <Row className="mt-2">
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor"
                            htmlFor="staffmember_email"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                            }}
                          >
                            Email *
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "60%",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="staffmember_email"
                            placeholder="Enter email"
                            type="text"
                            name="vendor_email"
                            onBlur={VendorFormik.handleBlur}
                            onChange={VendorFormik.handleChange}
                            value={VendorFormik.values.vendor_email}
                          />
                          {VendorFormik.touched.vendor_email &&
                          VendorFormik.errors.vendor_email ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {VendorFormik.errors.vendor_email}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="">
                    <Row className="mt-2 mb-3">
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor"
                            htmlFor="staffmember_password"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                            }}
                          >
                            Password *
                          </label>
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "6px",
                              width: "60%",
                            }}
                          >
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              id="staffmember_password"
                              placeholder="Enter password"
                              name="vendor_password"
                              type={showPassword ? "text" : "password"}
                              onBlur={VendorFormik.handleBlur}
                              onChange={VendorFormik.handleChange}
                              value={VendorFormik.values.vendor_password}
                            />
                            <Button
                              type="button"
                              style={{ padding: "7px" }}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </Button>
                          </div>
                          {VendorFormik.touched.vendor_password &&
                          VendorFormik.errors.vendor_password ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {VendorFormik.errors.vendor_password}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <Row className="my-row">
                    {submitLoader ? (
                      <Button
                        type="submit"
                        className="btn fontstylerentr"
                        style={{ background: "#152B51", color: "white" }}
                        disabled
                      >
                        Loading...
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="btn fontstylerentr"
                        style={{
                          background: "#152B51",
                          color: "white",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: vendor_id ? "12px" : "14px",
                          cursor: "pointer",
                        }}
                        disabled={!VendorFormik.isValid}
                        onClick={() => {
                          VendorFormik.handleSubmit();
                        }}
                      >
                        {vendor_id ? "Update Vendor" : "Add Vendor"}
                      </Button>
                    )}

                    <Button
                      // color="primary"
                      className="btn fontstylerentr"
                      onClick={handleCloseButtonClick}
                      size="small"
                      style={{ background: "white", color: "#152B51" }}
                    >
                      Cancel
                    </Button>
                    {/* Conditional message */}
                    {!VendorFormik.isValid && (
                      <div
                        className="fontstylerentr"
                        style={{ color: "red", marginTop: "10px" }}
                      >
                        Please fill in all fields correctly.
                      </div>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default StaffAddVendor;
