import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Box from "@mui/material/Box";
import profile from "../../assets/img/icons/common/profile1.svg";
import VendorHeader from "components/Headers/VendorHeader";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";

import {
  Card,
  CardHeader,
  FormGroup,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Grid } from "@mui/material";
import { useCookies } from "react-cookie";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
// import MapComponent from "../../components/MapComponent";

const VendorWorkDetail = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { workorder_id } = useParams();
  const [outstandDetails, setoutstandDetails] = useState({});
  const [showTenantTable, setShowTenantTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("Summary");
  const [imagedetails, setImageDetails] = useState([]);
  const [workOrderStatus, setWorkOrderStatus] = useState("");
  const [userroleData, setUserroleData] = useState(null);

  let navigate = useNavigate();
  const updateWorkorderFormik = useFormik({
    initialValues: {
      status: "",
      staffmember_name: "",
      date: "",
      staffmember_id: "",
      message: "",
      statusUpdatedBy: "",
    },
    onSubmit: (values) => {
      // updateValues()
      // updateWorkorder(values);
    },
  });
  const getOutstandData = async () => {
    if (workorder_id) {
      try {
        const response = await axiosInstance.get(
          `/work-order/workorder_details/${workorder_id}`
        );
        setoutstandDetails(response?.data?.data);

        if (
          response?.data?.data?.workorder_updates &&
          response.data.data.workorder_updates.length > 0
        ) {
          const reversedUpdates = [
            ...response?.data?.data?.workorder_updates,
          ].reverse();

          const latestUpdate = reversedUpdates[0];

          setWorkOrderStatus(reversedUpdates);

          updateWorkorderFormik.setValues({
            status: response?.data?.data?.status,
            staffmember_name: latestUpdate?.staffmember_name,
            date: response?.data?.data?.date,
            assigned_to: response?.data?.data.staff_data?.staffmember_name,
            message: response?.data?.data?.message
              ? response?.data?.data?.message
              : "",
            statusUpdatedBy: latestUpdate?.statusUpdatedBy
              ? latestUpdate?.statusUpdatedBy
              : "Admin",
          });
        } else {
          console.log("No updates found in workorder_updates");
        }

        setLoading(false);
        setImageDetails(response?.data?.data?.workOrder_images);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
        setError(error);
        setLoading(false);
      }
    }
  };

  const handleMouseEnter = (buttonValue) => {
    setHoveredButton(buttonValue);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleChange = () => {
    setShowTenantTable(!showTenantTable);
  };

  function formatDateWithoutTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }

  const [accessType, setAccessType] = useState(null);
  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/vendor/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  React.useEffect(() => {
    getOutstandData();
  }, [workorder_id]);

  const getPropertyData = async () => {
    // if (outstandDetails.rental_units === "") {
    //   try {
    //     const response = await axiosInstance.get(
    //       `/propertyunit/property/${outstandDetails.rental_adress}`
    //     );
    //     setPropertyDetails(response.data[0]);
    //   } catch (error) {
    //     console.error("Error fetching tenant details:", error);
    //   }
    // } else if (outstandDetails.rental_adress && outstandDetails.rental_units) {
    //   try {
    //     const response = await axiosInstance.get(
    //       `/propertyunit/property/${outstandDetails.rental_adress}/${outstandDetails.rental_units}`
    //     );
    //     setPropertyDetails(response.data[0]);
    //   } catch (error) {
    //     console.error("Error fetching tenant details:", error);
    //   }
    // }
  };

  React.useEffect(() => {
    getPropertyData();
  }, [outstandDetails]);

  const [tenantsDetails, setTenantsDetails] = useState();

  // const getTenantsData = async () => {
  //   try {
  //     const response = await axiosInstance.get(`/tenant/findData`, {
  //       params: {
  //         rental_adress: propertyDetails?.rental_adress,
  //         rental_units: propertyDetails?.rental_units,
  //       },
  //     });
  //     response.data.map((data) => {
  //       data.entries.map((item) => {
  //         const currentDate = new Date();
  //         const sdate = new Date(item.start_date);
  //         const edate = new Date(item.end_date);

  //         // Compare the current date with start and end dates
  //         if (
  //           currentDate >= sdate &&
  //           currentDate < edate &&
  //           item.rental_adress === propertyDetails?.rental_adress &&
  //           item.rental_units === propertyDetails?.rental_units
  //         ) {
  //           setTenantsDetails(data);
  //         }
  //       });
  //     });
  //   } catch (error) {
  //     console.error("Error fetching tenant details:", error);
  //     setError(error);
  //   }
  // };

  // React.useEffect(() => {
  //   getTenantsData();
  // }, [propertyDetails]);

  const tableHeaderStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    fontWeight: "bold",
    textAlign: "left",
  };

  const tableCellStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    textAlign: "left",
  };

  const tableFooterStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    textAlign: "left",
  };

  const total = () => {
    let total = 0;
    outstandDetails?.partsandcharge_data?.map((item) => {
      total = total + item.amount;
    });
    return total;
  };

  const detailstyle = {
    fontSize: "15px",
    color: "#525f7f",
    fontWeight: 600,
  };

  const SmallSummaryCard = ({ label, value, textTruncate }) => {
    return (
      <div className="small-summary-card p-3">
        {" "}
        {/* Added padding with the p-3 class */}
        <h6
          className="text-uppercase text-muted mb-0 fontstylerentr"
          style={{ fontSize: "14px", fontFamily: "Poppins", fontWeight: "400" }}
        >
          {label}
        </h6>
        <span
          className={`font-weight-bold fontstylerentr ${
            textTruncate ? "text-truncate" : ""
          }`}
          style={{
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: "500",
            color: "#152B51",
          }}
        >
          {value}
        </span>
      </div>
    );
  };
  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.vendor_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);
  return (
    <>
      <VendorHeader />
      {/* Page content */}
      <Container
        className="stylecontainer "
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Row>
          <Col className="text-right">
            <Button
              // color="primary"
              className="mr-2 fontstylerentr"
              //  href="#rms"
              onClick={() => navigate("/vendor/vendorworktable")}
              size="small"
              style={{ background: "#152B51", color: "#fff" }}
            >
              Back
            </Button>
          </Col>

          <Col xs="12" lg="12" sm="12">
            <FormGroup className="">
              {/* <h1 style={{ color: "red" }}>Work Order Details</h1> */}
            </FormGroup>
            <CardHeader
              className=" mt-3 mx-2 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Work Order Details
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        {/* Table */}
        <Row>
          <div className="col">
            {/* <Card className="" style={{backgroundColor:"transparent"}}> */}
            <CardHeader
              className=" border-0 d-flex justify-content-between align-items-center"
              style={{ padding: "8px", marginBottom: "10px" }}
            >
              <div>
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton
                    className="labelfontstyle"
                    value="Summary"
                    style={{
                      border: "none",
                      background: "none",
                      textTransform: "capitalize",
                      cursor: "pointer",
                      fontSize: "18px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      color: activeButton === "Summary" ? "#152B51" : "inherit",
                    borderBottom: activeButton === "Summary" ? "0.5px solid #324567" : "none",
                    borderRadius:"0px"
                    }}
                    onMouseEnter={() => handleMouseEnter("Summary")}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => setActiveButton("Summary")}
                  >
                    Summary
                  </ToggleButton>
                  <ToggleButton
                    className="labelfontstyle"
                    value="Task"
                    style={{
                      border: "none",
                      background: "none",
                      textTransform: "capitalize",
                      cursor: "pointer",
                      fontSize: "18px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      color: activeButton === "Task" ? "#152B51" : "inherit",
                    borderBottom: activeButton === "Task" ? "0.5px solid #324567" : "none",
                    borderRadius:"0px"
                    }}
                    onMouseEnter={() => handleMouseEnter("Task")}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => setActiveButton("Task")}
                  >
                    Task
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {userroleData?.workorder_edit && (
                <div className="ml-auto ">
                  {" "}
                  <Button
                    className="fontstylerentr"
                    color="primary"
                    //  href="#rms"
                    onClick={() =>
                      navigate(`/vendor/vendoraddwork/${workorder_id}`)
                    }
                    size="small"
                    style={{
                      background: "#152B51",
                      color: "#fff",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    Edit Details
                  </Button>
                </div>
              )}
            </CardHeader>
            <div className="table-responsive">
              {activeButton === "Summary" && (
                <div
                  className="container-fluid"
                  style={{
                    marginRight: "auto",
                    // overflowY: "scroll",
                    // overflowX: "hidden",
                    // scrollbarWidth: "thin",
                    // msOverflowStyle: "none",
                    // maxHeight: "100vh",
                    // paddingRight: "20px"
                  }}
                >
                  <Row className="mb-4">
                    <Col lg="8" md="12">
                      {loading ? (
                        <div>Loading Work Order details...</div>
                      ) : error ? (
                        <div>Error: {error.message}</div>
                      ) : outstandDetails?.workOrder_id ? (
                        <>
                          <Box
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            padding="16px"
                            // maxWidth="960px"
                            boxShadow=" 0px 4px 4px 0px #00000040"

                            // margin="20px"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              marginBottom="20px"
                            >
                              <Box
                                width="40px"
                                height="40px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                backgroundColor="#152B51"
                                borderRadius="8px"
                                color="white"
                                fontSize="24px"
                                marginRight="16px"
                              >
                                {/* <AssignmentOutlinedIcon /> */}
                                <FormatListBulletedIcon />
                              </Box>
                              <Box flex="1">
                                <h2
                                  className="text-lg fontstylerentr"
                                  style={{ color: "#152B51" }}
                                >
                                  {outstandDetails?.work_subject || "N/A"}
                                </h2>
                                <span className="fontstylerentr">
                                  {outstandDetails?.property_data
                                    .rental_adress || "N/A"}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection={{ xs: "column", md: "row" }}
                              alignItems="stretch"
                            >
                              {/* Left side */}
                              <Box
                                flex="1"
                                className={{ xs: "col-12", md: "col-7" }}
                                marginBottom={{ xs: "20px", md: "0" }}
                              >
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkdescription"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontWeight: "500",
                                      fontFamily: "Poppins",
                                      fontSize: "16px",
                                      color: "#152B51",
                                    }}
                                  >
                                    Description
                                  </label>
                                  <span
                                    className="fontstylerentr"
                                    style={{
                                      fontWeight: "400",
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails.work_performed || "N/A"}
                                  </span>
                                </FormGroup>
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkpermission"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontWeight: "500",
                                      fontFamily: "Poppins",
                                      fontSize: "16px",
                                      color: "#152B51",
                                    }}
                                  >
                                    Permission to enter
                                  </label>

                                  <span
                                    className="fontstylerentr"
                                    style={{
                                      fontWeight: "400",
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails?.entry_allowed
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </FormGroup>
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkvendor"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontWeight: "500",
                                      fontFamily: "Poppins",
                                      fontSize: "16px",
                                      color: "#152B51",
                                    }}
                                  >
                                    Vendor Notes
                                  </label>
                                  <span
                                    className="fontstylerentr"
                                    style={{
                                      fontWeight: "400",
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails.vendor_notes || "N/A"}
                                  </span>
                                </FormGroup>
                              </Box>

                              {/* Right side */}

                              <Box
                                flex="1"
                                className="d-flex flex-column justify-content-end"
                              >
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Status"
                                      value={outstandDetails.status || "N/A"}
                                      textTruncate
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Due Date"
                                      value={outstandDetails.date || "N/A"}
                                      textTruncate
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Assignees"
                                      value={
                                        outstandDetails?.staff_data
                                          ?.staffmember_name || "N/A"
                                      }
                                      textTruncate
                                    />
                                  </Col>
                                </Row>
                              </Box>
                            </Box>
                          </Box>
                          {outstandDetails?.partsandcharge_data?.length > 0 &&
                          outstandDetails?.partsandcharge_data ? (
                            <Box
                              // border="1px solid #ccc"
                              border="0.5px solid #737791"
                              borderRadius="10px"
                              padding="16px"
                              // maxWidth="960px"
                              boxShadow=" 0px 4px 4px 0px #00000040"
                              marginTop="20px"
                              style={{
                                marginRight: "auto",
                                overflowX: "auto",
                              }}
                            >
                              <h2
                                className="text text-lg fontstylerentr"
                                style={{
                                  color: "#152B51",
                                  fontWeight: "500",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                }}
                              >
                                Parts and Labor
                              </h2>
                              <Box overflowX="auto">
                                <table
                                  style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <thead>
                                    <tr
                                      className="fontstylerentr tableefontworkorder"
                                      style={{
                                        fontWeight: "500",
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        color: "#152B51",
                                      }}
                                    >
                                      <th style={tableHeaderStyle}>QTY</th>
                                      <th style={tableHeaderStyle}>ACCOUNT</th>
                                      <th style={tableHeaderStyle}>
                                        DESCRIPTION
                                      </th>
                                      <th style={tableHeaderStyle}>PRICE</th>
                                      <th style={tableHeaderStyle}>AMOUNT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* Add your table rows dynamically here */}
                                    {outstandDetails?.partsandcharge_data?.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.parts_quantity}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.account}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.description}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={{
                                              ...tableCellStyle,
                                              textAlign: "right",
                                            }}
                                          >
                                            ${item.parts_price}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={{
                                              ...tableCellStyle,
                                              textAlign: "right",
                                            }}
                                          >
                                            ${item.amount}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td
                                        className="fontstylerentr tableefontworkorder"
                                        colSpan="4"
                                        style={{
                                          ...tableFooterStyle,
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          color: "#152B51",
                                        }}
                                      >
                                        Total
                                      </td>
                                      <td
                                        className="fontstylerentmodal tableefontworkorder"
                                        style={{
                                          ...tableFooterStyle,
                                          textAlign: "right",
                                          fontWeight: "400",
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          color: "#152B51",
                                        }}
                                      >
                                        ${total()}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </Box>
                            </Box>
                          ) : null}
                          <Grid
                            className="mb-3"
                            container
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            padding="16px"
                            // maxWidth="960px"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                            marginTop="20px"
                            style={{
                              marginRight: "auto",
                              overflowX: "auto",
                              maxHeight: "500px",
                              scrollbarWidth: "thin",
                              msOverflowStyle: "none",
                            }}
                          >
                            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                              <h2
                                className="text-lg fontstylerentr"
                                style={{
                                  color: "#152B51",
                                  fontWeight: "500",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                }}
                              >
                                Updates
                              </h2>
                            </Grid>
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              {outstandDetails?.workorder_updates &&
                                outstandDetails?.workorder_updates.length > 0 &&
                                outstandDetails?.workorder_updates.map(
                                  (item, index) => (
                                    <Grid item xs={12}>
                                      <Box
                                        padding="12px"
                                        maxWidth="700px"
                                        style={{
                                          // marginLeft: "auto",
                                          marginRight: "auto",
                                          overflowX: "auto",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            className="fontstylerentr"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {item.statusUpdatedBy}{" "}
                                            {item.createdAt
                                              ? "Created this work order"
                                              : "Updated this work order"}
                                            <span
                                              className="fontstylerentr"
                                              style={{ fontSize: "13px" }}
                                            >
                                              &nbsp;(
                                              {item.updatedAt
                                                ? item.updatedAt
                                                : item.createdAt}
                                              )
                                            </span>
                                          </div>
                                        </div>
                                        <hr
                                          style={{
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                          }}
                                        />

                                        <Grid container>
                                          {!Object.keys(item).includes(
                                            "status"
                                          ) ||
                                          Object.keys(item).includes("date") ||
                                          item.status !== (" " || "") ||
                                          item.date !== (" " || "") ||
                                          item.staffmember_name !==
                                            (" " || "") ? (
                                            <>
                                              <Grid
                                                className="fontstylerentr"
                                                item
                                                xs={4}
                                                style={
                                                  !Object.keys(item).includes(
                                                    "status"
                                                  ) ||
                                                  item.status === (" " || null)
                                                    ? { display: "none" }
                                                    : { display: "block" }
                                                }
                                              >
                                                Status: {item.status}
                                              </Grid>
                                              <Grid
                                                className="fontstylerentr"
                                                itemx
                                                xs={4}
                                                style={
                                                  !Object.keys(item).includes(
                                                    "date"
                                                  ) ||
                                                  item.due_date ===
                                                    (" " || null)
                                                    ? { display: "none" }
                                                    : { display: "block" }
                                                }
                                              >
                                                Due Date: {item.date}
                                              </Grid>
                                              <Grid
                                                className="fontstylerentr"
                                                item
                                                xs={4}
                                                style={{
                                                  display:
                                                    item?.staffmember_name &&
                                                    item?.staffmember_name?.trim() !==
                                                      ""
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Assigned To:{" "}
                                                {item?.staffmember_name}
                                              </Grid>
                                            </>
                                          ) : (
                                            <>
                                              <Grid
                                                className="fontstylerentr"
                                                item
                                              >
                                                {" "}
                                                Work Order Is Updated
                                              </Grid>
                                            </>
                                          )}
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  )
                                )}
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <div className="fontstylerentr">No details found.</div>
                      )}
                    </Col>
                    <Col lg="4" md="12" sm="12" xs="12">
                      {outstandDetails?.workOrder_id ? (
                        <Box
                          className="boxworkorderstylee"
                          // border="1px solid #ccc"
                          border="0.5px solid #737791"
                          borderRadius="10px"
                          boxShadow=" 0px 4px 4px 0px #00000040"
                          maxWidth="100%"
                          // margin="20px"
                        >
                          <Box
                            className="align-item-center text-center boxinnertextworkorder"
                            borderBottom="1px solid #ccc"
                            style={{
                              width: "100%",
                              padding: "16px",
                              textAlign: "left",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                              color: "#5e72e4",
                            }}
                          >
                            <span
                              className="labelfontstyle"
                              style={{
                                color: "#152B51",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Contacts
                            </span>
                          </Box>
                          <Box
                            borderBottom="1px solid #ccc"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "100%",
                              padding: "16px 16px 5px 16px",
                            }}
                          >
                            <Box width="16px" marginRight="10px">
                              {/* SVG icon */}
                              <img src={profile} height={20} width={20} />
                            </Box>
                            <Box
                              width="100%"
                              style={{ minWidth: "100%", padding: "0 16px" }}
                            >
                              <span
                                className="fontstylerentr"
                                style={{
                                  color: "#152B51",
                                  fontWeight: "500",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                }}
                              >
                                Vendor
                              </span>{" "}
                              <br />
                              <span
                                className="fontstylerentmodal"
                                style={{
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                }}
                              >
                                {outstandDetails?.vendor_data.vendor_name ||
                                  "N/A"}
                              </span>
                            </Box>
                          </Box>
                          {outstandDetails?.tenant_data &&
                          typeof outstandDetails?.tenant_data === "object" ? (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                minWidth: "100%",
                                padding: "16px 16px 5px 16px",
                              }}
                            >
                              <Box width="16px" marginRight="10px">
                                <img src={profile} height={20} width={20} />
                              </Box>
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  padding: "0 16px",
                                }}
                              >
                                <span
                                  className="fontstylerentr"
                                  style={{
                                    color: "#152B51",
                                    fontWeight: "500",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                  }}
                                >
                                  Tenant
                                </span>{" "}
                                <br />
                                <span
                                  className="fontstylerentmodal"
                                  style={{
                                    fontWeight: "400",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  }}
                                >
                                  {outstandDetails?.tenant_data
                                    .tenant_firstName ? (
                                    <>
                                      {
                                        outstandDetails?.tenant_data
                                          .tenant_firstName
                                      }{" "}
                                      {
                                        outstandDetails?.tenant_data
                                          .tenant_lastName
                                      }
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Box>
                            </Box>
                          ) : null}
                        </Box>
                      ) : null}
                      {outstandDetails ? (
                        <>
                          <Box
                            className="boxcontactstyle mt-3"
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            maxWidth="100%"
                            // margin="20px"
                            display="flex"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Box
                              className="align-item-center text-center boxinnertextworkorder"
                              borderBottom="1px solid #ccc"
                              style={{
                                width: "100%",
                                padding: "16px",
                                textAlign: "left",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                color: "#5e72e4",
                              }}
                            >
                              <span
                                className="labelfontstyle"
                                style={{
                                  color: "#152B51",
                                  fontWeight: "500",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                }}
                              >
                                Property
                              </span>
                            </Box>
                            {outstandDetails?.property_data?.rental_image ? (
                              <Box
                                style={{
                                  width: "100%",
                                  padding: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  width="100%"
                                  style={{
                                    minWidth: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    className="workorderimgwidthstyle"
                                    src={`${imageGetUrl}/${outstandDetails?.property_data.rental_image}`}
                                    alt="property"
                                    style={{
                                      maxWidth: "80%",
                                      maxHeight: "100%",
                                      borderRadius: "8px",
                                      border: "1px solid #ccc",
                                    }}
                                  />
                                </Box>
                              </Box>
                            ) : null}
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className="labelfontstyle"
                                  style={{
                                    fontWeight: "400",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  }}
                                >
                                  {outstandDetails?.property_data
                                    ?.rental_adress || "N/A"}
                                  {outstandDetails?.unit_data?.rental_unit ? (
                                    " (" +
                                    outstandDetails?.unit_data?.rental_unit +
                                    ")"
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span className="labelfontstyle">
                                  {outstandDetails.property_data
                                    ?.rental_city ? (
                                    <>
                                      {
                                        outstandDetails.property_data
                                          ?.rental_city
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails.property_data
                                    ?.rental_state ? (
                                    <>
                                      {
                                        outstandDetails.property_data
                                          ?.rental_state
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails.property_data
                                    ?.rental_country ? (
                                    <>
                                      {
                                        outstandDetails.property_data
                                          ?.rental_country
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails.property_data
                                    ?.rental_postcode ? (
                                    <>
                                      {
                                        outstandDetails.property_data
                                          ?.rental_postcode
                                      }
                                      .
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Box>
                            </Box>
                            {/* <Box
                              width="100%"
                              style={{
                                padding: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ height: "220px", width: "90%", borderRadius: "30px", overflow: "hidden" }}>
                                <MapComponent
                                  address={`${outstandDetails?.property_data?.rental_adress}, ${outstandDetails?.property_data?.rental_city}, ${outstandDetails?.property_data?.rental_state}, ${outstandDetails?.property_data?.rental_country}, ${outstandDetails?.property_data?.rental_postcode}`}
                                />
                              </div>
                            </Box> */}
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
              )}

              {activeButton === "Task" && (
                <div className="container-fluid">
                  <Row className="mb-4">
                    <Col lg="8" md="12">
                      <Box
                        // border="1px solid #ccc"
                        border="0.5px solid #737791"
                        borderRadius="10px"
                        padding="16px"
                        // maxWidth="960px"
                        // margin={"20px"}
                        boxShadow=" 0px 4px 4px 0px #00000040"
                      >
                        <Row style={{ justifyContent: "space-between" }}>
                          <Col
                            lg="8"
                            className="d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <Box
                              width="40px"
                              height="40px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              backgroundColor="#152B51"
                              borderRadius="8px"
                              color="white"
                              fontSize="24px"
                            >
                              <FormatListBulletedIcon />
                            </Box>

                            <Col>
                              <h2
                                className="fontstylerentr"
                                style={{
                                  color: "#152B51",
                                  marginBottom: 0,
                                  fontFamily: "poppins",
                                }}
                              >
                                {outstandDetails?.work_subject || "N/A"}
                              </h2>

                              <span
                                className="fontstylerentr"
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails.property_data?.rental_adress ||
                                  "N/A"}
                                {outstandDetails?.unit_data?.rental_unit ? (
                                  " (" +
                                  outstandDetails?.unit_data?.rental_unit +
                                  ")"
                                ) : (
                                  <></>
                                )}
                              </span>
                            </Col>
                          </Col>
                          <Col lg="2" className="text-end">
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {/* <span
                                style={{
                                  border: "2px solid",
                                  borderColor:
                                    outstandDetails.priority === "High"
                                      ? "red"
                                      : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                          ? "#FFD700"
                                          : "inherit",
                                  borderRadius: "8px",
                                  padding: "4px",
                                  fontSize: "15px",
                                  color:
                                    outstandDetails.priority === "High"
                                      ? "red"
                                      : outstandDetails.priority === "Normal"
                                      ? "green"
                                      : outstandDetails.priority === "Low"
                                      ? "#FFD700"
                                      : "inherit",
                                }}
                              >
                                &nbsp;{outstandDetails?.priority}&nbsp;
                              </span> */}
                              {outstandDetails?.priority && (
                                <span
                                  className="fontstylerentr"
                                  style={{
                                    border: "2px solid",
                                    borderColor:
                                      outstandDetails.priority === "High"
                                        ? "red"
                                        : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                        ? "#FFD700"
                                        : "inherit",
                                    borderRadius: "8px",
                                    padding: "4px",
                                    fontSize: "15px",
                                    color:
                                      outstandDetails.priority === "High"
                                        ? "red"
                                        : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                        ? "#FFD700"
                                        : "inherit",
                                  }}
                                >
                                  &nbsp;{outstandDetails?.priority}&nbsp;
                                </span>
                              )}
                            </span>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  color: "#152B51",
                                }}
                              >
                                Description
                              </label>
                              <br />
                              <span
                                className="fontstylerentr"
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails.work_performed || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  color: "#152B51",
                                }}
                              >
                                Status
                              </label>
                              <br />
                              <span
                                className="fontstylerentr"
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails.status || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>

                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  color: "#152B51",
                                }}
                              >
                                Due Date
                              </label>
                              <br />
                              <span
                                className="fontstylerentr"
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails.date || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  color: "#152B51",
                                }}
                              >
                                Assignees
                              </label>
                              <br />
                              <span
                                className="fontstylerentr"
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails?.staff_data
                                  ?.staffmember_name || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  color: "#152B51",
                                }}
                              >
                                Permission to enter
                              </label>
                              <br />
                              <span
                                className="fontstylerentr"
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                }}
                              >
                                {outstandDetails?.entry_allowed ? "Yes" : "No"}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Box>
                    </Col>

                    <Col lg="4" md="12">
                      {outstandDetails ? (
                        <>
                          <Box
                            className="boxcontactstyle"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            maxWidth="100%"
                            // margin="20px"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                          >
                            <Box
                              className="align-item-center text-center boxinnertextworkorder"
                              borderBottom="1px solid #ccc"
                              style={{
                                width: "100%",
                                padding: "10px",
                                textAlign: "left",
                                color: "#152B51",
                                boxShadow: "0px 4px 4px 0px #00000040",
                                marginBottom: "10px",
                              }}
                            >
                              <span
                                className="labelfontstyle"
                                style={{
                                  color: "#152B51",
                                  fontWeight: "500",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                }}
                              >
                                Images
                              </span>
                            </Box>

                            {outstandDetails?.workOrder_images &&
                            outstandDetails?.workOrder_images.length > 0 ? (
                              <Box
                                style={{
                                  width: "100%",
                                  padding: "16px",
                                  marginTop: "10px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "center",
                                  fontFamily: "poppins",
                                }}
                              >
                                {outstandDetails?.workOrder_images.map(
                                  (imageUrl, index) => (
                                    <Box
                                      key={index}
                                      width="48%"
                                      style={{
                                        minWidth: "48%",
                                        margin: "1%",
                                      }}
                                    >
                                      <img
                                        src={`${imageGetUrl}/${imageUrl}`}
                                        alt={`property ${index}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: "8px",
                                          border: "1px solid #ccc",
                                        }}
                                      />
                                    </Box>
                                  )
                                )}
                              </Box>
                            ) : (
                              <div className="mt-3 labelfontstyle">
                                No Images Attached
                              </div>
                            )}
                            <br />
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className="labelfontstyle"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                  }}
                                >
                                  {outstandDetails.property_data
                                    ?.rental_adress || "N/A"}{" "}
                                  ({outstandDetails.unit_data?.rental_unit})
                                </span>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className="labelfontstyle"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                  }}
                                >
                                  {outstandDetails.property_data
                                    ?.rental_city ? (
                                    <>
                                      {
                                        outstandDetails.property_data
                                          ?.rental_city
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails.property_data
                                    ?.rental_state ? (
                                    <>
                                      {
                                        outstandDetails.property_data
                                          ?.rental_state
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails.property_data
                                    ?.rental_country ? (
                                    <>
                                      {
                                        outstandDetails.property_data
                                          ?.rental_country
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails.property_data
                                    ?.rental_postcode ? (
                                    <>
                                      {
                                        outstandDetails.property_data
                                          ?.rental_postcode
                                      }
                                      .
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <>No Details Found</>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <br />
            {/* </Card> */}
          </div>
        </Row>
        <br />
        <br />
      </Container>
    </>
  );
};

export default VendorWorkDetail;
