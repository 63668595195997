import { Spinner } from "reactstrap";

const Loader = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "30px" }}>
      <Spinner style={{ margin: "auto", color: "var(--bg-blue-color)" }} />
    </div>
  );
};

export default Loader;
