import * as React from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Formik, Form } from "formik";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import swal from "sweetalert";
import deleicon from "../../../assets/img/icons/common/delete.svg";
import editicon from "../../../assets/img/icons/common/editicon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton, Tooltip } from "@mui/material";
import { useCookies } from "react-cookie";
import { Col, Container, Row, CardHeader } from "reactstrap";
import Header from "components/Headers/Header";
import axiosInstance from "axiosInstance";

const MailConfigurationCard = ({
  data,
  getMailData,
  click,
  setClick,
  setEditData,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (click && data.email_configration_id) {
      fetchMailConfiguration();
    }
  }, [click]);

  const fetchMailConfiguration = async () => {
    try {
      const response = await axiosInstance.get(
        `/email_configration/mail/${data.email_configration_id}`
      );
      if (response.data.statusCode === 200) {
        setEditData(response.data.data);
      } else {
        console.error(
          "Failed to fetch mail configuration data:",
          response.data.message
        );
        // Handle error if needed
      }
    } catch (error) {
      console.error("Error fetching mail configuration data:", error);
      // Handle error if needed
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        `/email_configration/mail/${data.email_configration_id}`
      );
      if (response.data.statusCode === 200) {
        toast.success("Email Configuration deleted successfully", {
          position: "top-center",
          autoClose: 2000,
        });
        getMailData();
      } else {
        toast.error(response.data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error deleting email configuration:", error);
      toast.error("Error deleting email configuration", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="col-lg-6 mb-4">
      <div
        className="card h-100"
        style={{
          boxShadow: " 0px 4px 4px 0px #00000040 ",
          border: "1px solid #152B51",
        }}
      >
        <div className="card-body">
          <Row>
            <Col lg="9">
              <label
                className="card-title font-weight-bold labelfontstyle"
                style={{ borderBottom: "1px solid #ccc", fontSize: "20px" }}
              >
                Configuration Details
              </label>
            </Col>
            <Col lg="3">
              {/* <div className=""> */}
              <Tooltip title="Edit" arrow>
                <IconButton
                  onClick={() => {
                    fetchMailConfiguration();
                  }}
                >
                  <img
                    className="propertyiconsrentals"
                    src={editicon}
                    width={20}
                    height={20}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <IconButton onClick={handleDelete}>
                  <img
                    className="propertyiconsrentals"
                    src={deleicon}
                    width={20}
                    height={20}
                  />
                </IconButton>
              </Tooltip>
              {/* </div> */}
            </Col>
          </Row>
          <p className="card-text font-weight-bold fontstylerentr">Host:</p>
          <p className="card-text fontstylerentr">{data.host}</p>
          <p className="card-text font-weight-bold fontstylerentr">Port:</p>
          <p className="card-text fontstylerentr">{data.port}</p>
          <p className="card-text font-weight-bold fontstylerentr">User:</p>
          <p className="card-text fontstylerentr">{data.user}</p>
          {/* <p className="card-text font-weight-bold">Label:</p>
          <p className="card-text">{data.from_name}</p> */}
        </div>
      </div>
    </div>
  );
};

const EmailTable = () => {
  const [formikValues, setFormikValues] = useState({
    email_configration_id: "",
    superadmin_id: "",
    host: "",
    port: "",
    user: "",
    pass: "",
    secure: "",
    from_name: "",
    from_email: "",
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState();
  const navigate = useNavigate();
  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(
          `/admin/superadmin_token_check`,
          {
            token: cookies.token,
          }
        );
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);
  const [mailData, setMailData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [click, setClick] = useState(false);
  const [loader, setLoader] = useState(false);
  const [testLoader, setTestLoader] = useState(false);
  const [responseText, setResponseText] = useState(null);

  useEffect(() => {
    setFormikValues({
      email_configration_id: editData?.email_configration_id,
      superadmin_id: editData?.superadmin_id,
      host: editData?.host,
      port: editData?.port,
      user: editData?.user,
      pass: editData?.pass,
      secure: editData?.secure,
      // from_name: "",
      from_email: editData?.from_email,
    });

    setTimeout(() => {
      if (editData?.email_configration_id) {
        setClick(true);
      }
    }, 500);
  }, [editData]);
  const getMailData = async () => {
    try {
      const response = await axiosInstance.get(`/email_configration/mail`);

      setMailData(response.data.data);
    } catch (error) {
      console.error("Error fetching rental data:", error);
    }
  };

  useEffect(() => {
    if (click) {
      setResponseText(null);
    }
  }, [click]);

  useEffect(() => {
    getMailData();
  }, [accessType]);

  const handleSubmitMail = async (values) => {
    setLoader(true);
    if (editData?.email_configration_id) {
      try {
        // values.admin_id = Admin_Id;
        const res = await axiosInstance.put(
          `/email_configration/mail/${editData.email_configration_id}`,
          values
        );
        if (res.data.statusCode === 200) {
          toast.success("Email Configuration Updated", {
            position: "top-center",
            autoClose: 1000,
          });
          setClick(false);
          setEditData(null);
          setResponseText(null);
          getMailData();
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error, {
          position: "top-center",
          autoClose: 1000,
        });
      } finally {
        // setLoader(false);
      }
    } else {
      try {
        // values.admin_id = Admin_Id;
        const res = await axiosInstance.post(
          `/email_configration/mail`,
          values
        );
        if (res.data.statusCode === 200) {
          toast.success("Email Configuration Added", {
            position: "top-center",
            autoClose: 1000,
          });
          setClick(false);
          setResponseText(null);
          getMailData();
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error, {
          position: "top-center",
          autoClose: 1000,
        });
      } finally {
        setLoader(false);
      }
    }
  };

  async function handleTestClick(values) {
    setTestLoader(true);
    try {
      const object = {
        email: accessType.email,
        host: values.host,
        port: values.port,
        user: values.user,
        pass: values.pass,
        secure: values.secure,
        from_email: values.from_email,
      };
      const res = await axiosInstance.post(
        `/email_configration/test_mail`,
        object
      );

      if (res.data.statusCode === 200) {
        // toast.success("Account Linked Successfully", {
        //   position: "top-center",
        //   autoClose: 700,
        //   onClose:()=> navigate('/superadmin/admin')
        // });
        setResponseText(res.data.message);
      } else if (res.data.statusCode === 500) {
        // toast.error("Something went wrong", {
        //   position: "top-center",
        //   autoClose: 1000,
        // });
        setResponseText(res.data.message);
      }

      // setResponseText(res.data.message);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setTestLoader(false);
    }
  }
  return (
    <>
      <Header />
      {/* <Container className="mt--8" fluid> */}
      <Container
        className="stylecontainer "
        fluid
        style={{ marginTop: "3rem" }}
      >
        <Row>
          <Col xs="12" lg="12" sm="6">
            {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Tenants</h1>
            </FormGroup> */}
            <CardHeader
              className=" mt-3 headproptypebar "
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className=" headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Mail Service
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="table-responsive">
              <div className="m-3">
                <Row className="mt-5 mb-4 ml-0">
                  <span className="labelfontstyle">
                    Set your mail configuration settings from here
                  </span>
                </Row>
                <div>
                  {click ? (
                    <Formik
                      initialValues={formikValues}
                      validationSchema={yup.object().shape({
                        host: yup.string().required("Required"),
                        port: yup.number().required("Required"),
                        user: yup.string().required("Required"),
                        pass: yup.string().required("Required"),
                        secure: yup.string().required("Required"),
                        from_name: yup.string().required("Required"),
                        from_email: yup.string().required("Required"),
                      })}
                      onSubmit={(values, { resetForm }) => {
                        handleSubmitMail(values);
                        resetForm();
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            {/* Left Column */}
                            <Col lg={6} md={12}>
                              <div className="mb-3">
                                <label
                                  className="form-control-label fontstylerentr"
                                  htmlFor="input-property"
                                  style={{
                                    maxWidth: "100px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Host *
                                </label>
                                <TextField
                                  type="text"
                                  size="small"
                                  placeholder="cloudpress.host"
                                  name="host"
                                  value={values.host}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={touched.host && !!errors.host}
                                  helperText={touched.host && errors.host}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  className="form-control-label fontstylerentr"
                                  htmlFor="input-property"
                                  style={{
                                    maxWidth: "100px",
                                    minWidth: "100px",
                                  }}
                                >
                                  User *
                                </label>
                                <TextField
                                  type="text"
                                  size="small"
                                  placeholder="Admin Name"
                                  name="user"
                                  value={values.user}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={touched.user && !!errors.user}
                                  helperText={touched.user && errors.user}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  className="form-control-label fontstylerentr"
                                  htmlFor="input-property"
                                  style={{
                                    maxWidth: "100px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Secure
                                </label>
                                <FormControl>
                                  <InputLabel
                                    className="fontstylerentr"
                                    size="small"
                                  >
                                    Select
                                  </InputLabel>
                                  <Select
                                    className="fontstylerentr"
                                    size="small"
                                    fullWidth
                                    name="secure"
                                    label="Select"
                                    value={values.secure}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    MenuProps={{
                                      style: { maxHeight: 210 },
                                    }}
                                    style={{ width: "100px" }}
                                  >
                                    <MenuItem
                                      className="fontstylerentr"
                                      value={true}
                                    >
                                      True
                                    </MenuItem>
                                    <MenuItem
                                      className="fontstylerentr"
                                      value={false}
                                    >
                                      False
                                    </MenuItem>
                                  </Select>
                                  {touched.secure && errors.secure ? (
                                    <div className="text-danger">
                                      {errors.secure}
                                    </div>
                                  ) : null}
                                </FormControl>
                              </div>
                            </Col>

                            {/* Right Column */}
                            <Col lg={6} md={12}>
                              <div className="mb-3">
                                <label
                                  className="form-control-label fontstylerentr"
                                  htmlFor="input-property"
                                  style={{
                                    maxWidth: "100px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Port *
                                </label>
                                <TextField
                                  type="number"
                                  size="small"
                                  placeholder="111"
                                  name="port"
                                  value={values.port}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={touched.port && !!errors.port}
                                  helperText={touched.port && errors.port}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  className="form-control-label fontstylerentr"
                                  htmlFor="input-property"
                                  style={{
                                    maxWidth: "100px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Password *
                                </label>
                                <TextField
                                  type="text"
                                  size="small"
                                  placeholder="Password"
                                  name="pass"
                                  value={values.pass}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={touched.pass && !!errors.pass}
                                  helperText={touched.pass && errors.pass}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  className="form-control-label fontstylerentr"
                                  htmlFor="input-property"
                                  style={{
                                    maxWidth: "100px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Mail *
                                </label>
                                <TextField
                                  type="text"
                                  size="small"
                                  placeholder="john@mail.com"
                                  name="from_email"
                                  value={values.from_email}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={
                                    touched.from_email && !!errors.from_email
                                  }
                                  helperText={
                                    touched.from_email && errors.from_email
                                  }
                                />
                              </div>
                            </Col>
                          </Row>

                          {/* Additional Elements */}
                          {/* Test Button and Response */}
                          <Row className="mt-4 mb-4 mx-2">
                            {/* Test Button */}
                            {/* <Col lg={6} md={12}> */}
                            {testLoader ? (
                              <Button
                                type="submit"
                                className="btn btn-warning fontstylerentr"
                                style={{
                                  color: "#fff",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                                disabled
                              >
                                Loading...
                              </Button>
                            ) : (
                              <Button
                                className="btn bg-warning fontstylerentr"
                                type="submit"
                                style={{
                                  color: "#fff",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                                onClick={(e) => {
                                  handleTestClick(values);
                                  e.preventDefault();
                                }}
                              >
                                Verify Email
                              </Button>
                            )}
                            {/* </Col>
        <Col lg={6} md={12} className="mt-1"> */}
                            <label className="mt-2">{responseText}</label>
                            {/* </Col> */}
                          </Row>

                          {/* Submit and Cancel Buttons */}
                          <div className="mb-3 mx-2">
                            {loader ? (
                              <Button
                                className="btn btn-primary"
                                style={{
                                  backgroundColor: "#152B51",
                                  color: "#fff",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                                disabled
                              >
                                Loading...
                              </Button>
                            ) : (
                              <Button
                                className="btn btn-primary fontstylerentr"
                                style={{
                                  backgroundColor: "#152B51",
                                  color: "#fff",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                                type="submit"
                                onClick={() => handleSubmitMail(values)}
                                disabled={loader}
                              >
                                {editData ? "Update Email" : "Add Email"}
                              </Button>
                            )}
                            {/* Cancel Button */}
                            <Button
                              className="fontstylerentr"
                              variant=""
                              style={{
                                backgroundColor: "#fff",
                                color: "#152B51",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              onClick={() => {
                                setClick(false);
                                setEditData(null);
                                setFormikValues({
                                  email_configration_id: "",
                                  superadmin_id: "",
                                  host: "",
                                  port: "",
                                  user: "",
                                  pass: "",
                                  secure: "",
                                  from_name: "",
                                  from_email: "",
                                });
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <>
                      <div className="mb-3 mx-2">
                        <Button
                          className="btn1 btn-primary fontstylerentr"
                          color="primary"
                          style={{ background: "#152B51", color: "#fff" }}
                          onClick={() => setClick(true)}
                        >
                          Add New Configuration
                        </Button>
                      </div>
                      <div className="row row-cols-1 row-cols-md-2 g-4">
                        {mailData &&
                          mailData.map((data, index) => (
                            <MailConfigurationCard
                              key={index}
                              data={data}
                              getMailData={getMailData}
                              click={click}
                              setClick={setClick}
                              setEditData={setEditData}
                            />
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default EmailTable;
