import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  Label,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import * as React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import { useState } from "react";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./tenantstyle.css";
import Img from "../../assets/img/theme/site-logo.png";

import logoa from "../../assets/icons/websitelogo.svg";

import jsPDF from "jspdf";
import "jspdf-autotable";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import Surcharge from "./Surcharge";

const Staffdailytransactionreport = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState(null);
  let [transactionData, setTransactionData] = useState([]);
  let [reportData, setReportData] = useState([]);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [chargetypedropdownopen, setChargetypeDropdownOpen] = useState(false);
  const toggle3 = () => setChargetypeDropdownOpen((prevState) => !prevState);
  let [expireData, setExpireData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let [loader, setLoader] = useState(true);
  const { admin } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  // const [pageItem, setPageItem] = useState(10);
  // const [upArrow, setUpArrow] = useState([]);
  // const [sortBy, setSortBy] = useState([]);
  // const [properties, setProperties] = useState('All rentals');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecorrds] = useState(0);

  let navigate = useNavigate();

  
  const [cookies] = useCookies(["token"]);
  React.useEffect(() => {
      const checkToken = async () => {
        try {
          const response = await axiosInstance.post(`/staffmember/token_check`, {
            token: cookies.token,
          });
          setAccessType(response.data);
  
          if ("Admin Not Found" === response.data.message) {
            navigate("/auth/login");
          }
        } catch (error) {
          console.error("Error checking token:", error);
          // Handle error here, maybe redirect to login page or show an error message
        }
      };
  
      if (cookies.token) {
        checkToken();
      } else {
        navigate("/auth/login");
      }
    }, [cookies.token, navigate]);

  // let getPaymentsData = async () => {
  //   if (accessType?.admin_id) {
  //     try {
  //       let response = await axiosInstance.get(
  //         `/payment/todayspayment/${accessType?.admin_id}`
  //       );
  //       if (response.data.statusCode === 200) {
  //         let data = response.data.data;
  //         setTransactionData(data);
  //         setTotalPages(Math.ceil(data.length / pageItem));
  //         // setLoader(false);
  //       } else {
  //         console.log(response.data.message);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching tenants data:", error);
  //     } finally {
  //       setLoader(false);
  //     }
  //   }
  // };

  // React.useEffect(() => {
  //   getPaymentsData();
  //   //  getTenantsLimit();
  // }, [pageItem, accessType, upArrow, sortBy]);

  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // format date to YYYY-MM-DD
  });

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value); // Update the date on selection
    setCurrentPage(1); // Reset to page 1
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      setCurrentPage(prevPage);
    }
  };

  const [selectedChargeType, setSelectedChargeType] =
    useState("Select charge type");

  const getPaymentsData = async () => {
    if (accessType?.admin_id) {
      setLoader(true);
      try {
        const params = {
          selectedDate: selectedDate,
          page: currentPage,
          limit: itemsPerPage,
        };

        if (
          selectedChargeType !== "All" &&
          selectedChargeType !== "Select charge type"
        ) {
          params.selectedChargeType = selectedChargeType;
        }

        const response = await axiosInstance.get(
          `/payment/todayspayment/${accessType?.admin_id}`,
          { params }
        );
        if (response.data.statusCode === 200) {
          const data = response.data.data;
          setTransactionData(data);
          setTotalPages(response.data.totalPages);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching payments data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    getPaymentsData();
  }, [
    accessType,
    currentPage,
    itemsPerPage,
    selectedDate,
    totalPages,
    selectedChargeType,
  ]);

  const handleChargeTypeSelect = (type) => {
    setSelectedChargeType(type);
  };

  const getReportData = async () => {
    if (accessType?.admin_id) {
      try {
        const params = {
          selectedDate: selectedDate,
        };
        if (
          selectedChargeType !== "All" &&
          selectedChargeType !== "Select charge type"
        ) {
          params.selectedChargeType = selectedChargeType;
        }
        const response = await axiosInstance.get(
          `/payment/dailyreport/${accessType?.admin_id}`,
          { params }
        );
        const data = response.data.data;
        setReportData(data);
      } catch (error) {
        console.error("Error fetching payments data:", error);
      }
    }
  };
  React.useEffect(() => {
    getReportData();
  }, [accessType, selectedDate, selectedChargeType]);

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, [accessType]);

  //pdf generate
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  // Update the handleOptionClick function
  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF("l");

    // Add company logo
    const img1Width = 15;
    const img1Height = 13;
    doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

    // Add the title "Daily Transaction Report"
    const titleYPosition = 10 + 10;
    doc.setFontSize(20);
    doc.text(
      "Daily Transaction Report",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    // Add the subtitle with the selected date
    const subtitleYPosition = titleYPosition + 10;
    doc.setFontSize(12);
    doc.text(
      `Date: ${selectedDate}`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    // Add contact information on the right
    const contactInfo = [
      userProfile?.company_name || "",
      userProfile?.company_address || "",
      [userProfile?.city, userProfile?.state, userProfile?.country]
        .filter(Boolean)
        .join(", "),
      userProfile?.postal_code || "",
    ]
      .filter(Boolean)
      .join("\n");
    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20;
    const lineHeight = 4;

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight
      );
    });

    // Create table for transaction details
    const tableYPosition = subtitleYPosition + 20;

    const tableColumns = [
      { header: "Property", dataKey: "property" },
      { header: "Tenant", dataKey: "tenant" },
      { header: "Date", dataKey: "date" },
      { header: "Pmt Type", dataKey: "type" },
      { header: "Txn ID", dataKey: "transaction_id" },
      { header: "Reference", dataKey: "reference" },
      { header: "Crd Type", dataKey: "cc_type" },
      { header: "Crd No", dataKey: "cc_number" },
      { header: "Total", dataKey: "total" },
    ];

    // Add rows for each transaction by mapping the input data
    const tableRows = reportData?.map((transaction) => {
      return [
        {
          content:
            (transaction.rental_data?.rental_adress || "") +
            (transaction.unit_data?.rental_unit
              ? " - " + transaction.unit_data?.rental_unit
              : ""),
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: transaction.tenant_data?.tenant_firstName
            ? `${transaction.tenant_data?.tenant_firstName} ${transaction.tenant_data?.tenant_lastName}`
            : "N/A",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: transaction?.createdAt || "-",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content:
            transaction?.payment_type +
            (transaction?.type === "Refund" ? " (Refund)" : ""),
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        // {
        //   content: transaction?.payment_type || "N/A",
        //   styles: { borderBottom: "none", fontStyle: "bold" },
        // },
        // {
        //   content: transaction?.payment_type || "N/A",
        //   styles: { borderBottom: "none", fontStyle: "bold" },
        // },
        {
          content: transaction?.transaction_id || "N/A",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: transaction?.payment_id || "N/A",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: transaction?.cc_type || "N/A",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: transaction?.cc_number || "N/A",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: `$${transaction?.total_amount?.toFixed(2) || 0}`,
          styles: { borderBottom: "none", fontStyle: "bold", halign: "right" },
        },
      ];
    });

    const tableEntry = reportData?.map((transaction) => {
      // Safely map nested details and amounts, ensuring no null values
      const nestedDetails = (transaction?.entry || [])
        .map((entry) => entry?.account || "N/A") // Fallback to "N/A" if account is null or undefined
        .join("\n");

      const nestedDetailsAmount = (transaction?.entry || [])
        ?.map((entry) => {
          const amount =
            entry?.amount !== undefined && entry?.amount !== null
              ? entry?.amount
              : 0;
          return `$${amount.toFixed(2)}`; // Ensure amount is a number before calling toFixed()
        })
        .join("\n");

      // Check if surcharge exists, and handle null or undefined gracefully
      const surcharge = transaction?.surcharge;
      const hasSurcharge =
        surcharge !== undefined && surcharge !== null && surcharge > 0;

      // Prepare surcharge content if available, ensuring surcharge is a valid number
      const surchargeDetails = hasSurcharge
        ? `${Number(surcharge).toFixed(2)}`
        : "";

      // Prepare row for nested details and surcharge (if exists)
      const detailsRow = [
        {
          content: `${nestedDetails}${hasSurcharge ? "\nSurcharge" : ""}`, // Add "Surcharge" label only if surcharge exists
          colSpan: 8,
          styles: {
            halign: "left",
            cellPadding: { top: 1, bottom: 5, left: 15, right: 0 },
            borderTop: 0,
            fontSize: 9, // Adjusted font size
          },
          border: 0,
        },
        {
          content: `${nestedDetailsAmount}${
            hasSurcharge ? `\n${surchargeDetails}` : ""
          }`, // Add surcharge value only if it exists
          styles: {
            halign: "right",
            cellPadding: { top: 1, bottom: 5, left: 0, right: 2 },
            borderTop: 0,
            fontSize: 9, // Adjusted font size
          },
          border: 0,
        },
      ];

      return detailsRow;
    });

    const result = [];
    const maxLength = Math.max(tableRows.length, tableEntry.length);

    for (let i = 0; i < maxLength; i++) {
      if (tableRows[i] !== undefined) result.push(tableRows[i]);
      if (tableEntry[i] !== undefined) result.push(tableEntry[i]);
    }

    // Calculate the total amount for all transactions
    const totalAmount = reportData
      .reduce((sum, t) => sum + t.total_amount, 0)
      .toFixed(2);

    // Add the total amount row
    const totalRow = [
      {
        content: `TOTAL: $${totalAmount}`,
        colSpan: 9,
        styles: { halign: "right", fontStyle: "bold", fontSize: 14 },
      },
    ];

    // Add the total row to the result array
    result.push(totalRow);

    // Add the table to the PDF
    doc.autoTable({
      startY: tableYPosition,
      head: [tableColumns.map((col) => col.header)],
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 11,
        fontStyle: "bold",
      },
      body: result,
      theme: "plain",
      styles: { fontSize: 10, cellPadding: 2 },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
        4: { cellWidth: 25 },
        5: { cellWidth: 30 },
        6: { cellWidth: 25 },
        7: { cellWidth: 35 },
        8: { cellWidth: 25 },
      },
    });

    // Draw the double horizontal lines before the total
    const lineSpacing = 3; // Space between lines
    const lineYPosition1 = doc.autoTable.previous.finalY - 10; // Adjusted to be above the total row
    const lineYPosition2 = lineYPosition1 - lineSpacing;

    // Ensure the line positions are valid
    if (lineYPosition1 > 10 && lineYPosition2 > 10) {
      // Ensure the lines are within the page bounds
      doc.setDrawColor(0, 0, 0); // Black color for the lines
      doc.setLineWidth(0.5); // Line width
      doc.line(
        10,
        lineYPosition1,
        doc.internal.pageSize.getWidth() - 10,
        lineYPosition1
      );
      doc.line(
        10,
        lineYPosition2,
        doc.internal.pageSize.getWidth() - 10,
        lineYPosition2
      );
    } else {
      console.error("The lines are positioned outside the page bounds.");
    }

    // Save the PDF
    doc.save(`Daily_transaction_report_${selectedDate}.pdf`);
  };

  const generateExcel = () => {
    const wb = XLSX.utils.book_new();

    const uniqueAccounts = new Set();
    reportData.forEach((transaction) => {
      transaction.entry?.forEach((entry) => {
        uniqueAccounts.add(entry.account); // Add unique account names
      });
    });

    const accountHeaders = Array.from(uniqueAccounts);

    const worksheetHeader = [
      "Property",
      "Tenant",
      "Date",
      "Payment Type",
      "Transaction Id",
      "Reference",
      "Card Type",
      "Card No",
      ...accountHeaders, // Add dynamic account headers
      "Surcharge",
      "Total",
    ];

    const worksheetData = [
      worksheetHeader,
      ...reportData.map((transaction) => {
        const accountAmounts = accountHeaders.map((account) => {
          // Find the amount for each account in this transaction
          const entry = transaction.entry?.find((e) => e.account === account);
          return entry ? entry.amount : "0"; // Return the amount or "0" if not found
        });

        return [
          (transaction.rental_data?.rental_adress || "") +
            (transaction.unit_data?.rental_unit
              ? " - " + transaction.unit_data?.rental_unit
              : ""),
          transaction.tenant_data?.tenant_firstName
            ? `${transaction.tenant_data?.tenant_firstName} ${transaction.tenant_data?.tenant_lastName}`
            : "N/A",
          transaction?.createdAt || "-",
          transaction?.payment_type || "N/A",
          transaction?.transaction_id || "N/A",
          transaction?.payment_id || "N/A",
          transaction?.cc_type || "N/A",
          transaction?.cc_number || "N/A",
          ...accountAmounts, // Add the amounts for each unique account
          transaction?.surcharge?.toFixed(2) || "0.00",
          transaction?.total_amount?.toFixed(2) || "0.0",
        ];
      }),
    ];

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Set column widths
    const wscols = worksheetHeader.map((header) => {
      return { wch: Math.max(header.length + 5, 20) }; // Set a minimum width, and adjust based on header length
    });
    ws["!cols"] = wscols;

    // Define header style with bold font and solid border
    const headerStyle = {
      font: { bold: true, color: "000000", sz: "15" },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
      },
      alignment: { horizontal: "center", vertical: "center" },
    };

    // Apply styles to the entire header row (first row)
    worksheetHeader.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
      ws[cellAddress].s = headerStyle;
    });

    // Apply text wrapping and alignment to all cells
    Object.keys(ws).forEach((cellAddress) => {
      if (ws[cellAddress].s) {
        ws[cellAddress].s.alignment = {
          wrapText: true, // Enable text wrap
          vertical: "center",
          horizontal: ws[cellAddress].s.alignment?.horizontal || "left", // Preserve horizontal alignment if already set
        };
      }
    });

    // Apply right alignment specifically for account amounts
    reportData.forEach((_, rowIndex) => {
      accountHeaders.forEach((_, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({
          r: rowIndex + 1, // Adjust for the header row
          c: worksheetHeader.indexOf(accountHeaders[colIndex]),
        });
        if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
        ws[cellAddress].s = {
          alignment: {
            horizontal: "right", // Align account amounts to the right
            vertical: "center",
            wrapText: true,
          },
        };
      });

      const surchargeCellAddress = XLSX.utils.encode_cell({
        r: rowIndex + 1,
        c: worksheetHeader.indexOf("Surcharge"),
      });
      if (!ws[surchargeCellAddress]) ws[surchargeCellAddress] = { t: "s" };
      ws[surchargeCellAddress].s = {
        alignment: {
          horizontal: "right",
          vertical: "center",
          wrapText: true,
        },
      };

      // Apply right alignment specifically for the Total column
      const totalAmountCellAddress = XLSX.utils.encode_cell({
        r: rowIndex + 1, // Adjust for the header row
        c: worksheetHeader.length - 1, // Last column for 'Total'
      });
      if (!ws[totalAmountCellAddress]) ws[totalAmountCellAddress] = { t: "s" };
      ws[totalAmountCellAddress].s = {
        alignment: {
          horizontal: "right", // Align total amount to the right
          vertical: "center",
          wrapText: true,
        },
      };
    });

    XLSX.utils.book_append_sheet(wb, ws, "Daily Transaction Report");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      `Daily_transaction_report_${selectedDate}.xlsx`
    );
  };

  const generateCSV = () => {
    // Step 1: Collect all unique account names
    const uniqueAccounts = new Set();
    reportData.forEach((transaction) => {
      transaction.entry?.forEach((entry) => {
        uniqueAccounts.add(entry.account); // Add unique account names
      });
    });

    const accountHeaders = Array.from(uniqueAccounts);

    // Step 2: Create the CSV header row with unique accounts
    const csvHeader = [
      "Property",
      "Tenant",
      "Date",
      "Payment Type",
      "Transaction Id",
      "Reference",
      "Card Type",
      "Card No",
      ...accountHeaders, // Add dynamic account headers
      "Surcharge",
      "Total",
    ];

    // Function to format data for CSV
    const formatCSVCell = (cell) => {
      if (typeof cell === "string") {
        return `"${cell.replace(/"/g, '""')}"`; // Escape double quotes in the cell
      }
      return cell === null || cell === undefined ? "" : cell.toString();
    };

    // Step 3: Create CSV data rows
    const csvData = [
      csvHeader.map(formatCSVCell).join(","), // Convert header row to CSV format
      ...reportData.map((transaction) => {
        const accountAmounts = accountHeaders.map((account) => {
          // Find the amount for each account in this transaction
          const entry = transaction?.entry?.find((e) => e.account === account);
          return entry ? `$${entry.amount}` : "-"; // Return the amount or "--" if not found
        });

        return [
          (transaction.rental_data?.rental_adress || "") +
            (transaction.unit_data?.rental_unit
              ? " - " + transaction.unit_data?.rental_unit
              : ""),
          transaction.tenant_data?.tenant_firstName
            ? `${transaction.tenant_data?.tenant_firstName} ${transaction.tenant_data?.tenant_lastName}`
            : "N/A",
          transaction?.createdAt || "-",
          transaction?.payment_type || "N/A",
          transaction?.transaction_id || "N/A",
          transaction?.payment_id || "N/A",
          transaction?.cc_type || "N/A",
          transaction?.cc_number || "N/A",

          ...accountAmounts,
          transaction?.surcharge?.toFixed(2) || "0.0",
          transaction?.total_amount?.toFixed(2) || "0.0",
        ]
          .map(formatCSVCell)
          .join(","); // Convert each row to CSV format
      }),
    ].join("\n"); // Join rows with new line

    // Create and save the CSV file
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Daily_transaction_report_${selectedDate}.csv`);
  };

  return (
    <>
      <Header />
      {/* <Container className="mt--8" fluid> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Daily Transaction Report
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row className="mb-3">
          {/* <Col xs="2" sm="2">
            <FormGroup className="searchspace searchstyle">
              <Input
                className="filterh"
                fullWidth
                type="text"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  minWidth: "200px",
                  fontWeight: "500",
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #ced4da",
                }}
              />
            </FormGroup>
          </Col> */}
          <Col lg="2" md="3" sm="5" xl="2">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor"
                htmlFor="select-date"
                style={{
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                Select Date
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-date"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </FormGroup>
          </Col>
          <Col lg="5" md="5" sm="12" xl="3">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                Charge Type
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle"
                isOpen={chargetypedropdownopen}
                toggle={toggle3}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                  }}
                >
                  {selectedChargeType}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Card")}
                  >
                    Card
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("ACH")}
                  >
                    ACH
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Check")}
                  >
                    Check
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Cash")}
                  >
                    Cash
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Money Order")}
                  >
                    Money Order
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Cashier 's Check")}
                  >
                    Cashier 's Check
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("All")}
                  >
                    All
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
          <Col className="d-flex justify-content-end addtotnstyle">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle
                caret
                style={{
                  marginBottom: "12px",
                  fontSize: "16px",
                  border: "1px solid #152B5180",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  color: "#fff",
                  backgroundColor: "#152B51",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
              >
                Exports
              </DropdownToggle>
              <DropdownMenu style={{ minWidth: "120px" }}>
                <DropdownItem
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#152B51",
                  }}
                  onClick={() => handleOptionClick("PDF")}
                >
                  PDF
                </DropdownItem>
                <DropdownItem
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#152B51",
                  }}
                  onClick={() => handleOptionClick("XLSX")}
                >
                  XLSX
                </DropdownItem>
                <DropdownItem
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#152B51",
                  }}
                  onClick={() => handleOptionClick("CSV")}
                >
                  CSV
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
        <Table
          className="align-items-center table-flush"
          responsive
          style={{ borderCollapse: "collapse" }}
        >
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            // ) : transactionData().length !== 0 ? (
            //   <>
            <>
              <thead
                className=""
                style={{
                  height: "45px",
                  fontSize: "14px",
                  fontFamily: "poppins",
                  fontWeight: "600",
                  boxShadow: " 0px 4px 4px 0px #00000040",
                }}
              >
                <tr
                  style={{
                    border: "2px solid rgba(50, 69, 103, 1)",
                  }}
                >
                  <th
                    className="tablefontstyle"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      color: "#152B51",
                    }}
                  >
                    Tenant Name
                  </th>
                  <th
                    className="tablefontstyle"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      color: "#152B51",
                    }}
                  >
                    Property
                  </th>
                  <th
                    className="tablefontstyle"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      color: "#152B51",
                    }}
                  >
                    Transaction Id
                  </th>
                  <th
                    className="tablefontstyle"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      color: "#152B51",
                    }}
                  >
                    Transaction Date
                  </th>

                  <th
                    className="tablefontstyle"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      color: "#152B51",
                    }}
                  >
                    Transaction Type
                  </th>

                  <th
                    className="tablefontstyle"
                    scope="col"
                    style={{ color: "#152B51" }}
                  >
                    Payment Detail
                  </th>

                  <th
                    className="tablefontstyle"
                    scope="col"
                    style={{ color: "#152B51" }}
                  >
                    Detail line
                  </th>

                  <th
                    className="tablefontstyle"
                    scope="col"
                    style={{ color: "#152B51" }}
                  >
                    Total Transaction Amount
                  </th>
                </tr>
              </thead>

              <tbody>
                {transactionData?.length > 0 ? (
                  transactionData?.map((tenant) => (
                    <tr
                      key={tenant?.transaction_id} // Adding a key to each row
                      style={{
                        cursor: "pointer",
                        border: "0.5px solid rgba(50, 69, 103, 1)",
                        fontSize: "12px",
                        height: "40px",
                        fontFamily: "poppins",
                        fontWeight: "600",
                      }}
                    >
                      <td className="bordertopintd tablebodyfont">
                        {tenant?.tenant_data?.tenant_firstName || "N/A"}{" "}
                        {tenant?.tenant_data?.tenant_lastName || "N/A"}
                      </td>
                      <td className="bordertopintd tablebodyfont">
                        {(tenant?.rental_data?.rental_adress || "") +
                          (tenant?.unit_data?.rental_unit
                            ? " - " + tenant?.unit_data?.rental_unit
                            : " ")}
                      </td>
                      <td className="bordertopintd tablebodyfont">
                        {tenant?.transaction_id || "N/A"}
                      </td>
                      <td className="bordertopintd tablebodyfont">
                        {tenant?.createdAt || "N/A"}
                      </td>
                      <td className="bordertopintd tablebodyfont">
                        {tenant?.payment_type || "N/A"}
                      </td>
                      <td className="bordertopintd tablebodyfont">
                        {!tenant?.cc_type ||
                        Object.keys(tenant.cc_type).length !== 0 ? (
                          <>
                            {tenant?.cc_number || "N/A"} <br />
                            {tenant?.cc_type}
                          </>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td className="bordertopintd tablebodyfont">
                        <span
                          className="tablebodyfont"
                          style={{ color: "#5A86D5" }}
                        >
                          Account
                        </span>
                        <br />
                        {tenant?.entry?.length > 0
                          ? tenant?.entry.map((item) => item?.account)
                          .join(", ")
                          : "No account"}
                        <br />

                        <span
                          className="tablebodyfont"
                          style={{ color: "#5A86D5" }}
                        >
                          Charge
                        </span>
                        <br />
                        {tenant?.entry?.length > 0
                          ? tenant?.entry.map((item) => item?.charge_type)
                          .join(", ")
                          : "No charge"}
                        <br />
                        {/* <span
                          className="tablebodyfont"
                          style={{ color: "#5A86D5" }}
                        >
                          Memo
                        </span>
                        <br />
                        {tenant?.entry?.length > 0
                          ? tenant?.entry[0].memo
                          : "No memo"}
                        <br /> */}
                        <span
                          className="tablebodyfont"
                          style={{ color: "#5A86D5" }}
                        >
                          Amount
                        </span>
                        <br />
                        {tenant?.entry?.length > 0
                          ? tenant?.entry.map((item) => item?.amount)
                          .join(", ")
                          : "0"}
                        <br />
                        <span
                          className="tablebodyfont"
                          style={{ color: "#5A86D5" }}
                        >
                          Surcharge
                        </span>
                        <br />
                        {tenant?.surcharge?.toFixed(2) || "0.00"}
                        <br />
                      </td>
                      <td className="bordertopintd tablebodyfont">
                        <span className="d-flex justify-content-center tablebodyfont">
                          {tenant?.total_amount}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td
                      colSpan="9"
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      No Transaction Found
                    </td>
                  </tr>
                )}
              </tbody>
            </>
            // ) : (
            //   <tbody>
            //     <tr className="text-center">
            //       <td colSpan="8" style={{ fontSize: "15px" }}>
            //         No Daily Transaction
            //       </td>
            //     </tr>
            //   </tbody>
          )}
        </Table>
        {/* {paginatedData.length > 0 ? ( */}
        <Row>
          <Col className="text-right mt-3 mb-3">
            <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
              <DropdownToggle className="paginationbtnstyle" caret>
                {itemsPerPage} {/* Displaying selected items per page */}
              </DropdownToggle>
              <DropdownMenu>
                {[10, 25, 50, 100].map((perPage) => (
                  <DropdownItem
                    key={perPage}
                    onClick={() => setItemsPerPage(perPage)} // Update itemsPerPage on click
                  >
                    {perPage}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button
              className="p-0"
              style={{ backgroundColor: "#d0d0d0" }}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-caret-left"
                viewBox="0 0 16 16"
              >
                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
              </svg>
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>{" "}
            <Button
              className="p-0"
              style={{ backgroundColor: "#d0d0d0" }}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-caret-right"
                viewBox="0 0 16 16"
              >
                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
              </svg>
            </Button>
          </Col>
        </Row>

        <ToastContainer />
      </Container>
    </>
  );
};

export default Staffdailytransactionreport;
