import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import BaseButton from "components/ReusableComponents/BaseButton";
import Loader from "components/ReusableComponents/Loader";
import { updateCardTransactionType } from "plugins/ApiHandler";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { GetCardTransactionType } from "plugins/ApiHandler";

const CardTransactionTypeSettings = ({ rentalOwnerId }) => {
  const [getCardTypesLoading, setGetCardTypesLoading] = useState(false);
  const [updateCardTypesLoading, setUpdateCardTypesLoading] = useState(false);
  const [getCardTypeError, setGetCardTypesError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    rentalOwnerId,
    creditCardAccepted: false,
    debitCardAccepted: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const handleDisableChange = () => {
      setDisabled(!formData.creditCardAccepted && !formData.debitCardAccepted);
    };

    handleDisableChange();
  }, [formData]);

  const handleUpdateSettings = async () => {
    try {
      setUpdateCardTypesLoading(true);

      toast.success("Card type updated successfully", {
        position: "top-center",
        autoClose: 1000,
      });
      setTimeout(async () => {
        updateCardTransactionType(formData);
      }, 1200);
      setGetCardTypesError("");
    } catch (error) {
      toast.error(error);
    } finally {
      setUpdateCardTypesLoading(false);
    }
  };

  // Get card transaction type debit/credit
  useEffect(() => {
    const fetchData = async () => {
      if (rentalOwnerId) {
        try {
          setGetCardTypesLoading(true);
          setGetCardTypesError("");

          const response = await GetCardTransactionType(rentalOwnerId);
          const data = response.data;

          setFormData({
            rentalOwnerId,
            creditCardAccepted: data.creditCardAccepted,
            debitCardAccepted: data.debitCardAccepted,
          });
        } catch (error) {
          setGetCardTypesError(error);
        } finally {
          setGetCardTypesLoading(false);
        }
      }
    };

    fetchData();

    // No cleanup function needed
  }, [rentalOwnerId]);

  return (
    <>
      {getCardTypesLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h3
              className="mb-0 labelfontstyle textcolorblue fontfamilysty"
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Card Transaction Type Management
            </h3>
            <p className="textcolorblue fontfamilysty">
              Select the type of card you wish to accept
            </p>
            <div>
              <FormGroup>
                <FormControlLabel
                  className="textcolorblue fontfamilysty"
                  control={
                    <Checkbox
                      style={{ color: "#152B51" }}
                      checked={formData.creditCardAccepted}
                      onChange={handleChange}
                      name="creditCardAccepted"
                    />
                  }
                  label="Credit Card"
                />
                <FormControlLabel
                  className="textcolorblue fontfamilysty"
                  control={
                    <Checkbox
                      style={{ color: "#152B51" }}
                      checked={formData.debitCardAccepted}
                      onChange={handleChange}
                      name="debitCardAccepted"
                    />
                  }
                  label="Debit Card"
                />
                {getCardTypeError && (
                  <p
                    style={{
                      textAlign: "center",
                      color: "red",
                      margin: "10px 0px",
                    }}
                  >
                    {getCardTypeError}
                  </p>
                )}
                <BaseButton
                  onClick={handleUpdateSettings}
                  loading={updateCardTypesLoading}
                  disabled={disabled}
                  label="Update"
                  style={{ marginTop: "20px" }}
                />
              </FormGroup>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default CardTransactionTypeSettings;
