import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "components/Headers/Header.js";
import { useCookies } from "react-cookie";
import axiosInstance from "axiosInstance";
import axios from "axios";

const Reports = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate();
  const { admin } = useParams();
  const [accessType, setAccessType] = useState(null);
  const [cookies] = useCookies(["token"]);
  const [hoveredCard, setHoveredCard] = useState(null);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/admin/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  const cardStyle = (isHovered) => ({
    cursor: "pointer",
    height: "150px",
    borderRadius: "20px",
    boxShadow: isHovered
      ? "rgba(0, 0, 0, 0.35) 0px 8px 15px"
      : "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
    display: "flex",
    flexDirection: "column",
    transform: isHovered ? "scale(1.05)" : "scale(1)",
    transition: "transform 0.3s, box-shadow 0.3s",
  });

  const handleCardClick = (report) => {
    const path = report.replace(/[-\s']/g, "");
    navigate(`/${admin}/${path}`);
  };

  const reportDescriptions = {
    "Renter's Insurance": "Produces a list of all insured units",
    "Expiring Leases":
      "Lists all leases that will end during a specified timeframe",
    "Delinquent Tenants":
      "Tenants with an outstanding ledger balance as of a specific date",
    "Open Work Orders": "Report of all Work Orders not yet in a complete state",
    "Completed Work Orders": "Report of all completed Work Orders",
    "Daily Transaction Report": "Reports of daily transaction",
    "Rental Owner Report": "Reports of rental owner transaction",
  };

  return (
    <div>
      <Header />
      <Container fluid>
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <h2
              className="headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Reports
            </h2>
          </CardHeader>
        </Col>
        <Row className="mt-4">
          {/* <div className="col"> */}
          <Col className="order-xl-1" xl="12">
            <Row>
              <Col xs={12}>
                <Row className="pl-2">
                  {Object.keys(reportDescriptions).map((report, index) => (
                    <Col
                      className="pt-3"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={4}
                      key={index}
                    >
                      <Col>
                        <Card
                          className="second-cards dashcardsheight"
                          style={cardStyle(hoveredCard === index)}
                          onMouseEnter={() => setHoveredCard(index)}
                          onMouseLeave={() => setHoveredCard(null)}
                          onClick={() => handleCardClick(report)}
                        >
                          <Row>
                            <Col lg={12}>
                              <span
                                style={{
                                  height: "50px",
                                  backgroundColor: "#152B51",
                                  borderTopLeftRadius: "20px",
                                  borderTopRightRadius: "20px",
                                  color: "#fff",
                                  fontFamily: "Poppins",
                                  fontWeight: "600",
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                                  fontSize: "16px",
                                }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                {report}
                              </span>
                            </Col>
                          </Row>
                          {/* <Row>
                              <Col> */}
                          <CardBody
                          // style={{
                          //   flexGrow: 1,
                          //   display: "flex",
                          //   flexDirection: "column",
                          //   justifyContent: "space-between",
                          // }}
                          // className=""
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                fontSize: "16px",
                              }}
                              className="d-flex justify-content-center align-items-center barforsummaryrent  titleecolor"
                            >
                              {reportDescriptions[report]}
                            </span>
                          </CardBody>
                          {/* </Col>
                            </Row> */}
                        </Card>
                      </Col>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
          {/* </div> */}
        </Row>
      </Container>
    </div>
  );
};

export default Reports;
