import { useState } from "react";
import { Input } from "reactstrap";

const BaseInput = ({
  placeholder,
  id,
  type,
  required,
  label,
  pattern,
  styles,
  onChange,
  name,
  value,
  showStar,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const labelStyles = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    margin: "5px 0px",
  };

  const inputStyles = {
    display: "block",
    width: "100%",
    height: "calc(1.5em + 1.25rem + 2px)",
    padding: "0.625rem 0.75rem",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#474747",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: isFocused ? "1px solid #0279dd" : "1px solid var(--bg-blue-color)",
    borderRadius: "0.375rem",
    boxShadow: "none",
    transition: "all 0.2s ease",
  };

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div style={styles}>
      {label && (
        <label htmlFor={id} style={labelStyles}>
          {label}{" "}
          {(required || showStar) && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <Input
        onChange={handleChange}
        type={type}
        style={inputStyles}
        id={id}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        required={required}
        pattern={pattern}
        name={name}
        value={value}
      />
    </div>
  );
};

export default BaseInput;
