import React from "react";
import {
  Label, 
  Input,
} from "reactstrap";
const CheckBoxComponent = ({ label, id, name, checked, onChange, disabled }) => {
  return (
    <Label
      check
      className=" titleecolor"
      style={{
        fontWeight: "500",
        fontFamily: "Poppins",
        fontSize: "16px",
        textTransform: "none",
        marginRight:"60px"
      }}
    >
      <Input
        type="checkbox"
        className="checkbox-custom"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}

      />
      {label}
    </Label>
  );
};

export default CheckBoxComponent;
