import "./AddPaymentByTenant.scss";
import AddCardDetailsForm from "./AddCardDetailsForm";
import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import {
  getCardDetails,
  getCardLogo,
  getCardType,
  getCurrentBalance,
  getCustomerVaultId,
  getRentalOwnerCardTypeSettings,
  getTokenizationKey,
  makePayment,
} from "../../../plugins/ApiHandler";
import BaseInput from "components/ReusableComponents/BaseInput";
import Loader from "components/ReusableComponents/Loader";
import BaseButton from "components/ReusableComponents/BaseButton";
import { addTokenizationScript } from "plugins/helpers";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { currencyFormatter } from "plugins/helpers";
import axiosInstance from "axiosInstance";
import Dropdown from "components/ReusableComponents/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyToken } from "components/Functions/Functions";

const AddPaymentByTenant = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalShow, setModalShow] = useState(false);
  const [cardDetalis, setCardDetails] = useState([]);
  const [getCardLoading, setGetCardLoading] = useState(false);
  const [getCardError, setGetcardError] = useState("");
  const [scriptGenerating, setScriptGenerating] = useState(false);
  const [scriptError, setScriptError] = useState("");
  const [dueAmountDetails, setDueAmountDetails] = useState({});
  const [selectedCardData, setSelectedCardData] = useState({});
  const [partialAmount, setPartialAmount] = useState(0);
  const [dueAmountLoading, setDueAmountLoading] = useState(false);
  const [dueAmountError, setDueAmountError] = useState("");
  const [paymentAmountType, setPaymentAmountType] = useState("full");
  const [propertyDropdownData, setPropertyDropdownData] = useState([]);
  const [leaseId, setLeaseId] = useState("");
  const [accessType, setAccessType] = useState(undefined);
  const [rentalAddress, setRentalAddress] = useState("");

  const [cookie, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const getNmiKey = async (tenant_id) => {
    setScriptGenerating(true);
    setScriptError("");

    try {
      const keyResponse = await getTokenizationKey(tenant_id);
      await addTokenizationScript(keyResponse.publicKey);
    } catch (error) {
      setScriptError(
        error ||
          "Failed to load the tokenization script. Make sure you have suitable internet connection."
      );
    } finally {
      setScriptGenerating(false);
    }
  };

  const getTenantDueAmount = async (leaseId) => {
    const tenant_id = accessType?.tenant_id;
    if (tenant_id && leaseId) {
      try {
        setDueAmountLoading(true);
        setDueAmountError("");

        const data = await getCurrentBalance(tenant_id, leaseId);
        setDueAmountDetails(data);
      } catch (error) {
        if (typeof error === "string") setDueAmountError(error);
        else setDueAmountError(JSON.stringify(error));
      } finally {
        setDueAmountLoading(false);
      }
    } else {
      setDueAmountLoading(false);
    }
  };

  const hanleRadioSelection = (event) => {
    setPaymentAmountType(event.target.value);
  };

  const getCardsData = async () => {
    if (!leaseId) return;
    try {
      setGetCardLoading(true);
      setGetcardError("");

      const customerData = await getCustomerVaultId(accessType?.tenant_id);
      const customer_vault_id = customerData.customer_vault_id;
      const cardDetails = await getCardDetails({
        customer_vault_id,
        admin_id: accessType?.admin_id,
      });

      const billingData = cardDetails?.data?.customer;

      if (Array.isArray(billingData.billing)) {
        const extractedData = await Promise.all(
          billingData.billing.map((item) => handleSetCardDetails(item))
        );

        // Filter out any undefined or null values (which would be returned if card type is not accepted)
        const filteredData = extractedData.filter((item) => item);

        setCardDetails(filteredData);
      } else if (billingData?.billing) {
        // Handle single record
        const extractedData = await handleSetCardDetails(billingData.billing);

        if (extractedData) {
          // Only set the value if it's not undefined
          setCardDetails([extractedData]);
        }
      } else {
        console.error(
          "Invalid response structure - customer.billing is not an array"
        );
        setCardDetails([]);
      }
    } catch (error) {
      if (typeof error === "string") setGetcardError(error);
      else setGetcardError(JSON.stringify(error));
    } finally {
      setGetCardLoading(false);
    }
  };

  const handleSetCardDetails = async (item) => {
    try {
      const cardType = await getCardType(item.cc_bin, item.cc_type);
      const response = await getRentalOwnerCardTypeSettings(
        accessType?.tenant_id,
        leaseId
      );
      const cardLogo = await getCardLogo(item.cc_type);
      const { creditCardAccepted, debitCardAccepted } = response.data;

      // Check if the card type is accepted
      if (
        (cardType.toLowerCase() === "credit" && !creditCardAccepted) ||
        (cardType.toLowerCase() === "debit" && !debitCardAccepted)
      )
        item.allowPayment = false;
      else item.allowPayment = true;

      return {
        allowPayment: item.allowPayment,
        billing_id: item["@attributes"].id,
        cc_number: item.cc_number,
        cc_exp: item.cc_exp,
        cc_type: item.cc_type,
        cc_bin: item.cc_bin,
        customer_vault_id: item.customer_vault_id,
        card_type: cardType,
        card_logo: cardLogo,
      };
    } catch (error) {
      console.error(error, "error in bin check");
      return {};
    }
  };

  useEffect(() => {
    if (accessType?.tenant_id) getNmiKey(accessType?.tenant_id);
  }, [accessType?.tenant_id]);

  useEffect(() => {
    if (accessType?.tenant_id && accessType?.admin_id && leaseId) {
      getCardsData();
    }
  }, [accessType?.tenant_id, accessType?.admin_id, leaseId]);

  const handleCardSelection = (event, data) => {
    if (event.target.checked) {
      setSelectedCardData(data);
    } else {
      setSelectedCardData({});
    }
  };

  const calculateSurcharge = (amount, cardType, dueDetails) => {
    let percentage =
      cardType === "DEBIT"
        ? dueDetails?.enable_override_fee
          ? dueDetails?.override_fee
          : dueDetails?.surcharge?.surcharge_percent_debit || 0
        : dueDetails?.surcharge?.surcharge_percent || 0;

    return (Number(amount) * Number(percentage)) / 100;
  };

  useEffect(() => {
    const setSurcharge = () => {
      if (selectedCardData?.billing_id) {
        const amount =
          paymentAmountType === "partial"
            ? partialAmount
            : dueAmountDetails.total_due_amount;
        const surchargeAmount = calculateSurcharge(
          amount,
          selectedCardData.card_type,
          dueAmountDetails
        );

        setDueAmountDetails((values) => ({
          ...values,
          surcargeAmount: surchargeAmount,
        }));
      } else {
        setDueAmountDetails((values) => ({
          ...values,
          surcargeAmount: null,
        }));
      }
    };

    setSurcharge();
  }, [partialAmount, paymentAmountType, selectedCardData]);

  const handleSubmit = async () => {
    if (!selectedCardData?.billing_id) {
      return;
    }
    const object = {
      admin_id: dueAmountDetails.admin_id,
      lease_id: dueAmountDetails.lease_id,
      tenant_firstName: dueAmountDetails?.tenant?.tenant_firstName,
      tenant_lastName: dueAmountDetails?.tenant?.tenant_lastName,
      tenant_email: dueAmountDetails?.tenant?.tenant_email,
      customer_vault_id: selectedCardData?.customer_vault_id,
      billing_id: selectedCardData?.billing_id,
      surcharge: dueAmountDetails?.surcargeAmount,
      total_amount: Number(
        paymentAmountType === "partial"
          ? partialAmount
          : dueAmountDetails?.total_due_amount || 0
      ),
      tenant_id: dueAmountDetails?.tenant_id,
      processor_id: dueAmountDetails?.processorId,
    };
    setDueAmountLoading(true);
    setPartialAmount(0);
    await makePayment(object);
    await getTenantDueAmount();
  };

  const [getLeasesLoading, setGetLeasesLoading] = useState(false);
  const [getLeasesError, setGetLeasesError] = useState("");

  const fetchLedger = async () => {
    const cookies = new Cookies();
    if (accessType?.tenant_id) {
      const tenant_id = accessType.tenant_id;

      if (tenant_id) {
        try {
          setGetLeasesLoading(true);
          setGetLeasesError("");

          const response = await axiosInstance.get(
            `/leases/get_leases/${tenant_id}`
          );
          setPropertyDropdownData(response.data.data.leases);
        } catch (error) {
          setGetLeasesError(error);
          console.error("Error fetching tenant details:", error);
        } finally {
          setGetLeasesLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchLedger();
  }, [accessType]);

  const handleSelection = (property) => {
    setRentalAddress(property.rental_adress);
    setLeaseId(property.lease_id);
    getTenantDueAmount(property.lease_id);
  };

  return (
    <div className="add-payment-page">
      <div className="add-payment-page--card-details card">
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <label
            className="crm-input-label"
            style={{ textTransform: "uppercase" }}
          >
            Leases <span className="required">*</span>
          </label>
          <Dropdown
            loading={getLeasesLoading}
            emptyMsg={getLeasesError}
            disabled={dueAmountLoading || getCardLoading}
            options={propertyDropdownData}
            labelKey="rental_adress"
            placeholder="Select a lease"
            valueKey="lease_id"
            onSelect={handleSelection}
          />
        </div>

        <p className="heading">Payment Card Details</p>
        {getCardLoading ? (
          <Loader />
        ) : getCardError ? (
          <p>{getCardError}</p>
        ) : cardDetalis.length === 0 ? (
          <p>
            No Card Found. If you have not selected any lease, please select it
            first.
          </p>
        ) : (
          cardDetalis.map((el) => (
            <div className="payment-card">
              <Tooltip
                arrow
                title={
                  el.allowPayment
                    ? ""
                    : `${el.card_type} card types not accepted by rental owner`
                }
              >
                <span>
                  <Checkbox
                    disabled={!el.allowPayment}
                    style={{
                      color: "#152B51",
                      opacity: el.allowPayment ? "1" : "0.4",
                    }}
                    onChange={(e) => handleCardSelection(e, el)}
                    checked={el.billing_id === selectedCardData.billing_id}
                  />
                </span>
              </Tooltip>
              <div className="card-inner">
                <p className="label">Card Number</p>
                <p className="label">Card Type</p>
                <p className="value">{el.cc_number}</p>
                <p className="value">{el.card_type}</p>
              </div>
              {el.card_logo && (
                <img
                  src={el.card_logo}
                  style={{ width: "30px", height: "fit-content" }}
                />
              )}
            </div>
          ))
        )}

        {scriptGenerating ? (
          <p>NMI card fields loading...</p>
        ) : scriptError ? (
          <p style={{ color: "red" }}>{scriptError}</p>
        ) : (
          <>
            {!scriptGenerating && !scriptError ? (
              <div>
                <AddCardDetailsForm
                  rentalAddress={rentalAddress}
                  getCardsData={getCardsData}
                  onHide={() => setModalShow(false)}
                  show={modalShow}
                  scriptGenerating={scriptGenerating}
                  getCardLoading={getCardLoading}
                  getCardError={getCardError}
                  scriptError={scriptError}
                  cardDetalis={cardDetalis}
                  tenant_id={accessType?.tenant_id}
                  admin_id={accessType?.admin_id}
                />
                <BaseButton
                  onClick={() => setModalShow(true)}
                  label="Add a New Card"
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className="add-payment-page--functions card">
        {dueAmountLoading ? (
          <Loader />
        ) : dueAmountError ? (
          <p style={{ textAlign: "center" }}>{dueAmountError}</p>
        ) : (
          <>
            <div className="current-balance">
              <p className="heading">Current Balance</p>
              <p className="amount">
                ${Number(dueAmountDetails?.total_due_amount || 0).toFixed(2)}
              </p>
            </div>
            <div className="choose-balance">
              <p className="heading">Choose Payment Amount</p>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={paymentAmountType}
                name="radio-buttons-group"
                onChange={hanleRadioSelection}
              >
                <FormControlLabel
                  value="full"
                  control={<Radio style={{ color: "#152B51" }} />}
                  label={`Pay Full Amount (${currencyFormatter(
                    dueAmountDetails?.total_due_amount
                  )})`}
                />
                <FormControlLabel
                  value="partial"
                  control={<Radio style={{ color: "#152B51" }} />}
                  label={`Pay Partial Amount`}
                />
              </RadioGroup>
            </div>
            {paymentAmountType === "partial" && (
              <BaseInput
                placeholder="Enter Partial Amount"
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) &&
                    e.target.value <= dueAmountDetails?.total_due_amount
                  ) {
                    setPartialAmount(e.target.value);
                  }
                }}
                value={partialAmount}
              />
            )}
            <div>
              <div className="amount-selection">
                <p style={{ fontWeight: "bold" }}>Payment: </p>
                <label htmlFor="halfAmount">
                  $
                  {Number(
                    paymentAmountType === "partial"
                      ? partialAmount
                      : dueAmountDetails?.total_due_amount || 0
                  ).toFixed(2)}
                </label>
              </div>
              <div className="amount-selection">
                <p style={{ fontWeight: "bold" }}>Surcharge:</p>
                <label htmlFor="halfAmount">
                  ${Number(dueAmountDetails?.surcargeAmount || 0).toFixed(2)}
                </label>
              </div>
              <div className="amount-selection">
                <p style={{ fontWeight: "bold" }}>Amount to pay: </p>
                <label htmlFor="halfAmount">
                  $
                  {Number(
                    Number(
                      paymentAmountType === "partial"
                        ? partialAmount
                        : dueAmountDetails?.total_due_amount || 0
                    ) + Number(dueAmountDetails?.surcargeAmount || 0)
                  ).toFixed(2)}
                </label>
              </div>
            </div>
            <BaseButton
              disabled={
                !dueAmountDetails?.total_due_amount ||
                dueAmountDetails?.total_due_amount === 0
                  ? true
                  : false
              }
              label="Make a Payment"
              onClick={handleSubmit}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AddPaymentByTenant;
