import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./properties.css";
import axiosInstance from "axiosInstance";
import CheckBoxComponent from "../../components/CheckBoxComponent";

const Userrole = () => {
  const { admin } = useParams();
  const navigate = useNavigate();
  const [accessType, setAccessType] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const [userroleData, setUserroleData] = useState(null);

  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/admin/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  let userroleFormik = useFormik({
    initialValues: {
      admin_id: "",

      tenant_permission: {
        property_view: false,
        financial_view: false,
        financial_add: false,
        financial_edit: false,
        workorder_view: false,
        workorder_add: false,
        workorder_edit: false,
        workorder_delete: false,
        documents_view: false,
        documents_add: false,
        documents_edit: false,
        documents_delete: false,
      },

      staff_permission: {
        property_view: false,
        property_add: false,
        property_edit: false,
        property_delete: false,
        tenant_view: false,
        tenant_add: false,
        tenant_edit: false,
        tenant_delete: false,
        lease_view: false,
        lease_add: false,
        lease_edit: false,
        lease_delete: false,
        workorder_view: false,
        workorder_add: false,
        workorder_edit: false,
        workorder_delete: false,
        propertytype_view: false,
        propertytype_add: false,
        propertytype_edit: false,
        propertytype_delete: false,
        rentalowner_view: false,
        rentalowner_add: false,
        rentalowner_edit: false,
        rentalowner_delete: false,
        applicant_view: false,
        applicant_add: false,
        applicant_edit: false,
        applicant_delete: false,
        vendor_view: false,
        vendor_add: false,
        vendor_edit: false,
        vendor_delete: false,
        setting_view: false,
      },
      vendor_permission: {
        workorder_view: false,
        workorder_edit: false,
      },
    },
    onSubmit: handleSubmit,
  });

  async function handleSubmit(values) {
    values.admin_id = accessType?.admin_id;

    try {
      const res = await axiosInstance.post(`/permission/permission`, values);
      handleResponse(res);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
    }
  }

  function handleResponse(response) {
    const successMessage = "User Permission Added Successfully";
    const errorMessage = response.data.message;

    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 1000,
        onClose: () => navigate(`/${admin}/index`),
      });
    } else {
      // Show an error toast
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  useEffect(() => {
    let Admin_Id = accessType?.admin_id;

    axiosInstance
      .get(`/permission/permission/${Admin_Id}`)
      .then((response) => {
        const adminData = response.data.data;
        setUserroleData(adminData);
        userroleFormik.setValues({
          tenant_permission: {
            property_view: false,
            financial_view: false,
            financial_add: false,
            financial_edit: false,
            workorder_view: false,
            workorder_add: false,
            workorder_edit: false,
            workorder_delete: false,
            documents_view: false,
            documents_add: false,
            documents_edit: false,
            documents_delete: false,
          },
          staff_permission: {
            property_view: false,
            property_add: false,
            property_edit: false,
            property_delete: false,
            tenant_view: false,
            tenant_add: false,
            tenant_edit: false,
            tenant_delete: false,
            lease_view: false,
            lease_add: false,
            lease_edit: false,
            lease_delete: false,
            workorder_view: false,
            workorder_add: false,
            workorder_edit: false,
            workorder_delete: false,
            propertytype_view: false,
            propertytype_add: false,
            propertytype_edit: false,
            propertytype_delete: false,
            rentalowner_view: false,
            rentalowner_add: false,
            rentalowner_edit: false,
            rentalowner_delete: false,
            applicant_view: false,
            applicant_add: false,
            applicant_edit: false,
            applicant_delete: false,
            vendor_view: false,
            vendor_add: false,
            vendor_edit: false,
            vendor_delete: false,
            setting_view: false,
          },
          vendor_permission: {
            workorder_view: false,
            workorder_edit: false,
          },
          ...adminData,
        });
      })
      .catch((error) => {
        console.error("Error fetching vendor data:", error);
      });
  }, [accessType]);

  const handleCloseButtonClick = () => {
    navigate(-1);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    userroleFormik.setFieldValue(name, checked);
    const [section, permission] = name.split(".");
    if (permission.endsWith("_view") && !checked) {
      const associatedPermissions = ["_edit", "_delete", "_add"];
      associatedPermissions.forEach((suffix) => {
        const associatedPermissionName = `${section}.${
          permission.split("_")[0]
        }${suffix}`;
        userroleFormik.setFieldValue(associatedPermissionName, false);
      });
    }
  };

  const handleMasterCheckboxChange = (event) => {
    const { checked } = event.target;

    const updatedValues = {};
    Object.keys(userroleFormik.values).forEach((sectionKey) => {
      if (typeof userroleFormik.values[sectionKey] === "object") {
        updatedValues[sectionKey] = Object.keys(
          userroleFormik.values[sectionKey]
        ).reduce((acc, key) => {
          acc[key] = checked;
          return acc;
        }, {});
      }
    });

    userroleFormik.setValues({
      ...userroleFormik.values,
      ...updatedValues,
    });
  };

  return (
    <>
      {/* <RentalHeader /> */}
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3  headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              User Permission
            </h2>
          </CardHeader>
        </Col>
        <Form onSubmit={userroleFormik.handleSubmit}>
          <Row>
            <Col>
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="heading-small labelfontstyle  mb-4"
                    style={{
                      // marginBottom: "10px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      textTransform: "none",
                      color: "#152B51",
                    }}
                  >
                    Permissions
                  </h6>
                  <div className="mb-2">
                    <Row className="mt-3">
                      {/* <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        Select All
                      </Col> */}
                      <Col xl={10} lg={9}  md={9} sm={9} xs={9} className="d-flex mt-2 ml-4">
                        {Object.entries(userroleFormik.values)?.length > 0 && (
                          <CheckBoxComponent
                            label="Select All"
                            id="select_all"
                            name="select_all"
                            checked={
                              Object.values(
                                userroleFormik.values.tenant_permission
                              ).every(Boolean) &&
                              Object.values(
                                userroleFormik.values.staff_permission
                              ).every(Boolean) &&
                              Object.values(
                                userroleFormik.values.vendor_permission
                              ).every(Boolean)
                            }
                            onChange={handleMasterCheckboxChange}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="heading-small labelfontstyle  mb-4"
                    style={{
                      // marginBottom: "10px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      textTransform: "none",
                      color: "#152B51",
                    }}
                  >
                    Tenant Permissions
                  </h6>
                  <div className="mb-2">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        Properties
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="property_view"
                          name="tenant_permission.property_view"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.property_view
                          }
                          onChange={handleCheckboxChange}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-2">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        Financial
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="financial_view"
                          name="tenant_permission.financial_view"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.financial_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="financial_add"
                          name="tenant_permission.financial_add"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.financial_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.tenant_permission
                              ?.financial_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="financial_edit"
                          name="tenant_permission.financial_edit"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.financial_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.tenant_permission
                              ?.financial_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        Work Order
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="workorder_view"
                          name="tenant_permission.workorder_view"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.workorder_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="workorder_add"
                          name="tenant_permission.workorder_add"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.workorder_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.tenant_permission
                              ?.workorder_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="workorder_edit"
                          name="tenant_permission.workorder_edit"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.workorder_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.tenant_permission
                              ?.workorder_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="workorder_delete"
                          name="tenant_permission.workorder_delete"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.workorder_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.tenant_permission
                              ?.workorder_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        Documents
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="documents_view"
                          name="tenant_permission.documents_view"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.documents_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="documents_add"
                          name="tenant_permission.documents_add"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.documents_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.tenant_permission
                              ?.documents_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="documents_edit"
                          name="tenant_permission.documents_edit"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.documents_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.tenant_permission
                              ?.documents_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="documents_delete"
                          name="tenant_permission.documents_delete"
                          checked={
                            userroleFormik.values?.tenant_permission
                              ?.documents_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.tenant_permission
                              ?.documents_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="heading-small labelfontstyle  mb-4"
                    style={{
                      // marginBottom: "10px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      textTransform: "none",
                      color: "#152B51",
                    }}
                  >
                    Staff Permissions
                  </h6>
                  {/* {Staffs.map((menu, index) => ( */}
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Property Type
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="propertytype_view"
                          name="staff_permission.propertytype_view"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.propertytype_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="propertytype_add"
                          name="staff_permission.propertytype_add"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.propertytype_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.propertytype_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="propertytype_edit"
                          name="staff_permission.propertytype_edit"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.propertytype_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.propertytype_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="propertytype_delete"
                          name="staff_permission.propertytype_delete"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.propertytype_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.propertytype_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Properties
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="property_view"
                          name="staff_permission.property_view"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.property_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="property_add"
                          name="staff_permission.property_add"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.property_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.property_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="property_edit"
                          name="staff_permission.property_edit"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.property_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.property_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="property_delete"
                          name="staff_permission.property_delete"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.property_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.property_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Rental Owner
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="rentalowner_view"
                          name="staff_permission.rentalowner_view"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.rentalowner_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="rentalowner_add"
                          name="staff_permission.rentalowner_add"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.rentalowner_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.rentalowner_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="rentalowner_edit"
                          name="staff_permission.rentalowner_edit"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.rentalowner_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.rentalowner_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="rentalowner_delete"
                          name="staff_permission.rentalowner_delete"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.rentalowner_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.rentalowner_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Tenant
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="tenant_view"
                          name="staff_permission.tenant_view"
                          checked={
                            userroleFormik.values?.staff_permission?.tenant_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="tenant_add"
                          name="staff_permission.tenant_add"
                          checked={
                            userroleFormik.values?.staff_permission?.tenant_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.tenant_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="tenant_edit"
                          name="staff_permission.tenant_edit"
                          checked={
                            userroleFormik.values?.staff_permission?.tenant_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.tenant_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="tenant_delete"
                          name="staff_permission.tenant_delete"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.tenant_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.tenant_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Rent Roll
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="lease_view"
                          name="staff_permission.lease_view"
                          checked={
                            userroleFormik.values?.staff_permission?.lease_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="lease_add"
                          name="staff_permission.lease_add"
                          checked={
                            userroleFormik.values?.staff_permission?.lease_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission?.lease_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="lease_edit"
                          name="staff_permission.lease_edit"
                          checked={
                            userroleFormik.values?.staff_permission?.lease_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission?.lease_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="lease_delete"
                          name="staff_permission.lease_delete"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.lease_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission?.lease_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Applicant
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="applicant_view"
                          name="staff_permission.applicant_view"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.applicant_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="applicant_add"
                          name="staff_permission.applicant_add"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.applicant_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.applicant_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="applicant_edit"
                          name="staff_permission.applicant_edit"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.applicant_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.applicant_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="applicant_delete"
                          name="staff_permission.applicant_delete"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.applicant_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.applicant_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Vendor
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="vendor_view"
                          name="staff_permission.vendor_view"
                          checked={
                            userroleFormik.values?.staff_permission?.vendor_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="vendor_add"
                          name="staff_permission.vendor_add"
                          checked={
                            userroleFormik.values?.staff_permission?.vendor_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.vendor_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="vendor_edit"
                          name="staff_permission.vendor_edit"
                          checked={
                            userroleFormik.values?.staff_permission?.vendor_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.vendor_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="vendor_delete"
                          name="staff_permission.vendor_delete"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.vendor_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.vendor_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Work Order
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="workorder_view"
                          name="staff_permission.workorder_view"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.workorder_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="ADD"
                          id="workorder_add"
                          name="staff_permission.workorder_add"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.workorder_add
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.workorder_view
                          }
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="workorder_edit"
                          name="staff_permission.workorder_edit"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.workorder_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.workorder_view
                          }
                        />

                        <CheckBoxComponent
                          label="DELETE"
                          id="workorder_delete"
                          name="staff_permission.workorder_delete"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.workorder_delete
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.staff_permission
                              ?.workorder_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        Setting
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="Manage"
                          id="setting_view"
                          name="staff_permission.setting_view"
                          checked={
                            userroleFormik.values?.staff_permission
                              ?.setting_view
                          }
                          onChange={handleCheckboxChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="heading-small labelfontstyle   mb-4"
                    style={{
                      // marginBottom: "10px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      textTransform: "none",
                      color: "#152B51",
                    }}
                  >
                    Vendor Permissions
                  </h6>
                  {/* {Vendor.map((menu, index) => ( */}
                  <div className="mb-2 ">
                    <Row className="mt-3">
                      <Col
                       xl={2} lg={3} md={3} sm={3} xs={12}
                        className="font-weight-bold mt-2"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#152B51",
                          textTransform: "none",
                        }}
                      >
                        {/* {menu.title} */}
                        Work Order
                      </Col>
                      <Col xl={10} lg={9}  md={9} sm={9} xs={12} className="ml-4 ml-sm-0 mt-2">
                        <CheckBoxComponent
                          label="VIEW"
                          id="workorder_view"
                          name="vendor_permission.workorder_view"
                          checked={
                            userroleFormik.values?.vendor_permission
                              ?.workorder_view
                          }
                          onChange={handleCheckboxChange}
                        />

                        <CheckBoxComponent
                          label="EDIT"
                          id="workorder_edit"
                          name="vendor_permission.workorder_edit"
                          checked={
                            userroleFormik.values?.vendor_permission
                              ?.workorder_edit
                          }
                          onChange={handleCheckboxChange}
                          disabled={
                            !userroleFormik.values?.vendor_permission
                              ?.workorder_view
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  {/* ))} */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="ml-3 mt-3 mb-3">
            <Button
              type="submit"
              className="btn fontstylerentr"
              style={{
                background: "#152B51",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              Save
            </Button>
            {/* )} */}
            <Button
              className="btn fontstylerentr"
              onClick={handleCloseButtonClick}
              style={{
                background: "white",
                color: "#152B51",
                cursor: "pointer",
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>

        <ToastContainer />
      </Container>
    </>
  );
};

export default Userrole;
