import {
  Button,
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import editicon from "../../assets/img/icons/common/editicon.svg";
import deleicon from "../../assets/img/icons/common/delete.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./tenantstyle.css";
import StaffHeader from "components/Headers/StaffHeader";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import swal from "sweetalert";
import { verifyToken } from "components/Functions/Functions";

const StaffTenants = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let [tentalsData, setTenantsDate] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  let [loader, setLoader] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [upArrow, setUpArrow] = React.useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [userroleData, setUserroleData] = useState(null);

  let navigate = useNavigate();
  const location  = useLocation();

  const [accessType, setAccessType] = useState(null);

  // const [cookies] = useCookies(["token"]);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/staffmember/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  const [cookie, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const navigateToTenantsDetails = (tenantId) => {
    navigate(`/staff/stafftenantdetail/${tenantId}`);
  };

  let getTenantsData = async () => {
    if (accessType?.admin_id) {
      try {
        let response = await axiosInstance.get(
          `/tenant/tenants/${accessType?.admin_id}`
        );
        if (response.data.statusCode === 200) {
          let data = response.data.data;
          let reversedData = data.reverse();
          setTenantsDate(reversedData);
          setTotalPages(Math.ceil(reversedData.length / pageItem));
          // setLoader(false);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching tenants data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  const [countRes, setCountRes] = useState("");
  const getTenantsLimit = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/tenant/limitation/${accessType?.admin_id}`
        );
        setCountRes(response.data);
      } catch (error) {
        console.error("Error fetching rental data:", error);
      }
    }
  };

  React.useEffect(() => {
    getTenantsData();
    getTenantsLimit();
  }, [pageItem, accessType, upArrow, sortBy]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  const paginatedData = tentalsData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filterTenantsBySearch = () => {
    let filteredData = [...tentalsData];

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((tenant) => {
        if (!tenant) {
          return false;
        }

        const name = `${tenant.tenant_firstName} ${tenant.tenant_lastName}`;

        return (
          tenant.tenant_firstName
            .toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          tenant.tenant_lastName.toLowerCase().includes(lowerCaseSearchQuery) ||
          //   tenant.rental_adress.toLowerCase().includes(lowerCaseSearchQuery) ||
          //   tenant.tenant_phoneNumber.toLowerCase().includes(lowerCaseSearchQuery) ||
          tenant.tenant_email.toLowerCase().includes(lowerCaseSearchQuery) ||
          name.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    }

    if (upArrow.length > 0) {
      const sortingArrows = upArrow;
      sortingArrows.forEach((sort) => {
        switch (sort) {
          case "tenant_lastName":
            filteredData.sort((a, b) =>
              a.tenant_lastName?.localeCompare(b.tenant_lastName)
            );
            break;
          case "rental_adress":
            filteredData.sort((a, b) =>
              a.rental_adress?.localeCompare(b.rental_adress)
            );
            break;
          case "tenant_phoneNumber":
            filteredData.sort(
              (a, b) => a.tenant_phoneNumber - b.tenant_phoneNumber
            );
            break;
          case "tenant_email":
            filteredData.sort((a, b) =>
              a.tenant_email?.localeCompare(b.tenant_email)
            );
            break;
          case "createdAt":
            filteredData.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
          case "updatedAt":
            filteredData.sort(
              (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
            );
            break;
          default:
            break;
        }
      });
    }
    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };

  const editLeasing = (id) => {
    navigate(`/staff/staffedittenant/${id}`);
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
  };

  const deleteTenants = async (tenant) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "Once deleted, you will not be able to recover this tenant!",
    //   icon: "warning",
    //   buttons: ["Cancel", "Delete"],
    //   dangerMode: true,
    // }).then(async (willDelete) => {
    //   if (willDelete) {
    //     const res = await axiosInstance.delete(
    //       `/tenant/tenant/${tenant.tenant_id}`,
    //       {
    //         params: {
    //           company_name: accessType.company_name,
    //           tenant_email: tenant.tenant_email,
    //         },
    //       }
    //     );
    //     if (res.data.statusCode === 200) {
    //       getTenantsData();
    //       getTenantsLimit();
    //       toast.success(res.data.message, {
    //         position: "top-center",
    //         autoClose: 800,
    //       });
    //     } else {
    //       toast.warning(res.data.message, {
    //         position: "top-center",
    //         autoClose: 800,
    //       });
    //     }
    //   } else {
    //     toast.success("Tenant is safe!", {
    //       position: "top-center",
    //       autoClose: 800,
    //     });
    //   }
    // });
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this tenant!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
            console.log("Input Value:", reason);
  
            const deleteButton = document.querySelector(".swal-button--confirm");
            deleteButton.disabled = reason.trim() === "";
          },
        },
      },
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deletionReason = document.getElementById("delete-reason").value;
        console.log("Confirmed Deletion Reason:", deletionReason);
  
        try {
          const res = await axiosInstance.delete(`/tenant/tenant/${tenant.tenant_id}`, {
            params: {
              company_name: accessType.company_name,
              tenant_email: tenant.tenant_email,
            },
            data:{
              reason: deletionReason,
            }
          });
  
          if (res.data.statusCode === 200) {
            getTenantsData();
            getTenantsLimit();
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 800,
            });
          } else {
            toast.warning(res.data.message, {
              position: "top-center",
              autoClose: 800,
            });
          }
        } catch (error) {
          console.error("Error deleting tenant:", error);
          toast.error(error.message, {
            position: "top-center",
          });
        }
      } else {
        toast.success("Tenant is safe!", {
          position: "top-center",
          autoClose: 800,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  return (
    <>
      <StaffHeader />
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          {userroleData?.tenant_add && (
            <Col className="text-right">
              <Button
                className="tenantnewbtn background-colorsty bgtextwhite fontfamilysty"
                // color="primary"
                //  href="#rms"
                onClick={() => {
                  if (countRes.statusCode === 201) {
                    swal(
                      "Plan Limitation",
                      "The limit for adding tenants according to the plan has been reached.",
                      "warning"
                    );
                  } else {
                    navigate(`/staff/staffaddtenant`);
                  }
                }}
                size="small"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Add Tenant
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs="12" lg="12" sm="12">
            {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Tenants</h1>
            </FormGroup> */}
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Tenants
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                {/* <Card className="shadow"> */}
                {/* <CardHeader className="border-0"> */}
                <Row className="mb-3">
                  <Col xs="6" sm="6">
                    <FormGroup className="searchspace searchstyle">
                      <Input
                        className="filterh"
                        fullWidth
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end addtotnstyle">
                    <FormGroup className="addtotal">
                      <p
                        className="addedtotalstyle rentaladded"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Added :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.rentalCount}
                        </b>{" "}
                        {" / "}
                        Total :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.propertyCountLimit}
                        </b>
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                {/* </CardHeader> */}
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Tenant name
                        {sortBy.includes("tenant_lastName") ? (
                          upArrow.includes("tenant_lastName") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("tenant_lastName")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("tenant_lastName")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("tenant_lastName")}
                          />
                        )}
                      </th>

                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Property
                        {sortBy.includes("tenant_lastName") ? (
                          upArrow.includes("tenant_lastName") ? (
                            <ArrowDropDownIcon
                              onClick={() => sortData("tenant_lastName")}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              onClick={() => sortData("tenant_lastName")}
                            />
                          )
                        ) : (
                          <ArrowDropUpIcon
                            onClick={() => sortData("tenant_lastName")}
                          />
                        )}
                      </th>

                      <th className="tablefontstyle textcolorblue" scope="col">
                        Phone
                        {sortBy.includes("tenant_phoneNumber") ? (
                          upArrow.includes("tenant_phoneNumber") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("tenant_phoneNumber")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("tenant_phoneNumber")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("tenant_phoneNumber")}
                          />
                        )}
                      </th>

                      <th className="tablefontstyle textcolorblue" scope="col">
                        Email
                        {sortBy.includes("tenant_email") ? (
                          upArrow.includes("tenant_email") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("tenant_email")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("tenant_email")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("tenant_email")}
                          />
                        )}
                      </th>

                      <th className="tablefontstyle textcolorblue" scope="col">
                        Created At
                        {sortBy.includes("createdAt") ? (
                          upArrow.includes("createdAt") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("createdAt")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("createdAt")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("createdAt")}
                          />
                        )}
                      </th>
                      {/* <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>
                        Last Updated{" "}
                        {sortBy.includes("updatedAt") ? (
                          upArrow.includes("updatedAt") ? (
                            <ArrowDropDownIcon
                              onClick={() => sortData("updatedAt")}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              onClick={() => sortData("updatedAt")}
                            />
                          )
                        ) : (
                          <ArrowDropUpIcon
                            onClick={() => sortData("updatedAt")}
                          />
                        )}
                      </th> */}
                      {(userroleData?.tenant_edit ||
                        userroleData?.tenant_delete) && (
                          <th
                          className="tablefontstyle textcolorblue"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                          }}
                        >
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  {tentalsData.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="8" style={{ fontSize: "15px" }}>
                          No Tenants Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage().map((tenant) => (
                        <>
                          <tr
                            key={tenant.tenant_id}
                            onClick={() =>
                              navigateToTenantsDetails(tenant.tenant_id)
                            }
                            style={{
                              cursor: "pointer",
                              border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                            }}
                          >
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {tenant.tenant_firstName} {tenant.tenant_lastName}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {tenant.rental_adress ? (
                                <>
                                  {tenant.rental_adress}
                                  {tenant.rental_unit
                                    ? ` - ${tenant.rental_unit}`
                                    : null}
                                </>
                              ) : (
                                "Not Available"
                              )}
                            </td>

                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {tenant.tenant_phoneNumber}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {tenant.tenant_email}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {tenant.createdAt}{" "}
                            </td>
                            {/* <td className="bordertopintd tablebodyfont ">
                              {tenant.updatedAt ? tenant.updatedAt : "-"}{" "}
                            </td> */}
                            <td className="bordertopintd">
                              <div className="d-flex " style={{ gap: "5px" }}>
                                {userroleData?.tenant_delete && (
                                  <div
                                    className=""
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deleteTenants(tenant);
                                    }}
                                  >
                                    <img
                                      src={deleicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                                {userroleData?.tenant_edit && (
                                  <div
                                    className=""
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      editLeasing(tenant.tenant_id);
                                    }}
                                  >
                                    <img
                                      src={editicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  )}
                </Table>
                {paginatedData.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default StaffTenants;
