import * as React from "react";
import IconButton from "@mui/material/IconButton";
import "./nav.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { Button, DialogActions } from "@mui/material";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Circles } from "react-loader-spinner";
import SuperAdminHeader from "../Headers/SuperAdminHeader";
import {
  Col,
  Container,
  Row,
  ModalHeader,
  ModalBody,
  Modal,
  FormGroup,
  Input,
  ModalFooter,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import edit from "../../../assets/icons/edit.svg";
import deleterecord from "../../../assets/icons/delete.svg";
import usericon from "../../../assets/icons/Admin3.svg";
import logout from "../../../assets/icons/Group 1000002974.svg";
import leftarrow from "../../../assets/icons/left.svg";
import rightarrow from "../../../assets/icons/right.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import axiosInstance from "axiosInstance";

const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Name",
  },
  {
    label: "Plan Name",
  },
  {
    label: "Company Name",
  },
  {
    label: "Mobile",
  },
  {
    label: "Email",
  },
  {
    label: "Start Date",
  },
  {
    label: "End Date",
  },
  {
    label: "Status",
  },
  {
    label: "NMI Key",
  },
  {
    label: "Mail Service",
  },
  {
    label: "Action",
  },
];

function Rows(props) {
  const {
    row,
    handleClick,
    isItemSelected,
    labelId,
    seletedEditData,
    getData,
    isModalOpen,
    isMailModalOpen,
    openCardForm,
    openMailCardForm,
    closeModal,
    closeMailModal,
  } = props;
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleLoginButtonClick = async () => {
    if (row.admin_id) {
      try {
        await axiosInstance.get(`/login/${row.admin_id}`);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };
  const toggleAdminStatusWithConfirmation = (admin) => {
    const currentAction =
      admin.status === "activate" ? "deactivate" : "activate";
    const confirmMessage = `Are you sure you want to ${currentAction} ${admin.first_name} ${admin.last_name}? This action cannot be undone.`;

    swal({
      title: "Are you sure?",
      text: confirmMessage,
      icon: "warning",
      buttons: [
        "Cancel",
        `${currentAction.charAt(0).toUpperCase() + currentAction.slice(1)}`,
      ],
      dangerMode: true,
    }).then((willToggle) => {
      if (willToggle) {
        const newStatus =
          admin.status === "activate" ? "deactivate" : "activate";
        axiosInstance
          .put(`/admin/togglestatus/${admin.admin_id}`, {
            status: newStatus,
          })
          .then((response) => {
            if (response.data.success) {
              swal(
                "Success",
                `Admin status updated to ${newStatus}.`,
                "success"
              );
              getData();
            } else {
              swal(
                "Error",
                response.data.message ||
                "There was an error updating the admin status.",
                "error"
              );
            }
          })
          .catch((error) => {
            console.error("Error toggling admin status:", error);
            swal("Error", "Error toggling admin status", "error");
          });
      } else {
        swal(
          `${admin.first_name} ${admin.last_name}'s status remains unchanged.`
        );
      }
    });
  };
  const checkboxStyle = {
    backgroundColor: isItemSelected ? "red" : "transparent",
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => {
          handleClick(event, row.admin_id);
          navigate(`/superadmin/staffmember/${row?.admin_id}`);
        }}
        style={{ cursor: "pointer", color: "#152B51" }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
      >
        <TableCell align="center" padding="checkbox">
          <Checkbox
            color="primary"
            style={{ color: "#152B51" }}
            checked={isItemSelected}
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event, row.admin_id);
            }}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        {/* <TableCell align="center">{ row + 1}</TableCell> */}
        <TableCell align="left" style={{ color: "#152B51" }}>
          <img src={usericon} /> {row?.first_name} {row?.last_name}
        </TableCell>
        <TableCell align="left" style={{ color: "#152B51" }}>
          {row?.planName}
        </TableCell>
        <TableCell align="left" style={{ color: "#152B51" }}>
          {row?.company_name}
        </TableCell>
        <TableCell align="left" style={{ color: "#152B51" }}>
          {row?.phone_number}
        </TableCell>
        <TableCell align="left" style={{ color: "#152B51" }}>
          {row?.email}
        </TableCell>
        <TableCell align="left" style={{ color: "#152B51" }}>
          {row.planPurchased?.status === "active"
            ? `${moment(row.planPurchased?.purchase_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            )} `
            : `${moment(row.planPurchased?.purchase_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            )} 
              `}
        </TableCell>
        <TableCell align="left" style={{ color: "#152B51" }}>
          {row.planPurchased?.status === "active"
            ? `${moment(row.planPurchased?.expiration_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            )} `
            : `${moment(row.planPurchased?.expiration_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            )} 
              `}
        </TableCell>

        <TableCell>
          <Button
            size="small"
            variant="outlined"
            sx={{ borderRadius: "80px" }}
            color={row.status === "activate" ? "success" : "error"}
            onClick={(event) => {
              event.stopPropagation();
              toggleAdminStatusWithConfirmation(row);
            }}
          >
            {row.status === "activate" ? "Active" : "Inactive"}
          </Button>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            variant="outlined"
            sx={{ borderRadius: "80px" }}
            // color={row.status === "activate" ? "success" : "error"}
            onClick={(event) => {
              event.stopPropagation();
              openCardForm(row);
            }}
          >
            View
            {/* {row.status === "activate" ? "Key" : "Add Key"} */}
          </Button>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            variant="outlined"
            sx={{ borderRadius: "80px" }}
            // color={row.status === "activate" ? "success" : "error"}
            onClick={(event) => {
              event.stopPropagation();
              openMailCardForm(row);
            }}
          >
            Add
            {/* {row.status === "activate" ? "Key" : "Add Key"} */}
          </Button>
        </TableCell>
        <TableCell align="center">
          <div className="d-flex">
            <div
              onClick={(event) => {
                seletedEditData(row);
                event.stopPropagation();
              }}
              title="Edit"
            >
              <img src={edit} alt="edit"></img>
            </div>
            {/* <div
              className="ml-1 my-1"
              onClick={(event) => {
                event.stopPropagation();
                handleLoginButtonClick();
              }}
              title="Login"
            >
              
              <img src={logout} alt="logout"></img>
            </div> */}
          </div>
        </TableCell>

        {/* <TableCell align="left">
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleLoginButtonClick();
            }}
          >
            Login
          </Button>
        </TableCell> */}

        {/* <TableCell align="left">
          <button onClick={handleLoginClick}>Login</button>
        </TableCell> */}
      </TableRow>
      {/* <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          Are you sure you want to{" "}
          {selectedAdmin?.status === "activate" ? "deactivate" : "activate"}{" "}
          {selectedAdmin?.first_name} {selectedAdmin?.last_name}?
      
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleStatusChangeConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* <ToastContainer /> */}
    </React.Fragment>
  );
}

export default function Admin() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState();
  let [modalShowForPopupForm, setModalShowForPopupForm] = useState(false);
  const navigate = useNavigate();
  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(
          `/admin/superadmin_token_check`,
          {
            token: cookies.token,
          }
        );
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);
  let [adminData, setAdminData] = useState([]);
  let [loader, setLoader] = useState(true);
  let [countData, setCountData] = useState(0);
  
  // pagination
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(1);
  const [leasedropdownOpen, setLeasedropdownOpen] = useState(false);
  const toggle2 = () => setLeasedropdownOpen(prevState => !prevState);

  const getData = async () => {
    try {
      const res = await axiosInstance.get('/admin/admin', {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page - 1,
        },
      });
      setAdminData(res.data.data);
      setCountData(res.data.count);
      setTotalPages(Math.ceil(res.data.count / rowsPerPage));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    getData();
  }, [rowsPerPage, page]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
    setPageItem(+event.target.value);
  };


  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = adminData?.map((n) => n.admin_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  var handleDelete = () => {
    swal({
      text: " Are you sure you want to delete ?",
      buttons: {
        no: {
          text: "No",
          className: "swal-button",
          style: {
            backgroundColor: "#152B51",
            marginRight: "10px", // Adjust spacing if necessary
          },
          value: false,
        },
        yes: {
          text: "Yes",
          className: "swal-button",
          style: {
            backgroundColor: "#152B51",
          },
          value: true,
        },
      },
      padding: "2rem", // Adjust padding for centering
      allowOutsideClick: false,
    }).then(async (value) => {
      if (value === true) {
        axiosInstance
          .delete(`/admin/admin`, {
            data: selected,
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              getData();
              setSelected([]);
              toast.success("Admin Deleted Successfully", {
                position: "top-center",
                autoClose: 1000,
              });
            } else {
              toast.error(response.data.message, {
                position: "top-center",
                autoClose: 1000,
              });
            }
          });
      }
    });
  };

  // const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const [pageItem, setPageItem] = React.useState(10);
  // const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  // const [totalPages, setTotalPages] = React.useState(1);
  const [searchLoader, setSearchLoader] = useState(false);
  let [id, setId] = React.useState();
  var handleSubmit;

  const toggle = () => {
    setModalShowForPopupForm((prevState) => !prevState);
  };

  if (!id) {
    handleSubmit = async (values) => {
      try {
        values["is_addby_superdmin"] = true;
        values["role"] = "admin";
        const res = await axiosInstance.post(`/admin/register`, values);
        if (res.data.statusCode === 200) {
          toggle();
          getData();
          toast.success(res.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        } else if (res.data.statusCode === 401) {
          toast.error(res.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        } else if (res.data.statusCode === 402) {
          toast.error(res.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-center",
          });
        }
      } catch (error) {
        toast.error(error, {
          position: "top-center",
        });
      }
    };
  } else {
    handleSubmit = async (values) => {
      try {
        const response = await axiosInstance.put(
          `/admin/admin_edit/${id}`,
          values
        );

        if (response.data.statusCode === 200) {
          toggle();
          getData();
          toast.success(response.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error, {
          position: "top-center",
        });
      }
    };
  }

  // "add form logic"
  let [editData, setEditData] = React.useState({});

  // auto form fill up in edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas.admin_id);
    setEditData(datas);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMailModalOpen, setIsMailModalOpen] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [admin_id, setAdminId] = useState(null);
  const [admin_id2, setAdminId2] = useState(null);
  const [nmiKey, setNmiKey] = useState(null);
  const [mailData, setMailData] = useState(null);
  const [admin_mailId, setAdmin_mailId] = useState(null);
  const [selectedMailConfigId, setSelectedMailConfigId] = useState(null);
  const [key_id, setKeyId] = useState(null);
  const [responseText, setResponseText] = useState(null);
  const [testLoader, setTestLoader] = useState(false);
  //NMI key logic
  const closeModal = () => {
    setIsModalOpen(false);
    // getCreditCard();
    setKeyId(null);
    setNmiKey(null);
    setResponseText(null);
    nmikeyFormik.resetForm();
  };

  const openCardForm = (row) => {
    // console.warning("row", row)
    setAdminId(row.admin_id);
    setIsModalOpen(true);
  };

  const nmikeyFormik = useFormik({
    initialValues: {
      security_key: "",
      admin_id: "",
      cc_number: "",
      cc_exp: "",
    },
    validationSchema: yup.object({
      security_key: yup.string().required("Required"),
      cc_number: yup.number().required("Required"),
      cc_exp: yup.string().required("Required"),


    }),
    onSubmit: (values) => {
      handleNmiKeySubmit(values);
    },
  });
  useEffect(() => {
    // let Admin_Id = accessType?.admin_id;
    axiosInstance
      .get(`/nmi-keys/nmi-keys/${admin_id}`)
      .then((response) => {
        const Data = response.data.data;
        setNmiKey(Data);
        setKeyId(Data.key_id);
        nmikeyFormik.setValues({
          security_key: Data.security_key || "",
          //  late_fee: Data.late_fee || "",
        });
      })
      .catch((error) => {
        console.error("Error fetching late fee data:", error);
      });
  }, [admin_id]);

  async function handleNmiKeySubmit(values) {
    setSubmitLoader(true);
    try {
      const object = {
        role: "admin",
        admin_id: admin_id,
        security_key: values.security_key,
      };

      if (!key_id) {
        const res = await axiosInstance.post(`/nmi-keys/nmi-keys`, object);
        if (res.data.statusCode === 200) {
          toast.success("Security Key Added", {
            position: "top-center",
            autoClose: 800,
            onClose: () => closeModal(),
          });
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/nmi-keys/nmi-keys/${key_id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          toast.success("Security Key Updated", {
            position: "top-center",
            autoClose: 800,
            onClose: () => closeModal(),
          });
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  async function handleTestKeyClick(values) {
    setTestLoader(true);
    try {
      const object = {
        admin_id:admin_id,
        first_name: accessType.first_name,
        last_name: accessType.last_name,
        email: accessType.email,
        security_key: values.security_key,
        cc_number: values.cc_number,
        cc_exp: values.cc_exp,
      };

      const res = await axiosInstance.post(`/nmipayment/test_sale`, {
        paymentDetails: object,
      });

      if (res.data.statusCode === 100) {
        // toast.success("Account Linked Successfully", {
        //   position: "top-center",
        //   autoClose: 700,
        //   onClose:()=> navigate('/superadmin/admin')
        // });
        setResponseText(res.data.message);
      } else if (res.data.statusCode === 200) {
        // toast.error("Something went wrong", {
        //   position: "top-center",
        //   autoClose: 1000,
        // });
        setResponseText(res.data.message);
      }

      // setResponseText(res.data.message);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setTestLoader(false);
    }
  }

  //Mail configuration logic
  const closeMailModal = () => {
    setIsMailModalOpen(false);
    setAdmin_mailId(null);
    setMailData(null);
    setAdminId2(null);
    setSelectedMailConfigId(null);
    // setResponseText(null);
    mailConfFormik.resetForm();
  };

  const openMailCardForm = (row) => {
    setAdminId2(row.admin_id);
    setIsMailModalOpen(true);
  };

  useEffect(() => {
    axiosInstance
      .get(`/email_configration/mail`)
      .then((response) => {
        const Data = response.data.data;
        setMailData(Data);
        // setAdmin_mailId(Data.email_configration_id);
        // mailConfFormik.setValues({
        //   email_configration_id: Data.email_configration_id || "",
        //   //  late_fee: Data.late_fee || "",
        // });
      })
      .catch((error) => {
        console.error("Error fetching late fee data:", error);
      });
  }, [isMailModalOpen]);

  const mailConfFormik = useFormik({
    initialValues: {
      email_configration_id: "",
      admin_id: "",
    },
    validationSchema: yup.object({
      email_configration_id: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleMailConfSubmit(values);
    },
  });

  useEffect(() => {
    axiosInstance
      .get(`/admin-mail/admin-mail/${admin_id2}`)
      .then((response) => {
        const Data = response.data.data;
        setAdmin_mailId(Data.email_configration_id);
        setSelectedMailConfigId(Data.email_configration_id);
        mailConfFormik.setValues({
          email_configration_id: Data.email_configration_id || "",
        });
      })
      .catch((error) => {
        console.error("Error fetching late fee data:", error);
      });
  }, [admin_id2]);

  const handleCheckboxChange = (emailConfigId) => {
    // const isChecked = e.target.checked;
    // if (isChecked) {
    setSelectedMailConfigId(emailConfigId);
    // } else {
    //   setSelectedMailConfigId(null);
    // }
  };

  async function handleMailConfSubmit(values) {
    setSubmitLoader(true);
    try {
      const object = {
        admin_id: admin_id2,
        email_configration_id: selectedMailConfigId,
      };

      if (!admin_mailId) {
        const res = await axiosInstance.post(
          `/admin-mail/admin-mail`,
          object
        );
        if (res.data.statusCode === 200) {
          toast.success("Mail Configuration Added", {
            position: "top-center",
            autoClose: 800,
            onClose: () => closeMailModal(),
          });
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/admin-mail/admin-mail/${admin_id2}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          toast.success("Mail Configuration Updated", {
            position: "top-center",
            autoClose: 800,
            onClose: () => closeMailModal(),
          });
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  // async function handleTestKeyClick(values) {
  //   setTestLoader(true);
  //   try {
  //     const object = {
  //       first_name: accessType.first_name,
  //       last_name: accessType.last_name,
  //       email: accessType.email,
  //       security_key: values.security_key,
  //       cc_number: values.cc_number,
  //       cc_exp: values.cc_exp,
  //     };

      // const res = await axiosInstance.post(`/nmipayment/test_sale`, {
      //   paymentDetails: object,
      // });

  //     if (res.data.statusCode === 100) {
  //       // toast.success("Account Linked Successfully", {
  //       //   position: "top-center",
  //       //   autoClose: 700,
  //       //   onClose:()=> navigate('/superadmin/admin')
  //       // });
  //       setResponseText(res.data.message);
  //     } else if (res.data.statusCode === 200) {
  //       // toast.error("Something went wrong", {
  //       //   position: "top-center",
  //       //   autoClose: 1000,
  //       // });
  //       setResponseText(res.data.message);
  //     }

  //     // setResponseText(res.data.message);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     if (error.response) {
  //       console.error("Response Data:", error.response.data);
  //     }
  //   } finally {
  //     setTestLoader(false);
  //   }
  // }

  return (
    <>
      {/* <SuperAdminHeader prop={"Admins"} /> */}
      <Container className="stylecontainer containerremovespacepalns" fluid style={{ marginTop: "4rem", }}>
        <Row>
          <Col>
            <div
            // style={{
            //   width: "93%",
            // }}
            >
              <div id="main-btn-add-machinetype">
                <div className="d-flex flex-row justify-content-end mb-2">
                  <Button
                    className="text-capitalize fontstylerentr"
                    // size="small"
                    onClick={() => {
                      setModalShowForPopupForm(true);
                      setId(null);
                      setEditData({});
                    }}
                    variant="contained"
                    style={{
                      backgroundColor: "#152B51",
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    Add Admin
                  </Button>
                </div>
              </div>
              <Toolbar
                className="border-top border-bottom adminbarsuperadmin"
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  color: "white", // Set the font color to white
                  borderRadius: "10px",
                  marginBottom: "18px",
                  backgroundColor: "#152B51",
                  border: "none",
                }}
              >
                {/* <Typography className="barforsummaryrent"
                  sx={{ flex: "1 1 97%", color: "white" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Admin
                </Typography> */}
                <Typography className="barforsummaryrent"
                    sx={{
                      flex: "1 1 100%",
                      color: "#fff",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "24px",
                    }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Admin
                  </Typography>
                <>
                  {selected.length > 0 ? (
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDelete()}>
                        <img className="propertyiconsrentals"
                          src={deleterecord}
                          alt={"deleterecord"}
                          style={{
                            width: "20px",
                            height: "20px",
                            margin: "10px",
                            alignItems: "center",
                            color: "#fff",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </>
              </Toolbar>
              <Paper
                sx={{
                  width: "100%",
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                {loader || searchLoader ? (
                  <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                    <Circles
                      height="50"
                      width="50"
                      color="#1171ef"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperclassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <TableContainer>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell align="center"></TableCell> */}

                          <TableCell align="center" padding="checkbox">
                            <Checkbox
                              style={{ color: "#152B51" }} // Set custom checkbox color here
                              indeterminate={
                                selected.length > 0 &&
                                selected.length < adminData?.length
                              }
                              checked={
                                adminData?.length > 0 &&
                                selected.length === adminData?.length
                              }
                              onChange={handleSelectAllClick}
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </TableCell>
                          {headCells.map((headCell, id) => {
                            return (
                              <TableCell
                                style={{ fontWeight: "600", color: "#152B51" }}
                                key={headCell.id}
                                className="fw-bold fontstylerentr"
                                align="left"
                              >
                                {headCell.label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adminData?.map((row, index) => {
                          const isItemSelected = isSelected(row.admin_id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <Rows
                              row={row}
                              isItemSelected={isItemSelected}
                              labelId={labelId}
                              handleClick={handleClick}
                              selected={selected}
                              index={index}
                              seletedEditData={seletedEditData}
                              getData={getData}
                              isModalOpen={isModalOpen}
                              isMailModalOpen={isMailModalOpen}
                              closeModal={closeModal}
                              closeMailModal={closeMailModal}
                              openCardForm={openCardForm}
                              openMailCardForm={openMailCardForm}
                              style={{ color: "#152B51" }}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Paper>
            </div>
            {adminData?.length > 0 ? (
              <Row>
                <Col className="text-right m-3">
                  <Dropdown
                    isOpen={leasedropdownOpen}
                    toggle={toggle2}
                  >
                    <DropdownToggle className="paginationbtnstyle" caret>{pageItem}</DropdownToggle>
                    <DropdownMenu>
                {[10, 25, 50, 100].map((value) => (
                  <DropdownItem
                    key={value}
                    onClick={() => {
                      handleChangeRowsPerPage({ target: { value } });
                    }}
                  >
                    {value}
                  </DropdownItem>
                ))}
              </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0", minWidth: '20px', color: "black" }}
                    onClick={() => handleChangePage(page - 1)}
                    disabled={page === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span className="fontstylerentr">
                    Page {page} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0", minWidth: '20px', color: "black" }}
                    onClick={() => handleChangePage(page + 1)}
                    disabled={page === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>{" "}
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Dialog
              fullWidth
              open={modalShowForPopupForm}
              onClose={toggle}
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
            >
              <DialogTitle className="labelfontstyle"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  fontFamily: "Poppins",
                }}
              >
                {"Admin Form"}
              </DialogTitle>
              <DialogContent dividers>
                <Formik
                  initialValues={{
                    first_name:
                      editData && editData.first_name
                        ? editData.first_name
                        : "",
                    last_name:
                      editData && editData.last_name ? editData.last_name : "",
                    email: editData && editData.email ? editData.email : "",
                    company_name:
                      editData && editData.company_name
                        ? editData.company_name
                        : "",

                    phone_number:
                      editData && editData.phone_number
                        ? editData.phone_number
                        : "",
                    password:
                      editData && editData.password ? editData.password : "",
                    confpassword: ""
                  }}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string().required("Required"),
                    last_name: Yup.string().required("Required"),
                    email: Yup.string().required("Required"),
                    company_name: Yup.string().required("Required"),
                    phone_number: Yup.number().required("Required"),
                    password: Yup.string()
                      .required("No Password Provided")
                      .min(8, "Password is too short")
                      .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        "Must contain one uppercase, one lowercase, one number, and one special character"
                      ),
                    confpassword: Yup.string()
                      .required("Required")
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      ),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    resetForm(values);
                  }}
                >
                  {({ values, errors, touched, handleBlur, handleChange }) => (
                    <Form>
                      {/* <FormikValues /> */}
                      <div>
                        {/* Plan Name */}
                        <div className="mt-3">
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            placeholder="First Name"
                            label="First Name*"
                            name="first_name"
                            value={values.first_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="texmt fontstylerentr"
                          />
                          {touched.first_name && errors.first_name ? (
                            <div className="text-danger">
                              {errors.first_name}
                            </div>
                          ) : null}
                        </div>

                        {/* Plan Price */}
                        <div className="mt-3">
                          <TextField
                            className="fontstylerentr"
                            type="text"
                            size="small"
                            fullWidth
                            placeholder="Last Name"
                            label="Last Name*"
                            name="last_name"
                            value={values.last_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.last_name && errors.last_name ? (
                            <div className="text-danger">
                              {errors.last_name}
                            </div>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            placeholder="Email"
                            label="Email*"
                            name="email"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="fontstylerentr"
                          />
                          {touched.email && errors.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            placeholder="Company Name"
                            label="Company Name*"
                            name="company_name"
                            value={values.company_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="fontstylerentr"
                          />
                          {touched.company_name && errors.company_name ? (
                            <div className="text-danger">
                              {errors.company_name}
                            </div>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <TextField
                            type="number"
                            size="small"
                            fullWidth
                            placeholder="Phone Number"
                            label="Phone Number*"
                            name="phone_number"
                            value={values.phone_number}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="fontstylerentr"
                          />
                          {touched.phone_number && errors.phone_number ? (
                            <div className="text-danger">
                              {errors.phone_number}
                            </div>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            placeholder="Password"
                            label="Password*"
                            name="password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="fontstylerentr"
                          />
                          {touched.password && errors.password ? (
                            <div className="text-danger">{errors.password}</div>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            placeholder="Confirm Password"
                            label="Confirm Password*"
                            name="confpassword"
                            value={values.confpassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="fontstylerentr"
                          />
                          {touched.confpassword && errors.confpassword ? (
                            <div className="text-danger">
                              {errors.confpassword}
                            </div>
                          ) : null}
                        </div>

                        <Button
                          className="mt-3 fontstylerentr"
                          type="submit"
                          variant="primary"
                          style={{
                            backgroundColor: "#152B51",
                            color: "white",
                            fontFamily: "Poppins",
                            textTransform: "none"
                          }}
                        >
                          Add Admin
                        </Button>
                        <Button
                          className="mt-3 mx-3 fontstylerentr"
                          variant="primary"
                          style={{ color: "#152B51", fontFamily: "Poppins", textTransform: "none" }}
                          onClick={() => setModalShowForPopupForm(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>
          </Col>
        </Row>

        <Modal
          isOpen={isModalOpen}
          toggle={closeModal}
          style={{ maxWidth: "600px" }}
        >
          <ModalHeader toggle={closeModal} className="bg-secondary text-white">
            <strong className="barforsummaryrent" style={{ fontSize: 18, fontFamily: "Poppins", fontWeight: "600" }}>
              {nmiKey ? "Change NMI Security Key" : "Add NMI Security Key"}
            </strong>
          </ModalHeader>

          <ModalBody>
            <Row className="mt-3">
              <Col lg="9">
                <FormGroup>
                  <label className="form-control-label fontstylerentr" htmlFor="input-unitadd" style={{ fontWeight: "600", fontStyle: "14px", fontFamily: "Poppins" }}>
                    Security Key
                  </label>
                  <Input
                    style={{ fontWeight: "500", fontStyle: "12px", fontFamily: "Poppins" }}
                    className="form-control-alternative fontstylerentmodal"
                    id="input-unitadd"
                    placeholder="Enter Security Key"
                    type="text"
                    name="security_key"
                    onBlur={nmikeyFormik.handleBlur}
                    onChange={nmikeyFormik.handleChange}
                    value={nmikeyFormik.values.security_key}
                  />
                  <div className="requiredstylefont" style={{ color: "red" }}>

                    {nmikeyFormik.touched.security_key && nmikeyFormik.errors.security_key && "Required"}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label fontstylerentr" htmlFor="input-unitadd" style={{ fontWeight: "600", fontStyle: "14px", fontFamily: "Poppins" }}>
                    Test NMI Account
                  </label>
                  <p className="fontstylerentmodal" style={{ fontWeight: "500", fontStyle: "12px", fontFamily: "Poppins", color: "#979797" }}>
                    To test whether the account system has been successfully
                    linked to the NMI account or not, please click on the button
                    that will initiate a $1.00 transaction to this security
                    key's account.
                  </p>
                  <Row className="mt-4">
                    <Col lg="5">
                      <FormGroup>
                        <label style={{ fontWeight: "600", fontStyle: "14px", fontFamily: "Poppins" }}
                          className="form-control-label fontstylerentr"
                          htmlFor="input-unitadd"
                        >
                          Card Number
                        </label>
                        <Input style={{ fontWeight: "500", fontStyle: "12px", fontFamily: "Poppins" }}
                          className="form-control-alternative fontstylerentmodal"
                          id="input-unitadd"
                          placeholder="Card Number"
                          type="number"
                          name="cc_number"
                          onBlur={nmikeyFormik.handleBlur}
                          onChange={nmikeyFormik.handleChange}
                          value={nmikeyFormik.values.cc_number}
                        />
                        {/* <div className="requiredstylefont" style={{ color: "red" }}>

                          {nmikeyFormik.touched.cc_number && nmikeyFormik.errors.cc_number && "Required"}
                        </div> */}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label style={{ fontWeight: "600", fontStyle: "14px", fontFamily: "Poppins" }}
                          className="form-control-label fontstylerentr"
                          htmlFor="input-unitadd"
                        >
                          Expiration Date
                        </label>
                        <Input style={{ fontWeight: "500", fontStyle: "12px", fontFamily: "Poppins" }}
                          className="form-control-alternative fontstylerentmodal"
                          id="input-unitadd"
                          placeholder="MM/YYYY"
                          type="text"
                          name="cc_exp"
                          onBlur={nmikeyFormik.handleBlur}
                          onChange={nmikeyFormik.handleChange}
                          value={nmikeyFormik.values.cc_exp}

                        />
                        {/* <div className="requiredstylefont" style={{ color: "red" }}>

                          {nmikeyFormik.touched.cc_exp && nmikeyFormik.errors.cc_exp && "Required"}
                        </div> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg="12">
                      {testLoader ? (
                        <Button 
                          style={{
                            color: "white",
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "500"
                          }}
                          type="submit"
                          // color="warning"
                          className="btn btn-warning fontstylerentr"
                          disabled
                        >
                          Loading...
                        </Button>
                      ) : (
                        <Button
                          // color="warning"
                          className="btn bg-warning fontstylerentr"
                          type="submit"
                          style={{
                            textTransform: "none",

                            color: "white",
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "500"
                          }}
                          disabled={
                            !nmikeyFormik.values.cc_number || !nmikeyFormik.values.cc_exp
                          }
                          onClick={(e) => {
                            handleTestKeyClick(nmikeyFormik.values);
                            e.preventDefault();
                          }}
                        >
                          Test Transaction
                        </Button>
                      )}
                    </Col>
                    <Col lg="12" className="mt-3">
                      <label style={{ fontWeight: "500", fontStyle: "12px", fontFamily: "Poppins", color: "#979797" }}>{responseText}</label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {paymentLoader ? (
              <Button disabled type="submit"
              className="fontstylerentr"
                style={{
                  backgroundColor: "#152B51",
                  color: "white",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "500"
                }}>
                Loading
              </Button>
            ) : (

              <>
              
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: nmikeyFormik.values.security_key ? "#152B51" : "#d6e1f5",
                  color: "white",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
                className="mr-2 fontstylerentr"
                type="submit"
                onClick={(e) => {
                  handleNmiKeySubmit(nmikeyFormik.values);
                  e.preventDefault();
                }}
                disabled={!nmikeyFormik.values.security_key}
              >
                Save
              </Button>

        
              </>

            )}
            <Button className="fontstylerentr" onClick={closeModal} style={{
              textTransform: "none",

              backgroundColor: "white",
              color: "#152B51",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "500"
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={isMailModalOpen}
          toggle={closeMailModal}
          style={{ maxWidth: "600px" }}
        >
          <ModalHeader
            toggle={closeMailModal}
            className="bg-secondary text-white"
          >
            <strong className="labelfontstyle" style={{ fontSize: 18, fontFamily:"Poppins", color:"#152B51",fontWeight:"600" }}>
              {admin_mailId
                ? "Change Mail Configuration"
                : "Add Mail Configuration"}
            </strong>
          </ModalHeader>

          <ModalBody>
            <div style={{overflowX:"auto"}}>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  width: "100%"
                  // overflowX: "auto",
                  // position: "relative", 
                }}
              >
                <thead>
                  <tr style={{ border: "1px solid black" }}>
                    <th className="fontstylerentr" style={{ border: "1px solid black", padding: "10px" }}>Host</th>
                    <th className="fontstylerentr" style={{ border: "1px solid black", padding: "10px" }}>Port</th>
                    <th className="fontstylerentr" style={{ border: "1px solid black", padding: "10px" }}>Email</th>
                    <th className="fontstylerentr" style={{ border: "1px solid black", padding: "10px" }}>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {mailData &&
                    mailData.map((mail) => (
                      <tr key={mail.email_configration_id} style={{ border: "1px solid black" }}>
                        <td className="fontstylerentr" style={{ border: "1px solid black", padding: "10px" }}>{mail.host}</td>
                        <td className="fontstylerentr" style={{ border: "1px solid black", padding: "10px" }}>{mail.port}</td>
                        <td className="fontstylerentr" style={{ border: "1px solid black", padding: "10px" }}>{mail.from_email}</td>
                        <td className="fontstylerentr" style={{ border: "1px solid black", padding: "10px" }}>
                          <input
                            type="checkbox"
                            checked={
                              mail.email_configration_id === selectedMailConfigId
                            }
                            onChange={() =>
                              handleCheckboxChange(mail.email_configration_id)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>

          </ModalBody>

          <ModalFooter>
            {paymentLoader ? (
              <Button className="fontstylerentr" disabled  type="submit" style={{color:"white", backgroundColor:"#152B51",fontSize:"14px", fontWeight:"500", fontFamily:"Poppins",textTransform:"none"}}>
                Loading
              </Button>
            ) : (
              <Button  style={{color:"white", backgroundColor:"#152B51",fontSize:"14px", fontWeight:"500", fontFamily:"Poppins", cursor:"pointer", textTransform:"none"}}
                className=" mr-2 fontstylerentr"
                type="submit"
                onClick={(e) => {
                  handleMailConfSubmit(selectedMailConfigId);
                  e.preventDefault();
                }}
              >
                Save
              </Button>
            )}
            <Button className="fontstylerentr" onClick={closeMailModal}  style={{color:"#152B51", backgroundColor:"white",fontSize:"14px", fontWeight:"500", fontFamily:"Poppins", cursor:"pointer", textTransform:"none"}}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <ToastContainer />
      </Container>
    </>
  );
}
