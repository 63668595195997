// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  max-width: 1000px !important;
}
@media screen and (max-width: 1280px) {
  .modal-content {
    min-width: 90vw !important;
  }
}

.payment-field input {
  border: 1px solid #ababab;
  border-radius: 5px;
  height: 40px;
  padding: 10px;
  outline: none;
}

#payment-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

#ccnumber {
  width: 100%;
  font-size: 24px;
}

#ccexp,
#cvv {
  font-size: 20px;
  width: 100%;
}

.full-fields {
  width: 100%;
}

.half-fields {
  width: 45%;
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/Form.scss"],"names":[],"mappings":"AAAA;EAEE,4BAAA;AAAF;AAEE;EAJF;IAKI,0BAAA;EACF;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,SAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF;;AAEA;;EAEE,eAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".modal-content {\n  // width: 95vw !important;\n  max-width: 1000px !important;\n\n  @media screen and (max-width: 1280px) {\n    min-width: 90vw !important;\n  }\n}\n\n.payment-field input {\n  border: 1px solid #ababab;\n  border-radius: 5px;\n  height: 40px;\n  padding: 10px;\n  outline: none;\n}\n\n#payment-fields {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  gap: 10px;\n}\n\n#ccnumber {\n  width: 100%;\n  font-size: 24px;\n}\n\n#ccexp,\n#cvv {\n  font-size: 20px;\n  width: 100%;\n}\n\n.full-fields {\n  width: 100%;\n}\n\n.half-fields {\n  width: 45%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
