import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  import { useFormik } from "formik";
  import * as yup from "yup";
  import React, { useState, useEffect } from "react";
  import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
  } from "reactstrap";
  import axiosInstance from "axiosInstance";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  
  const EditAccountDialog= (props) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
  
    const [selectedAccountType, setSelectedAccountType] = useState("");
    const [selectedFundType, setSelectedFundType] = useState("");
    const [selectAccountDropDown, setSelectAccountDropDown] = useState(false);
    const [selectFundTypeDropDown, setSelectFundTypeDropDown] = useState(false);
  
    const toggleAccountDropdown = () => setSelectAccountDropDown(!selectAccountDropDown);
    const toggleFundTypeDropdown = () => setSelectFundTypeDropDown(!selectFundTypeDropDown);
  
    let accountFormik = useFormik({
        initialValues: {
          account: "",
          account_type: "",
          fund_type: "",
          notes: "",
        },
        validationSchema: yup.object({
          account: yup.string().required("Required"),
          account_type: yup.string().required("Required"),
          fund_type: yup.string().required("Required"),
        }),
        onSubmit: (values) => {
          handleEdit(values);
          accountFormik.resetForm();
        },
      });
  
    // useEffect(() => {
    //   // Set initial values from props
    //   if (props.accountData) {
    //     const { account_type, fund_type, account_name, notes } = props.accountData;
    //     setSelectedAccountType(account_type);
    //     setSelectedFundType(fund_type);
    //     accountFormik.setValues({
    //       account: account_name || "",
    //       account_type: account_type || "",
    //       fund_type: fund_type || "",
    //       notes: notes || "",
    //     });
    //   }
    // }, [props.accountData]);


    useEffect(() => {
        const fetchAccountData = async () => {
          try {
            const res = await axiosInstance.get(`/accounts/get_account/${props.accountData}`);
              const accountDetail = res.data.data;
              setSelectedAccountType(accountDetail.account_type);
              setSelectedFundType(accountDetail.fund_type);
              accountFormik.setValues(accountDetail);
          } catch (error) {
            console.log("error",error)
          }
        };
      
          fetchAccountData();
        
      }, [props?.accountData]);
      
      const handleSelectedAccountType = (type) => {
        accountFormik.setFieldValue('account_type', type);
        setSelectedAccountType(type); // Update local state
      };
      
      const handleSelectedFundType = (type) => {
        accountFormik.setFieldValue('fund_type', type);
        setSelectedFundType(type); // Update local state
      };
      
  
    const handleEdit = async (values) => {
      const object = {
        ...values,
        admin_id: props.adminId,
        charge_type: props.chargeType,
        createdAt: props.createdAt,
        account_id: props.accountId,
      };
      try {
        const res = await axiosInstance.put(`/accounts/accounts/${props.accountId}`, object);
        if (res.status === 200) {
          toast.success("Account Updated", {
            position: "top-center",
            autoClose: 1000,
          });
          accountFormik.resetForm();
          setSelectedAccountType("");
          setSelectedFundType("");
          props.setEditAccountDialogOpen(false);
          props.fetchAccounts();
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        if (error.response.status === 400) {
          toast.warning("Error updating account", {
            position: "top-center",
            autoClose: 1000,
          });
        }
        accountFormik.resetForm();
        setSelectedAccountType("");
        setSelectedFundType("");
      }
    };
  
    return (
      <Dialog
        open={props.editAccountDialogOpen}
        onClose={() => {
          props.setEditAccountDialogOpen(false);
          setSelectedAccountType("");
          setSelectedFundType("");
        }}
      >
        <DialogTitle
          className="labelfontstyle"
          style={{
            color: "#152B51",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Edit account
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <div className="formInput" style={{ margin: "10px 10px" }}>
            <label
              className="form-control-label titleecolor fontstylerentr"
              htmlFor="input-address"
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Account Name *
            </label>
            <br />
            <Input
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "6px",
              }}
              className="form-control-alternative fontstylerentr titleecolor"
              id="input-accname"
              placeholder="Enter account name"
              type="text"
              name="account"
              onBlur={accountFormik.handleBlur}
              onChange={accountFormik.handleChange}
              value={accountFormik.values.account}
            />
            {accountFormik.touched.account && accountFormik.errors.account ? (
              <div className="requiredstylefont" style={{ color: "red" }}>
                {accountFormik.errors.account}
              </div>
            ) : null}
          </div>
  
          <div className="formInput" style={{ margin: "30px 10px" }}>
            <label
              className="form-control-label titleecolor fontstylerentr"
              htmlFor="input-address"
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Account Type
            </label>
            <br />
            <Dropdown
              className="dropdownfontsyle"
              isOpen={selectAccountDropDown}
              toggle={toggleAccountDropdown}
            >
              <DropdownToggle
                className="dropdownfontsyle titleecolor"
                caret
                style={{
                  width: "100%",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #ced4da",
                  backgroundColor: "transparent",
                }}
              >
                {selectedAccountType ? selectedAccountType : "Select"}
              </DropdownToggle>
              <DropdownMenu
                className="dropdownfontsyle"
                style={{ width: "100%" }}
                name="account_type"
                onBlur={accountFormik.handleBlur}
                onChange={accountFormik.handleChange}
                value={accountFormik.values.account_type}
              >
                <DropdownItem
                  className="dropdownfontsyle"
                  onClick={() => handleSelectedAccountType("Income")}
                >
                  Income
                </DropdownItem>
                <DropdownItem
                  className="dropdownfontsyle"
                  onClick={() => handleSelectedAccountType("Non Operating Income")}
                >
                  Non Operating Income
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
  
          <div className="formInput" style={{ margin: "30px 10px" }}>
            <label
              className="form-control-label fontstylerentr titleecolor"
              htmlFor="input-address"
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Fund Type
            </label>
            <br />
            <Dropdown
              className="dropdownfontsyle"
              isOpen={selectFundTypeDropDown}
              toggle={toggleFundTypeDropdown}
            >
              <DropdownToggle
                className="dropdownfontsyle titleecolor"
                caret
                style={{
                  width: "100%",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #ced4da",
                  backgroundColor: "transparent",
                }}
              >
                {selectedFundType ? selectedFundType : "Select"}
              </DropdownToggle>
              <DropdownMenu
                className="dropdownfontsyle"
                style={{ width: "100%" }}
                name="fund_type"
                onBlur={accountFormik.handleBlur}
                onChange={accountFormik.handleChange}
                value={accountFormik.values.fund_type}
              >
                <DropdownItem
                  className="dropdownfontsyle"
                  onClick={() => handleSelectedFundType("Reserve")}
                >
                  Reserve
                </DropdownItem>
                <DropdownItem
                  className="dropdownfontsyle"
                  onClick={() => handleSelectedFundType("Operating")}
                >
                  Operating
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
  
          <div className="formInput" style={{ margin: "10px 10px" }}>
            <label
              className="form-control-label fontstylerentr titleecolor"
              htmlFor="input-address"
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Notes
            </label>
            <br />
            <Input
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "6px",
            }}
            className="form-control-alternative fontstylerentr titleecolor"
            id="input-notes"
            placeholder="Enter notes"
            type="text"
            name="notes"
            onBlur={accountFormik.handleBlur}
            onChange={accountFormik.handleChange}
            value={accountFormik.values.notes}
          />
          {accountFormik.touched.notes && accountFormik.errors.notes ? (
            <div className="requiredstylefont" style={{ color: "red" }}>
              {accountFormik.errors.notes}
            </div>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          type="submit"
          onClick={accountFormik.handleSubmit}
        >
          Save
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            props.setEditAccountDialogOpen(false);
            setSelectedAccountType("");
            setSelectedFundType("");
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
}

export default EditAccountDialog;
  