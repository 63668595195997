import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const TenantsHeader = ({ prop }) => {
  return (
    <>
      <div className="header pb-3 pt-1 px-5 d-flex align-items-center vendordashmt">
        <Row>
          <Col>
            <h1 className="vendormydashboardtext"
              style={{
                fontFamily: "manrope",
                fontSize: "30px",
                fontWeight: "700",
                color: "#333",

              }}
            >
              {prop}
            </h1>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TenantsHeader;
