import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";
import Header from "components/Headers/Header";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";

import leftarrow from "../../assets/icons/left.svg";
import rightarrow from "../../assets/icons/right.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./rentalownerdetails.css";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";

const StaffRentalownerTable = () => {
  const { admin } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [rentalsData, setRentalsData] = useState([]);
  let [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  let navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = page * rowsPerPage;
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [userroleData, setUserroleData] = useState(null);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [accessType, setAccessType] = useState(null);
  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/staffmember/token_check`, {
          token: cookies.token,
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const fetchRentalsData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/rental-owners/${accessType?.admin_id}`
        );
        if (response.status === 200) {
          const totalCount = response.data.length;
          setTotalPages(Math.ceil(totalCount / rowsPerPage));
          const slicedData = response.data.slice(startIndex, endIndex);
          setRentalsData(slicedData);
        } else {
          console.error("Invalid API response structure: ", response.data);
        }
      } catch (error) {
        console.error("Error fetching rentals data: ", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (accessType?.admin_id) {
      fetchRentalsData();
      getRentalOwnersLimit();
    }
  }, [accessType, page, rowsPerPage]);

  function navigateToRentRollDetails(rentalowner_id) {
    navigate(`/staff/staffrentalownerdetail/${rentalowner_id}`);
  }

  const editRentalOwner = (id) => {
    navigate(`/staff/staffaddrentalowner/${id}`);
  };

  const deleteTenant = (rentalowner_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this rental-owner!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance
          .delete(`/rentals/rental-owners/${rentalowner_id}`)
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Rental Owner Deleted Successfully", {
                position: "top-center",
                autoClose: 2000, // Adjusted timeout for quick display
              });
              setTimeout(() => {
                fetchRentalsData();
                getRentalOwnersLimit();
              }, 1000);
            } else if (response.data.statusCode === 201) {
              toast.warn(response.data.message, {
                position: "top-center",
              });
            } else {
              toast.error(response.data.message, {
                position: "top-center",
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting tenant:", error);
            toast.error(error.message, {
              position: "top-center",
            });
          });
      } else {
        toast.success("Rental Owner is safe :)", {
          position: "top-center",
        });
      }
    });
  };

  const filterRentalOwnersBySearch = () => {
    let filteredArray = rentalsData.filter((rentalOwner) => {
      return (
        rentalOwner?.rentalOwner_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        (rentalOwner.rentalOwner_streetAdress &&
          rentalOwner.rentalOwner_streetAdress
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        `${rentalOwner.rental_city}, ${rentalOwner.rental_country}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        rentalOwner.rentalOwner_primaryEmail
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    });

    let sortedArray = [...filteredArray];

    if (upArrow && upArrow.length > 0) {
      upArrow.forEach((value) => {
        switch (value) {
          case "rentalOwner_name":
            sortedArray.sort((a, b) => {
              const nameA =
                a?.rentalOwner_name?.toString()?.toLowerCase() || "";
              const nameB =
                b?.rentalOwner_name?.toString()?.toLowerCase() || "";
              return nameA?.localeCompare(nameB);
            });
            break;
          case "rentalOwner_phoneNumber":
            sortedArray.sort((a, b) => {
              const phoneA = a?.rentalOwner_phoneNumber?.toString() || "";
              const phoneB = b?.rentalOwner_phoneNumber?.toString() || "";
              return phoneA?.localeCompare(phoneB);
            });
            break;
          case "rentalOwner_primaryEmail":
            sortedArray.sort((a, b) => {
              const emailA =
                a?.rentalOwner_primaryEmail?.toString()?.toLowerCase() || "";
              const emailB =
                b?.rentalOwner_primaryEmail?.toString()?.toLowerCase() || "";
              return emailA?.localeCompare(emailB);
            });
            break;
          default:
            break;
        }
      });
    }
    return sortedArray; // Return the sorted data
  };

  const [countRes, setCountRes] = useState("");
  const getRentalOwnersLimit = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/rental_owner/limitation/${accessType?.admin_id}`
        );
        setCountRes(response.data);
      } catch (error) {
        console.error("Error fetching rental data:", error);
      }
    }
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterRentalOwnersBySearch();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterRentalOwnersBySearch();
    }
  };

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  return (
    <>
      <Header />
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          {userroleData?.rentalowner_add && (
            <Col xs="12" lg="12" sm="12" className="text-right">
              {/* <Button
              color="primary"
              onClick={() => navigate("/" + admin + "/Rentalowner")}
              size="sm"
              style={{ background: "white", color: "blue" }}
            >
              Add New Rental Owner
            </Button> */}

              <Button
                className="rentaltablebtn"
                // color="primary"
                // className="mr-4"

                onClick={() => {
                  if (countRes.statusCode === 201) {
                    swal(
                      "Plan Limitation",
                      "The limit for adding rentalowners according to the plan has been reached.",
                      "warning"
                    );
                  } else {
                    navigate("/staff/staffaddrentalowner");
                  }
                }}
                size="small"
                style={{
                  background: "#152B51",
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Add Rental Owner
              </Button>
              <br />
            </Col>
          )}
          <Col xs="12" lg="12" sm="12">
            {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Property Type</h1>
            </FormGroup> */}
            <CardHeader
              className=" mt-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Rental Owner
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                {/* <Card className="shadow"> */}
                {/* <CardHeader className="border-0"> */}
                <Row className="mb-3">
                  <Col xs="6" sm="6">
                    <FormGroup className="searchspace searchstyle">
                      <Input
                        className="filterh titleecolor"
                        fullWidth
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",

                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end addtotnstyle">
                    <FormGroup className="addtotal">
                      <p
                        className="addedtotalstyle rentaladded"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Added :{" "}
                        <b style={{ color: "#152B51", fontWeight: 1000 }}>
                          {countRes.rentalownerCount}
                        </b>{" "}
                        {" / "}
                        Total :{" "}
                        <b style={{ color: "#152B51", fontWeight: 1000 }}>
                          {countRes.rentalOwnerCountLimit}
                        </b>
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                {/* </CardHeader> */}
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",

                          color: "#152B51",
                        }}
                      >
                        Contact Name
                        {sortBy.includes("rentalOwner_name") ? (
                          upArrow.includes("rentalOwner_name") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("rentalOwner_name")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rentalOwner_name")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rentalOwner_name")}
                          />
                        )}
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Phone
                        {sortBy.includes("rentalOwner_phoneNumber") ? (
                          upArrow.includes("rentalOwner_phoneNumber") ? (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("rentalOwner_phoneNumber")
                              }
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() =>
                                sortData("rentalOwner_phoneNumber")
                              }
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rentalOwner_phoneNumber")}
                          />
                        )}
                      </th>
                      <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Email
                        {sortBy.includes("rentalOwner_primaryEmail") ? (
                          upArrow.includes("rentalOwner_primaryEmail") ? (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("rentalOwner_primaryEmail")
                              }
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() =>
                                sortData("rentalOwner_primaryEmail")
                              }
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rentalOwner_primaryEmail")}
                          />
                        )}
                      </th>
                      {(userroleData?.rentalowner_edit ||
                        userroleData?.rentalowner_delete) && (
                        <th
                          className="tablefontstyle"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                            color: "#152B51",
                          }}
                        >
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  {rentalsData.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="5" style={{ fontSize: "15px" }}>
                          No RentalOwners Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterRentalOwnersBySearch()?.map((rentalOwner) => (
                        <tr
                          key={rentalOwner.rentalowner_id}
                          onClick={() =>
                            navigateToRentRollDetails(
                              rentalOwner.rentalowner_id
                            )
                          }
                          style={{
                            cursor: "pointer",
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont ">
                            {rentalOwner?.rentalOwner_name}
                          </td>
                          <td className="bordertopintd tablebodyfont ">
                            {rentalOwner?.rentalOwner_phoneNumber}
                          </td>
                          <td className="bordertopintd tablebodyfont ">
                            {rentalOwner?.rentalOwner_primaryEmail}
                          </td>
                          {(userroleData?.rentalowner_edit ||
                            userroleData?.rentalowner_delete) && (
                            <td
                              className="bordertopintd tablebodyfont "
                              style={{}}
                            >
                              <div style={{ display: "flex", gap: "5px" }}>
                                {userroleData?.rentalowner_delete && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      rentalOwner.admin_id === "is_trial"
                                        ? freetrialData()
                                        : deleteTenant(
                                            rentalOwner.rentalowner_id
                                          );
                                    }}
                                  >
                                    <img
                                      src={deleicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                                {userroleData?.rentalowner_edit && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      rentalOwner.admin_id === "is_trial"
                                        ? freetrialData()
                                        : editRentalOwner(
                                            rentalOwner.rentalowner_id
                                          );
                                    }}
                                  >
                                    <img
                                      src={editicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>

                <Row>
                  <Col className="text-right mt-3 mb-3">
                    <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                      <DropdownToggle className="paginationbtnstyle" caret>
                        {rowsPerPage}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setRowsPerPage(10);
                            setPage(1);
                          }}
                        >
                          10
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setRowsPerPage(25);
                            setPage(1);
                          }}
                        >
                          25
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setRowsPerPage(50);
                            setPage(1);
                          }}
                        >
                          50
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setRowsPerPage(100);
                            setPage(1);
                          }}
                        >
                          100
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Button
                      className="p-0"
                      style={{}}
                      onClick={() => handleChangePage(page - 1)}
                      disabled={page === 1}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-caret-left"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                      </svg>
                    </Button>
                    <span>
                      Page {page} of {totalPages}
                    </span>{" "}
                    <Button
                      className="p-0"
                      style={{}}
                      onClick={() => handleChangePage(page + 1)}
                      disabled={page === totalPages}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-caret-right"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                      </svg>
                    </Button>{" "}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default StaffRentalownerTable;
