import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap";
import AddpropertyModal from "./AddpropertyModal";
import StaffMemberModal from "./StaffMemberModal";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import deleicon from "../../assets/img/icons/common/delete.svg";
import "./properties.css";

//rentals
import {
  dialogPaperStyles,
  editProperty,
  handleSubmit,
} from "./Functions/Rentals";

//units
import { roomsArray, bathArray } from "./Functions/Units";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

const Rentals = () => {
  const { rental_id, admin } = useParams();
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const [accessType, setAccessType] = useState({});
  const [isFromDetails, setIsFromDetails] = useState(false);
  const location = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  // const [cookies] = useCookies(["token"]);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/admin/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);

  useEffect(() => {
    if (location?.state?.id) {
      setIsFromDetails(true);
    }
  }, [location.state]);
  const [loader, setLoader] = useState(false);
  const [display, setDisplay] = useState(false);
  const [selectedPropType, setSelectedPropType] = useState("");
  const [open, setOpen] = useState(false);
  const [propType, setPropType] = useState("");
  const [StaffMemberData, setStaffMemberData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userdropdownOpen, setuserDropdownOpen] = useState(false);
  const [proDropdownOpen, setproDropdownOpen] = useState(false);
  const [showRentalOwnerTable, setshowRentalOwnerTable] = useState(false);
  const [isRentalDialogOpen, setRentalDialogOpen] = useState(false);
  const [checkedCheckbox, setCheckedCheckbox] = useState();
  const [selectedRentalOwnerData, setSelectedRentalOwnerData] = useState([]);
  const [rentalownerData, setRentalownerData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedProcessorId, setSelectedProcessorId] = useState(null);

  const toggle1 = () => setproDropdownOpen((prevState) => !prevState);
  const toggle3 = () => setuserDropdownOpen((prevState) => !prevState);

  const residentialSchema = yup.object({
    rental_sqft: yup.string().required("Required"),
  });

  const commercialSchema = yup.object({
    rental_sqft: yup.string().required("Required"),
  });

  const residentialSchema2 = yup.object({
    rental_unit: yup.string().required("Required"),
    rental_sqft: yup.string().required("Required"),
  });

  const commercialSchema2 = yup.object({
    rental_sqft: yup.string().required("Required"),
    rental_unit: yup.string().required("Required"),
  });

  let rentalsFormik = useFormik({
    initialValues: {
      rental_id: "",
      rental_adress: "",
      is_rent_on: false,
      rental_city: "",
      rental_state: "",
      rental_country: "",
      rental_postcode: "",
      staffmember_id: "",
      staffmember_name: "",
      property_type: "",
      processor_id: "",

      //RESIDENTIAL
      residential: [
        {
          rental_bed: "",
          rental_bath: "",
          propertyres_image: [],
          rental_sqft: "",
          rental_unit: "",
          rental_unit_adress: "",
        },
      ],

      //COMMERCIAL
      commercial: [
        {
          rental_sqft: "",
          rental_unit: "",
          rental_unit_adress: "",
          property_image: [],
        },
      ],
    },
    validationSchema: yup.object({
      rental_postcode: yup.string().required("Required"),
      property_type: yup.string().required("Required"),
      rental_adress: yup.string().required("Required"),
      rental_city: yup.string().required("Required"),
      rental_country: yup.string().required("Required"),
      rental_state: yup.string().required("Required"),
      ...(selectedPropType.is_multiunit
        ? propType === "Residential"
          ? { residential: yup.array().of(residentialSchema2) }
          : { commercial: yup.array().of(commercialSchema2) }
        : propType === "Residential"
        ? { residential: yup.array().of(residentialSchema) }
        : { commercial: yup.array().of(commercialSchema) }),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      if (selectedRentalOwnerData.length !== 0) {
        try {
          const res = await handleSubmit(
            values,
            rentalOwnerFormik.values,
            accessType?.admin_id,
            admin,
            selectedPropType.property_id,
            selectedFiles
          );
          if (res == false) {
            setLoader(false);
            setTimeout(() => {
              navigate("/" + admin + "/propertiesTable");
            }, 2000);
          } else {
            setLoader(false);
          }
        } catch {
          setLoader(false);
        } finally {
          setLoader(false);
        }
      } else {
        setDisplay(true);
      }
    },
  });

  const checkRentalOwner = async (values) => {
    try {
      const res = await axiosInstance.post(
        `/rental_owner/check_rental_owner`,
        values
      );
      if (res.data.statusCode === 200) {
        setSelectedRentalOwnerData(values);
        setshowRentalOwnerTable(false);
        handleAddrentalOwner();
        handleClose();
      }
      if (res.data.statusCode === 201) {
        toast.warn(res.data.message, {
          position: "top-center",
          autoClose: 500,
        });
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  let rentalOwnerFormik = useFormik({
    initialValues: {
      rentalowner_id: "",
      rentalOwner_name: "",
      rentalOwner_companyName: "",
      rentalOwner_primaryEmail: "",
      rentalOwner_alternativeEmail: "",
      rentalOwner_phoneNumber: "",
      rentalOwner_homeNumber: "",
      rentalOwner_businessNumber: "",
      street_address: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      chooseExistingOwner: false,
      processor_list: [
        {
          processor_id: "",
        },
      ],
    },
    validationSchema: yup.object({
      rentalOwner_name: yup.string().required("Required"),
      rentalOwner_companyName: yup.string().required("Required"),
      rentalOwner_primaryEmail: yup.string().email("Invalid email address"),
      rentalOwner_phoneNumber: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (!values.rentalowner_id) {
        checkRentalOwner(values);
      } else {
        setSelectedRentalOwnerData(values);
        setshowRentalOwnerTable(false);
        handleAddrentalOwner();
        handleClose();
      }
    },
  });

  const [processorIds, setProcessorIds] = useState([""]);
  const addProcessorIdInput = () => {
    setProcessorIds([...processorIds, ""]);
    const updatedProcessorList = [
      ...rentalOwnerFormik.values.processor_list,
      { processor_id: "" },
    ];
    rentalOwnerFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const removeProcessorIdInput = (index) => {
    const updatedProcessorIds = [...processorIds];
    updatedProcessorIds.splice(index, 1);
    setProcessorIds(updatedProcessorIds);
    const updatedProcessorList = [...rentalOwnerFormik.values.processor_list];
    updatedProcessorList.splice(index, 1);
    rentalOwnerFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const handleProcessorIdChange = (index, value) => {
    const updatedProcessorIds = [...processorIds];
    updatedProcessorIds[index] = value;
    setProcessorIds(updatedProcessorIds);
    const updatedProcessorList = [...rentalOwnerFormik.values.processor_list];
    updatedProcessorList[index] = { processor_id: value };
    rentalOwnerFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const fetchEditeData = async () => {
    if (rental_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/rental_summary/${rental_id}`
        );

        const response2 = await axiosInstance.get(
          `/unit/rental_unit/${rental_id}`
        );

        setPropType(response.data.data[0]?.property_type_data?.property_type);
        setSelectedPropType(response.data.data[0]?.property_type_data);
        setSelectedUser(
          response.data.data[0]?.staffmember_data?.staffmember_name
        );
        rentalsFormik.setValues({
          rental_id: response.data.data[0].rental_id,
          rental_adress: response.data.data[0].rental_adress,
          is_rent_on: response.data.data[0].is_rent_on,
          rental_city: response.data.data[0].rental_city,
          rental_state: response.data.data[0].rental_state,
          rental_country: response.data.data[0].rental_country,
          rental_postcode: response.data.data[0].rental_postcode,
          staffmember_id: response.data.data[0].staffmember_id,
          staffmember_name: response.data.data[0].staffmember_name,
          property_type:
            response.data.data[0].property_type_data.propertysub_type,
          residential:
            response.data.data[0].property_type_data.property_type ===
            "Residential"
              ? response2.data.data.map((item) => {
                  const {
                    rental_unit,
                    rental_unit_adress,
                    rental_sqft,
                    rental_bath,
                    rental_bed,
                    rental_images,
                    unit_id,
                  } = item;

                  return {
                    rental_unit: rental_unit,
                    rental_unit_adress: rental_unit_adress,
                    rental_sqft: rental_sqft,
                    rental_bath: rental_bath,
                    rental_bed: rental_bed,
                    rental_images: rental_images,
                    unit_id: unit_id,
                  };
                })
              : [],
          commercial:
            response.data.data[0].property_type_data.property_type ===
            "Commercial"
              ? response2.data.data.map((item) => {
                  const {
                    rental_unit,
                    rental_unit_adress,
                    rental_sqft,
                    rental_images,
                    unit_id,
                  } = item;

                  return {
                    rental_unit: rental_unit,
                    rental_unit_adress: rental_unit_adress,
                    rental_sqft: rental_sqft,
                    rental_images: rental_images,
                    unit_id: unit_id,
                  };
                })
              : [],
        });

        rentalOwnerFormik.setValues({
          rentalowner_id:
            response.data.data[0].rental_owner_data.rentalowner_id,
          rentalOwner_name:
            response.data.data[0].rental_owner_data.rentalOwner_name,
          rentalOwner_companyName:
            response.data.data[0].rental_owner_data.rentalOwner_companyName,
          rentalOwner_primaryEmail:
            response.data.data[0].rental_owner_data.rentalOwner_primaryEmail,
          rentalOwner_alternativeEmail:
            response.data.data[0].rental_owner_data
              .rentalOwner_alternativeEmail,
          rentalOwner_phoneNumber:
            response.data.data[0].rental_owner_data.rentalOwner_phoneNumber,
          rentalOwner_homeNumber:
            response.data.data[0].rental_owner_data.rentalOwner_homeNumber,
          rentalOwner_businessNumber:
            response.data.data[0].rental_owner_data.rentalOwner_businessNumber,
          street_address:
            response.data.data[0].rental_owner_data.street_address,
          city: response.data.data[0].rental_owner_data.city,
          state: response.data.data[0].rental_owner_data.state,
          country: response.data.data[0].rental_owner_data.country,
          postal_code: response.data.data[0].rental_owner_data.postal_code,
          chooseExistingOwner: false,
          processor_list:
            response.data.data[0].rental_owner_data.processor_list || [],
        });
        const processorIds = response.data.data[0].rental_owner_data
          .processor_list
          ? response.data.data[0].rental_owner_data.processor_list.map(
              (item) => item.processor_id
            )
          : [];
        setProcessorIds(processorIds);
        setSelectedProcessorId(response.data.data[0].processor_id);
        setSelectedRentalOwnerData(response.data.data[0].rental_owner_data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchPropertyTypeData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/propertytype/property_type/${accessType?.admin_id}`
        );
        setPropertyData(response.data.data);
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };

  const fetchRentalOwnerData = async () => {
    if (accessType?.admin_id)
      try {
        const response = await axiosInstance.get(
          `/rentals/rental-owners/${accessType?.admin_id}`
        );

        if (response.status === 200) {
          setRentalownerData(response.data || []);
        } else {
          console.error(
            "Error:",
            response.data.message || "Failed to fetch data"
          );
        }
      } catch (error) {
        console.error("Network error:", error);
      }
  };

  const fetchStaffMemberData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/staffmember/staff_member/${accessType?.admin_id}`
        );
        setStaffMemberData(response.data.data);
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };

  useEffect(() => {
    fetchEditeData();
  }, [rental_id]);

  useEffect(() => {
    fetchPropertyTypeData();
    fetchRentalOwnerData();
    fetchStaffMemberData();
  }, [accessType]);

  const handleClose = () => {
    setOpen(false);
    setRentalDialogOpen(false);
  };

  const handleCloseButtonClick = () => {
    if (isFromDetails) {
      navigate(`/${admin}/PropDetails/${location.state.id}`);
    } else {
      navigate("/" + admin + "/propertiesTable");
    }
  };

  const addCommercialUnit = () => {
    const newUnit = {
      rental_unit: "",
      rental_soft: "",
      rental_unit_adress: "",
      property_image: [],
    };

    rentalsFormik.setValues({
      ...rentalsFormik.values,
      commercial: [...rentalsFormik.values.commercial, newUnit],
    });
  };

  const addResidentialUnits = () => {
    const newUnit = {
      rental_bed: "",
      rental_bath: "",
      propertyres_image: [],
      rental_sqft: "",
      rental_unit: "",
      rental_unit_adress: "",
    };

    rentalsFormik.setValues({
      ...rentalsFormik.values,
      residential: [...rentalsFormik.values.residential, newUnit],
    });
  };

  const deleteCommercialUnit = (index) => {
    const updatedCommercialUnits = [...rentalsFormik.values.commercial];
    updatedCommercialUnits.splice(index, 1);
    rentalsFormik.setValues({
      ...rentalsFormik.values,
      commercial: updatedCommercialUnits,
    });

    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = { ...prevSelectedFiles };
      delete updatedFiles[index];
      return updatedFiles;
    });
  };

  const deleteResidentialUnit = (index) => {
    const updatedResidentialUnits = [...rentalsFormik.values.residential];
    updatedResidentialUnits.splice(index, 1);
    rentalsFormik.setValues({
      ...rentalsFormik.values,
      residential: updatedResidentialUnits,
    });

    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = { ...prevSelectedFiles };
      delete updatedFiles[index];
      return updatedFiles;
    });
  };

  const fileData = (e, i) => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [i]: [...e.target.files],
    }));
  };

  const clearSelectedPhoto = (commercialIndex, index) => {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = { ...prevSelectedFiles };

      if (updatedFiles[index]) {
        updatedFiles[index] = updatedFiles[index].filter(
          (file, i) => i !== commercialIndex
        );
      }

      return updatedFiles;
    });
  };
  const togglePhotoDialog = () => {};

  const handleUserSelection = (value) => {
    setSelectedUser(value.staffmember_name);
    setuserDropdownOpen(true);
  };

  const handleRentalownerDelete = () => {
    setSelectedRentalOwnerData([]);
    rentalOwnerFormik.resetForm();
  };

  const handleCheckboxChange = (rentalOwnerInfo) => {
    rentalOwnerFormik.setValues({
      rentalowner_id: rentalOwnerInfo.rentalowner_id,
      rentalOwner_name: rentalOwnerInfo.rentalOwner_name,
      rentalOwner_companyName: rentalOwnerInfo.rentalOwner_companyName,
      rentalOwner_primaryEmail: rentalOwnerInfo.rentalOwner_primaryEmail,
      rentalOwner_alternativeEmail:
        rentalOwnerInfo.rentalOwner_alternativeEmail,
      rentalOwner_phoneNumber: rentalOwnerInfo.rentalOwner_phoneNumber,
      rentalOwner_homeNumber: rentalOwnerInfo.rentalOwner_homeNumber,
      rentalOwner_businessNumber: rentalOwnerInfo.rentalOwner_businessNumber,
      street_address: rentalOwnerInfo.street_address,
      city: rentalOwnerInfo.city,
      state: rentalOwnerInfo.state,
      postal_code: rentalOwnerInfo.postal_code,
      country: rentalOwnerInfo.country,
      chooseExistingOwner: true,
      processor_list: rentalOwnerInfo.processor_list || [],
    });
    const processorIds = rentalOwnerInfo.processor_list
      ? rentalOwnerInfo.processor_list.map((item) => item.processor_id)
      : [];
    setProcessorIds(processorIds);
  };

  const handleAddrentalOwner = () => {
    const newrentalOwnerDetails = {
      rentalOwner_name: rentalOwnerFormik.values.rentalOwner_name,
      rentalOwner_phoneNumber: rentalOwnerFormik.values.rentalOwner_phoneNumber,
      rentalOwner_primaryEmail:
        rentalOwnerFormik.values.rentalOwner_primaryEmail,
      rentalOwner_alternativeEmail:
        rentalOwnerFormik.values.rentalOwner_alternativeEmail,
      rentalOwner_homeNumber: rentalOwnerFormik.values.rentalOwner_homeNumber,
      rentalOwner_businessNumber:
        rentalOwnerFormik.values.rentalOwner_businessNumber,
      rentalowner_id: rentalOwnerFormik.values.rentalowner_id || "",
      processor_list: rentalOwnerFormik.values.processor_list || [],
    };
    setSelectedRentalOwnerData(newrentalOwnerDetails);
    toast.success("Rental Owner Added Successfully!", {
      position: "top-center",
      autoClose: 500,
    });
    // if (!rental_id) {
    // }
    setDisplay(false);
  };

  const handleCheckboxProccessor = (id) => {
    setSelectedProcessorId(id);
    rentalsFormik.setFieldValue("processor_id", id);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChange = () => {
    setshowRentalOwnerTable(!showRentalOwnerTable);
  };

  const handlePropSelection = (propertyType) => {
    rentalsFormik.setFieldValue("property_type", propertyType.property_type);
    setSelectedPropType(propertyType);
    setPropType(propertyType.property_type);
    setSelectedFiles([]);
  };
  const openCardForm = () => {
    setIsModalOpen(true);
  };
  const openCardForm1 = () => {
    setisMOdalopen1(true);
  };

  const closeModal = () => {
    setisMOdalopen1(false);
    fetchStaffMemberData();
  };

  const closeModal2 = () => {
    setIsModalOpen(false);
    fetchPropertyTypeData();
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMOdalopen1, setisMOdalopen1] = useState(false);
  const { tenantId, entryIndex } = useParams();

  return (
    <>
      {/* <RentalHeader /> */}
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Property Type</h1>
            </FormGroup> */}
          <CardHeader
            className=" mt-3  headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {rental_id ? "Edit Property" : "Add Property"}
            </h2>
          </CardHeader>
        </Col>
        <Form
          role="form"
          open={open}
          onClose={handleClose}
          onSubmit={rentalsFormik.handleSubmit}
        >
          <Row>
            <Col>
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                {/* <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0" style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Poppins", color: "#152B51" }}>

                        {rental_id ? "Edit Property" : "New Property"}
                      </h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader> */}
                <CardBody>
                  <Form role="form" open={open} onClose={handleClose}>
                    <h6
                      className="heading-small labelfontstyle titleecolor mb-4 fontfamilysty"
                      style={{
                        // marginBottom: "10px",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      Property information
                    </h6>
                    <div className="">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              What is the property type?
                            </label>
                            <br />
                            <Dropdown
                              className="fontstylerentmodal"
                              isOpen={proDropdownOpen}
                              toggle={toggle1}
                              disabled={!!rental_id}
                            >
                              <DropdownToggle
                                caret
                                className="dropwidth fontstylerentmodal titleecolor"
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040",
                                  border: "1px solid #ced4da",
                                  maxWidth: "200px",
                                  minWidth: "200px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {selectedPropType &&
                                selectedPropType.propertysub_type
                                  ? selectedPropType.propertysub_type
                                  : selectedPropType &&
                                    !selectedPropType.propertysub_type
                                  ? selectedPropType
                                  : "Add Property Type"}
                              </DropdownToggle>
                              <DropdownMenu className="fontstylerentmodal">
                                {Object?.values(
                                  propertyData?.reduce((acc, item) => {
                                    if (!acc[item?.property_type]) {
                                      acc[item?.property_type] = [item];
                                    } else {
                                      acc[item?.property_type].push(item);
                                    }
                                    return acc;
                                  }, {})
                                ).map((propertyGroup) => (
                                  <React.Fragment
                                    key={propertyGroup[0].property_type}
                                  >
                                    <DropdownItem
                                      className="fontstylerentmodal"
                                      header
                                      style={{ color: "#152B51" }}
                                    >
                                      {propertyGroup[0].property_type}
                                    </DropdownItem>
                                    {propertyGroup.map((subtype) => (
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        key={subtype.propertysub_type}
                                        onClick={() => {
                                          handlePropSelection(subtype);
                                        }}
                                      >
                                        {subtype.propertysub_type}
                                      </DropdownItem>
                                    ))}
                                  </React.Fragment>
                                ))}
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  style={{ borderTop: "1px solid grey" }}
                                  onClick={() => openCardForm()}
                                >
                                  {/* {subtype.propertysub_type} */}
                                  Add New Properties
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                            {
                              <div>
                                {rentalsFormik.errors &&
                                rentalsFormik.errors?.property_type &&
                                rentalsFormik.touched &&
                                rentalsFormik.touched?.property_type ? (
                                  <div
                                    className="requiredstylefont"
                                    style={{ color: "red" }}
                                  >
                                    {rentalsFormik.errors?.property_type}
                                  </div>
                                ) : null}
                              </div>
                            }
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                              htmlFor="input-address"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              What is the street address?
                            </label>
                            <br />
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                htmlFor="input-address"
                                style={{
                                  // marginBottom: "10px",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Address *
                              </label>
                              <Input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                  width: "80%",
                                }}
                                required
                                className="form-control-alternative fontstylerentmodal titleecolor"
                                id="input-address"
                                placeholder="Enter address"
                                type="text"
                                name="rental_adress"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={(e) =>
                                  rentalsFormik.setFieldValue(
                                    "rental_adress",
                                    e.target.value
                                  )
                                }
                                value={rentalsFormik.values?.rental_adress}
                              />
                              {
                                <div>
                                  {rentalsFormik.errors &&
                                  rentalsFormik.errors?.rental_adress &&
                                  rentalsFormik.touched &&
                                  rentalsFormik.touched?.rental_adress ? (
                                    <div
                                      className="requiredstylefont"
                                      style={{ color: "red" }}
                                    >
                                      {rentalsFormik.errors?.rental_adress}
                                    </div>
                                  ) : null}
                                </div>
                              }
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* 6002 changing the design */}

                      <Row className="mt-2">
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-city"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              City *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              required
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              id="input-city"
                              placeholder="Enter city"
                              type="text"
                              name="rental_city"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={(e) =>
                                rentalsFormik.setFieldValue(
                                  "rental_city",
                                  e.target.value
                                )
                              }
                              value={rentalsFormik.values?.rental_city}
                            />
                            {
                              <div>
                                {rentalsFormik.errors &&
                                rentalsFormik.errors?.rental_city &&
                                rentalsFormik.touched &&
                                rentalsFormik.touched?.rental_city ? (
                                  <div
                                    className="requiredstylefont"
                                    style={{ color: "red" }}
                                  >
                                    {rentalsFormik.errors?.rental_city}
                                  </div>
                                ) : null}
                              </div>
                            }
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-country"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              State *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              required
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              id="input-country"
                              placeholder="Enter state"
                              type="text"
                              name="rental_state"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={(e) =>
                                rentalsFormik.setFieldValue(
                                  "rental_state",
                                  e.target.value
                                )
                              }
                              value={rentalsFormik.values?.rental_state}
                            />
                            {
                              <div>
                                {rentalsFormik.errors &&
                                rentalsFormik.errors?.rental_state &&
                                rentalsFormik.touched &&
                                rentalsFormik.touched?.rental_state ? (
                                  <div
                                    className="requiredstylefont"
                                    style={{ color: "red" }}
                                  >
                                    {rentalsFormik.errors?.rental_state}
                                  </div>
                                ) : null}
                              </div>
                            }
                          </FormGroup>
                        </Col>
                        {/* </Row>
                      <Row className="mt-2"> */}
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-country"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Country *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              required
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              id="input-country"
                              placeholder="Enter country"
                              type="text"
                              name="rental_country"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={(e) =>
                                rentalsFormik.setFieldValue(
                                  "rental_country",
                                  e.target.value
                                )
                              }
                              value={rentalsFormik.values?.rental_country}
                            />
                            {
                              <div>
                                {rentalsFormik.errors &&
                                rentalsFormik.errors?.rental_country &&
                                rentalsFormik.touched &&
                                rentalsFormik.touched?.rental_country ? (
                                  <div
                                    className="requiredstylefont"
                                    style={{ color: "red" }}
                                  >
                                    {rentalsFormik.errors?.rental_country}
                                  </div>
                                ) : null}
                              </div>
                            }
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-country"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Postal code *
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              required
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              id="input-postal-code"
                              placeholder="Enter postal code"
                              type="text"
                              name="rental_postcode"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={(e) =>
                                rentalsFormik.setFieldValue(
                                  "rental_postcode",
                                  e.target.value.toUpperCase()
                                )
                              }
                              value={rentalsFormik.values?.rental_postcode}
                              onInput={(e) => {
                                const inputValue = e.target.value;
                                const sanitizedValue = inputValue.replace(
                                  /[^A-Za-z0-9-]/g,
                                  ""
                                );
                                e.target.value = sanitizedValue.toUpperCase();
                              }}
                            />
                            {
                              <div>
                                {rentalsFormik.errors &&
                                rentalsFormik.errors?.rental_postcode &&
                                rentalsFormik.touched &&
                                rentalsFormik.touched?.rental_postcode ? (
                                  <div
                                    className="requiredstylefont"
                                    style={{ color: "red" }}
                                  >
                                    {rentalsFormik.errors?.rental_postcode}
                                  </div>
                                ) : null}
                              </div>
                            }
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* 6002 end */}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <Form role="form" open={open} onClose={handleClose}>
                    <h6
                      className="heading-small labelfontstyle mb-4 titleecolor fontfamilysty"
                      style={{
                        // marginBottom: "10px",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      Owner information
                    </h6>
                    <div className="">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                              htmlFor="input-address"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Who is the property owner? (Required)
                            </label>
                            <br />
                            <label
                              className="label2 fontstylerentmodal titleecolor fontfamilysty"
                              style={{
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              This information wiil be used to help prepare
                              owner drawns and 1099s.
                            </label>
                            <br />
                            <span
                              className="fontstylerentr fontfamilysty"
                              onClick={setRentalDialogOpen}
                              style={{
                                cursor: "pointer",
                                fontSize: "12px",
                                color: "#26C22C",
                                fontWeight: "500",
                              }}
                            >
                              <b
                                className="fontstylerentr"
                                style={{ fontSize: "15px" }}
                              >
                                +{" "}
                              </b>
                              <b
                                className="fontstylerentr"
                                style={{ fontSize: "15px" }}
                              >
                                {" "}
                                Add Rental Owner
                              </b>

                              {display === false ? (
                                <></>
                              ) : (
                                <div
                                  className="requiredstylefont"
                                  style={{ color: "red" }}
                                >
                                  Required
                                </div>
                              )}
                            </span>
                            <Dialog
                              open={isRentalDialogOpen}
                              onClose={handleClose}
                              PaperProps={{ style: dialogPaperStyles }}
                            >
                              <Form onSubmit={rentalOwnerFormik.handleSubmit}>
                                <DialogTitle
                                  className="ml-3 labelfontstyle fontfamilysty"
                                  style={{
                                    fontSize: "18px",
                                    fontweight: "500",
                                  }}
                                >
                                  Add Rental Owner
                                </DialogTitle>

                                <DialogContent style={{ width: "100%" }}>
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        // paddingTop: "25px",
                                      }}
                                    >
                                      <label
                                        htmlFor="existing-owner"
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontweight: "500",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <Checkbox
                                          id="existing-owner"
                                          onChange={handleChange}
                                          style={{
                                            marginRight: "10px",
                                            color: "#152B51",
                                          }}
                                          checked={
                                            showRentalOwnerTable === true
                                          }
                                        />
                                        Choose an existing rental owner
                                      </label>
                                    </div>
                                    {/* <br /> */}
                                  </div>
                                  {showRentalOwnerTable && rentalownerData && (
                                    <div
                                      style={{
                                        maxHeight: "400px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <Input
                                        className="fontstylerentmodal"
                                        type="text"
                                        placeholder="Search by first and last name"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        style={{
                                          marginBottom: "10px",
                                          width: "100%",
                                          padding: "8px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px",
                                        }}
                                      />
                                      <div
                                        style={{
                                          maxHeight: "calc(400px - 40px)",
                                          overflowY: "auto",
                                          border: "1px solid #ddd",
                                        }}
                                      >
                                        <table
                                          style={{
                                            width: "100%",
                                            borderCollapse: "collapse",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th
                                                className="fontstylerentr"
                                                style={{
                                                  padding: "15px",
                                                }}
                                              >
                                                RentalOwner Name
                                              </th>
                                              <th
                                                className="fontstylerentr"
                                                style={{
                                                  padding: "15px",
                                                }}
                                              >
                                                Processor ID
                                              </th>
                                              <th
                                                className="fontstylerentr"
                                                style={{
                                                  padding: "15px",
                                                }}
                                              >
                                                Select
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Array.isArray(rentalownerData) &&
                                              rentalownerData
                                                ?.filter((rentalOwner) => {
                                                  const fullName =
                                                    rentalOwner?.rentalOwner_name;
                                                  return fullName
                                                    ?.toLowerCase()
                                                    .includes(
                                                      searchQuery?.toLowerCase()
                                                    );
                                                })
                                                .map((rentalOwner, index) => (
                                                  <tr
                                                    key={index}
                                                    style={{
                                                      border: "1px solid #ddd",
                                                    }}
                                                  >
                                                    <td
                                                      className="fontstylerentmodal"
                                                      style={{
                                                        paddingLeft: "15px",
                                                        paddingTop: "15px",
                                                      }}
                                                    >
                                                      {
                                                        rentalOwner?.rentalOwner_name
                                                      }
                                                      {` (${rentalOwner?.rentalOwner_phoneNumber})`}
                                                    </td>
                                                    <td
                                                      className="fontstylerentmodal"
                                                      style={{
                                                        paddingLeft: "15px",
                                                        paddingTop: "15px",
                                                      }}
                                                    >
                                                      {/* Display processor_id(s) here */}
                                                      {rentalOwner?.processor_list?.map(
                                                        (processor, idx) => (
                                                          <div key={idx}>
                                                            {
                                                              processor?.processor_id
                                                            }
                                                          </div>
                                                        )
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingLeft: "15px",
                                                        paddingTop: "15px",
                                                      }}
                                                    >
                                                      <Checkbox
                                                        type="checkbox"
                                                        name="rentalOwner"
                                                        id={
                                                          rentalOwner?.rentalOwner_phoneNumber
                                                        }
                                                        checked={
                                                          rentalOwner?.rentalOwner_phoneNumber ===
                                                          checkedCheckbox
                                                        }
                                                        onChange={(event) => {
                                                          setCheckedCheckbox(
                                                            rentalOwner?.rentalOwner_phoneNumber
                                                          );

                                                          handleCheckboxChange(
                                                            rentalOwner
                                                          );
                                                          setshowRentalOwnerTable(
                                                            false
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                  </tr>
                                                ))}
                                          </tbody>
                                        </table>
                                      </div>
                                      <br />
                                    </div>
                                  )}
                                  {!showRentalOwnerTable && (
                                    <div>
                                      <div
                                        className="formInput"
                                        style={{ margin: "10px 10px" }}
                                      >
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-country"
                                          style={{
                                            fontSize: "16px",
                                            fontweight: "500",
                                          }}
                                        >
                                          Contact Name *
                                        </label>
                                        <br />
                                        <Input
                                          id="standard-multiline-static"
                                          className="popinput fontstylerentmodal titleecolor"
                                          type="text"
                                          placeholder="Enter name"
                                          style={{
                                            marginRight: "10px",
                                            flex: 1,
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                          name="rentalOwner_name"
                                          onBlur={rentalOwnerFormik.handleBlur}
                                          onChange={(e) => {
                                            rentalOwnerFormik.setFieldValue(
                                              "rentalOwner_name",
                                              e.target.value
                                            );
                                          }}
                                          value={
                                            rentalOwnerFormik.values
                                              .rentalOwner_name
                                          }
                                        />

                                        {rentalOwnerFormik.touched
                                          .rentalOwner_name &&
                                        rentalOwnerFormik.errors
                                          .rentalOwner_name ? (
                                          <div
                                            className="requiredstylefont"
                                            style={{ color: "red" }}
                                          >
                                            {
                                              rentalOwnerFormik.errors
                                                .rentalOwner_name
                                            }
                                          </div>
                                        ) : null}
                                        {/* <Input
                                          id="standard-multiline-static"
                                          className="popinput fontstylerentmodal"
                                          type="text"
                                          placeholder="Enter last name"
                                          style={{
                                            flex: 1,
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                          name="rentalOwner_lastName"
                                          onBlur={rentalOwnerFormik.handleBlur}
                                          onChange={(e) => {
                                            rentalOwnerFormik.setFieldValue(
                                              "rentalOwner_lastName",
                                              e.target.value
                                            );
                                          }}
                                          value={
                                            rentalOwnerFormik.values
                                              .rentalOwner_lastName
                                          }
                                        />

                                        {rentalOwnerFormik.touched
                                          .rentalOwner_lastName &&
                                          rentalOwnerFormik.errors
                                            .rentalOwner_lastName ? (
                                          <div className="requiredstylefont" style={{ color: "red" }}>
                                            {
                                              rentalOwnerFormik.errors
                                                .rentalOwner_lastName
                                            }
                                          </div>
                                        ) : null} */}
                                      </div>
                                      <div
                                        className="formInput"
                                        style={{ margin: "20px 10px" }}
                                      >
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-address"
                                          style={{
                                            fontSize: "16px",
                                            fontweight: "500",
                                          }}
                                        >
                                          Company Name *
                                        </label>
                                        <br />
                                        <Input
                                          id="standard-multiline-static"
                                          className="popinput fontstylerentmodal titleecolor"
                                          type="text"
                                          placeholder="Enter company name"
                                          style={{
                                            marginRight: "10px",
                                            flex: 1,
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                          name="rentalOwner_companyName"
                                          onBlur={rentalOwnerFormik.handleBlur}
                                          onChange={(e) => {
                                            rentalOwnerFormik.setFieldValue(
                                              "rentalOwner_companyName",
                                              e.target.value
                                            );
                                          }}
                                          value={
                                            rentalOwnerFormik.values
                                              .rentalOwner_companyName
                                          }
                                        />
                                        {rentalOwnerFormik.touched
                                          .rentalOwner_companyName &&
                                        rentalOwnerFormik.errors
                                          .rentalOwner_companyName ? (
                                          <div
                                            className="requiredstylefont"
                                            style={{ color: "red" }}
                                          >
                                            {
                                              rentalOwnerFormik.errors
                                                .rentalOwner_companyName
                                            }
                                          </div>
                                        ) : null}
                                      </div>
                                      <div
                                        className="formInput"
                                        style={{ margin: "20px 10px" }}
                                      >
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-address"
                                          style={{
                                            fontSize: "16px",
                                            fontweight: "500",
                                          }}
                                        >
                                          Primary Email *
                                        </label>
                                        <br />
                                        <InputGroup
                                          style={{
                                            marginRight: "10px",
                                            marginTop: "5px",
                                            flex: 1,
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          <InputGroupAddon addonType="prepend">
                                            <span
                                              className="input-group-text"
                                              style={
                                                {
                                                  // paddingBottom: "8px",
                                                  // paddingTop: "8px",
                                                }
                                              }
                                            >
                                              {/* <EmailIcon /> */}
                                              <i className="fas fa-envelope"></i>
                                            </span>
                                          </InputGroupAddon>
                                          <Input
                                            id="standard-multiline-static"
                                            className=" fontstylerentmodal titleecolor"
                                            type="text"
                                            name="rentalOwner_primaryEmail"
                                            onBlur={
                                              rentalOwnerFormik.handleBlur
                                            }
                                            placeholder="Enter primary email"
                                            onChange={(e) => {
                                              rentalOwnerFormik.setFieldValue(
                                                "rentalOwner_primaryEmail",
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              rentalOwnerFormik.values
                                                .rentalOwner_primaryEmail
                                            }
                                          />
                                        </InputGroup>

                                        {rentalOwnerFormik.touched
                                          .rentalOwner_primaryEmail &&
                                        rentalOwnerFormik.errors
                                          .rentalOwner_primaryEmail ? (
                                          <div
                                            className="requiredstylefont"
                                            style={{ color: "red" }}
                                          >
                                            {
                                              rentalOwnerFormik.errors
                                                .rentalOwner_primaryEmail
                                            }
                                          </div>
                                        ) : null}
                                      </div>
                                      <div
                                        className="formInput"
                                        style={{ margin: "20px 10px" }}
                                      >
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-address"
                                          style={{
                                            fontSize: "16px",
                                            fontweight: "500",
                                          }}
                                        >
                                          Alternative Email
                                        </label>
                                        <br />
                                        <InputGroup
                                          style={{
                                            marginRight: "10px",
                                            marginTop: "5px",
                                            flex: 1,

                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          <InputGroupAddon addonType="prepend">
                                            <span
                                              className="input-group-text"
                                              // style={{
                                              //   paddingBottom: "8px",
                                              //   paddingTop: "8px",
                                              // }}
                                            >
                                              {/* <EmailIcon /> */}
                                              <i className="fas fa-envelope"></i>
                                            </span>
                                          </InputGroupAddon>
                                          <Input
                                            id="standard-multiline-static"
                                            className="popinput fontstylerentmodal titleecolor"
                                            type="text"
                                            name="rentalOwner_alternativeEmail"
                                            onBlur={
                                              rentalOwnerFormik.handleBlur
                                            }
                                            placeholder="Enter alternative email"
                                            onChange={(e) => {
                                              rentalOwnerFormik.setFieldValue(
                                                "rentalOwner_alternativeEmail",
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              rentalOwnerFormik.values
                                                .rentalOwner_alternativeEmail
                                            }
                                          />
                                        </InputGroup>
                                      </div>
                                      <div
                                        className="formInput"
                                        style={{ margin: "20px 10px" }}
                                      >
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-address"
                                          style={{
                                            fontSize: "16px",
                                            fontweight: "500",
                                          }}
                                        >
                                          Phone Numbers *
                                        </label>
                                        <br />
                                        <InputGroup
                                          style={{
                                            marginRight: "10px",
                                            marginTop: "5px",
                                            flex: 1,

                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          <InputGroupAddon addonType="prepend">
                                            <span
                                              className="input-group-text"
                                              // style={{
                                              //   paddingBottom: "8px",
                                              //   paddingTop: "8px",
                                              // }}
                                            >
                                              {/* <PhoneIcon /> */}
                                              <i className="fas fa-mobile-alt"></i>
                                            </span>
                                          </InputGroupAddon>
                                          <Input
                                            id="standard-multiline-static"
                                            className="popinput fontstylerentmodal titleecolor"
                                            type="text"
                                            name="rentalOwner_phoneNumber"
                                            onBlur={
                                              rentalOwnerFormik.handleBlur
                                            }
                                            placeholder="Enter phone number"
                                            onChange={(e) => {
                                              rentalOwnerFormik.setFieldValue(
                                                "rentalOwner_phoneNumber",
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              rentalOwnerFormik.values
                                                .rentalOwner_phoneNumber
                                            }
                                            // onInput={(e) => {
                                            //   const inputValue = e.target.value;
                                            //   const numericValue =
                                            //     inputValue.replace(/\D/g, "");
                                            //   e.target.value = numericValue;
                                            // }}
                                          />
                                        </InputGroup>
                                        {rentalOwnerFormik.touched
                                          .rentalOwner_phoneNumber &&
                                        rentalOwnerFormik.errors
                                          .rentalOwner_phoneNumber ? (
                                          <div
                                            className="requiredstylefont"
                                            style={{ color: "red" }}
                                          >
                                            {
                                              rentalOwnerFormik.errors
                                                .rentalOwner_phoneNumber
                                            }
                                          </div>
                                        ) : null}

                                        <InputGroup
                                          style={{
                                            marginBottom: "20px",
                                            marginTop: "20px",
                                            marginRight: "10px",
                                            flex: 1,

                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          <InputGroupAddon addonType="prepend">
                                            <span
                                              className="input-group-text"
                                              // style={{
                                              //   paddingBottom: "8px",
                                              //   paddingTop: "8px",
                                              // }}
                                            >
                                              {/* <HomeIcon /> */}
                                              <i className="fas fa-home"></i>
                                            </span>
                                          </InputGroupAddon>
                                          <Input
                                            id="standard-multiline-static"
                                            className="popinput fontstylerentmodal titleecolor"
                                            type="text"
                                            name="rentalOwner_homeNumber"
                                            onBlur={
                                              rentalOwnerFormik.handleBlur
                                            }
                                            placeholder="Enter home number"
                                            onChange={(e) => {
                                              rentalOwnerFormik.setFieldValue(
                                                "rentalOwner_homeNumber",
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              rentalOwnerFormik.values
                                                .rentalOwner_homeNumber
                                            }
                                            // onInput={(e) => {
                                            //   const inputValue = e.target.value;
                                            //   const numericValue =
                                            //     inputValue.replace(/\D/g, "");
                                            //   e.target.value = numericValue;
                                            // }}
                                          />
                                        </InputGroup>

                                        <InputGroup
                                          style={{
                                            marginBottom: "10px",
                                            marginRight: "10px",
                                            flex: 1,
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          <InputGroupAddon addonType="prepend">
                                            <span
                                              className="input-group-text"
                                              // style={{
                                              //   paddingBottom: "8px",
                                              //   paddingTop: "8px",
                                              // }}
                                            >
                                              {/* <BusinessIcon /> */}
                                              <i className="fas fa-fax"></i>
                                            </span>
                                          </InputGroupAddon>
                                          <Input
                                            id="standard-multiline-static"
                                            className="popinput fontstylerentmodal titleecolor"
                                            type="text"
                                            name="rentalOwner_businessNumber"
                                            onBlur={
                                              rentalOwnerFormik.handleBlur
                                            }
                                            placeholder="Enter business number"
                                            onChange={(e) => {
                                              rentalOwnerFormik.setFieldValue(
                                                "rentalOwner_businessNumber",
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              rentalOwnerFormik.values
                                                .rentalOwner_businessNumber
                                            }
                                            // onInput={(e) => {
                                            //   const inputValue = e.target.value;
                                            //   const numericValue =
                                            //     inputValue.replace(/\D/g, "");
                                            //   e.target.value = numericValue;
                                            // }}
                                          />
                                        </InputGroup>
                                      </div>
                                      <div
                                        className="formInput"
                                        style={{ margin: "20px 10px" }}
                                      >
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-address"
                                          style={{
                                            fontSize: "16px",
                                            fontweight: "500",
                                          }}
                                        >
                                          Address Information
                                        </label>
                                        <Row>
                                          <FormGroup className="col-12">
                                            <Input
                                              className="form-control-alternative fontstylerentmodal titleecolor"
                                              id="input-address"
                                              placeholder="Enter street address "
                                              type="text"
                                              name="street_address"
                                              onBlur={
                                                rentalOwnerFormik.handleBlur
                                              }
                                              onChange={(e) =>
                                                rentalOwnerFormik.setFieldValue(
                                                  "street_address",
                                                  e.target.value
                                                )
                                              }
                                              value={
                                                rentalOwnerFormik.values
                                                  ?.street_address
                                              }
                                              style={{
                                                border: "1px solid #cad1d7",
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                              }}
                                            />
                                          </FormGroup>
                                          <FormGroup className="col-6">
                                            <Input
                                              className="form-control-alternative fontstylerentmodal titleecolor"
                                              id="input-address"
                                              placeholder="Enter city"
                                              type="text"
                                              name="city"
                                              onBlur={
                                                rentalOwnerFormik.handleBlur
                                              }
                                              onChange={(e) =>
                                                rentalOwnerFormik.setFieldValue(
                                                  "city",
                                                  e.target.value
                                                )
                                              }
                                              value={
                                                rentalOwnerFormik.values?.city
                                              }
                                              style={{
                                                border: "1px solid #cad1d7",
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                              }}
                                            />
                                          </FormGroup>
                                          <FormGroup className="col-6">
                                            <Input
                                              className="form-control-alternative fontstylerentmodal titleecolor"
                                              id="input-address"
                                              placeholder="Enter state"
                                              type="text"
                                              name="state"
                                              onBlur={
                                                rentalOwnerFormik.handleBlur
                                              }
                                              onChange={(e) =>
                                                rentalOwnerFormik.setFieldValue(
                                                  "state",
                                                  e.target.value
                                                )
                                              }
                                              value={
                                                rentalOwnerFormik.values?.state
                                              }
                                              style={{
                                                border: "1px solid #cad1d7",
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                              }}
                                            />
                                          </FormGroup>
                                        </Row>
                                        <Row>
                                          <FormGroup className="col-6">
                                            <Input
                                              className="form-control-alternative fontstylerentmodal titleecolor"
                                              id="input-address"
                                              placeholder="Enter country"
                                              type="text"
                                              name="country"
                                              onBlur={
                                                rentalOwnerFormik.handleBlur
                                              }
                                              onChange={(e) =>
                                                rentalOwnerFormik.setFieldValue(
                                                  "country",
                                                  e.target.value
                                                )
                                              }
                                              value={
                                                rentalOwnerFormik.values
                                                  ?.country
                                              }
                                              style={{
                                                border: "1px solid #cad1d7",
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                              }}
                                            />
                                          </FormGroup>
                                          <FormGroup className="col-6">
                                            <Input
                                              className="form-control-alternative fontstylerentmodal titleecolor"
                                              id="input-address"
                                              placeholder="Enter postal code"
                                              type="text"
                                              name="postal_code"
                                              onBlur={
                                                rentalOwnerFormik.handleBlur
                                              }
                                              onChange={(e) =>
                                                rentalOwnerFormik.setFieldValue(
                                                  "postal_code",
                                                  e.target.value
                                                )
                                              }
                                              value={
                                                rentalOwnerFormik.values
                                                  ?.postal_code
                                              }
                                              style={{
                                                border: "1px solid #cad1d7",
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                              }}
                                            />
                                          </FormGroup>
                                        </Row>
                                      </div>
                                      {/* <div
                                        className="formInput"
                                        style={{ margin: "30px 10px" }}
                                      >
                                        <label
                                          className="form-control-label fontstylerentr titleecolor"
                                          htmlFor="input-address"
                                          style={{
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Merchant Id
                                        </label>
                                        <br />
                                        <FormGroup
                                          style={{
                                            marginRight: "10px",
                                            marginTop: "5px",
                                            flex: 1,
                                            borderRadius: "6px",
                                          }}
                                        > */}
                                      {/* {processorIds.map(
                                            (processorId, index) => (
                                              <div
                                                className="d-flex flex-row align-items-center"
                                                key={index}
                                              >
                                                <FormGroup
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <Checkbox
                                                    // id="checked"
                                                    className="checkbox"
                                                    style={{
                                                      height: "4px",
                                                      color: "#152B51",
                                                    }}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        setSelectedProcessorId(
                                                          processorId
                                                        );
                                                        rentalsFormik.setFieldValue(
                                                          "processor_id",
                                                          processorId
                                                        );
                                                      } else {
                                                        setSelectedProcessorId(
                                                          ""
                                                        );
                                                        rentalsFormik.setFieldValue(
                                                          "processor_id",
                                                          ""
                                                        );
                                                      }
                                                    }}
                                                    checked={
                                                      selectedProcessorId ===
                                                        processorId &&
                                                      selectedProcessorId !== ""
                                                    }
                                                  />
                                                </FormGroup>
                                                <FormGroup
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  <Input
                                                    className="inputstyle fontstylerentmodal titleecolor"
                                                    style={{
                                                      boxShadow:
                                                        "0px 4px 4px 0px #00000040",
                                                      borderRadius: "6px",
                                                    }}
                                                    id={`processor_id_${index}`}
                                                    placeholder="Enter processor id"
                                                    onBlur={
                                                      rentalOwnerFormik.handleBlur
                                                    }
                                                    onChange={(e) =>
                                                      handleProcessorIdChange(
                                                        index,
                                                        e.target.value
                                                      )
                                                    }
                                                    value={processorId}
                                                  />
                                                </FormGroup>
                                                {index >= 0 && (
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className="mx-2 mb-2"
                                                    onClick={() =>
                                                      removeProcessorIdInput(
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      className="propertyiconsrentals"
                                                      src={deleicon}
                                                      width={20}
                                                      height={20}
                                                      alt="Delete Icon"
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            )
                                          )} */}
                                      {/* {processorIds.length <= 1 ? (
                                            <div className="d-flex flex-row align-items-center"> */}
                                      {/* <FormGroup
                                                style={{ marginRight: "10px" }}
                                              >
                                                <Checkbox
                                                  className="checkbox"
                                                  style={{
                                                    height: "4px",
                                                    color: "#152B51",
                                                  }}
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedProcessorId(
                                                        ""
                                                      );
                                                      rentalsFormik.setFieldValue(
                                                        "processor_id",
                                                        ""
                                                      );
                                                    }
                                                  }}
                                                  checked={true}
                                                />
                                              </FormGroup> */}
                                      {/* <FormGroup
                                                style={{ marginRight: "10px" }}
                                              >
                                                <Input
                                                  className="inputstyle fontstylerentmodal titleecolor"
                                                  style={{
                                                    boxShadow:
                                                      "0px 4px 4px 0px #00000040",
                                                    borderRadius: "6px",
                                                  }}
                                                  placeholder="Enter processor id"
                                                  onBlur={
                                                    rentalsFormik.handleBlur
                                                  }
                                                  onChange={(e) =>
                                                    handleProcessorIdChange(
                                                      0,
                                                      e.target.value
                                                    )
                                                  }
                                                  // value={rentalOwnerFormik.values.processor_list[0].processor_id || 0}
                                                  value={
                                                    rentalOwnerFormik.values
                                                      .processor_list.length <=
                                                    1
                                                      ? rentalOwnerFormik.values
                                                          .processor_list[0]?.processor_id
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                            </div>
                                          ) : (
                                            processorIds.map(
                                              (processorId, index) => (
                                                <div
                                                  className="d-flex flex-row align-items-center"
                                                  key={index}
                                                >
                                                  <FormGroup
                                                    style={{
                                                      marginRight: "10px",
                                                    }}
                                                  >
                                                    <Checkbox
                                                      className="checkbox"
                                                      style={{
                                                        height: "4px",
                                                        color: "#152B51",
                                                      }}
                                                      onChange={(e) => {
                                                        if (e.target.checked) {
                                                          setSelectedProcessorId(
                                                            processorId
                                                          );
                                                          rentalsFormik.setFieldValue(
                                                            "processor_id",
                                                            processorId
                                                          );
                                                        } else {
                                                          // Unchecking logic: Keep the first processor checked if unchecking the current one
                                                          if (
                                                            selectedProcessorId ===
                                                              processorId &&
                                                            processorIds.length >
                                                              0
                                                          ) {
                                                            const newSelectedProcessorId =
                                                              processorIds[0];
                                                            setSelectedProcessorId(
                                                              newSelectedProcessorId
                                                            );
                                                            rentalsFormik.setFieldValue(
                                                              "processor_id",
                                                              newSelectedProcessorId
                                                            );
                                                          } else {
                                                            setSelectedProcessorId(
                                                              ""
                                                            );
                                                            rentalsFormik.setFieldValue(
                                                              "processor_id",
                                                              ""
                                                            );
                                                          }
                                                        }
                                                      }}
                                                      checked={
                                                        selectedProcessorId ===
                                                        processorId
                                                      }
                                                    />
                                                  </FormGroup>
                                                  <FormGroup
                                                    style={{
                                                      marginRight: "10px",
                                                    }}
                                                  >
                                                    <Input
                                                      className="inputstyle fontstylerentmodal titleecolor"
                                                      style={{
                                                        boxShadow:
                                                          "0px 4px 4px 0px #00000040",
                                                        borderRadius: "6px",
                                                      }}
                                                      id={`processor_id_${index}`}
                                                      placeholder="Enter processor id"
                                                      onBlur={
                                                        rentalsFormik.handleBlur
                                                      }
                                                      onChange={(e) =>
                                                        handleProcessorIdChange(
                                                          index,
                                                          e.target.value
                                                        )
                                                      }
                                                      value={processorId}
                                                    />
                                                  </FormGroup>
                                                  {index >= 0 && (
                                                    <div
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="mx-2 mb-2"
                                                      onClick={() =>
                                                        removeProcessorIdInput(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        className="propertyiconsrentals"
                                                        src={deleicon}
                                                        width={20}
                                                        height={20}
                                                        alt="Delete Icon"
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )
                                          )} */}
                                      {/* <Button
                                            className="fontstylerentr"
                                            style={{
                                              background: "#152B51",
                                              color: "white",
                                              fontFamily: "Poppins",
                                              fontWeight: "400",
                                              fontSize: "14px",
                                              cursor: "pointer",
                                            }}
                                            size="sm"
                                            onClick={addProcessorIdInput}
                                          >
                                            Add Another
                                          </Button> */}
                                      {/* </FormGroup>
                                      </div> */}
                                    </div>
                                  )}
                                </DialogContent>
                                <DialogActions className="d-flex justify-content-start ml-4">
                                  <Button
                                    className="fontstylerentr bgtextwhite background-colorsty"
                                    type="submit"
                                    disabled={!rentalOwnerFormik.isValid}
                                  >
                                    Add
                                  </Button>
                                  <Button
                                    className="fontstylerentr textcolorblue"
                                    style={{
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={handleClose}
                                  >
                                    Cancel
                                  </Button>
                                </DialogActions>
                                <div
                                  className="mb-2"
                                  style={{ marginLeft: "34px" }}
                                >
                                  {!rentalOwnerFormik.isValid && (
                                    <div
                                      className="fontstylerentr"
                                      style={{
                                        color: "red",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Please fill in all fields correctly.
                                    </div>
                                  )}
                                </div>
                              </Form>
                            </Dialog>
                            {Object.keys(selectedRentalOwnerData).length > 0 ? (
                              <div>
                                <h3
                                  className="fontstylerentr textcolorblue"
                                  style={{
                                    marginTop: "2%",
                                    fontSize: "16px",
                                  }}
                                >
                                  Rental owner Information
                                </h3>
                                <div
                                  style={{
                                    overflowX: "auto",
                                    scrollbarWidth: "thin",
                                    maxHeight: "300px",
                                    marginTop: "2%",
                                  }}
                                >
                                  <table
                                    style={{
                                      borderCollapse: "collapse",
                                      width: "100%",
                                    }}
                                  >
                                    <thead>
                                      <tr style={{ background: "#f2f2f2" }}>
                                        <th
                                          className="fontstylerentr"
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Name
                                        </th>
                                        <th
                                          className="fontstylerentr"
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Phone Number
                                        </th>
                                        <th
                                          className="fontstylerentr"
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td
                                          className="fontstylerentmodal"
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {
                                            selectedRentalOwnerData.rentalOwner_name
                                          }
                                        </td>

                                        <td
                                          className="fontstylerentmodal"
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {
                                            selectedRentalOwnerData.rentalOwner_phoneNumber
                                          }
                                        </td>
                                        <td
                                          className="fontstylerentmodal"
                                          style={{
                                            padding: "8px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <EditIcon
                                            className="propertyiconsrentals"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setshowRentalOwnerTable(false);
                                              setRentalDialogOpen(true);
                                            }}
                                          />
                                          <DeleteIcon
                                            className="propertyiconsrentals rentalsbtnstyledel"
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                            }}
                                            onClick={() => {
                                              setshowRentalOwnerTable(false);
                                              handleRentalownerDelete();
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <Form role="form" open={open} onClose={handleClose}>
                    <div className="">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                              htmlFor="input-address"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Who will be the primary manager of this property?
                            </label>
                            <br />
                            <label
                              className="label2 fontstylerentmodal titleecolor fontfamilysty"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              If the staff member has not yet been added as a
                              user in your account,they can be added to the
                              account,then as the manager later through the
                              property's summary details.
                            </label>
                            <br />
                            <label
                              className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                              htmlFor="input-address"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Manager (Optional)
                            </label>
                            <br />
                            <FormGroup>
                              <Dropdown
                                className="fontstylerentmodal"
                                isOpen={userdropdownOpen}
                                toggle={toggle3}
                              >
                                <DropdownToggle
                                  caret
                                  className="dropwidth fontstylerentmodal titleecolor"
                                  style={{
                                    boxShadow: " 0px 4px 4px 0px #00000040",
                                    border: "1px solid #ced4da",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {selectedUser ? selectedUser : "Select"}
                                </DropdownToggle>
                                <DropdownMenu className="fontstylerentmodal">
                                  {StaffMemberData.map((user) => (
                                    <DropdownItem
                                      className="fontstylerentmodal"
                                      key={user.staffmember_id}
                                      onClick={() => {
                                        handleUserSelection(user);
                                        rentalsFormik.setFieldValue(
                                          "staffmember_name",
                                          user.staffmember_name
                                        );
                                        rentalsFormik.setFieldValue(
                                          "staffmember_id",
                                          user.staffmember_id
                                        );
                                      }}
                                    >
                                      {user.staffmember_name}
                                    </DropdownItem>
                                  ))}
                                  <DropdownItem
                                    className="fontstylerentmodal"
                                    onClick={() => openCardForm1()}
                                    style={{ borderTop: "1px solid grey" }}
                                  >
                                    Add New Staff member
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {!rental_id && propType === "Residential" && (
                <Card
                  className="bg-white shadow mt-3 mb-3 mx-3 cardstyborderradius"
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  {/* 6002  bath bed input change */}
                  <CardBody>
                    <div className="">
                      <h6
                        className="heading-small labelfontstyle mb-4 titleecolor fontfamilysty"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Residential Unit
                      </h6>
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                          htmlFor="input-address"
                          style={{
                            // marginBottom: "10px",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Enter Residential Units
                        </label>
                        <br />
                        <br />
                        {rentalsFormik.values &&
                          rentalsFormik.values?.residential.map(
                            (residential, residentialIndex) => (
                              <div key={residentialIndex}>
                                <Row style={{ position: "relative" }}>
                                  <ClearIcon
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: "10px",
                                      top: "10px",
                                      display: selectedPropType.is_multiunit
                                        ? "block"
                                        : "none",
                                      marginBottom: "20px",
                                      zIndex: 1000,
                                    }}
                                    onClick={() => {
                                      deleteResidentialUnit(residentialIndex);
                                    }}
                                  />

                                  <Col
                                    lg="3"
                                    style={
                                      selectedPropType.is_multiunit
                                        ? {
                                            display: "block",
                                            // marginTop: "20px",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor={`input-unit-${residentialIndex}`}
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Unit *
                                      </label>
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                          width: "80%",
                                        }}
                                        required
                                        className="form-control-alternative fontstylerentmodal titleecolor"
                                        id={`input-unit-${residentialIndex}`}
                                        placeholder="Enter unit"
                                        type="text"
                                        name={`residential[${residentialIndex}].rental_unit`}
                                        onBlur={rentalsFormik.handleBlur}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const newValue = value.replace(
                                            /[^A-Za-z0-9]/g,
                                            ""
                                          );

                                          rentalsFormik.setFieldValue(
                                            `residential[${residentialIndex}].rental_unit`,
                                            newValue
                                          );
                                        }}
                                        value={
                                          rentalsFormik.values.residential[
                                            residentialIndex
                                          ].rental_unit
                                        }
                                      />
                                      {rentalsFormik.errors &&
                                      rentalsFormik.errors?.residential &&
                                      rentalsFormik.errors.residential[
                                        residentialIndex
                                      ]?.rental_unit &&
                                      rentalsFormik.touched &&
                                      rentalsFormik.touched?.residential &&
                                      rentalsFormik.touched.residential[
                                        residentialIndex
                                      ]?.rental_unit ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            rentalsFormik.errors.residential[
                                              residentialIndex
                                            ]?.rental_unit
                                          }
                                        </div>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="3"
                                    style={
                                      selectedPropType.is_multiunit
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor="input-unitadd"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Unit Address
                                      </label>
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                          width: "80%",
                                        }}
                                        required
                                        className="form-control-alternative fontstylerentmodal titleecolor"
                                        id="input-unitadd"
                                        placeholder="Enter unit address "
                                        type="text"
                                        name={`residential[${residentialIndex}].rental_unit_adress`}
                                        onBlur={rentalsFormik.handleBlur}
                                        onChange={(e) =>
                                          rentalsFormik.setFieldValue(
                                            `residential[${residentialIndex}].rental_unit_adress`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          rentalsFormik.values.residential[
                                            residentialIndex
                                          ].rental_unit_adress
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3">
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor="input-unitadd"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        SQFT *
                                      </label>
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                          width: "80%",
                                        }}
                                        required
                                        className="form-control-alternative fontstylerentmodal titleecolor"
                                        id="input-unitadd"
                                        placeholder="Enter Sqft"
                                        type="text"
                                        name={`residential[${residentialIndex}].rental_sqft`}
                                        onBlur={rentalsFormik.handleBlur}
                                        onChange={(e) => {
                                          rentalsFormik.setFieldValue(
                                            `residential[${residentialIndex}].rental_sqft`,
                                            e.target.value
                                          );
                                        }}
                                        value={
                                          rentalsFormik.values.residential[
                                            residentialIndex
                                          ].rental_sqft
                                        }
                                        onInput={(e) => {
                                          const inputValue = e.target.value;
                                          const numericValue =
                                            inputValue.replace(/\D/g, "");
                                          e.target.value = numericValue;
                                        }}
                                      />
                                      {rentalsFormik.errors &&
                                      rentalsFormik.errors?.residential &&
                                      rentalsFormik.errors.residential[
                                        residentialIndex
                                      ]?.rental_sqft &&
                                      rentalsFormik.touched &&
                                      rentalsFormik.touched?.residential &&
                                      rentalsFormik.touched.residential[
                                        residentialIndex
                                      ]?.rental_sqft ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            rentalsFormik.errors.residential[
                                              residentialIndex
                                            ]?.rental_sqft
                                          }
                                        </div>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="8">
                                    {/* <FormGroup> */}
                                    <Row
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Col md="4">
                                        <FormGroup>
                                          <label
                                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                            htmlFor="input-unitadd"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "16px",
                                            }}
                                          >
                                            Bath
                                          </label>
                                          <Autocomplete
                                            className="form-control-alternative fontstylerentmodal titleecolor"
                                            id="input-unitadd"
                                            freeSolo
                                            size="small"
                                            options={bathArray.map(
                                              (option) => option
                                            )}
                                            onChange={(event, newValue) => {
                                              rentalsFormik.setFieldValue(
                                                `residential[${residentialIndex}].rental_bath`,
                                                newValue
                                              );
                                            }}
                                            style={{ width: "230px" }}
                                            renderInput={(params) => (
                                              <TextField
                                                className="fontstylerentmodal"
                                                {...params}
                                                name={`residential[${residentialIndex}].rental_bath`}
                                                id={`residential[${residentialIndex}].rental_bath`}
                                                value={
                                                  rentalsFormik.values
                                                    .residential[
                                                    residentialIndex
                                                  ].rental_bath
                                                }
                                                onChange={(e) => {
                                                  rentalsFormik.setFieldValue(
                                                    `residential[${residentialIndex}].rental_bath`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            )}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col
                                        md="4"
                                        style={{ paddingLeft: "50px" }}
                                      >
                                        <FormGroup>
                                          <label
                                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                            htmlFor="input-unitadd"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "16px",
                                            }}
                                          >
                                            Bed
                                          </label>

                                          <Autocomplete
                                            className="form-control-alternative fontstylerentmodal titleecolor"
                                            id="input-unitadd"
                                            freeSolo
                                            size="small"
                                            options={roomsArray.map(
                                              (option) => option
                                            )}
                                            onChange={(event, newValue) => {
                                              rentalsFormik.setFieldValue(
                                                `residential[${residentialIndex}].rental_bed`,
                                                newValue
                                              );
                                            }}
                                            style={{ width: "230px" }}
                                            renderInput={(params) => (
                                              <TextField
                                                className="fontstylerentmodal"
                                                {...params}
                                                name={`residential[${residentialIndex}].rental_bed`}
                                                id={`residential[${residentialIndex}].rental_bed`}
                                                value={
                                                  rentalsFormik.values
                                                    .residential[
                                                    residentialIndex
                                                  ].rental_bed
                                                }
                                                onChange={(e) => {
                                                  rentalsFormik.setFieldValue(
                                                    `residential[${residentialIndex}].rental_bed`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            )}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    &nbsp;&nbsp;
                                    {/* </FormGroup> */}
                                  </Col>

                                  {/* myyphotos */}
                                  <Col lg="5">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <FormGroup
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label
                                          className="form-control-label mb-3 fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-unitadd"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Photo
                                        </label>
                                        <span
                                          className="fontfamilysty"
                                          onClick={togglePhotoDialog}
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            color: "#26C22C",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            className="form-control-file d-none"
                                            accept="image/*"
                                            multiple
                                            id={`propertyres_image_${residentialIndex}`}
                                            name={`propertyres_image_${residentialIndex}`}
                                            onChange={(e) =>
                                              fileData(e, residentialIndex)
                                            }
                                          />
                                          <label
                                            htmlFor={`propertyres_image_${residentialIndex}`}
                                          >
                                            <b
                                              className="fontstylerentr"
                                              style={{ fontSize: "15px" }}
                                            >
                                              +
                                            </b>
                                            <b
                                              className="fontstylerentr"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              Add
                                            </b>
                                          </label>
                                        </span>
                                      </FormGroup>

                                      <FormGroup
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        <div
                                          className="mt-3 d-flex"
                                          style={{
                                            justifyContent: "center",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {selectedFiles[residentialIndex] &&
                                            selectedFiles[residentialIndex]
                                              .length > 0 &&
                                            selectedFiles[residentialIndex].map(
                                              (unitImg, index) => (
                                                <div
                                                  key={index}
                                                  style={{
                                                    position: "relative",
                                                    width: "100px",
                                                    height: "100px",
                                                    margin: "10px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      unitImg instanceof File
                                                        ? URL.createObjectURL(
                                                            unitImg
                                                          )
                                                        : unitImg
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                      maxHeight: "100%",
                                                      maxWidth: "100%",
                                                      borderRadius: "10px",
                                                    }}
                                                    onClick={() => {
                                                      // setSelectedImage(unitImg);
                                                      setOpen(true);
                                                    }}
                                                  />
                                                  <ClearIcon
                                                    style={{
                                                      cursor: "pointer",
                                                      alignSelf: "flex-start",
                                                      position: "absolute",
                                                      top: "-12px",
                                                      right: "-12px",
                                                    }}
                                                    onClick={() =>
                                                      clearSelectedPhoto(
                                                        index,
                                                        residentialIndex
                                                      )
                                                    }
                                                  />
                                                </div>
                                              )
                                            )}
                                          {/* <OpenImageDialog
                                            open={open}
                                            setOpen={setOpen}
                                            selectedImage={selectedImage}
                                          /> */}
                                        </div>
                                      </FormGroup>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                        <Row>
                          <Col>
                            <Button
                              className="fontstylerentr textcolorblue"
                              onClick={addResidentialUnits}
                              style={{
                                display: selectedPropType.is_multiunit
                                  ? "block"
                                  : "none",
                                boxShadow: "0px 4px 4px 0px #00000040",
                                backgroundColor: "transparent",
                                border: "0.5px solid #152B51",
                              }}
                            >
                              Add another unit
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </div>
                  </CardBody>
                  {/* 6002 end  */}
                </Card>
              )}
              {!rental_id && propType === "Commercial" && (
                <Card
                  className="bg-white shadow mt-3 mb-3 mx-3 cardstyborderradius"
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #324567",
                  }}
                >
                  <CardBody>
                    <div className="">
                      <h6
                        className="heading-small labelfontstyle mb-4 titleecolor fontfamilysty"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Commercial Unit
                      </h6>
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                          htmlFor="input-address"
                          style={{
                            // marginBottom: "10px",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Enter Commercial Units
                        </label>
                        <br />
                        <br />
                        {rentalsFormik.values &&
                          rentalsFormik.values.commercial.map(
                            (commercialUnit, commercialIndex) => (
                              <div key={commercialIndex}>
                                <Row style={{ position: "relative" }}>
                                  <ClearIcon
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: "10px",
                                      display: selectedPropType.is_multiunit
                                        ? "block"
                                        : "none",
                                    }}
                                    onClick={() => {
                                      deleteCommercialUnit(commercialIndex);
                                    }}
                                  />

                                  <Col
                                    lg="3"
                                    style={
                                      selectedPropType.is_multiunit
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor={`input-unit-${commercialIndex}`}
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Unit *
                                      </label>
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                          width: "80%",
                                        }}
                                        required
                                        className="form-control-alternative fontstylerentmodal titleecolor"
                                        id={`input-unit-${commercialIndex}`}
                                        placeholder="Enter unit"
                                        type="text"
                                        name={`commercial[${commercialIndex}].rental_unit`}
                                        onBlur={rentalsFormik.handleBlur}
                                        onChange={(e) => {
                                          rentalsFormik.setFieldValue(
                                            `commercial[${commercialIndex}].rental_unit`,
                                            e.target.value
                                          );
                                        }}
                                        value={
                                          rentalsFormik.values.commercial[
                                            commercialIndex
                                          ].rental_unit
                                        }
                                      />
                                      {rentalsFormik.errors &&
                                      rentalsFormik.errors?.commercial &&
                                      rentalsFormik.errors.commercial[
                                        commercialIndex
                                      ]?.rental_unit &&
                                      rentalsFormik.touched &&
                                      rentalsFormik.touched?.commercial &&
                                      rentalsFormik.touched.commercial[
                                        commercialIndex
                                      ]?.rental_unit ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            rentalsFormik.errors.commercial[
                                              commercialIndex
                                            ]?.rental_unit
                                          }
                                        </div>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="3"
                                    style={
                                      selectedPropType.is_multiunit
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor="input-unitadd"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Unit Address
                                      </label>
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                          width: "80%",
                                        }}
                                        required
                                        className="form-control-alternative fontstylerentmodal titleecolor"
                                        id="input-unitadd"
                                        placeholder="Enter unit address"
                                        type="text"
                                        name={`commercial[${commercialIndex}].rental_unit_adress`}
                                        onBlur={rentalsFormik.handleBlur}
                                        onChange={(e) => {
                                          rentalsFormik.setFieldValue(
                                            `commercial[${commercialIndex}].rental_unit_adress`,
                                            e.target.value
                                          );
                                        }}
                                        value={
                                          rentalsFormik.values.commercial[
                                            commercialIndex
                                          ].rental_unit_adress
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3">
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor="input-unitadd"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        SQFT *
                                      </label>
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                          width: "80%",
                                        }}
                                        required
                                        className="form-control-alternative fontstylerentmodal titleecolor"
                                        id="input-unitadd"
                                        placeholder="Enter Sqft"
                                        type="text"
                                        name={`commercial[${commercialIndex}].rental_sqft`}
                                        onBlur={rentalsFormik.handleBlur}
                                        onChange={(e) => {
                                          rentalsFormik.setFieldValue(
                                            `commercial[${commercialIndex}].rental_sqft`,
                                            e.target.value
                                          );
                                        }}
                                        onInput={(e) => {
                                          const inputValue = e.target.value;
                                          const numericValue =
                                            inputValue.replace(/\D/g, "");
                                          e.target.value = numericValue;
                                        }}
                                        value={
                                          rentalsFormik.values.commercial[
                                            commercialIndex
                                          ].rental_sqft
                                        }
                                      />
                                      {rentalsFormik.errors &&
                                      rentalsFormik.errors?.commercial &&
                                      rentalsFormik.errors.commercial[
                                        commercialIndex
                                      ]?.rental_sqft &&
                                      rentalsFormik.touched &&
                                      rentalsFormik.touched?.commercial &&
                                      rentalsFormik.touched.commercial[
                                        commercialIndex
                                      ]?.rental_sqft ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            rentalsFormik.errors.commercial[
                                              commercialIndex
                                            ]?.rental_sqft
                                          }
                                        </div>
                                      ) : null}
                                    </FormGroup>
                                  </Col>

                                  {/* myyphotos */}
                                  <Col lg="5">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <FormGroup
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label
                                          className="form-control-label fontstylerentr mt-3 mb-2 titleecolor fontfamilysty"
                                          htmlFor="input-unitadd"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Photo
                                        </label>
                                        <span
                                          className="fontfamilysty"
                                          onClick={togglePhotoDialog}
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            color: "#26C22C",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            className="form-control-file d-none"
                                            accept="image/*"
                                            multiple
                                            id={`property_image${commercialIndex}`}
                                            name={`property_image${commercialIndex}`}
                                            onChange={(e) =>
                                              fileData(e, commercialIndex)
                                            }
                                          />
                                          <label
                                            htmlFor={`property_image${commercialIndex}`}
                                          >
                                            <b
                                              className="fontstylerentr"
                                              style={{ fontSize: "15px" }}
                                            >
                                              +
                                            </b>
                                            <b
                                              className="fontstylerentr"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              Add
                                            </b>
                                          </label>
                                        </span>
                                      </FormGroup>
                                      <FormGroup>
                                        <div
                                          className="mt-3 d-flex"
                                          style={{
                                            justifyContent: "center",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {selectedFiles[commercialIndex] &&
                                            selectedFiles[commercialIndex]
                                              .length > 0 &&
                                            selectedFiles[commercialIndex].map(
                                              (unitImg, index) => (
                                                <div
                                                  key={index}
                                                  style={{
                                                    position: "relative",
                                                    width: "100px",
                                                    height: "100px",
                                                    margin: "10px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      unitImg instanceof File
                                                        ? URL.createObjectURL(
                                                            unitImg
                                                          )
                                                        : unitImg
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                      maxHeight: "100%",
                                                      maxWidth: "100%",
                                                      borderRadius: "10px",
                                                    }}
                                                    onClick={() => {
                                                      // setSelectedImage(unitImg);
                                                      setOpen(true);
                                                    }}
                                                  />
                                                  <ClearIcon
                                                    style={{
                                                      cursor: "pointer",
                                                      alignSelf: "flex-start",
                                                      position: "absolute",
                                                      top: "-12px",
                                                      right: "-12px",
                                                    }}
                                                    onClick={() =>
                                                      clearSelectedPhoto(
                                                        index,
                                                        commercialIndex
                                                      )
                                                    }
                                                  />
                                                </div>
                                              )
                                            )}
                                          {/* <OpenImageDialog
                                            open={open}
                                            setOpen={setOpen}
                                            selectedImage={selectedImage}
                                          /> */}
                                        </div>
                                      </FormGroup>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                        <Row>
                          <Col style={{ color: "#152B51" }}>
                            <Button
                              className="fontstylerentr textcolorblue"
                              onClick={addCommercialUnit}
                              style={{
                                display: selectedPropType.is_multiunit
                                  ? "block"
                                  : "none",
                                boxShadow: "0px 4px 4px 0px #00000040",
                                backgroundColor: "transparent",
                                border: "0.5px solid #152B51",
                              }}
                            >
                              Add another unit
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </div>
                  </CardBody>
                </Card>
              )}

              <div className="ml-3 mt-3 mb-3">
                {loader ? (
                  <Button
                    type="submit"
                    className="btn background-colorsty bgtextwhite"
                    style={{
                      cursor: "not-allowed",
                    }}
                    disabled
                  >
                    Loading...
                  </Button>
                ) : rental_id ? (
                  <Button
                    type="submit"
                    className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={async (e) => {
                      e.preventDefault();
                      setLoader(true);
                      const res = await editProperty(
                        rentalsFormik.values,
                        rentalOwnerFormik.values,
                        accessType?.admin_id,
                        admin,
                        selectedPropType.property_id
                      );
                      setLoader(false);
                      if (res === false) {
                        setLoader(false);
                        setTimeout(() => {
                          if (isFromDetails) {
                            navigate(
                              `/${admin}/PropDetails/${location.state.id}`
                            );
                          } else {
                            navigate("/" + admin + "/propertiesTable");
                          }
                        }, 2000);
                      } else {
                        setLoader(false);
                      }
                    }}
                  >
                    Update Property
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (selectedRentalOwnerData.length !== 0) {
                        rentalsFormik.handleSubmit();
                      } else {
                        rentalsFormik.handleSubmit();
                        setDisplay(true);
                      }
                    }}
                    disabled={
                      !rentalsFormik.isValid || !rentalOwnerFormik.isValid
                    }
                  >
                    Create Property
                  </Button>
                )}
                <Button
                  // href="#pablo"
                  onClick={handleCloseButtonClick}
                  className="btn fontstylerentr backgroundwhitesty textcolorblue"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </Button>
                {(!rentalsFormik.isValid || !rentalOwnerFormik.isValid) && (
                  <div
                    className="fontstylerentr"
                    style={{ color: "red", marginTop: "10px" }}
                  >
                    Please fill in all fields correctly.
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Form>

        <Modal isOpen={isModalOpen} toggle={closeModal2}>
          <ModalHeader toggle={closeModal2} className="mt-2">
            <span
              className="labelfontstyle textcolorblue fontfamilysty"
              style={{
                fontSize: "18px",
                fontweight: "500",
              }}
            >
              Add Property Type
            </span>
          </ModalHeader>
          <ModalBody>
            <AddpropertyModal
              closeModal2={closeModal2}
              admin_id={accessType?.admin_id}
            />
          </ModalBody>
        </Modal>

        <Modal isOpen={isMOdalopen1} toggle={closeModal}>
          <div className="">
            <ModalHeader toggle={closeModal} className="mt-2">
              <span
                className="labelfontstyle textcolorblue fontfamilysty"
                style={{
                  fontSize: "18px",
                  fontweight: "500",
                }}
              >
                Add Staff Member
              </span>
            </ModalHeader>
          </div>
          <ModalBody>
            <StaffMemberModal
              closeModal={closeModal}
              admin_id={accessType?.admin_id}
            />
          </ModalBody>
        </Modal>
        <ToastContainer />
      </Container>
    </>
  );
};

export default Rentals;
