import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Table,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import "jspdf-autotable";
import "jspdf-autotable";
import { useCookies } from "react-cookie";
import moment from "moment";
import axiosInstance from "axiosInstance";
import AccountDialog from "components/AccountDialog";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  InputAdornment,
  DialogActions,
  TextField,
  FormLabel,
} from "@mui/material";
import { verifyToken } from "components/Functions/Functions";

const Staffaddcharge = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { lease_id, admin, charge_id } = useParams();
  const navigate = useNavigate();
  const location  = useLocation();

  const [accessType, setAccessType] = useState(null);

  // const [cookies] = useCookies(["token"]);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/staffmember/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  const [cookie, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [loader, setLoader] = useState(false);

  const generalledgerFormik = useFormik({
    initialValues: {
      charge_id: "",
      tenant_id: "",
      date: "",
      total_amount: "",
      charges_memo: "",
      charges: [
        {
          entry_id: "",
          account: "",
          amount: "",
          charge_type: "",
        },
      ],
      charges_attachment: [],
    },
    validationSchema: yup.object({
      tenant_id: yup.string().required("Required"),
      date: yup.string().required("Required"),
      total_amount: yup.string().required("Required"),
      charges: yup.array().of(
        yup.object().shape({
          account: yup.string().required("Required"),
          amount: yup.number().required("Required"),
        })
      ),
    }),
    onSubmit: (values) => {
      if (Number(generalledgerFormik.values.total_amount) === Number(total)) {
        handleSubmit(values);
      }
    },
  });

  const [recdropdownOpen, setrecDropdownOpen] = useState(false);
  const [selectedRec, setSelectedRec] = useState("Select Resident");
  const [tenantId, setTenantId] = useState("");
  const [selectedTenant, setSelectedTenant] = useState({});
  const [tenantData, setTenantData] = useState([]);

  const toggle2 = () => setrecDropdownOpen((prevState) => !prevState);

  const handleRecieverSelection = (tenant) => {
    // setProperty(property);
    setSelectedTenant(tenant);
    setSelectedRec(`${tenant.tenant_firstName} ${tenant.tenant_lastName}`);
    setTenantId(tenant.tenant_id);   
    generalledgerFormik.setValues({tenant_id: tenant.tenant_id});
  };

  const editCharge = async (values) => {
    setLoader(true);
    const fileUrl = [];
    if (file) {
      try {
        const uploadPromises = file?.map(async (fileItem, i) => {
          if (fileItem.upload_file instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem.upload_file);

              const res = await axios.post(`${imageUrl}/images/upload`, form);
              if (
                res &&
                res.data &&
                res.data.files &&
                res.data.files.length > 0
              ) {
                fileItem.upload_file = res.data.files[0].filename;
                fileUrl.push(res.data.files[0].filename);
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            fileUrl.push(fileItem.upload_file);
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    const object = {
      charge_id: charge_id,
      admin_id: accessType?.admin_id,
      tenant_id: tenantId,
      lease_id: lease_id,

      entry: values.charges?.map((item) => {
        const data = {
          entry_id: item.entry_id,
          account: item.account,
          amount: Number(item.amount),
          memo: values?.charges_memo || "charge",
          date: values?.date,
          charge_type: item.charge_type,
        };
        return data;
      }),

      total_amount: total,
      uploaded_file: fileUrl,
    };

    try {
      const res = await axiosInstance.put(
        `/charge/charge/${charge_id}`,
        object
      );
      if (res.data.statusCode === 200) {
        toast.success("Charge Updated Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate(`/staff/staffrentrolldetail/${lease_id}`, {
            state: { tab: "Financial" },
          });
        }, 2000);
      } else {
        toast.warning(res.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error: ", error.message);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 1000,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoader(true);
    const fileUrl = [];
    if (file) {
      try {
        const uploadPromises = file?.map(async (fileItem, i) => {
          if (fileItem.upload_file instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem.upload_file);

              const res = await axios.post(`${imageUrl}/images/upload`, form);
              if (
                res &&
                res.data &&
                res.data.files &&
                res.data.files.length > 0
              ) {
                fileItem.upload_file = res.data.files[0].filename;
                fileUrl.push(res.data.files[0].filename);
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            fileUrl.push(fileItem.upload_file);
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    const object = {
      admin_id: accessType?.admin_id,
      tenant_id: tenantId,
      lease_id: lease_id,
      entry: values.charges?.map((item) => {
        const data = {
          account: item.account,
          amount: Number(item.amount),
          due_amount: Number(item.amount),
          memo: values?.charges_memo || "charge",
          date: values?.date,
          charge_type: item.charge_type,
          is_repeatable: false,
        };
        return data;
      }),
      total_amount: total,
      is_leaseAdded: false,
      uploaded_file: fileUrl,
    };

    try {
      const res = await axiosInstance.post(`/charge/charge`, object);
      if (res.data.statusCode === 200) {
        toast.success("Charge Added Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate(`/staff/staffrentrolldetail/${lease_id}`, {
            state: { tab: "Financial" },
          });
        }, 2000);
      } else {
        toast.warning(res.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setLoader(false);
    }
  };

  const [selectChargeDropDown, setSelectChargetDropDown] = useState(false);

  const toggles1 = () => setSelectChargetDropDown(!selectChargeDropDown);

  const [accountTypeName, setAccountTypeName] = useState("");

  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] =
    useState(false);

  const toggleAddBankDialog = () => {
    setAddBankAccountDialogOpen((prevState) => !prevState);
  };

  const AddNewAccountName = async (accountName) => {
    toggleAddBankDialog();
    setAccountTypeName(accountName);
  };
  const [LiabilityAccounts, setLiabilityAccounts] = useState([]);
  const [recAccounts, setRecAccounts] = useState([]);
  const [oneTimeAccounts, setoneTimeAccounts] = useState([]);

  const fetchAccounts = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/accounts/accounts/${accessType?.admin_id}`
        );
        if (res.data.statusCode === 200) {
          const filteredData1 = res.data.data.filter(
            (item) => item.charge_type === "Liability Account"
          );
          const filteredData2 = res.data.data.filter(
            (item) => item.charge_type === "Recurring Charge"
          );
          const filteredData3 = res.data.data.filter(
            (item) => item.charge_type === "One Time Charge"
          );
          setLiabilityAccounts(filteredData1);
          setRecAccounts(filteredData2);
          setoneTimeAccounts(filteredData3);
        } else if (res.data.statusCode === 201) {
          setRecAccounts();
          setoneTimeAccounts();
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchTenant = async () => {
    if (lease_id) {
      try {
        const res = await axiosInstance.get(`/leases/lease_tenant/${lease_id}`);
        if (res.data.statusCode === 200) {
          setTenantData(res.data.data.tenants);
        }
      } catch (error) {
        console.error("Error: ", error.message);
      }
    }
  };

  const fetchChargeData = async () => {
    if (charge_id) {
      try {
        const res = await axiosInstance.get(`/charge/charge/${charge_id}`);
        if (res.data.statusCode === 200) {
          const data = res.data.data;
          setFile(data?.uploaded_file);
          setSelectedRec(
            data.tenantData
              ? `${data?.tenantData?.tenant_firstName} ${data?.tenantData?.tenant_lastName}`
              : "Select Resident"
          );
          setTenantId(data.tenant_id);
          generalledgerFormik.setValues({
            charge_id: charge_id,
            tenant_id: data.tenant_id,
            date: data.entry[0].date,
            total_amount: data?.total_amount,
            charges_memo: data.entry[0].memo,
            charges: data.entry.map((item) => {
              const items = {
                entry_id: item.entry_id,
                account: item.account,
                amount: item.amount,
                charge_type: item.charge_type,
              };
              return items;
            }),
            charges_attachment: data?.uploaded_file,
          });
        }
      } catch (error) {
        console.error("Error: ", error.message);
      }
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [accessType?.admin_id]);

  useEffect(() => {
    fetchTenant();
  }, [lease_id]);

  useEffect(() => {
    fetchChargeData();
  }, [charge_id]);

  const toggleDropdown = (index) => {
    const updatedCharges = [...generalledgerFormik.values.charges];
    updatedCharges[index].dropdownOpen = !updatedCharges[index].dropdownOpen;
    generalledgerFormik.setValues({
      ...generalledgerFormik.values,
      charges: updatedCharges,
    });
  };

  const handleAccountSelection = (value, index, chargeType) => {
    const updatedCharges = [...generalledgerFormik.values.charges];

    if (updatedCharges[index]) {
      updatedCharges[index].account = value;
      updatedCharges[index].charge_type = chargeType;
      generalledgerFormik.setValues({
        ...generalledgerFormik.values,
        charges: updatedCharges,
      });
    }
  };

  const [total, setTotal] = useState(0);
  const handleTotal = () => {
    let sum = 0;
    generalledgerFormik.values.charges?.forEach((element) => {
      sum += Number(element.amount);
    });
    setTotal(sum);
  };

  useEffect(() => {
    handleTotal();
  }, [generalledgerFormik.values.charges]);

  const handleAddRow = () => {
    const newCharges = {
      account: "",
      amount: "",
      total_amount: "",
    };
    generalledgerFormik.setValues({
      ...generalledgerFormik.values,
      charges: [...generalledgerFormik.values.charges, newCharges],
    });
  };

  const handleRemoveRow = (index) => {
    const updatedCharges = [...generalledgerFormik.values.charges];
    updatedCharges.splice(index, 1);
    generalledgerFormik.setValues({
      ...generalledgerFormik.values,
      charges: updatedCharges,
    });
  };

  const [file, setFile] = useState([]);
  const fileData = (files) => {
    const filesArray = [...files];

    if (file.length <= 10 && file.length === 0) {
      const finalArray = [];
      for (let i = 0; i < filesArray.length; i++) {
        const object = {
          upload_file: filesArray[i],
          upload_date: moment().format("YYYY-MM-DD"),
          upload_time: moment().format("HH:mm:ss"),
          upload_by: accessType?.first_name + " " + accessType?.last_name,
          file_name: filesArray[i].name,
        };
        finalArray.push(object);
      }
      setFile([...finalArray]);
    } else if (
      file.length >= 0 &&
      file.length <= 10 &&
      filesArray.length + file.length > 10
    ) {
      setFile([...file]);
    } else {
      const finalArray = [];

      for (let i = 0; i < filesArray.length; i++) {
        const object = {
          upload_file: filesArray[i],
          upload_date: moment().format("YYYY-MM-DD"),
          upload_time: moment().format("HH:mm:ss"),
          upload_by: accessType?.first_name + " " + accessType?.last_name,
          file_name: filesArray[i].name,
        };
        finalArray.push(object);
      }
      setFile([...file, ...finalArray]);
    }
  };

  const deleteFile = (index) => {
    const newFile = [...file];
    newFile.splice(index, 1);
    setFile(newFile);
    generalledgerFormik.setFieldValue("charges_attachment", newFile);
  };

  const handleOpenFile = (item) => {
    if (typeof item !== "string") {
      const url = URL.createObjectURL(item?.upload_file);
      window.open(url, "_blank");
    } else {
      window.open(`${imageGetUrl}/${item}`, "_blank");
    }
  };

  const handleCloseButtonClick = () => {
    navigate(`/staff/staffrentrolldetail/${lease_id}`, {
      state: { tab: "Financial" },
    });
  };

  const [openRecurringDialog, setOpenRecurringDialog] = useState(false);
  const handleClose = () => {
    setOpenRecurringDialog(false);
  };

  const handleClickOpenRecurring = () => {
    setOpenRecurringDialog(true);
  };

  return (
    <>
      {/* <ChargeHeader />
      <style>
        {`
            .custom-date-picker {
            background-color: white;
            }
        `}
      </style> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row className="mb-4">
          <Col xs="12" lg="12" sm="12">
            {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Property Type</h1>
            </FormGroup> */}
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
             <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                {charge_id ? "Edit Charge" : "Add Charge"}
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="mx-2 mb-3"
              style={{
                borderRadius: "20px",
                border: "1px solid #324567",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
              onSubmit={generalledgerFormik.handleSubmit}
            >
              {/* <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {charge_id ? "Edit Charge" : "New Charge"}
                    </h3>
                  </Col>
                </Row>
              </CardHeader> */}
              <CardBody>
                <Form>
                  {/* <Row className="mb-4">
                  <Col xs="12" sm="12" md="12" lg="12">
                      <h3 className="mb-0 labelfontstyle" style={{
                        color: "#152B51",
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "22px",
                        // padding:"6px 7px 1px 21px",
                      }}>
                        {" "}
                        Charge for{" "}
                        <span className="labelfontstyle" style={{
                          color: "#152B51",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          fontSize: "22px",
                          // padding:"6px 7px 1px 21px",
                        }}>
                          {tenantData?.tenant_firstName}{" "}
                          {tenantData?.tenant_lastName}
                        </span>
                      </h3>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col lg="2">
                      <label
                        className="form-control-label"
                        htmlFor="input-unitadd"
                      >
                        Charge for:{" "}
                        <span>
                          {tenantData?.tenant_firstName}{" "}
                          {tenantData?.tenant_lastName}
                        </span>
                      </label>
                    </Col>
                  </Row> */}
                  {/* 6002 changing the design of the page all inputs  */}
                  <Row className="mt-2">
                    <Col lg="3" md="3" sm="6" xs="8">
                      <FormGroup>
                      <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Received From *
                        </label>
                        <br />
                        <Dropdown
                          className="fontstylerentmodal d-flex"
                          isOpen={recdropdownOpen}
                          toggle={toggle2}
                          // disabled={charge_id}
                        >
                          <DropdownToggle
                            className="fontstylerentmodal titleecolor"
                            caret
                            style={{
                              width: "100%",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                              border: "1px solid #ced4da",
                              backgroundColor: "transparent",
                            }}
                          >
                            {selectedRec ? selectedRec : "Select Resident"}
                          </DropdownToggle>
                          <DropdownMenu
                            className="fontstylerentmodal"
                            style={{
                              width: "100%",
                              maxHeight: "200px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            {tenantData.map((property, index) => (
                              <DropdownItem
                                className="fontstylerentmodal"
                                key={index}
                                onClick={() =>
                                  handleRecieverSelection(property)
                                }
                              >
                                {`${property.tenant_firstName} ${property.tenant_lastName}`}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </FormGroup>
                    </Col>
                    {/* </Row>
                  <Row> */}
                    <Col lg="3" xl="3" md="5" sm="5" xs="8">
                      <FormGroup>
                      <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Date
                        </label>
                        <Input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          className="form-control-alternative fontstylerentmodal titleecolor"
                          id="input-unitadd"
                          placeholder="3000"
                          type="date"
                          name="date"
                          onBlur={generalledgerFormik.handleBlur}
                          onChange={generalledgerFormik.handleChange}
                          value={generalledgerFormik.values.date}
                        />
                        {generalledgerFormik.touched.date &&
                        generalledgerFormik.errors.date ? (
                          <div style={{ color: "red" }}>
                            {generalledgerFormik.errors.date}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {/* </Row> */}
                    {/* <Row> */}
                    <Col lg="3" xl="3" md="5" sm="5" xs="8">
                      <FormGroup>
                      <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Amount
                        </label>
                        <Input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                            width: "100%",
                          }}
                          className="form-control-alternative fontstylerentmodal titleecolor"
                          id="input-unitadd"
                          placeholder="$0.00"
                          type="number"
                          name="total_amount"
                          onBlur={generalledgerFormik.handleBlur}
                          onChange={generalledgerFormik.handleChange}
                          value={generalledgerFormik.values.total_amount}
                        />
                        {generalledgerFormik.touched.total_amount &&
                        generalledgerFormik.errors.total_amount ? (
                          <div style={{ color: "red" }}>
                            {generalledgerFormik.errors.total_amount}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {/* </Row>
                  <Row> */}
                    <Col lg="3" xl="3" md="5" sm="5" xs="8">
                      <FormGroup>
                      <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Memo
                        </label>
                        <Input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                            width: "80%",
                          }}
                          className="form-control-alternative fontstylerentmodal"
                          id="input-unitadd"
                          placeholder="if left blank, will show 'charge'"
                          type="text"
                          name="charges_memo"
                          onBlur={generalledgerFormik.handleBlur}
                          onChange={generalledgerFormik.handleChange}
                          value={generalledgerFormik.values.charges_memo}
                        />
                        {generalledgerFormik.touched.charges_memo &&
                        generalledgerFormik.errors.charges_memo ? (
                          <div style={{ color: "red" }}>
                            {generalledgerFormik.errors.charges_memo}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* 6002 end */}

                  <Row className="mt-3">
                    <Col lg="12">
                      <FormGroup>
                      <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Apply Payment to Balances
                        </label>
                        <div className="table-responsive">
                          <Table
                            className="table table-bordered"
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid #ddd",
                              overflow: "hidden",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                            }}
                          >
                            <thead>
                            <tr
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  border: "1px solid #152B51",
                                }}
                              >
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Account
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {generalledgerFormik.values.charges?.map(
                                  (charges, index) => (
                                    <tr
                                      key={index}
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <td
                                        style={{
                                          border: "1px solid #152B51",
                                        }}
                                      >
                                        <Dropdown
                                          className="fontstylerentmodal"
                                          isOpen={charges.dropdownOpen}
                                          toggle={() => toggleDropdown(index)}
                                        >
                                          <DropdownToggle
                                            className="fontstylerentmodal titleecolor"
                                            caret
                                            style={{
                                              width: "100%",
                                              boxShadow:
                                                " 0px 4px 4px 0px #00000040",
                                              border: "1px solid #ced4da",
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            {charges.account
                                              ? charges.account
                                              : "Select"}
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className="fontstylerentmodal"
                                            style={{
                                              zIndex: 999,
                                              maxHeight: "200px",
                                              overflowY: "auto",
                                            }}
                                          >
                                            <DropdownItem
                                              className="dropdownfontsyle"
                                              onClick={handleClickOpenRecurring}
                                              style={{
                                                backgroundColor: "#152B51",
                                                color: "#fff",
                                              }}
                                            >
                                              Add new account
                                            </DropdownItem>

                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              header
                                              style={{
                                                color: "#152B51",
                                                fontFamily: "Poppins",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Liability Account
                                            </DropdownItem>

                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              onClick={() => {
                                                handleAccountSelection(
                                                  "Rent Income",
                                                  index,
                                                  "Rent"
                                                );
                                              }}
                                            >
                                              Rent Income
                                            </DropdownItem>
                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              onClick={() => {
                                                handleAccountSelection(
                                                  "Late Fee Income",
                                                  index,
                                                  "Late Fee Income"
                                                );
                                              }}
                                            >
                                              Late Fee Income
                                            </DropdownItem>
                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              onClick={() => {
                                                handleAccountSelection(
                                                  "Pre-payments",
                                                  index,
                                                  "Pre-payments"
                                                );
                                              }}
                                            >
                                              Pre-payments
                                            </DropdownItem>
                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              onClick={() => {
                                                handleAccountSelection(
                                                  "Security Deposit Liability",
                                                  index,
                                                  "Security Deposit"
                                                );
                                              }}
                                            >
                                              Security Deposit
                                            </DropdownItem>

                                            {LiabilityAccounts?.length > 0 ? (
                                              <>
                                                {LiabilityAccounts?.map(
                                                  (item) => (
                                                    <DropdownItem
                                                      key={item._id}
                                                      onClick={() => {
                                                        handleAccountSelection(
                                                          item.account,
                                                          index,
                                                          "Liability Account"
                                                        );
                                                      }}
                                                    >
                                                      {item.account}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}

                                            {recAccounts?.length > 0 ? (
                                              <>
                                                <DropdownItem
                                                  className="fontstylerentmodal"
                                                  header
                                                  style={{
                                                    color: "#152B51",
                                                    fontFamily: "Poppins",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  Recuring Charges
                                                </DropdownItem>
                                                {recAccounts?.map((item) => (
                                                  <DropdownItem
                                                    key={item._id}
                                                    onClick={() => {
                                                      handleAccountSelection(
                                                        item.account,
                                                        index,
                                                        "Recurring Charge"
                                                      );
                                                    }}
                                                  >
                                                    {item.account}
                                                  </DropdownItem>
                                                ))}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {oneTimeAccounts?.length > 0 ? (
                                              <>
                                                <DropdownItem
                                                  className="fontstylerentmodal"
                                                  header
                                                  style={{
                                                    color: "#152B51",
                                                    fontFamily: "Poppins",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  One Time Charges
                                                </DropdownItem>
                                                {oneTimeAccounts?.map(
                                                  (item) => (
                                                    <DropdownItem
                                                      key={item._id}
                                                      onClick={() => {
                                                        handleAccountSelection(
                                                          item.account,
                                                          index,
                                                          "One Time Charge"
                                                        );
                                                      }}
                                                    >
                                                      {item.account}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </DropdownMenu>
                                        </Dropdown>
                                      </td>
                                      <Dialog
                                        open={openRecurringDialog}
                                        onClose={handleClose}
                                      >
                                        <DialogTitle
                                          className="labelfontstyle textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Select Account Type
                                        </DialogTitle>

                                        <div
                                          className="pl-lg-3 modalpaddingrent"
                                          style={{
                                            height: "190px",
                                            width: "380px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginLeft: "4%",
                                              marginRight: "4%",
                                            }}
                                          >
                                            <br />
                                            <div
                                              className="formInput"
                                              // style={{ margin: "30px 10px" }}
                                            >
                                              <label
                                                className="form-control-label titleecolor fontstylerentr fontfamilysty"
                                                htmlFor="input-address"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Select Account Type
                                              </label>
                                              <br />
                                              <Dropdown
                                                className="dropdownfontsyle"
                                                isOpen={selectChargeDropDown}
                                                toggle={toggles1}
                                              >
                                                <DropdownToggle
                                                  className="dropdownfontsyle titleecolor"
                                                  caret
                                                  style={{
                                                    width: "100%",
                                                    boxShadow:
                                                      " 0px 4px 4px 0px #00000040",
                                                    border: "1px solid #ced4da",
                                                    backgroundColor:
                                                      "transparent",
                                                  }}
                                                >
                                                  Select
                                                </DropdownToggle>
                                                <DropdownMenu
                                                  className="dropdownfontsyle"
                                                  style={{ width: "100%" }}
                                                  name="rent_cycle"
                                                  // onBlur={accountFormik.handleBlur}
                                                  // onChange={accountFormik.handleChange}
                                                  // value={accountFormik.values.charge_type}
                                                >
                                                  <DropdownItem
                                                    className="dropdownfontsyle"
                                                    onClick={() => {
                                                      AddNewAccountName(
                                                        "Liability Account"
                                                      );
                                                      handleClose();
                                                    }}
                                                  >
                                                    Liability Account
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    className="dropdownfontsyle"
                                                    onClick={() => {
                                                      AddNewAccountName(
                                                        "Recurring Charge"
                                                      );
                                                      handleClose();
                                                    }}
                                                  >
                                                    Recurring Charge
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    className="dropdownfontsyle"
                                                    onClick={() => {
                                                      AddNewAccountName(
                                                        "One Time Charge"
                                                      );
                                                      handleClose();
                                                    }}
                                                  >
                                                    One Time Charge
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-end">
                                            <Button
                                                className="fontstylerentr mt-4 background-colorsty bgtextwhite fontfamilysty"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "14px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={handleClose}
                                              >
                                                Cancel
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </Dialog>
                                      <AccountDialog
                                        addBankAccountDialogOpen={
                                          addBankAccountDialogOpen
                                        }
                                        setAddBankAccountDialogOpen={
                                          setAddBankAccountDialogOpen
                                        }
                                        accountTypeName={accountTypeName}
                                        adminId={accessType?.admin_id}
                                        fetchAccounts={fetchAccounts}
                                      />
                                      {/* <td>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-unitadd"
                                          placeholder="$0.00"
                                          type="number"
                                          name={`charges[${index}].balance`}
                                          onBlur={
                                            generalledgerFormik.handleBlur
                                          }
                                          onChange={
                                            generalledgerFormik.handleChange
                                          }
                                          value={charges.balance}
                                        />
                                        {generalledgerFormik.touched.charges &&
                                        generalledgerFormik.touched.charges[
                                          index
                                        ] &&
                                        generalledgerFormik.errors.charges &&
                                        generalledgerFormik.errors.charges[
                                          index
                                        ] &&
                                        generalledgerFormik.errors.charges[
                                          index
                                        ].balance ? (
                                          <div style={{ color: "red" }}>
                                            {
                                              generalledgerFormik.errors
                                                .charges[index].balance
                                            }
                                          </div>
                                        ) : null}
                                      </td> */}
                                      <td
                                        style={{
                                          border: "1px solid #152B51",
                                        }}
                                      >
                                        <Input
                                          className="form-control-alternative fontstylerentmodal"
                                          id="input-unitadd"
                                          placeholder="$0.00"
                                          style={{ width: "80%" }}
                                          type="text"
                                          name={`charges[${index}].amount`}
                                          onBlur={
                                            generalledgerFormik.handleBlur
                                          }
                                          onChange={(e) => {
                                            generalledgerFormik.handleChange(e);
                                            // handleTotal();
                                          }}
                                          // onInput={(e) => {
                                          //   const inputValue = e.target.value;
                                          //   const numericValue =
                                          //     inputValue.replace(/\D/g, "");
                                          //   e.target.value = numericValue;
                                          // }}
                                          value={charges.amount}
                                        />
                                        {generalledgerFormik.touched.charges &&
                                        generalledgerFormik.touched.charges[
                                          index
                                        ] &&
                                        generalledgerFormik.errors.charges &&
                                        generalledgerFormik.errors.charges[
                                          index
                                        ] &&
                                        generalledgerFormik.errors.charges[
                                          index
                                        ].amount ? (
                                          <div style={{ color: "red" }}>
                                            {
                                              generalledgerFormik.errors
                                                .charges[index].amount
                                            }
                                          </div>
                                        ) : null}
                                      </td>
                                      {!charge_id && (
                                        <td
                                          style={{
                                            border: "1px solid #152B51",
                                          }}
                                        >
                                          <ClearIcon
                                            className="addpaymenticonremove"
                                            type="button"
                                            style={{
                                              cursor: "pointer",
                                              padding: 0,
                                            }}
                                            onClick={() =>
                                              handleRemoveRow(index)
                                            }
                                          >
                                            Remove
                                          </ClearIcon>
                                        </td>
                                      )}
                                    </tr>
                                  )
                                )}
                                <tr
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                >
                                    <th
                                    className="fontstylerentmodal textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    Total
                                  </th>
                                  <th
                                    className="fontstylerentmodal textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    {total.toFixed(2)}
                                  </th>
                                </tr>
                                {Number(
                                  generalledgerFormik.values.total_amount || 0
                                ) !== Number(total) ? (
                                  <tr
                                    style={{
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    <th
                                      className="fontstylerentmodal textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        border: "1px solid #152B51",
                                      }}
                                      colSpan={2}
                                    >
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                      >
                                        The charge's amount must match the total
                                        applied to balance. The difference is $
                                        {Math.abs(
                                          generalledgerFormik.values
                                            .total_amount - total
                                        ).toFixed(2)}
                                      </span>
                                    </th>
                                  </tr>
                                ) : null}
                              </>
                            </tbody>
                            {!charge_id && (
                              <tfoot>
                                <tr>
                                  <td
                                    colSpan="4"
                                    style={{
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    <Button
                                      type="button"
                                      className="btn fontstylerentmodal backgroundwhitesty textcolorblue fontfamilysty"
                                      onClick={handleAddRow}
                                      style={{
                                        border: "0.5px solid #152B51",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Add Row
                                    </Button>
                                  </td>
                                </tr>
                              </tfoot>
                            )}
                          </Table>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="4">
                      <FormGroup>
                      <label
                          className="form-control-label fontstylerentr fontfamilysty"
                          htmlFor="input-address"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Upload Files (Maximum of 10)
                        </label>

                        <div className="d-flex">
                          <div className="file-upload-wrapper">
                            <input
                              type="file"
                              className="form-control-file d-none"
                              accept="file/*"
                              name="upload_file"
                              id="upload_file"
                              multiple
                              onChange={(e) => fileData(e.target.files)}
                            />
                            <label
                              htmlFor="upload_file"
                              className="btn fontstylerentmodal background-colorsty bgtextwhite fontfamilysty"
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                              }}
                            >
                              Choose Files...
                            </label>
                          </div>

                          <div className="d-flex addingfileaddpay ">
                            {file?.length > 0 &&
                              file?.map((file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    marginLeft: "50px",
                                  }}
                                >
                                  <p
                                    className="fontstylerentr"
                                    onClick={() =>
                                      handleOpenFile(file ? file : file)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {file && typeof file !== "string"
                                      ? file?.file_name?.substr(0, 5)
                                      : file.substr(0, 5)}
                                    {file && typeof file !== "string"
                                      ? file?.file_name?.length > 5
                                        ? "..."
                                        : null
                                      : file?.length > 5
                                      ? "..."
                                      : null}
                                  </p>
                                  <CloseIcon
                                    className="cleariconaddpayment"
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      left: "64px",
                                      top: "-2px",
                                    }}
                                    onClick={() => deleteFile(index)}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="5">
                      <FormGroup>
                        {loader ? (
                           <button
                           type="submit"
                           className="btn fontstylerentr background-colorsty "
                           style={{
                             cursor: "not-allowed",
                           }}
                           disabled
                         >
                            Loading...
                          </button>
                        ) : charge_id ? (
                          <button
                          type="submit"
                          className="btn fontstylerentr background-colorsty bgtextwhite"
                          style={{
                            cursor: "pointer",
                          }}
                            onClick={(e) => {
                              e.preventDefault();
                              editCharge(generalledgerFormik.values);
                            }}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn fontstylerentr background-colorsty bgtextwhite"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              generalledgerFormik.handleSubmit();
                            }}
                          >
                            Add Charge
                          </button>
                        )}

                        <Button
                          // color="primary"
                           className="btn fontstylerentr cancelbtnaddpay backgroundwhitesty textcolorblue"
                          onClick={handleCloseButtonClick}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default Staffaddcharge;
