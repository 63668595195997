import React, { useState, useEffect } from "react";
import { Country, State, City } from "country-state-city";
import {
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  Input,
  FormGroup,
  Form,
} from "reactstrap";
import "./plan.css";
import axios from "axios";
import creditCardType from "credit-card-type";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { FormHelperText, InputAdornment, OutlinedInput } from "@mui/material";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

function Planpurches() {
  const [Continue, setContinue] = useState(false);
  const { admin } = useParams();
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [accessType, setAccessType] = useState(null);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // const [cookies] = useCookies(["token"]);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/admin/token_check`, {
  //         token: cookies.token,
  //       });
  //       setAccessType(response.data);
  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);
  const [cookie, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const location = useLocation();
  const receivedData = location.state?.plan || "No data received";

  useEffect(() => {
    // Get all countries
    const countriesData = Country.getAllCountries();

    // Get all states
    const statesData = countriesData.flatMap((country) => {
      return State.getStatesOfCountry(country.isoCode);
    });

    // Extract unique country names and subregions
    const uniqueCountries = countriesData.map((country) => country.name);
    const uniqueStates = Array.from(
      new Set(statesData.map((state) => state.name))
    );

    setCountries(uniqueCountries);
    setStates(uniqueStates);
  }, []);

  const [cardLogo, setCardLogo] = useState({});
  const setCardNumber = async (cur_val) => {
    try {
      const sanitizedValue = cur_val.replace(/\D/g, "");
      const cardType = creditCardType(sanitizedValue)[0];

      if (cardType && cardType.type) {
        const sanitizedCardType = cardType.type
          .replace(/[-\s]/g, "")
          .toLowerCase();
        const response = await axios.get(
          `https://logo.clearbit.com/${sanitizedCardType}.com`
        );
        if (response.status === 200) {
          setCardLogo(response.config.url);
        } else {
          setCardLogo("");
        }
        planPurchaseFormik.setFieldValue("card_type", cardType.type);
      } else {
        planPurchaseFormik.setFieldValue("card_type", " ");
        setCardLogo("");
      }
    } catch (error) {
      console.error("Error fetching name:", error);
    }
  };

  const planPurchaseFormik = useFormik({
    initialValues: {
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      card_type: "",
      card_number: "",
      expiration_month: "",
      expiration_year: "",
      cvv: "",
      cardholder_name: "",
    },
    validationSchema: Yup.object().shape({}),

    onSubmit: (values) => {
      setContinue(true);
      handleSubmit(values);
      planPurchaseFormik.resetForm();
    },
  });

  const handleSubmit = async (values) => {
    const addSubObject = {
      admin_id: accessType.admin_id,
      plan_id: receivedData?.plan_id,
      plan_amount: receivedData?.plan_price,
      purchase_date: moment().format("YYYY-MM-DD"),
      expiration_date: moment(endDate).format("YYYY-MM-DD"),
      plan_duration_months: receivedData?.billing_interval,
      status: "",
      address: values.street_address,
      city: values.city,
      state: values.state,
      postal_code: values.postal_code,
      country: values.country,
      card_type: values.card_type,
      card_number: values.card_number,
      cvv: values.cvv,
      cardholder_name: values.cardholder_name,
      is_active: true,
      day_of_month: receivedData.day_of_month,
      billing_interval: receivedData.billing_interval,
    };

    const nmiObject = {
      admin_id: accessType?.superadmin_id,
      adminId: accessType.admin_id,
      planId: receivedData?.plan_id,
      ccnumber: values.card_number,
      ccexp: values.expiration_month + "/" + values.expiration_year,
      first_name: accessType?.first_name,
      last_name: accessType?.last_name,
      address: values.street_address,
      email: accessType?.email,
      city: values.city,
      state: values.state,
      zip: values.postal_code,
    };
    await axiosInstance
      .post(`/nmipayment/custom-add-subscription`, nmiObject)
      .then(async (res) => {
        if (res.data.status === 200) {
          await axiosInstance
            .post(`/purchase/purchase`, {
              ...addSubObject,
              subscription_id: res.data.data.transactionid,
            })
            .then((resp) => {
              if (resp.data.statusCode === 200) {
                toast.success(resp.data.message, {
                  position: "top-center",
                  autoClose: 1000,
                });
                setTimeout(() => {
                  navigate(`/${accessType.company_name}/index`);
                  window.location.reload();
                }, 2000);
              } else {
                toast.error(resp.data.message, {
                  position: "top-center",
                });
              }
            })
            .catch((err) => {
              toast.error(err.data.message, {
                position: "top-center",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.data.error, {
          position: "top-center",
          autoClose: 1000,
        });
      });
  };

  const billingIntervalNumber = parseInt(receivedData.billing_interval);

  const currentDate = new Date();

  const endDate = new Date(currentDate);
  endDate.setMonth(currentDate.getMonth() + billingIntervalNumber);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const planDuration = `Plan - ${formatDate(currentDate)} to ${formatDate(
    endDate
  )}`;

  const calculateTotal = (data) => {
    if (data.is_annual_discount && data.annual_discount) {
      const discountAmount = (data.plan_price * data.annual_discount) / 100;
      return data.plan_price - discountAmount;
    }
    return data.plan_price;
  };

  const total = calculateTotal(receivedData);

  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: (i + 1).toString().padStart(2, "0"),
    label: (i + 1).toString().padStart(2, "0"),
  }));

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 12 }, (_, i) => ({
    value: currentYear + i,
    label: currentYear + i,
  }));
  const discountAmount =
    (receivedData.plan_price * receivedData.annual_discount) / 100;

  return (
    <>
      {/* <RentalHeader /> */}
      <Container
        fluid
        className="stylecontainer containerremovespace"
        id="home"
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className="mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Premium Plans
            </h2>
          </CardHeader>
        </Col>
        <Row className=" justify-content-center">
          <Col
            xs={12}
            md={12}
            lg={12}
            className="my-3 d-flex flex-column interactive-card"
            style={{ borderTopRightRadius: "30px" }}
          >
            <div className="">
              {/* <div
                className="homee-section card h-100 premium-cards "
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              > */}
              <div className=" card-body mb-5">
                <div className="pl-lg-2">
                  <b
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "22px",
                      fontWeight: "700",
                    }}
                  >
                    <h2 className="labelfontstyle">
                      1. Enter the company Address
                    </h2>
                  </b>
                  <p
                    className="fontstylerentr fontfamilysty"
                    style={{
                      color: "#8999B4",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Enter the company’s headquarters to ensure accurate tax
                    information.
                  </p>
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setContinue(true);
                    }}
                  >
                    <FormGroup>
                      <label
                        className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                        htmlFor="input-member"
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Street Address
                      </label>
                      <Input
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                          width: "45%",
                        }}
                        type="textarea"
                        className="mb-1 fontstylerentr cardwidthpurchaseplan"
                        placeholder="Enter street address"
                        // type="text"
                        required
                        onChange={planPurchaseFormik.handleChange}
                        value={planPurchaseFormik.values.street_address}
                        onBlur={planPurchaseFormik.handleBlur}
                        name="street_address"
                        id="street_address"
                      />
                    </FormGroup>
                    <div className="d-flex mt-3">
                      <FormGroup className="">
                        <label
                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                          htmlFor="input-member"
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          City
                        </label>

                        <Input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                            width: "80%",
                          }}
                          className="mb-1 fontstylerentr"
                          placeholder="Enter city..."
                          type="text"
                          required
                          onChange={planPurchaseFormik.handleChange}
                          value={planPurchaseFormik.values.city}
                          onBlur={planPurchaseFormik.handleBlur}
                          name="city"
                          id="city"
                        />
                      </FormGroup>
                      <FormGroup className="">
                        <label
                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                          htmlFor="input-member"
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          State
                        </label>

                        <Input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                            width: "78%",
                          }}
                          placeholder="Enter state..."
                          className="mb-1 fontstylerentr"
                          type="text"
                          required
                          onChange={planPurchaseFormik.handleChange}
                          value={planPurchaseFormik.values.state}
                          onBlur={planPurchaseFormik.handleBlur}
                          name="state"
                          id="state"
                        />
                      </FormGroup>

                      <FormGroup className="">
                        <label
                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                          htmlFor="input-text"
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Postal Code
                        </label>

                        <Input
                          className="mb-1 fontstylerentr"
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                            width: "80%",
                          }}
                          type="text"
                          placeholder="Enter postal code..."
                          required
                          name="postal_code"
                          id="postal_code"
                          onChange={planPurchaseFormik.handleChange}
                          value={planPurchaseFormik.values.postal_code}
                          onBlur={planPurchaseFormik.handleBlur}
                          onInput={(e) => {
                            const inputValue = e.target.value;
                            const numericValue = inputValue.replace(/\D/g, "");
                            e.target.value = numericValue;
                          }}
                        />
                      </FormGroup>
                    </div>
                    <FormGroup className="mt-3">
                      <label
                        className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                        htmlFor="input-member"
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        COUNTRY
                      </label>
                      <Input
                        className="mb-1 add fontstylerentr cardwidthpurchaseplan"
                        style={{
                          width: "45%",
                          backgroundColor: "#f8f9fa",
                          borderColor: "#ced4da",
                          borderRadius: "0.375rem",
                          color: "#495057",
                          padding: "0.375rem 0.75rem",
                          fontSize: "1rem",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        type="select"
                        required
                        onChange={planPurchaseFormik.handleChange}
                        value={planPurchaseFormik.values.country}
                        onBlur={planPurchaseFormik.handleBlur}
                        name="country"
                        id="country"
                      >
                        <option value="">Select country here...</option>
                        {countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <Button
                      size="small"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                      className="mb-5 mt-3 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                    >
                      Continue
                    </Button>
                  </Form>
                  {Continue === true ? (
                    <>
                      <b
                        className="textcolorblue fontfamilysty"
                        style={{
                          fontSize: "22px",
                          fontWeight: "700",
                        }}
                      >
                        <h2 className="labelfontstyle">
                          2. Review subscription & enter payment information
                        </h2>
                      </b>

                      <div
                        className="card mt-3 cardplanpurchasestyle"
                        style={{
                          // boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                          width: "50%",
                          border: "0.5px solid #32456780",
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                        }}
                      >
                        <div className=" card-body " style={{ color: "black" }}>
                          <b
                            className="fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Subtotal
                            <hr />
                          </b>
                          <div className="d-flex justify-content-between">
                            <p
                              className="fontstylerentr fontfamilysty"
                              style={{
                                color: "#7B8AA4",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              Plan Price:
                            </p>
                            <p
                              className="fontstylerentr fontfamilysty"
                              style={{
                                color: "#7B8AA4",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              {receivedData.plan_price}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            {receivedData.is_annual_discount === true ? (
                              <p
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  color: "#7B8AA4",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                {receivedData.billing_interval} - Annual
                                Subscription{" "}
                                <span
                                  className="fontstylerentr fontfamilysty"
                                  style={{
                                    color: "#7B8AA4",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {receivedData.annual_discount}% Discount
                                </span>
                              </p>
                            ) : (
                              <p
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  color: "#7B8AA4",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                {receivedData.billing_interval} Subscription
                              </p>
                            )}
                            {receivedData.is_annual_discount === true ? (
                              <p
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  color: "#7B8AA4",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                <p className="fontstylerentr">
                                  {discountAmount}
                                </p>
                              </p>
                            ) : (
                              <p></p>
                            )}
                          </div>
                          <p
                            style={{
                              color: "#7B8AA4",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                            className="mb-5 fontstylerentr fontfamilysty"
                          >
                            {planDuration}
                          </p>
                          <div className="d-flex justify-content-between">
                            <p
                              className="fontstylerentr fontfamilysty"
                              style={{
                                color: "#7B8AA4",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              TOTAL:
                            </p>
                            <p
                              className="fontstylerentr fontfamilysty"
                              style={{
                                color: "#7B8AA4",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              {total}
                            </p>
                          </div>
                        </div>
                      </div>
                      <Form
                        onSubmit={(event) => {
                          event.preventDefault();
                          planPurchaseFormik.handleSubmit();
                        }}
                      >
                        <div
                          className="card cardplanpurchasestyle"
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            border: "0.5px solid #32456780",

                            width: "50%",
                            marginTop: "30px",
                          }}
                        >
                          <div className=" card-body" style={{}}>
                            <b
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontSize: "22px",
                                fontWeight: "700",
                              }}
                            >
                              Payment information
                              <hr />
                            </b>
                            {/* <div className="d-flex carddd">
                                <label
                                  className="form-control-label  mb-3"
                                  htmlFor="input-member"
                                >
                                  Card type
                                </label>
                                <Input
                                  className="mb-3"
                                  style={{ width: "50%", marginLeft: "77px" }}
                                  type="text"
                                  required
                                  onChange={planPurchaseFormik.handleChange}
                                  value={planPurchaseFormik.values.card_type}
                                  id="card_type"
                                  name="card_type"
                                  onBlur={planPurchaseFormik.handleBlur}
                                  disabled
                                />
                              </div> */}
                            <div className="d-flex carddd">
                              <label
                                className="form-control-label mb-3 fontstylerentr fontfamilysty"
                                htmlFor="input-member"
                                style={{
                                  color: "#7B8AA4",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                Card Number
                              </label>

                              <OutlinedInput
                                className="mb-3 fontstylerentr cardmlplanpurchase"
                                onChange={(e) => {
                                  const inputValue = e.target.value.slice(
                                    0,
                                    16
                                  );
                                  setCardNumber(inputValue);
                                  planPurchaseFormik.setFieldValue(
                                    "card_number",
                                    inputValue
                                  );
                                }}
                                style={{ width: "50%", marginLeft: "49px" }}
                                type="number"
                                required
                                value={planPurchaseFormik.values.card_number}
                                id="card_number"
                                name="card_number"
                                size="small"
                                onBlur={planPurchaseFormik.handleBlur}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {planPurchaseFormik.values.card_number &&
                                      cardLogo && (
                                        <img
                                          src={cardLogo}
                                          // height="20px"
                                          // width="35px"
                                          // style={{ borderRadius: "30px" }}
                                          alt="mastercard"
                                          style={{ height: "40px" }}
                                        />
                                      )}
                                  </InputAdornment>
                                }
                              />
                            </div>
                            <div className="d-flex carddd">
                              <label
                                className="form-control-label fontstylerentr fontfamilysty"
                                htmlFor="input-member"
                                style={{
                                  color: "#7B8AA4",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                Expiration Date
                              </label>
                              <div className="d-flex">
                                <Input
                                  className="mb-3 dateyear fontstylerentr"
                                  onChange={planPurchaseFormik.handleChange}
                                  name="expiration_month"
                                  id="expiration_month"
                                  value={
                                    planPurchaseFormik.values.expiration_month
                                  }
                                  onBlur={planPurchaseFormik.handleBlur}
                                  style={{
                                    width: "120%",
                                    marginLeft: "41px",
                                  }}
                                  type="select"
                                  required
                                >
                                  <option value="">Month</option>
                                  {monthOptions.map((month) => (
                                    <option
                                      key={month.value}
                                      value={month.value}
                                    >
                                      {month.label}
                                    </option>
                                  ))}
                                </Input>
                                <p
                                  style={{
                                    paddingLeft: "18px",
                                    paddingRight: "18px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  /
                                </p>
                                <Input
                                  className="mb-3 dateyear fontstylerentr"
                                  style={{ width: "100%" }}
                                  type="select"
                                  name="expiration_year"
                                  id="expiration_year"
                                  value={
                                    planPurchaseFormik.values.expiration_year
                                  }
                                  onBlur={planPurchaseFormik.handleBlur}
                                  onChange={planPurchaseFormik.handleChange}
                                  required
                                >
                                  <option value="">Year</option>
                                  {yearOptions.map((year) => (
                                    <option key={year.value} value={year.value}>
                                      {year.label}
                                    </option>
                                  ))}
                                </Input>
                              </div>
                            </div>
                            <div className="d-flex carddd">
                              <label
                                className="form-control-label fontstylerentr fontfamilysty"
                                htmlFor="input-member"
                                style={{
                                  color: "#7B8AA4",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                CVV{" "}
                              </label>
                              <Input
                                id="cvv"
                                name="cvv"
                                onChange={planPurchaseFormik.handleChange}
                                onBlur={planPurchaseFormik.handleBlur}
                                value={planPurchaseFormik.values.cvv}
                                maxLength={4}
                                onInput={(e) =>
                                  (e.target.value = e.target.value
                                    .replace(/[^0-9]/g, "")
                                    .replace(/(\..*)\./g, "$1"))
                                }
                                className="mb-3 fontstylerentr cardmlplanpurchase"
                                style={{ width: "50%", marginLeft: "129px" }}
                                type="text"
                                required
                              />
                            </div>
                            <div className="d-flex carddd">
                              <label
                                className="form-control-label fontstylerentr fontfamilysty"
                                htmlFor="input-member"
                                style={{
                                  color: "#7B8AA4",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                              >
                                Cardholder Name{" "}
                              </label>
                              <Input
                                className="mb-3 fontstylerentr cardmlplanpurchase"
                                style={{ width: "50%", marginLeft: "20px" }}
                                type="text"
                                required
                                id="cardholder_name"
                                name="cardholder_name"
                                onChange={planPurchaseFormik.handleChange}
                                onBlur={planPurchaseFormik.handleBlur}
                                value={
                                  planPurchaseFormik.values.cardholder_name
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          size="small"
                          type="submit"
                          style={{
                            justifyContent: "center",
                            marginTop: "40px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                          className="mb-5 justify-content-center fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                        >
                          Submit
                        </Button>
                      </Form>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default Planpurches;
