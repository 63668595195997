import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, NavLink as NavLinkRRD, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import routes from "routes";
import logoa from "../../../assets/icons/websitelogo.svg";
import { useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SuperAdminSidebar = ({
  logo,
  isCollapse,
  toggleCollapse,
  sideOpen,
  setSideOpen,
}) => {
  const location = useLocation();
  const isMediumScreen = useMediaQuery("(max-width:991px)");
  const createLinks = () => {
    const filteredRoutes = routes.filter(
      (prop) =>
        (prop.name === "Dashboard" ||
          prop.name === "Plans" ||
          prop.name === "Admin" ||
          prop.name === "Mail Service") &&
        prop.layout === "/superadmin"
    );
    return filteredRoutes.map((prop, key) => {
      const path =
        prop.layout === "/superadmin" ? "/superadmin" : "/superadmin";
      const isActive = location.pathname === path + prop.path;
      return (
        <NavItem key={key}>
          <NavLink
            to={path + prop.path}
            tag={NavLinkRRD}
            style={{ justifyContent: isCollapse && "center", width: "100%" }}
            className="d-flex align-items-center"
            onClick={() => setSideOpen(false)}
          >
            <span
              style={{
                fontSize: isMediumScreen && "12px",
                marginRight: !isCollapse && "20px",
                marginLeft: !isCollapse && "10px",
              }}
            >
            {isActive ? (
                <img src={prop.icon2} width={isMediumScreen ? 15 : 20} />
              ) : (
                <img src={prop.icon} width={isMediumScreen ? 15 : 20} />
              )}
            </span>
            {!isCollapse && <>{prop.name}</>}
          </NavLink>
        </NavItem>
      );
    });
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideOpen && !!event.target.closest(".menub")) {
        setSideOpen(true);
      } else if (sideOpen && !event.target.closest(".collapse-sidebar")) {
        setSideOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sideOpen]);
  return (
    <>
      <div
        className={
          isMediumScreen
            ? "collapse-sidebar stylemobileres"
            : !isCollapse
            ? "sidebar"
            : "sidebar-active"
        }
        style={{
          display: !sideOpen && isMediumScreen && "none",
          borderTopRightRadius: isMediumScreen && "20px",
          borderBottomRightRadius: isMediumScreen && "20px",
        }}
      >
        <Nav vertical>
          <Link
            to="/superadmin/dashboard"
            style={{
              marginTop: "40px",
              marginBottom: "30px",
              justifyContent: isCollapse || (isMediumScreen && "center"),
              padding: "15px",
            }}
          >
            {isCollapse ? (
              <img src={logo.imgSrc2} width={60} />
            ) : (
              <img src={logoa} width={!isMediumScreen ? 250 : "100%"} />
            )}
          </Link>
          {createLinks()}
        </Nav>
        <span
          className={isCollapse ? "collapse-btn-active" : "collapse-btn"}
          style={{ display: isMediumScreen && "none" }}
          onClick={(e) => {
            toggleCollapse();
          }}
        >
          {isCollapse ? (
            <FontAwesomeIcon icon={faArrowRight} />
          ) : (
            <FontAwesomeIcon icon={faArrowLeft} />
          )}
        </span>
      </div>
      {isMediumScreen && (
        <CloseIcon className="closebtnstylesidebar"
          style={{
            width: 30,
            color: "#000",
            display: !sideOpen && "none",
            position: "absolute",
            top: "10px",
            left: "65%",
            zIndex: 9999,
            border: "1px solid #333",
            padding: "3px",
            borderRadius: "50%",
            backgroundColor: "#fff",
          }}
          onClick={() => {
            setSideOpen(false);
          }}
        />
      )}
    </>
  );
};

export default SuperAdminSidebar;
